import common from '@/base/common.js'
import http from '@/utils/require.js'

const base = process.env.NODE_ENV == 'production' ? '/apis-app/' : '/apis-app/'
const rbacUrl = process.env.NODE_ENV == 'production' ? '/apis-rbac/' : '/apis-rbac/'
const portalUrl = process.env.NODE_ENV == 'production' ? '/apis-portal/' : '/apis-portal/'
const baseUrl = process.env.NODE_ENV == 'production' ? '/apis-authz' : '/apis-authz'
const baseUrl2 = process.env.NODE_ENV != 'development' ? common.certifiedJkmc : ''

/****************************应用管理****************************************/

export const appSidebar = (params) =>
  http({
    method: 'get',
    url: `${portalUrl}app/tab`,
    params,
  })

// 文件服务：根据path批量查询文件信息
export const fileGetByPathList = (data) =>
  http({
    method: 'post',
    url: `/apis-third/file/listByPath`,
    data,
  })

//查询当前登录用户认证信息
export const getUserQueryInfo = (data) =>
  http({
    method: 'post',
    url: `${baseUrl}/auth/user/queryInfo`,
    data,
  })

//查询用户所有登录方式
export const userQueryAllAccount = (data) =>
  http({
    method: 'post',
    url: `${baseUrl}/auth/user/queryAllAccount`,
    data,
  })

export const getUserRoleInfo = (params) =>
  http({
    method: 'get',
    url: `${baseUrl2}apis-psychology/common/userRoleInfo`,
    params,
  })
export const queryClassTeacherInfo = (params) =>
  http({
    method: 'get',
    url: `${baseUrl2}apis-psychology/common/queryClassTeacherInfo`,
    params,
  })
export const queryAppointCount = (params) =>
  http({
    method: 'get',
    url: `${baseUrl2}apis-psychology/index/queryAppointCount`,
    params,
  })

// 获取角色权限应用
export const rolePermissionAppInfo = (data) =>
  http({
    method: 'post',
    url: `${base}app/account/app`,
    data,
  })

//获取我的收藏
export const getMyCollections = (params) =>
  http({
    method: 'get',
    url: `${base}app/collect/my`,
    params,
  })

//查询所有应用分类
export const queryAppCategoryDict = (params) =>
  http({
    method: 'get',
    url: `/apis-portal/app/category`,
    params,
  })

export const saveCollect = (data) =>
  http({
    method: 'post',
    url: `/apis-app/app/collect/save`,
    data,
  })


//授权中的应用列表（包含授权成功的应用,授权失败的应用,授权审核中的应用,通过授权角色获得使用权的应用）

export const getAppAuthorizing = (data) =>
  http({
    method: 'post',
    url: `/apis-portal/app/authorizing`,
    data,
  })
