/**
 * vuex的状态
 * 注意：
 *  1、每个状态必须有注释
 *  2、不要把状态都放到vuex里管理，有需要的才放；
 */

export default {
  permissionList: JSON.parse(localStorage.getItem('permissionList')) || [],
  title: '',
  params: '',
  getMenuItem: [],
  personalInfo: {}
};
