//权限控制
import Vue from 'vue';
import store from '@/store/index';

//重置权限数据格式
function getPermissionList() {
  let permissionList = store.getters['getPermissions'];
  permissionList = permissionList.map(({ path, perms }) => {
    perms.includes(',') ? (perms = perms.split(',')) : (perms = [perms]);
    return {
      path,
      perms,
    };
  });
  return permissionList;
}
function getCurrentRouteInfo() {
  let currentRoutePath = window.location.pathname;
  if (currentRoutePath.includes('dsp')) {
    currentRoutePath = currentRoutePath.slice(4);
  }
  let permissionList = getPermissionList();
  if (permissionList.length === 1) {
    const { path, perms } = permissionList[0];
    if (path == '*' && perms == '*') {
      return [{ path, perms }];
    }
  }
  let currentRouteData = permissionList.filter(({ path, perms }) => {
    return currentRoutePath == path;
  });

  return currentRouteData ? currentRouteData : [];
}
Vue.directive('permission', {
  inserted(el, { value }) {
    const [currentRoute = {}] = getCurrentRouteInfo();
    const { perms = [] } = currentRoute;
    if (!perms.includes(value) && value !== '' && !perms.includes('*')) {
      el.parentNode.removeChild(el);
    }
  },
});

Vue.prototype.$permission = function (value) {
  const [currentRoute = {}] = getCurrentRouteInfo();
  const { perms = [] } = currentRoute;
  if (perms.length && perms.includes('*')) {
    return true;
  }
  if (perms.length && perms.includes(value)) {
    return true;
  }

  return false;
};
