import user from './user'
import app from './app'
import rbacStudentTree from './rbacStudentTree'
import orgAndUser from './orgAndUser'
import auth from './auth'
import appApis from './appApis'
import base from './base'
import student from './student'
import homeVisitRecord from './homeVisitRecord'

let api = Object.assign(user, app, rbacStudentTree, orgAndUser, auth, appApis, base, student, homeVisitRecord)

export default Object.assign(api, {})
