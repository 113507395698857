import axios from '@/utils/require.js'

const base = process.env.NODE_ENV == 'production' ? '/apis-authz/' : '/apis-authz/'

export default {
  // 查询登录时间
  getLoginTimeInfo(data) {
    return axios({
      url: `${base}auth/user/login/info`,
      method: 'post',
      data,
    })
  },
  // 根据登录手机号查询教职工数据 v1.8
  UserQueryTeacher(params) {
    return axios({
      url: `${base}auth/user/queryTeacher`,
      method: 'get',
      params,
    })
  },
  // 根据姓名id绑定教职工 v1.8
  userBindTeacherName(data) {
    return axios({
      url: `${base}auth/user/bind/teacher/name`,
      method: 'post',
      data,
    })
  },
  // 根据账号密码绑定孩子信息 v1.8
  userBindChildAccount(data) {
    return axios({
      url: `${base}auth/user/bind/child/account`,
      method: 'post',
      data,
    })
  },
  // 根据账号密码绑定学生 v1.8
  userBindStudentAccount(data) {
    return axios({
      url: `${base}auth/user/bind/student/account`,
      method: 'post',
      data,
    })
  },
  // 根据账号密码绑定教职工 v1.8
  userBindTeacherAccount(data) {
    return axios({
      url: `${base}auth/user/bind/teacher/account`,
      method: 'post',
      data,
    })
  },
  //切换身份
  authzLoginRunAs(data) {
    return axios({
      url: `${base}authz/login/runAs`,
      method: 'post',
      data,
    })
  },
  //注册用户
  userRegister(data) {
    return axios({
      url: `${base}auth/user/register`,
      method: 'post',
      data,
    })
  },
  //查询用户所有登录方式
  userQueryAllAccount(data) {
    return axios({
      url: `${base}auth/user/queryAllAccount`,
      method: 'post',
      data,
    })
  },
  //更新用户认证信息
  userUpdateInfo(data) {
    return axios({
      url: `${base}auth/user/updateInfo`,
      method: 'post',
      data,
    })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err
      })
  },
  // 根据登录信息补充信息并绑定用户
  checkAndBindUser(data) {
    return axios({
      url: `${base}auth/user/checkAndBindUser`,
      method: 'post',
      data,
    })
  },
  //修改密码
  updatePassword(data) {
    return axios({
      url: `${base}auth/user/updatePassword`,
      method: 'post',
      data,
    })
  },

  //查询当前登录用户认证信息
  getUserQueryInfo(data) {
    return axios({
      url: `${base}auth/user/queryInfo`,
      method: 'post',
      data,
    })
  },

  //钉钉扫码登录
  loginDingtalkScancode(data) {
    return axios({
      url: `${base}login/dingtalk/scancode`,
      method: 'post',
      data,
    })
  },

  //用户二次认证发送验证码
  sendPhoneCodeOfLoginConfirm(data) {
    return axios({
      url: `${base}auth/user/forgetPassword/sendPhoneCodeOfLoginConfirm`,
      method: 'post',
      data,
    })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err
      })
  },
  //用户二次认证
  multiVerify(data) {
    return axios({
      url: `${base}auth/user/forgetPassword/multiVerify`,
      method: 'post',
      data,
    })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err
      })
  },
  //用户登陆
  userLoginJwt(data) {
    return axios({
      url: `${base}authz/login/jwt?sessionKey=${data.sessionKey}`,
      method: 'post',
      data,
    })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err
      })
  },
  //用户退出
  userCasLogout(params) {
    return axios({
      url: `${base}authz/casLogout`,
      method: 'get',
      params,
    }).then((data) => {
      return data
    })
  },
  userLogout(params) {
    return axios({
      url: `${base}authz/logout`,
      method: 'get',
      params,
    }).then((data) => {
      return data
    })
  },
  // // 获取用户身份
  // queryIdentityDeptByUser(params) {
  //   return axios({
  //     url: `${base}auth/user/identity/list`,
  //     method: 'get',
  //     params
  //   })
  // },
  //钉钉扫码登录
  // loginDingtalkScancode(payload) {
  //   return httpRequest.post(`${baseUrl}/login/dingtalk/scancode`,payload).then((data) => {
  //     return data
  //   })
  // },
  //查询用户所有登录方式
  // userQueryAllAccount(payload) {
  //   return httpRequest.post(`${baseUrl}/auth/user/queryAllAccount`,payload).then((data) => {
  //     return data
  //   })
  // },
  //更新用户认证信息
  // userUpdateInfo(payload) {
  //   return httpRequest.post(`${baseUrl}/auth/user/updateInfo`,payload).then((data) => {
  //     return data
  //   })
  // },
  //查询当前登录用户认证信息
  // getUserQueryInfo(payload) {
  //   return httpRequest.post(`${baseUrl}/auth/user/queryInfo`,payload).then((data) => {
  //     return data
  //   })
  // },
  //查询用户的所有身份
  // getQueryAllIdentity(payload) {
  //   return httpRequest.post(`${baseUrl}/auth/user/queryAllIdentity`,payload).then((data) => {
  //     return data
  //   })
  // },
  //修改密码
  // updatePassword(payload) {
  //   return httpRequest.post(`${baseUrl}/auth/user/updatePassword`,payload).then((data) => {
  //     return data
  //   })
  // },
  //  强制修改密码
  updateDefultPassword(data) {
    return axios({
      url: `${base}auth/user/forceUpdatePassword`,
      method: 'post',
      data,
    }).then((data) => {
      return data
    })
  },
  //  查询密码是否超过提醒时间
  checkPasswordExpire(data) {
    return axios({
      url: `${base}policy/expire`,
      method: 'post',
      data,
    })
  },
  //  当天不再提醒
  updateTodayHint(data) {
    return axios({
      url: `${base}policy/hint/update`,
      method: 'post',
      data,
    })
  },

  //  获取图片验证码
  getCaptchaNew(data) {
    return axios({
      url: `${base}auth/user/forgetPassword/generateCaptcha`,
      method: 'post',
      data,
    })
  },

  //  删除钉钉信息
  delDingInfo(data) {
    return axios({
      url: `${base}auth/user/unbind/dingtalk?account=${data.account}`,
      method: 'get',
      data,
    })
  },
}
