import { getToken, setToken, setUserLogin, setJSESSIONID, getBaseUrl } from '@/utils/auth.js'
import store from '@/store/index'
import { Message, MessageBox } from 'element-ui'
import { whiteRoutes } from '@/whiteRoutes'

const getHrefParam = function (name) {
  var reg = new RegExp('(^|)' + name + '=([^&]*)(&|$)')
  var r = window.location.href.substr(1).match(reg)
  var strValue = ''
  if (r != null) {
    strValue = unescape(r[2])
  }
  return strValue
}

export const toAuth = async (to, from, next) => {
  let token = getToken()
  let jsessionid = getHrefParam('token')
  if (jsessionid) {
    setJSESSIONID(jsessionid)
    token = jsessionid
    setToken(token)
  }
  if (!token) {
    window.location.href =
      (process.env.NODE_ENV == 'development' ? process.env.VUE_APP_AXIOS_BASEURL : getBaseUrl() + '/api/') +
      '/apis-authz/authz/login/cas?target=' +
      window.location.origin + '/jdp/data/home'
  } else {
    WAPI.getUserQueryInfo({}).then(async (res) => {
      setUserLogin(res.data); //设置用户信息
      if ([2, 3].includes(res.data.identityId)) {
        // 【1: 教职工，2: 学生，3: 家长，6: 管理员】
        next('/noPermission');
      } else {
        if (res.data.defaultPass) {
          next('/accountCenter')
        } else {

          next()
        }
      }
    });
  }
}
export const toCheckPermission = async (to, from, next) => {
  let currentPath = to.path
  // console.log(currentPath)
  //当前所有权限列表，缓存中读取/vuex里面读取
  let haveAuthPathList = []
  let whiteRoutesPathList = []
  let permissionList =
    JSON.parse(localStorage.getItem('permissionList')) || (await store.getters['getPermissions']) || []
  whiteRoutesPathList = whiteRoutes.map((item) => {
    return `/${item}`
  })
  if (whiteRoutesPathList.includes(currentPath)) {
    next()
    return false
  }
  const { path, perms } = permissionList[0]
  const flag = path == '*' && perms == '*'
  if (permissionList.length === 1 && flag) {
    next()
    return false
  } else {
    haveAuthPathList = permissionList.map(({ path }) => {
      return path
    })
    // if (!haveAuthPathList.includes(currentPath)) {
    //   Message.warning("暂无权限，请联系管理员！");
    //   next(false);
    //   return false;
    // }
  }
  return true
}
