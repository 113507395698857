<template>
  <div class="accountBox flex align_center" v-if="userInfo">
    <img class="pic" :src="pic" />
    <!-- <div class="username">
      <div class="el-dropdown-link flex">
        <label class="nickname" :title="userInfo.nickname ? userInfo.nickname : userInfo.account">{{
          userInfo.nickname ? userInfo.nickname : userInfo.account
        }}</label>
      </div>
    </div> -->

    <div class="username">
      <el-dropdown trigger="click">
        <div class="el-dropdown-link flex">
          <label class="nickname" :title="userInfo.nickname ? userInfo.nickname : '无'">
            {{ userInfo.nickname ? userInfo.nickname : '无' }}</label
          >
          <label class="icon">
            <i class="el-icon-caret-bottom el-icon--right"></i>
          </label>
        </div>
        <el-dropdown-menu slot="dropdown" class="dropdownAccountMenu">
          <div class="acountInfoBox">
            <el-dropdown-item>
              <div class="userInfo">
                <p>{{ currentIdentity.identityName ? currentIdentity.identityName : '无' }}</p>
                <p class="orgfont" :title="currentOrg.deptName ? currentOrg.deptName : '无'">
                  {{ currentOrg.deptName ? currentOrg.deptName : '无' }}
                </p>
              </div>
            </el-dropdown-item>
            <!-- <el-dropdown-item @click.native="$router.push('/portalSettings')" v-if="isAdmin"
                >门户设置</el-dropdown-item
              > -->
            <el-dropdown-item @click.native="$router.push('/accountCenter')">账号中心</el-dropdown-item>
            <!-- <el-dropdown-item @click.native="$router.push('/portalIndex/message')" style="position: relative">
                我的消息
                <div class="bot" v-if="infoCount && infoCount > 0">
                  <img src="@/assets/images/bot248.png" style="display: block" />
                </div>
              </el-dropdown-item> -->
            <!-- <el-dropdown-item class="change" v-if="roleList.length > 1">
                <div style="position: relative">
                  <div
                    @click.stop="
                      showOrg = false;
                      showIdentity = !showIdentity;
                    "
                  >
                    切换身份
                  </div>
                  <ul v-show="showIdentity" class="identityList">
                    <li v-for="(item, index) in roleList" :key="index" @click="changeRole(item)">
                      {{ item.identityName }}
                    </li>
                  </ul>
                </div>
              </el-dropdown-item> -->
            <!-- <el-dropdown-item class="change" v-if="orgList.length > 1">
                <div style="position: relative">
                  <div
                    @click.stop="
                      showIdentity = false;
                      showOrg = !showOrg;
                    "
                  >
                    切换机构
                  </div>
                  <ul v-show="showOrg" class="identityList">
                    <li v-for="(item, index) in orgList" :key="index" @click="changeOrg(item)" :title="item.deptName">
                      {{ item.deptName }}
                    </li>
                  </ul>
                </div>
              </el-dropdown-item> -->

            <el-dropdown-item @click.native="remove()" divided><span style="color: red">退出</span></el-dropdown-item>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import * as API from '@/api/app.js'
import { getUserLogin, getToken, setUserLogin } from '@/utils/auth'
export default {
  data () {
    return {
      userInfo: getUserLogin(),
      roleList: [],
      orgList: [],
      identityList: [],
      showIdentity: false,
      showOrg: false,
      pic: require('../../assets/image/defaultAvator.png'),
      currentOrg: {},
      currentIdentity: {},
      identityList: [],
      isAdmin: null,
    }
  },
  watch: {
    userInfo: {
      handler (nVal) {
        if (nVal) {
          this.$emit('updateInfo', nVal)
          if (nVal.avatar) {
            this.getByPath([nVal.avatar])
          }
          if (!nVal.account) {
            this.getuserQuery()
          }
        } else {
          this.getUserQueryInfo()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async created () {
    // this.initIdentityUser()
    this.init();
  },
  methods: {
    async queryIdentityUser () {
      await WAPI.queryIdentityDeptByUser({})
        .then((res) => {
          if (res.code == 200 && res.data.length > 0) {
            console.log('用户身份', res);
            this.identityList = res.data;
            this.$store.commit('SET_IDENTITY_LIST', res.data);
            this.$store.commit('SET_CURRENT_IDENTITY', res.data[0]);
            this.$store.commit(
              'SET_CURRENT_ORG',
              res.data[0].orgList && res.data[0].orgList.length > 0 ? res.data[0].orgList[0] : {},
            );
          }
        })
        .catch((err) => {
          console.log('@@@' + err);
          Message.error('获取用户身份失败！');
        });
    },
    async init () {
      this.identityList = this.$store.state.User.identityList;
      if (!this.identityList || this.identityList.length == 0) {
        await this.queryIdentityUser();
      }
      if (this.identityList.length > 0) {
        this.roleList = this.identityList.filter((e) => {
          return e.identityId != 4;
        });
        this.currentIdentity = this.$store.state.User.currentIdentity;
        this.orgList = this.currentIdentity ? this.currentIdentity.orgList : [];
        this.currentOrg = this.$store.state.User.currentOrg ? this.$store.state.User.currentOrg : {};
        // }
        // if(this.userInfo.avatarUrl){
        //   this.headImg = this.userInfo.avatarUrl
      }
      // console.log("存储的用户信息",identityList)
      // this.getInformPending();

      console.log(this.userInfo, '99999');
      this.getByPath([this.userInfo.avatar]);
      this.getIsAdmin();
    },
    getIsAdmin () {
      WAPI.judgeAdmin({}).then((res) => {
        if (res.code == 200) this.isAdmin = res.data;
      });
    },
    remove () {
      this.$store.commit('SET_USER_INFO', {});
      this.$store.commit('setIsOverdue', true);
      if (process.env.VUE_APP_IS_CERTIFICATION != 'false') {
        this.$store.dispatch('casLogin');
      }
    },
    // 初始化身份信息
    async initIdentityUser () {
      let userInfo = getUserLogin()
      if (!userInfo) {
        const result = await WAPI.getUserQueryInfo({})
        this.userInfo = result.data
        setUserLogin(result.data)
        this.$emit('updateInfo', result.data)
      }
    },
    async getUserQueryInfo () {
      let token = getToken()
      if (!token) return
      let userInfo = getUserLogin()
      if (userInfo) return
      const res = await WAPI.getUserQueryInfo({})
      this.userInfo = res.data
      setUserLogin(res.data)
      this.$emit('updateInfo', res.data)
    },
    async getuserQuery () {
      await API.userQueryAllAccount({}).then((data) => {
        let list = data.data
        let accObj = list.filter((item) => {
          return item.type == 'password'
        })
        this.$set(this.userInfo, 'account', accObj.length ? accObj[0].account : '') //账户赋值
      })
    },
    async getByPath (paths) {
      if (!paths.length) return
      let params = {
        paths,
        channel: 'OSS_MINIO',
      }
      let res = await API.fileGetByPathList(params)
      if (res.data && res.data.length > 0) {
        this.pic = res.data[0].base64 && res.data[0].base64.length > 0 ? res.data[0].base64 : res.data[0].url
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.accountBox {
  cursor: pointer;
  height: 100%;
  .pic {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 50%;
    display: block;
  }
  .username {
    margin-left: 12px;
    max-width: 120px;
    width: 100px;
    cursor: pointer;
    .el-dropdown {
      font-size: 16px !important;
      cursor: pointer;
      width: 100%;
      display: block;
    }
    .nickname {
      font-size: 16px;
      cursor: pointer;
      padding-right: 10px;
      display: inline-block;
      overflow: hidden; /*内容超出后隐藏*/
      text-overflow: ellipsis; /*超出部分以省略号显示*/
      white-space: nowrap; /*文本不进行换行*/
    }
    .btnBox {
      display: flex;
    }
  }
  .nickname {
    width: 100px;
    cursor: pointer;
    display: inline-block;
    overflow: hidden; /*内容超出后隐藏*/
    text-overflow: ellipsis; /*超出部分以省略号显示*/
    white-space: nowrap; /*文本不进行换行*/
  }
  .btnBox {
    display: flex;
  }
}
.change {
  // width: 100%;
  // height: 100%;#606266
  padding-right: 0;
  // &:hover{
  //     color: #606266 !important;
  //   }
  ul {
    margin-left: 10px;
    li {
      &:hover {
        color: #66b1ff !important;
      }
    }
  }
  .identityList {
    width: 150px;
    height: auto;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #666666;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 4px 16px rgba(95, 114, 146, 0.1);
    opacity: 1;
    border-radius: 4px;
    position: absolute;
    left: -160px;
    top: 0px;
  }
  .identityList li {
    padding: 0px 20px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .identityList li:hover {
    background-color: #f6f9ff;
  }
}
.accountInfoBox {
  /deep/ .el-dropdown-menu__item {
    padding: 0;
  }
  .dropdown-item {
    padding: 0 20px;
  }
}
</style>
<style>
.dropdownAccountMenu {
  padding: 0px !important;
  width: 180px !important;
}
/* .dropdownAccountMenu .el-popper .popper__arrow, .el-popper .popper__arrow::after{
  display: hidden !important;
} */
.dropdownAccountMenu .el-dropdown-menu__item:first-child {
  height: 77px;
  background-color: #edf3ff;
}
.dropdownAccountMenu .acountInfoBox .el-dropdown-menu__item {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #333333;
  white-space: nowrap;
}
.dropdownAccountMenu .userInfo {
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.dropdownAccountMenu .userInfo p {
  line-height: 20px;
}
.dropdownAccountMenu .acountInfoBox .el-dropdown-menu__item .orgfont {
  display: inline-block;
  width: 120px;
  font-size: 12px;
  color: #999999;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdownAccountMenu .el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #f6f9ff !important;
}
.dropdownAccountMenu .identityList {
  width: 150px;
  height: auto;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #666666;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 16px rgba(95, 114, 146, 0.1);
  opacity: 1;
  border-radius: 4px;
  position: absolute;
  left: -180px;
  top: 0px;
}
.dropdownAccountMenu .identityList li {
  padding: 0px 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdownAccountMenu .identityList li:hover {
  background-color: #f6f9ff;
}
.dropdownAccountMenu .bot {
  display: inline-block;
  position: absolute;
  top: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
</style>