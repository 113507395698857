<template>
  <div class="logoBox flex align_center">
    <!-- <img src="@/assets/image/logo2.png" class="logo" /> -->
    <div class="logoBox-title" :title="title">{{ title }}</div>
  </div>
</template>
<script>
export default {
  props: {
    // title: {
    //   type: String,
    //   default: '个人应用'
    // }
  },
  data () {
    return {
      logo: process.env.SYSTEM_LOGO,
    }
  },
  methods: {
    goBack () {
      window.history.back()
    },
  },
  computed: {
    title () {
      return this.$route.meta.title
    },
  },
}
</script>
<style lang="scss" scoped>
.logoBox {
  cursor: pointer;
  width: auto;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .logoBox-title {
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold;
    color: #333333;
    margin-left: 12px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .logo {
    width: 32px;
    height: 32px;
    object-fit: cover;
    vertical-align: middle;
  }
}
</style>
