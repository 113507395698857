import httpRequest from '@/utils/require.js'
const baseUrl = process.env.NODE_ENV == 'production' ? '/apis-rbac' : '/apis-rbac'

const baseUsersUrl = process.env.NODE_ENV == 'production' ? '/apis-user' : '/apis-user'

export default {
  // 获取年级下班级
  getRbacTreeClass(payload) {
    return httpRequest.get(`${baseUrl}/org/tree/class`, payload).then((data) => {
      return data
    })
  },
  // 获取班级学生树
  getRbacTreeStudent(payload) {
    return httpRequest.get(`${baseUrl}/org/tree/student`, payload).then((data) => {
      return data
    })
  },
  // 获取组织机构数（节点类型）
  getRbacTreeStudentType(payload) {
    return httpRequest.get(`${baseUrl}/org/tree/nodeTypes`, payload).then((data) => {
      return data
    })
  },
  // 添加组织机构节点
  addRbacTreeStudent(payload) {
    return httpRequest.post(`${baseUrl}/org/tree/add`, payload).then((data) => {
      return data
    })
  },
  // 树形结构数据（单个应用）
  getAppSetings(payload) {
    return httpRequest.get(`${baseUrl}/feature/tree?appId=${payload.app}&roleId=${payload.rol}`, {}).then((data) => {
      return data
    })
  },
  // 新增角色菜单权限
  addAppRoleSetings(payload) {
    return httpRequest.post(`${baseUrl}/role/perms/new`, payload).then((data) => {
      return data
    })
  },
  // 移除角色菜单权限
  delAppRoleSetings(payload) {
    return httpRequest.post(`${baseUrl}/role/perms/remove`, payload).then((data) => {
      return data
    })
  },
  // 查询已设置权限的应用
  searchAppRoleSetings(payload) {
    return httpRequest.post(`${baseUrl}/role/perms/permsAppCount`, payload).then((data) => {
      return data
    })
  },
  // 获取组织结构树节点
  getRbacTreeChildren(payload) {
    return httpRequest.post(`${baseUrl}/org/tree/children`, payload).then((data) => {
      return data
    })
  },

  // 导入学生基本信息数据读取
  studentReadExcel(payload) {
    return httpRequest.upload(`${baseUsersUrl}/xsjbsjzlb/readExcel`, payload, 'upload').then((data) => {
      return data
    })
  },

  // 导入班主任教师信息
  teacherClassReadExcel(payload) {
    return httpRequest.upload(`${baseUsersUrl}/classTeacher/readExcel`, payload, 'upload').then((data) => {
      return data
    })
  },

  // 导入数据-验证数据
  studentCheckReadData({ orgId, data }) {
    return httpRequest.post(`${baseUsersUrl}/xsjbsjzlb/checkReadData?orgId=${orgId}`, data).then((data) => {
      return data
    })
  },

  // 导入数据-保存
  studentSaveReadData({ orgId, data }) {
    return httpRequest.post(`${baseUsersUrl}/xsjbsjzlb/saveReadData?orgId=${orgId}`, data).then((data) => {
      return data
    })
  },

  // 导入数据-验证数据
  teacherCheckReadData({ xxId, data }) {
    return httpRequest.post(`${baseUsersUrl}/classTeacher/checkReadData?xxId=${xxId}`, data).then((data) => {
      return data
    })
  },

  // 导入数据-保存
  teacherSaveReadData({ xxId, data }) {
    return httpRequest.post(`${baseUsersUrl}/classTeacher/saveReadData?xxId=${xxId}`, data).then((data) => {
      return data
    })
  },

  // 导入班级分班模板 xls 的文件读取
  classDivideReadExcel(payload) {
    return httpRequest.upload(`${baseUsersUrl}/xsjbsjzlb/ImportInXsReadExcel`, payload, 'upload').then((data) => {
      return data
    })
  },

  // 班级分配导入数据-验证数据
  classStudentCheckReadData({ njId, bjId, data }) {
    return httpRequest
      .post(`${baseUsersUrl}/xsjbsjzlb/ImportInXsCheckReadData?njId=${njId}&bjId=${bjId}`, data)
      .then((data) => {
        return data
      })
  },

  // 班级分配导入数据-保存
  classStudentSaveReadData({ njId, bjId, data }) {
    return httpRequest
      .post(`${baseUsersUrl}/xsjbsjzlb/ImportInXsSaveReadData?njId=${njId}&bjId=${bjId}`, data)
      .then((data) => {
        return data
      })
  },

  // 班级分配导入数据-保存
  exportStudentsCpColumn(payload) {
    return httpRequest.post(`${baseUsersUrl}/account/studentPwd/column`, payload).then((data) => {
      return data
    })
  },
  // 获取角色权限
  getRolePermissionInfo(data) {
    return httpRequest.get(`${baseUrl}/user/identity/role/permission`, data).then((data) => {
      return data
    })
  },

  // 获取角色权限
  roleOrgIsOrgAdmin(data) {
    return httpRequest.get(`${baseUrl}/role/org/user/isOrgAdmin`, data).then((data) => {
      return data
    })
  },
}
