/**
 * 项目特有的标记
 */
import cn from '../cn.js';
export default Object.assign(cn,{
  Chinese:  "中文",
  PROJECT_NAME:"东阳管理系统",
  PROJECT_NAME_SHORT: "东阳管理系统",//简写 因为有些页面展示的很窄
  PROJECT_LOGO: '',//LOGO
  PROJECT_LOGO_WAP: '',//LOGO
  PROJECT_DOMAIN: '',//如果配置中性会有域名
  WECHAT_PUBLIC_CODE: '',//微信公众号
  WECHAT_APPLET_CODE: '',//微信小程序二维码
  MOBILE_LOOK_OVER: '',//手机端页面----"我的设备"查看
  WECHAT_BINGDING_PUBLIC_IMG_DESC: '扫描二维码关注公众平台',
  wechatBingdingGenerateCodeDesc: '关注公众平台后扫描二维码可直接绑定微信',
  USR_ClOUD_SYSTEM_THREE_MAIN_FUNCTIONS: '透传云三种使用方式',
  CLOUD_CONFIGURATION_INTRUDUCTION:'透传云像组态软件一样，添加设备和关联数据点后，立刻实现设备远程监控项目。',
  CLOUD_INTERFACE_INTRUDUCTION:'用户使用平台提供的SDK二次开发的软件接入云，可对设备数据自定义控制和查询。',
  ClOUD_SWITCH_INTRUDUCTION:'使用透传组+虚拟串口软件，解决串口软件（如组态）如何接入远端串口设备的问题。',
  RECEIVING_ALARM_PUSH_USING_WECHAT_SMALL_PROGRAM_CONTROL:'接收报警推送，使用微信小程序控制',
  HOME_PAGE_MOBILE_TERMINAL_INTRUDUCTION:'扫描二维码直接进入小程序（需微信6.5.7版本以上）',
  wechatBingdingTip: '',
  USR_ClOUD_VIDEOS: '透传云视频',
  'SYSTEM_SETTING_NOTES1':'注：本功能仅支持在阿里云备案的域名。',
  'SYSTEM_SETTING_NOTES2':'本功能实现管理后台完全中性自定义。',
  'SYSTEM_SETTING_NOTES3':'支持自定义域名、系统名称、公司名称、logo等信息自定义修改，轻松实现中性管理后台。',
  'SYSTEM_SETTING_NOTES4':'注：本功能需要申请开通后可用。',
  'DOMAIN_NAME_RESOLUTION_TIPS':'中性域名必须以console开头，支持二级和三级域名。必须将域名CNAME解析到“cname.usr.cn”后方能使用。',
  'FOR_EXAMPLE':'举例：',
  'DOMAIN_NAME_RESOLUTION_EXAMPLE':'用户域名test.com，将console.abc.test.com解析到cname.usr.cn，以阿里云域名解析为例，',
  'DOMAIN_NAME_RESOLUTION_NOTES1':'1、进入到阿里云域名管理页面，点击“解析设置”进入域名解析。',
  'DOMAIN_NAME_RESOLUTION_NOTES2':'2、点击“添加解析”，进入添加界面。',
  'DOMAIN_NAME_RESOLUTION_NOTES3':'3、在添加解析界面，记录类型选择“CNAME”,主机记录填写“console.abc”，记录值填写“cname.usr.cn”。',
  'DOMAIN_NAME_RESOLUTION_NOTES4':'4、点击“确定”后，等待域名解析成功即可通过域名访问本平台。',
  'USR_CLOUD_VIDEO1':'透传云视频教程一',
  'USR_CLOUD_VIDEO2':'透传云视频教程二',
  domainInfo: "当前暂无中性信息，点击确认前往设置",
  testAccountInfo: "您当前处于测试账号，只有查看权限，更多功能请注册账号进行体验",
  'ANNOUNCEMENT_TITLE':'关于系统维护升级的公告',
  'ANNOUNCEMENT_P_TITLE':'尊敬的用户：',
  'ANNOUNCEMENT_P_1':'    我司将于2019年5月7日08:30至2019年5月7日10:30暂停云平台服务，进行系统升级，给您带来的不便深感抱歉。',
  'ANNOUNCEMENT_P_2':'    以下是V2.7.0版本更新内容，敬请期待',
  'ANNOUNCEMENT_P_3':'  1. 增加了数据模板导入导出功能;',
  'ANNOUNCEMENT_P_4':'  2. 修复了已知的bug，优化了用户体验;',
  'ANNOUNCEMENT_P_5':'  3. 进行了性能优化，极大提高了负载能力和设备接入能力;',
  'ANNOUNCEMENT_P_END':'2019年5月6日',
  'ANNOUNCEMENT_A_GO_NEW_ADDRESS':'进入新版本',
  have_not_device_yet: "您当前还未添加设备，请前往添加",
  User_Agreement:"<<透传云用户使用协议>>",
  User_Agreement_address:"./static/htmls/protocol.html",
  Neutral_Introduced:"透传云中性运行",
  goToPlcnetForNavbar: "新版云组态",
  goToPlcnetForNotice_1: "全新云组态上线，全面取代透传云，未来透传云仅保持维护，",
  goToPlcnetForNotice_2: "点击前往云组态",
})
