/**
 * menuArr 格式说明
1. name: 路由名
2. list: 子菜单列表
   index: 默认打开的子菜单索引
   route: 子菜单的文件路由地址
   includesRoute: 包含该路由的子菜单索引列表
   label: 子菜单名
 */

export const menuArr = [
  {
    name: ['reminderConfiguration', 'reminderConfigurationManagement'],
    list: [
      {
        label: '提醒模型配置',
        key: 0,
        route: '/data/reminderConfiguration',
      },
      {
        label: '提醒模型修改日志',
        key: 1,
        route: '/data/reminderConfigurationManagement',
      },
    ],
  },
  {
    name: ['groupRecord', 'groupApply'],
    list: [
      {
        label: '团辅管理',
        key: 0,
        route: '/data/groupApply',
      },
      {
        label: '团辅记录',
        key: 1,
        route: '/data/groupRecord',
      },
    ],
  },
  {
    name: ['scaleMeasurement', 'scaleResult', 'otherEvaluation', 'otherScaleResult', 'systemScale'],
    index: 0,
    list: [
      {
        label: '量表测评',
        key: 0,
        route: '/data/scaleMeasurement',
        includesRoute: ['/data/scaleResult'],
      },
      {
        label: '他评',
        key: 1,
        route: '/data/otherEvaluation',
        includesRoute: ['/data/otherScaleResult'],
      },
      {
        label: '量表中心',
        key: 2,
        route: '/data/systemScale',
      },
    ],
  },
  // {
  //   name: ['Home', 'report'],
  //   list: [
  //     {
  //       label: '数据概览',
  //       key: 0,
  //       route: '/data/home',
  //     },
  //     {
  //       label: '数据报告',
  //       key: 1,
  //       route: '/data/report',
  //     },
  //   ],
  // },

  {
    name: ['scaleMeasurementTeacher', 'scaleResultTeacher', 'systemScaleTeacher'],
    list: [
      {
        label: '量表测评',
        key: 0,
        route: '/data/scaleMeasurementTeacher',
      },
      {
        label: '量表中心',
        key: 2,
        route: '/data/systemScaleTeacher',
      },
    ],
  },
  {
    name: ['AIScaleMeasurement', 'AIScaleResult', 'AISystemScale', 'AIReport'],
    list: [
      {
        label: '访谈测评',
        key: 0,
        route: '/data/AIScaleMeasurement',
      },
      {
        label: 'AI测评中心',
        key: 1,
        route: '/data/AISystemScale',
      },
      {
        label: 'AI心情树洞',
        key: 2,
        route: '/data/AIReport',
      },
    ],
  },
  {
    name: ['securityWarning', 'trajectoryQuery'],
    list: [
      {
        label: '行为告警',
        key: 0,
        route: '/data/securityWarning',
      },
      {
        label: '轨迹查询',
        key: 1,
        route: '/data/trajectoryQuery',
      },
    ],
  },
  // {
  //   name: ['AppointmentRecordQuery', 'SchedulingManage','PsychologistManage'],
  //   index:2,
  //   list: [
  //     {
  //       label: '咨询预约',
  //       key: 0,
  //       route: '/data/appointmentRecordQuery',
  //     },
  //     {
  //       label: '排班管理',
  //       key: 1,
  //       route: '/data/schedulingManage',
  //     },
  //     {
  //       label: '咨询师管理',
  //       key: 2,
  //       route: '/data/psychologistManage',
  //     },
  //   ],
  // },
]
