export default {
  UPDATE_PERMISSION(state) {
    state.permissionList = JSON.parse(localStorage.getItem('permissionList'));
  },

  setTitle(state, data) {
    state.title = data;
  },
  setParams(state, params) {
    state.params = params
  },
  setMenuItem(state, res) {
    state.getMenuItem = res
  },
  setPersonInfo(state, payload) {
    state.personalInfo = payload
  }
}
