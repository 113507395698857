import httpRequest from '@/utils/require.js'
import http from '@/utils/require.js'

const baseUrl = process.env.NODE_ENV == 'production' ? '/apis-user/' : '/apis-user/'

const rbacUrl = process.env.NODE_ENV == 'production' ? '/apis-rbac/' : '/apis-rbac/'

const authzUrl = process.env.NODE_ENV == 'production' ? '/apis-authz/' : '/apis-authz/'

const checkUrl = process.env.NODE_ENV == 'production' ? '/apis-checkoff/' : '/apis-checkoff/'
const psyUrl = process.env.NODE_ENV == 'production' ? '/apis-psychology/' : '/apis-psychology/'
export default {
  //查询本校已经升学（v1.9.0）
  getUpgradeInfoByXxdm(payload) {
    return httpRequest.post(`${baseUrl}xxjbsjlb/getUpgradeInfoByXxdm`, payload).then((data) => {
      return data
    })
  },
  //查询本校未升学（v1.9.0）
  getUnUpgradeInfoByXxdm(payload) {
    return httpRequest.post(`${baseUrl}xxjbsjlb/getUnUpgradeInfoByXxdm`, payload).then((data) => {
      return data
    })
  },
  //查询已完成升学学校（v1.9.0）
  getUpgradeCompletePage(payload) {
    return httpRequest.post(`${baseUrl}xxjbsjlb/getUpgradeCompletePage`, payload).then((data) => {
      return data
    })
  },
  //查询未完成升学学校（v1.9.0）
  getUpgradeInCompletePage(payload) {
    return httpRequest.post(`${baseUrl}xxjbsjlb/getUpgradeInCompletePage`, payload).then((data) => {
      return data
    })
  },
  // 学校查询未毕业年级（v1.9.0）
  xqjbsjzlbGetUnGraduateXqPage(payload) {
    return httpRequest.post(`${baseUrl}xqjbsjzlb/getUnGraduateXqPage`, payload).then((data) => {
      return data
    })
  },
  // 学校查询已经毕业年级（v1.9.0）
  xqjbsjzlbGetGraduateXqPage(payload) {
    return httpRequest.post(`${baseUrl}xqjbsjzlb/getGraduateXqPage`, payload).then((data) => {
      return data
    })
  },
  // 教育局查询未完成毕业学校（v1.9.0）
  xqjbsjzlbGetGraduateInCompletePage(payload) {
    return httpRequest.post(`${baseUrl}xqjbsjzlb/getGraduateInCompletePage`, payload).then((data) => {
      return data
    })
  },
  // 教育局查询已完成毕业学校（v1.9.0）
  xqjbsjzlbGetGraduateCompletePage(payload) {
    return httpRequest.post(`${baseUrl}xqjbsjzlb/getGraduateCompletePage`, payload).then((data) => {
      return data
    })
  },
  // 撤销升级
  njsjzlbUnupgrade(payload) {
    return httpRequest.post(`${baseUrl}njsjzlb/unupgrade`, payload).then((data) => {
      return data
    })
  },
  // 一键撤销升级
  njsjzlbOneKeyUnupgrade(payload) {
    return httpRequest.post(`${baseUrl}njsjzlb/oneKeyUnupgrade?xqId=${payload}`, {}).then((data) => {
      return data
    })
  },
  // 撤销毕业
  xsjbsjzlbUngraduateStudents(payload) {
    return httpRequest.post(`${baseUrl}xsjbsjzlb/ungraduateStudents`, payload).then((data) => {
      return data
    })
  },

  // 教职工数据核对列表统计
  checkListAdminTeacherCount(payload) {
    return httpRequest.post(`${checkUrl}check/list/admin/teacher/count`, payload).then((data) => {
      return data
    })
  },
  // 教职工数据核对任务列表
  checkPlanTaskListOrg(payload) {
    return httpRequest.get(`${checkUrl}check/plan/task/list/org`, payload).then((data) => {
      return data
    })
  },
  // 获取账号信息
  userInfoDetail(payload) {
    return httpRequest.get(`${baseUrl}user/info/detail`, payload).then((data) => {
      return data
    })
  },
  // 删除教职工病休信息（v1.7.0）
  jzgjbsjzlbIllnessRemove(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/illness/remove`, payload).then((data) => {
      return data
    })
  },
  // 编辑教职工病休信息（v1.7.0）
  jzgjbsjzlbIllnessRenew(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/illness/renew`, payload).then((data) => {
      return data
    })
  },
  // 新增教职工病休信息（v1.7.0）
  jzgjbsjzlbIllnessNew(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/illness/new`, payload).then((data) => {
      return data
    })
  },
  // 获取教职工病休信息（v1.7.0）
  jzgjbsjzlbIllnessList(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/illness/list`, payload).then((data) => {
      return data
    })
  },
  // 删除教职工编制异动信息（v1.7.0）
  jzgjbsjzlbBzydRemove(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/bzyd/remove`, payload).then((data) => {
      return data
    })
  },
  // 编辑教职工编制异动信息（v1.7.0）
  jzgjbsjzlbBzydRenew(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/bzyd/renew`, payload).then((data) => {
      return data
    })
  },
  // 新增教职工编制异动信息（v1.7.0）
  jzgjbsjzlbBzydNew(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/bzyd/new`, payload).then((data) => {
      return data
    })
  },
  // 获取教职工编制异动信息（v1.7.0）
  jzgjbsjzlbBzydList(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/bzyd/list`, payload).then((data) => {
      return data
    })
  },
  // 编辑教职工离岗信息（v1.7.0）
  LeaveOfficeRenew(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/leave/office/renew`, payload).then((data) => {
      return data
    })
  },
  // 删除教职工离岗信息（v1.7.0）
  leaveOfficeRemove(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/leave/office/remove`, payload).then((data) => {
      return data
    })
  },
  // 新增教职工离岗信息（v1.7.0）
  leaveOfficeNew(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/leave/office/new`, payload).then((data) => {
      return data
    })
  },
  // 获取教职工离岗信息（v1.7.0）
  leaveOfficeInfo(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/leave/office/info`, payload).then((data) => {
      return data
    })
  },
  // 获取学生List
  getOrgTreeStudentPageNewBjXs(payload) {
    return httpRequest.post(`${baseUrl}xsjbsjzlb/getOrgTreeStudentPageNewBjXs`, payload).then((data) => {
      return data
    })
  },
  // 查询分区序号（v1.5）
  dwfqjbsjzlbXh(payload) {
    return httpRequest.get(`${baseUrl}dwfqjbsjzlb/xh`, payload).then((data) => {
      return data
    })
  },
  // 查询分区信息（v1.5）
  dwfqjbsjzlbInfo(payload) {
    return httpRequest.get(`${baseUrl}dwfqjbsjzlb/info`, payload).then((data) => {
      return data
    })
  },
  // 删除分区（v1.5）
  dwfqjbsjzlbDelete(payload) {
    return httpRequest.delete(`${baseUrl}dwfqjbsjzlb/delete`, payload).then((data) => {
      return data
    })
  },
  // 编辑分区（v1.5）
  dwfqjbsjzlbRenew(payload) {
    return httpRequest.post(`${baseUrl}dwfqjbsjzlb/renew`, payload).then((data) => {
      return data
    })
  },
  // 创建分区（v1.5）
  dwfqjbsjzlbNew(payload) {
    return httpRequest.post(`${baseUrl}dwfqjbsjzlb/new`, payload).then((data) => {
      return data
    })
  },
  // 组织机构管理导出（v1.5）
  orgEntityDataExport(payload) {
    return httpRequest.post(`${baseUrl}org/entity/data/export`, payload).then((data) => {
      return data
    })
  },
  // 导出字段列表（v1.5）
  excelExportColumn(payload) {
    return httpRequest.get(`${baseUrl}excel/export/column`, payload).then((data) => {
      return data
    })
  },
  // 组织机构管理：查询列表（v1.5）
  orgEntityDataList(payload) {
    return httpRequest.post(`${baseUrl}org/entity/data/list`, payload).then((data) => {
      return data
    })
  },
  // 数据信息核对进度：获取当前登录机构
  checkOffCheckOrg(payload) {
    return httpRequest.post(`${checkUrl}check/org`, payload).then((data) => {
      return data
    })
  },
  // 数据信息核对进度：教师类别（v1.4.1）
  checkOffConfigTask(payload) {
    return httpRequest.get(`${checkUrl}check/config/task`, payload).then((data) => {
      return data
    })
  },
  // 数据信息核对进度：机构统计（v1.4.1）
  checkOffStatisticsOrgList(payload) {
    return httpRequest.get(`${checkUrl}check/statistics/org/list`, payload).then((data) => {
      return data
    })
  },
  // 数据信息核对进度：学段列表（v1.4.1）
  checkOffConfigOrgList(payload) {
    return httpRequest.get(`${checkUrl}check/item/config/org/list`, payload).then((data) => {
      return data
    })
  },
  // 数据信息核对进度：学校列表（v1.4.1）
  checkOffConfigSchoolList(payload) {
    return httpRequest.get(`${checkUrl}task/deliver/stat/org/log/jg/list`, payload).then((data) => {
      return data
    })
  },
  // 班级学生毕业升级保存（v1.4.1）
  userGraduateSave(payload) {
    return httpRequest.post(`${baseUrl}graduate/save`, payload).then((data) => {
      return data
    })
  },
  // 班级学生毕业升级获取日期（v1.4.1）
  userGraduateGet(payload) {
    return httpRequest.get(`${baseUrl}graduate/get`, payload).then((data) => {
      return data
    })
  },

  // 获取学校明细时，同步数据
  updateSchoolList(payload) {
    return httpRequest.post(`${checkUrl}task/deliver/stat/sync/user`, payload).then((data) => {
      return data
    })
  },
  // 数据核对查看（v1.4.1）
  checkOffDataLogInfo(payload) {
    return httpRequest.get(`${checkUrl}check/data/log/info`, payload).then((data) => {
      return data
    })
  },
  // 确认数据（v1.4.1）
  checkOffDataCheck(payload) {
    return httpRequest.post(`${checkUrl}check/data/check`, payload).then((data) => {
      return data
    })
  },
  // 机构统计列表（v1.4.1）
  checkStatisticsList(payload) {
    return httpRequest.get(`${checkUrl}check/statistics/list`, payload).then((data) => {
      return data
    })
  },

  // 机构统计列表（v1.4.1）
  checkStatisticsListNew(payload) {
    return httpRequest.post(`${checkUrl}task/deliver/stat/org/log/list`, payload).then((data) => {
      return data
    })
  },

  // 机构统计列表（v1.4.1）  中用户的数据
  checkStatisticsListNewUserList(payload) {
    return httpRequest.post(`${checkUrl}task/deliver/stat/user/log/list`, payload).then((data) => {
      return data
    })
  },
  // 教职工核对（v1.4.1）列表
  checkListAdminTeacher(payload) {
    return httpRequest.post(`${checkUrl}check/list/admin/teacher`, payload).then((data) => {
      return data
    })
  },
  // 校区管理：校验代码是否正确
  TreeCodeCodeCheck(payload) {
    return httpRequest.post(`${baseUrl}org/tree/node/code/check`, payload).then((data) => {
      return data
    })
  },
  // 教职工字段列表
  getJZGExportColumn(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/getJZGExportColumn`, payload).then((data) => {
      return data
    })
  },
  // 学生字段列表
  getXsExportColumn(payload) {
    return httpRequest.get(`${baseUrl}xsjbsjzlb/getXsExportColumn`, payload).then((data) => {
      return data
    })
  },
  // 新增单位: 获取单位序号
  dwjbsjlbMaxXh(payload) {
    return httpRequest.get(`${baseUrl}dwjbsjlb/maxXh`, payload).then((data) => {
      return data
    })
  },
  // 基础配置管理: 编辑学年学期
  xqsjzlbEdit(payload) {
    return httpRequest.post(`${baseUrl}xqsjzlb/edit`, payload).then((data) => {
      return data
    })
  },
  // 基础配置管理: 新增学年学期
  xqsjzlbNew(payload) {
    return httpRequest.post(`${baseUrl}xqsjzlb/new`, payload).then((data) => {
      return data
    })
  },
  // （校区管理） 新增单位
  dwjbsjlbNew(payload) {
    return httpRequest.post(`${baseUrl}dwjbsjlb/new`, payload).then((data) => {
      return data
    })
  },
  // （校区管理） 查询单位
  dwjbsjlbUnit(payload) {
    return httpRequest.get(`${baseUrl}dwjbsjlb/unit`, payload).then((data) => {
      return data
    })
  },
  // （校区管理） 新增单位
  dwjbsjlbNew(payload) {
    return httpRequest.post(`${baseUrl}dwjbsjlb/new`, payload).then((data) => {
      return data
    })
  },
  // （校区管理） 查询单位
  dwjbsjlbUnit(payload) {
    return httpRequest.get(`${baseUrl}dwjbsjlb/unit`, payload).then((data) => {
      return data
    })
  },
  // （校区管理） 编辑单位
  dwjbsjlbRenew(payload) {
    return httpRequest.post(`${baseUrl}dwjbsjlb/renew`, payload).then((data) => {
      return data
    })
  },
  // （校区管理） 删除单位
  dwjbsjlbDelete(payload) {
    return httpRequest.delete(`${baseUrl}dwjbsjlb/delete`, payload).then((data) => {
      return data
    })
  },
  // 查询脱敏信息
  getSensitiveInfo(payload) {
    return httpRequest.get(`${baseUrl}sensitive/user`, payload).then((data) => {
      return data
    })
  },
  // 查询脱敏信息
  getTeacherSensitiveInfo(payload) {
    return httpRequest.get(`${baseUrl}sensitive/teacher`, payload).then((data) => {
      return data
    })
  },
  // 查询教职工信息
  getTeachInfo(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/teacherByUserId`, payload).then((data) => {
      return data
    })
  },
  // 编辑年级信息
  editGrade(payload) {
    return httpRequest.post(`${baseUrl}njsjzlb/renew`, payload).then((data) => {
      return data
    })
  },
  // 查询年级
  searchNj(payload) {
    return httpRequest.get(`${baseUrl}njsjzlb/getById`, payload).then((data) => {
      return data
    })
  },
  // 查询班级
  searchGrade(payload) {
    return httpRequest.get(`${baseUrl}bjsjzlb/classById`, payload).then((data) => {
      return data
    })
  },
  // 添加班级
  createGrade(payload) {
    return httpRequest.post(`${baseUrl}bjsjzlb/new`, payload).then((data) => {
      return data
    })
  },
  // 获取班主任列表
  getClassTeachers(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/checkList`, payload).then((data) => {
      return data
    })
  },

  // 获取班号
  getClassCode(payload) {
    return httpRequest.get(`${baseUrl}bjsjzlb/xzbhList`, payload).then((data) => {
      return data
    })
  },
  // 添加年级
  createGrade(payload) {
    return httpRequest.post(`${baseUrl}njsjzlb/create`, payload).then((data) => {
      return data
    })
  },
  // 学校机构树
  getSchoolTree(payload) {
    return httpRequest.get(`${baseUrl}tree/getTree`, payload).then((data) => {
      return data
    })
  },
  // 获取新的学校机构树
  // 学校机构树
  getSchoolTreeNew(payload) {
    return httpRequest.get(`/apis-rbac/org/tree/preview`, payload).then((data) => {
      return data
    })
  },
  // 获取学校机构树（全部）
  getAllSchoolTree(payload) {
    return httpRequest.get(`${baseUrl}tree/getAllSchoolTree`, payload).then((data) => {
      return data
    })
  },
  // 组织机构树
  getOrgTree(payload) {
    return httpRequest.get(`${baseUrl}tree/getTree`, payload).then((data) => {
      return data
    })
  },
  // 组织机构子节点
  getOrgTreeChild(payload) {
    return httpRequest.post(`${baseUrl}tree/getTreeChildren`, payload).then((data) => {
      return data
    })
  },
  // 获取班主任信息
  getBzrInfo(payload) {
    return httpRequest.post(`${baseUrl}bjsjzlb/getBzr`, payload).then((data) => {
      return data
    })
  },
  // 学校相关字典
  getDictCode(params) {
    http({
      method: 'get',
      url: `${baseUrl}dictCode/list`,
      params,
    })
    // return httpRequest.get(`${baseUrl}dictCode/list`, payload).then((data) => {
    //   return data
    // })
  },
  // 根据id添加学校信息
  addSchool(payload) {
    return httpRequest.post(`${baseUrl}xxjbsjlb/new`, payload).then((data) => {
      return data
    })
  },
  // 根据id删除学校信息
  delSchool(payload) {
    return httpRequest.delete(`${baseUrl}xxjbsjlb/removeSchoolById`, payload).then((data) => {
      return data
    })
  },
  // 获取学校信息
  getSchoolById(payload) {
    return httpRequest.get(`${baseUrl}xxjbsjlb/school`, payload).then((data) => {
      return data
    })
  },
  // 获取教育局信息
  getEduById(payload) {
    return httpRequest.get(`${baseUrl}xxjbsjlb/getJyjInfo`, payload).then((data) => {
      return data
    })
  },

  // 修改学校信息
  editSchoolById(payload) {
    return httpRequest.post(`${baseUrl}xxjbsjlb/renew`, payload).then((data) => {
      return data
    })
  },
  // 获取学校基本数据子类表数据列表
  getSchoolList(payload) {
    return httpRequest.get(`${baseUrl}xxjbsjlb/list`, payload).then((data) => {
      return data
    })
  },
  // 查询本校升学信息
  getUpgradeInfoByXxdm(payload) {
    return httpRequest.post(`${baseUrl}xxjbsjlb/getUpgradeInfoByXxdm`, payload).then((data) => {
      return data
    })
  },
  // 获取学校列表，选择机构使用
  deptSchoolList(payload) {
    return httpRequest.post(`${baseUrl}xxjbsjlb/schoolList`, payload).then((data) => {
      return data
    })
  },
  // 学科管理---
  // 分页学科信息
  subjectListPage(payload) {
    return httpRequest.get(`${baseUrl}xkxxsjzlb/page`, payload).then((data) => {
      return data
    })
  },
  // 获取学科信息列表
  getSubjectList(payload) {
    return httpRequest.get(`${baseUrl}xkxxsjzlb/list`, payload).then((data) => {
      return data
    })
  },
  // 创建学科信息
  createSubject(payload) {
    return httpRequest.post(`${baseUrl}xkxxsjzlb/new`, payload).then((data) => {
      return data
    })
  },
  // 设置学科信息状态
  subjectStatus(payload) {
    return httpRequest.post(`${baseUrl}xkxxsjzlb/setup`, payload).then((data) => {
      return data
    })
  },
  // 删除学科信息信息
  delSubject(payload) {
    return httpRequest.post(`${baseUrl}xkxxsjzlb/delete`, payload).then((data) => {
      return data
    })
  },
  // 集群管理---
  // 获取树
  getGroupTree(payload) {
    return httpRequest.get(`${baseUrl}group/getTree`, payload).then((data) => {
      return data
    })
  },
  // 获取所有群-新
  getGroupInfoAll(payload) {
    return httpRequest.get(`${baseUrl}group/list/all`, payload).then((data) => {
      return data
    })
  },
  // 根据ID获取信息
  getGroupInfoById(payload) {
    return httpRequest.get(`${baseUrl}group/getById`, payload).then((data) => {
      return data
    })
  },
  // 获取分组部门信息-新
  getGroupDept(payload) {
    return httpRequest.get(`${baseUrl}group/org/list`, payload).then((data) => {
      return data
    })
  },
  // 获取未分组部门信息
  getNoGroupDept(payload) {
    return httpRequest.get(`${baseUrl}group/getNoGrougDept`, payload).then((data) => {
      return data
    })
  },
  // 新增
  groupAdd(payload) {
    return httpRequest.post(`${baseUrl}group/add`, payload).then((data) => {
      return data
    })
  },
  // 修改
  groupEdit(payload) {
    return httpRequest.post(`${baseUrl}group/edit`, payload).then((data) => {
      return data
    })
  },
  // 删除
  groupDel(payload) {
    return httpRequest.post(`${baseUrl}group/del?id=${payload.id}`, {}).then((data) => {
      return data
    })
  },
  // 添加机构
  groupAddDept(payload) {
    return httpRequest.post(`${baseUrl}group/org/add`, payload).then((data) => {
      return data
    })
  },
  // 删除机构
  groupDelDept(payload) {
    return httpRequest.post(`${baseUrl}group/org/del`, payload).then((data) => {
      return data
    })
  },
  // 群机构添加人员
  groupAddDeptUser(payload) {
    return httpRequest.post(`${baseUrl}deptGroupUser/add`, payload).then((data) => {
      return data
    })
  },
  // 删除群机构人员
  groupDelDeptUser(payload) {
    return httpRequest.post(`${baseUrl}deptGroupUser/del`, payload).then((data) => {
      return data
    })
  },
  // 获取群机构下人员关系
  getByDeptGroupId(payload) {
    return httpRequest.get(`${baseUrl}group/org/user/user`, payload).then((data) => {
      return data
    })
  },
  // 机构及人员---
  // 获取组织机构树
  getOrganizationTree(payload) {
    return httpRequest.get(`${baseUrl}tree/`, payload).then((data) => {
      return data
    })
  },
  // 初始化组织机构树
  getTreeInit(payload) {
    return httpRequest.get(`${baseUrl}tree/init`, payload).then((data) => {
      return data
    })
  },
  // 用户组---
  // 注册用户信息
  getUserGroup(payload) {
    return httpRequest.post(`${baseUrl}user/group/registerPage`, payload).then((data) => {
      return data
    })
  },
  // 获取用户列表
  getUserList(payload) {
    return httpRequest.post(`${baseUrl}user/info/list`, payload).then((data) => {
      return data
    })
  },
  // 开发者
  // 企业注册开发者
  setRegisterCompany(payload) {
    return httpRequest.post(`${baseUrl}developer/register/company`, payload).then((data) => {
      return data
    })
  },
  // 个人注册开发者
  setRegisterPersonal(payload) {
    return httpRequest.post(`${baseUrl}developer/register/personal`, payload).then((data) => {
      return data
    })
  },

  //
  getDeveloperInfo(payload) {
    return httpRequest.get(`${baseUrl}developer/`, payload).then((data) => {
      return data
    })
  },

  // 获取当前登录人的最新申请状态 开发者
  getDeveloperSelf(payload) {
    return httpRequest.get(`${baseUrl}developer/self`, payload).then((data) => {
      return data
    })
  },
  // 获取申请列表分页 开发者
  getDeveloperListPage(payload) {
    return httpRequest.post(`${baseUrl}developer/page`, payload).then((data) => {
      return data
    })
  },
  // 查询开发者申请列表
  registerList(payload) {
    return httpRequest.post(`${baseUrl}developer/registerList`, payload).then((data) => {
      return data
    })
  },
  // 获取申请列表分页 开发者
  getDevelopInfo(payload) {
    return httpRequest.get(`${baseUrl}developer/`, payload).then((data) => {
      return data
    })
  },
  // 审核通过 开发者
  putRegisterPass(payload) {
    return httpRequest.post(`${baseUrl}developer/register/pass`, payload).then((data) => {
      return data
    })
  },
  // 审核不通过 开发者
  putRegisterNoPass(payload) {
    return httpRequest.put(`${baseUrl}developer/register/nopass`, payload).then((data) => {
      return data
    })
  },
  // 查询用户的所有身份和身份所在部门
  getUserQueryAllIdentity(payload) {
    return httpRequest.post(`${baseUrl}identity/queryAllIdentity`, payload).then((data) => {
      return data
    })
  },
  // 查询指定类型用户的身份列表
  getUserQueryIdentityByUser(params) {
    console.log(params, 'paramsparams')

    http({
      method: 'get',
      url: `${baseUrl}identity/queryIdentityByUser`,
      params,
    })
    // return httpRequest.get(`${baseUrl}identity/queryIdentityByUser`, params).then((data) => {
    //   return data
    // })
  },
  // 查询用户的所有身份
  queryIdentityDeptByUser(payload) {
    return httpRequest.get(`${rbacUrl}user/identity/list`, payload).then((data) => {
      return data
    })
  },
  // 切换身份
  changeUserIdentity(payload) {
    return httpRequest.post(`${authzUrl}authz/login/runAs `, payload).then((data) => {
      return data
    })
  },
  // 账号中心 关联信息模块--
  // 获取组织机构信息列表
  getDeptDeptList(payload) {
    return httpRequest.get(`${baseUrl}dept/deptList`, payload).then((data) => {
      return data
    })
  },
  // 获取关联部门
  getBreauDepts(payload) {
    return httpRequest.get(`${baseUrl}dept/breauDepts`, payload).then((data) => {
      return data
    })
  },
  // 绑定用户身份信息
  getUserBindUserIdentity(payload) {
    return httpRequest.get(`${baseUrl}identity/bindUserIdentity`, payload).then((data) => {
      return data
    })
  },
  // 学生信息模块---
  // 分页获取教育局所有学生基本信息
  getStudentPage(payload) {
    return httpRequest.get(`${baseUrl}xsjbsjzlb/studentPage`, payload).then((data) => {
      return data
    })
  },
  // 获取学生基本信息列表
  getStudentList(payload) {
    return httpRequest.get(`${baseUrl}xsjbsjzlb/studentList`, payload).then((data) => {
      return data
    })
  },
  // 导入学生接口
  importStudentData(payload) {
    return httpRequest.upload(`${baseUrl}xsjbsjzlb/importStudentInfo`, payload, 'upload').then((data) => {
      2
      return data
    })
  },
  // 批量更新学生照片接口
  updateStudentPics(payload, options, cancelToken) {
    return httpRequest
      .upload(`${baseUrl}xsjbsjzlb/uploadPics`, payload, 'upload', null, options, cancelToken)
      .then((data) => {
        return data
      })
  },
  // 获取更新学生照片进度
  StudentPicsRates(payload) {
    return httpRequest.get(`${baseUrl}xsjbsjzlb/getRates`, payload).then((data) => {
      return data
    })
  },
  // 获取上传失败列表
  getFails(payload) {
    return httpRequest.post(`${baseUrl}xsjbsjzlb/getFails`, payload).then((data) => {
      return data
    })
  },
  // 分页获取教育局树节点所有学生基本信息
  getOrgTreeStudentPage(payload) {
    return httpRequest.get(`${baseUrl}xsjbsjzlb/orgTreeStudentPage`, payload).then((data) => {
      return data
    })
  },
  // 分页获取年级班级树节点所有学生基本信息
  getOrgTreeClassStudentPage(payload) {
    return httpRequest.get(`${baseUrl}xsjbsjzlb/orgTreeClassStudentPage`, payload).then((data) => {
      return data
    })
  },
  // 根据组织机构信息分页获取学生家庭成员基本信息
  getStudentFamilyInformationPage(payload) {
    return httpRequest.get(`${baseUrl}xsjbsjzlb/studentFamilyInformationPage`, payload).then((data) => {
      return data
    })
  },
  // 根据组织机构信息分页获取学生基本信息
  getStudentPageByDept(payload) {
    return httpRequest.get(`${baseUrl}xsjbsjzlb/page`, payload).then((data) => {
      return data
    })
  },
  // 获取学生基本信息
  getStudent(payload) {
    return httpRequest.get(`${baseUrl}xsjbsjzlb/student`, payload).then((data) => {
      return data
    })
  },
  // 根据学生id获取数据确认学生
  getCheckStudentById(payload) {
    return httpRequest.get(`${baseUrl}xsjbsjzlb/getCheckStudentById`, payload).then((data) => {
      return data
    })
  },
  // 根据用户ID获取用户绑定身份关系关联学生基本信息列表
  studentListByUserIdentity(payload) {
    return httpRequest.get(`${baseUrl}xsjbsjzlb/studentListByUserIdentity`, payload).then((data) => {
      return data
    })
  },
  // 创建学生基本信息
  createStudent(payload) {
    return httpRequest.post(`${baseUrl}xsjbsjzlb/new`, payload).then((data) => {
      return data
    })
  },
  // 修改学生基本信息信息
  editStudent(payload) {
    return httpRequest.post(`${baseUrl}xsjbsjzlb/renew`, payload).then((data) => {
      return data
    })
  },
  // 删除学生基本信息信息
  delStudent(payload) {
    return httpRequest.post(`${baseUrl}xsjbsjzlb/delete`, payload).then((data) => {
      return data
    })
  },
  // 教师数据确认学生
  teacherCheckSubmit(payload) {
    return httpRequest.post(`${baseUrl}userDataCheck/classTeacherCheckSubmit`, payload).then((data) => {
      return data
    })
  },
  // 教师学生数据上报
  teacherCheckOk(payload) {
    return httpRequest.post(`${baseUrl}userDataCheck/classTeacherCheckOk`, payload).then((data) => {
      return data
    })
  },
  // 获取学生数据核对字段内容列表
  checkContentList(payload) {
    return httpRequest.get(`${baseUrl}xsjbsjzlb/checkContentList`, payload).then((data) => {
      return data
    })
  },
  // 分页获取班级下所有学生基本信息
  studentPageByBJ(payload) {
    return httpRequest.post(`${baseUrl}xsjbsjzlb/studentPageByBJ`, payload).then((data) => {
      return data
    })
  },
  // 分页获取班级下所有学生基本信息(学生页面用)
  studentPageListForTree(payload, options, cancelToken) {
    return httpRequest
      .post(`${baseUrl}xsjbsjzlb/student/page/list/for/tree`, payload, '', null, options, cancelToken)
      .then((data) => {
        return data
      })
  },
  // 新增学生信息
  createNewStudent(payload) {
    return httpRequest.post(`${baseUrl}xsjbsjzlb/newStudent`, payload).then((data) => {
      return data
    })
  },
  // 学生批量毕业
  graduateStudents(payload) {
    return httpRequest.post(`${baseUrl}xsjbsjzlb/graduateStudents`, payload).then((data) => {
      return data
    })
  },
  // 教职工信息模块---
  // 分页获取教育局树节点所有教职工基本信息
  getOrgTreeTeacherPage(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/orgTreeTeacherPage`, payload).then((data) => {
      return data
    })
  },
  // 分页获取教育局所有教职工基本信息
  getTeacherPage(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/teacherPage`, payload).then((data) => {
      return data
    })
  },
  // 分页获取教育局所有教职工基本信息 -- 添加时使用
  getAddTeacherPage(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/teacherTakeOfficeInformationPage`, payload).then((data) => {
      return data
    })
  },
  // 获取教师基本信息列表
  getSearchList(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/searchList`, payload).then((data) => {
      return data
    })
  },
  // 获取教师基本信息列表
  getTeacherList(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/teacherList`, payload).then((data) => {
      return data
    })
  },
  // 获取教师基本信息
  getTeacher(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/teacher`, payload).then((data) => {
      return data
    })
  },
  // 批量更新教师照片接口
  updateTeacherPics(payload, options, cancelToken) {
    return httpRequest
      .upload(`${baseUrl}jzgjbsjzlb/uploadPics`, payload, 'upload', null, options, cancelToken)
      .then((data) => {
        return data
      })
  },
  // 导出教职工信息
  exportJzgData(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/exportJzgData`, payload).then((data) => {
      return data
    })
  },

  // 导出角色信息
  exportRoleData(payload) {
    return httpRequest.post(`${baseUrl}role/user/export`, payload).then((data) => {
      return data
    })
  },
  // 导出学生信息
  exportXsData(payload) {
    return httpRequest.post(`${baseUrl}xsjbsjzlb/exportStudents`, payload).then((data) => {
      return data
    })
  },
  // 部门数据确认教师
  deptCheckSubmit(payload) {
    return httpRequest.post(`${baseUrl}userDataCheck/deptCheckSubmit`, payload).then((data) => {
      return data
    })
  },
  // 部门教师数据上报
  deptCheckOk(payload) {
    return httpRequest.post(`${baseUrl}userDataCheck/schoolCheckOk`, payload).then((data) => {
      return data
    })
  },
  // 获取教职工数据核对字段内容列表
  checkTeacherContentList(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/checkContentList`, payload).then((data) => {
      return data
    })
  },
  // 新立方组织机构树结构分页获取部门树节点所有教职工基本信息（教职工信息可存在多个部门）
  orgTreeTeacherPageByDeptCode(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/orgTreeTeacherPageByDeptCode`, payload).then((data) => {
      return data
    })
  },
  // 新立方组织机构树结构分页获取学校树节点所有教职工基本信息（教职工信息不区分主校区分校区）
  orgTreeTeacherPageBySchoolCode(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/orgTreeTeacherPageBySchoolCode`, payload).then((data) => {
      return data
    })
  },
  // 新立方组织机构树结构分页获取教职工基本信息（教职工信息不区分主校区分校区）
  orgTreeConfigTeacherPage(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/orgTreeConfigTeacherPage`, payload).then((data) => {
      return data
    })
  },
  // 群组管理
  orgTreeListTeacherPage(payload) {
    return httpRequest.post(`${baseUrl}group/list/teacher`, payload).then((data) => {
      return data
    })
  },
  // 创建教职工
  saveTeacher(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/saveTeacher`, payload).then((data) => {
      return data
    })
  },
  // 离职退休
  setJzgjbsjzlb(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/setup`, payload).then((data) => {
      return data
    })
  },
  // 获取解绑信息
  getUnbindInfoService(payload) {
    return httpRequest.post(`${baseUrl}teacherReshuffle/unbind/info`, payload).then((data) => {
      return data
    })
  },
  // 解绑
  unbindInfoService(payload) {
    return httpRequest.post(`${baseUrl}teacherReshuffle/unbind`, payload).then((data) => {
      return data
    })
  },
  // 更新教职工信息
  updateTeacherById(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/updateTeacherById`, payload).then((data) => {
      return data
    })
  },
  // 分配 - 查询当前部门所有教职工
  getTeachersFromDept(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/getTeachersFromDept`, payload).then((data) => {
      return data
    })
  },
  // 分配 - 查询当前学校所有教职工
  getTeachersFromSchool(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/getTeachersFromSchool`, payload).then((data) => {
      return data
    })
  },
  // 新立方组织机构树结构分页获取教职工基本信息（分配教职工左侧教师列表）
  orgTreeConfigTeacherPageNotInDept(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/orgTreeConfigTeacherPageNotInDept`, payload).then((data) => {
      return data
    })
  },
  // 获取匹配的用户ID列表
  getUserInfoByIdCard(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/getUserInfoByIdCard`, payload).then((data) => {
      return data
    })
  },
  // 角色授权分配 - 查询当前可分配角色
  getRolesFromSchool(payload) {
    return httpRequest.get(`${baseUrl}role/getUnUsedUserByRoleId`, payload).then((data) => {
      return data
    })
  },
  // 角色授权分配 - 查询当前已经分配角色
  getRolesDeptSchool(payload) {
    return httpRequest.get(`${baseUrl}role/getUsedUserByRoleId`, payload).then((data) => {
      return data
    })
  },
  // 查询当前学生信息
  getQueryStudent(payload) {
    return httpRequest.post(`${baseUrl}xsjbsjzlb/queryStudent`, payload).then((data) => {
      return data
    })
  },
  // 编辑当前学生信息
  editQueryStudent(payload) {
    return httpRequest.post(`${baseUrl}xsjbsjzlb/editStudent`, payload).then((data) => {
      return data
    })
  },
  // 新增学生信息
  createQueryStudent(payload) {
    return httpRequest.post(`${baseUrl}xsjbsjzlb/CreatStudent`, payload).then((data) => {
      return data
    })
  },
  // 分配 - 查询当前可分配的所有学生
  getStudentsFromSchool(payload) {
    return httpRequest.get(`${baseUrl}xsjbsjzlb/getXxNotInBJList`, payload).then((data) => {
      return data
    })
  },
  // 分配 - 查询当前已分配的学生
  getStudentsForClass(payload) {
    return httpRequest.post(`${baseUrl}xsjbsjzlb/selectStudentPageInBjList`, payload).then((data) => {
      return data
    })
  },
  // 查询权限列表
  getAuthority(payload) {
    return httpRequest.get(`${baseUrl}role/getRoleListByDeptId`, payload).then((data) => {
      return data
    })
  },
  // 判断是否管理员
  judgeAdmin(payload) {
    return httpRequest.get(`${baseUrl}role/isAdmin`, payload).then((data) => {
      return data
    })
  },
  // 判断是否机构管理员
  judgeOrgAdmin(payload) {
    return httpRequest.get(`${baseUrl}role/isOrgAdmin`, payload).then((data) => {
      return data
    })
  },
  // 查询权限详细列表
  getAuthorityList(payload) {
    return httpRequest.get(`${baseUrl}/role/user/userList`, payload).then((data) => {
      return data
    })
  },
  // 查询局端还是校端
  getDproleTypes(payload) {
    return httpRequest.get(`${baseUrl}/role/isBureauUser`, payload).then((data) => {
      return data
    })
  },
  // 岗位设置 - 查询当前部门所有教职工
  getTeachersFromGw(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/getTeachersFromGw`, payload).then((data) => {
      return data
    })
  },
  // 岗位设置 - 查询当前学校所有教职工
  getTeachersGwFromSchool(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/getTeachersGwFromSchool`, payload).then((data) => {
      return data
    })
  },
  // 将教职工分配到部门
  saveUserToDept(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/saveUserToDept`, payload).then((data) => {
      return data
    })
  },
  // 将学生分配到班级
  saveStudentsToDept(payload) {
    return httpRequest.post(`${baseUrl}xsjbsjzlb/ImportXsInBj`, payload).then((data) => {
      return data
    })
  },
  // 将学生从班级移除
  removeStudentsFromDept(payload) {
    return httpRequest.post(`${baseUrl}xsjbsjzlb/ImportXsOutBj`, payload).then((data) => {
      return data
    })
  },
  // 将教职工分配到部门
  saveUserToGw(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/saveUserToGw`, payload).then((data) => {
      return data
    })
  },
  // 将教职工从部门移除
  removeUserFromDept(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/removeUserFromDept`, payload).then((data) => {
      return data
    })
  },
  // 将角色移除
  removeRoleFromDept(payload) {
    return httpRequest.put(`${baseUrl}role/removeUserFromRole`, payload).then((data) => {
      return data
    })
  },
  // 将角色添加
  addRoleFromDept(payload) {
    return httpRequest.post(`${baseUrl}role/relateUserRole`, payload).then((data) => {
      return data
    })
  },
  // 将教职工从岗位移除
  removeUserFromGw(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/removeUserFromGw`, payload).then((data) => {
      return data
    })
  },
  // 删除教职工信息
  delJzgjbsjzlb(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/delete`, payload).then((data) => {
      return data
    })
  },
  // 导出excel数据 -- 教职工模板
  exportStaffTemplate(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/exportTemplate`, payload).then((data) => {
      return data
    })
  },
  // 导出excel数据 -- 学生模板
  exportStudentTemplate(payload) {
    return httpRequest.get(`${baseUrl}xsjbsjzlb/download`, payload).then((data) => {
      return data
    })
  },
  // 导入教职工
  importJzgData(payload) {
    return httpRequest.upload(`${baseUrl}jzgjbsjzlb/importJzgData`, payload, 'upload').then((data) => {
      return data
    })
  },
  // 教职工分页列表
  getTeacherPageBySchoolCode(payload) {
    return httpRequest.post(`${baseUrl}userDataCheck/teacherPageBySchoolCode`, payload).then((data) => {
      return data
    })
  },

  // 登录用户机构管理员身份获取学校列表
  getSchoolListByRole(payload) {
    return httpRequest.post(`${checkUrl}xxjbsjlb/schoolListByUserRole`, payload).then((data) => {
      return data
    })
  },

  // 确认教师
  schoolCheckOk(payload) {
    return httpRequest.post(`${baseUrl}userDataCheck/schoolCheckOk`, payload).then((data) => {
      return data
    })
  },

  // 登录用户班主任身份获取班级列表
  getClassTeacherList(payload) {
    return httpRequest.post(`${baseUrl}classTeacher/classList`, payload).then((data) => {
      return data
    })
  },

  // 家长信息模块---
  // 分页家庭成员信息
  getParentsPage(payload) {
    return httpRequest.get(`${baseUrl}parents/page`, payload).then((data) => {
      return data
    })
  },
  // 获取家长数据
  getParentsInfo(payload) {
    return httpRequest.get(`${baseUrl}parents/getBySzfjh`, payload).then((data) => {
      return data
    })
  },
  // 获取家长数据(根据子女)
  getParentsByChild(payload) {
    return httpRequest.get(`${baseUrl}parents/getByChild`, payload).then((data) => {
      return data
    })
  },
  // 获取家长数据(id)
  getParentsById(payload) {
    return httpRequest.get(`${baseUrl}parents/getById`, payload).then((data) => {
      return data
    })
  },
  // 创建家庭成员信息
  addParents(payload) {
    return httpRequest.post(`${baseUrl}parents/new`, payload).then((data) => {
      return data
    })
  },
  // 创建家庭成员子女关系信息
  addParentsChild(payload) {
    return httpRequest.post(`${baseUrl}parents/newChild`, payload).then((data) => {
      return data
    })
  },
  // 修改家庭成员信息
  editParents(payload) {
    return httpRequest.post(`${baseUrl}parents/renew`, payload).then((data) => {
      return data
    })
  },
  // 修改家庭成员子女关系信息
  editParentsChild(payload) {
    return httpRequest.post(`${baseUrl}parents/renewChild`, payload).then((data) => {
      return data
    })
  },
  // 删除家庭成员信息
  delParents(payload) {
    return httpRequest.post(`${baseUrl}parents/delete`, payload).then((data) => {
      return data
    })
  },
  // 删除家庭成员子女关系信息
  delParentsChild(payload) {
    return httpRequest.post(`${baseUrl}parents/deleteChild`, payload).then((data) => {
      return data
    })
  },
  // 移动学生/教职工基本信息至其他目录---
  // 学生入校
  moveNewStudent(payload) {
    return httpRequest.post(`${baseUrl}moveUser/newStudent`, payload).then((data) => {
      return data
    })
  },
  // 学生离校
  removeStudent(payload) {
    return httpRequest.post(`${baseUrl}moveUser/removeStudent`, payload).then((data) => {
      return data
    })
  },
  // 教师入校
  moveNewTeacher(payload) {
    return httpRequest.post(`${baseUrl}moveUser/newTeacher`, payload).then((data) => {
      return data
    })
  },
  // 教师离校
  removeTeacher(payload) {
    return httpRequest.post(`${baseUrl}moveUser/removeTeacher`, payload).then((data) => {
      return data
    })
  },
  // 年级班级添加学生
  newClassStudent(payload) {
    return httpRequest.post(`${baseUrl}moveUser/newClassStudent`, payload).then((data) => {
      return data
    })
  },
  // 年级班级删除学生
  removeClassStudent(payload) {
    return httpRequest.post(`${baseUrl}moveUser/removeClassStudent`, payload).then((data) => {
      return data
    })
  },
  // 部门添加教职工
  newDeptUser(payload) {
    return httpRequest.post(`${baseUrl}moveUser/newDeptUser`, payload).then((data) => {
      return data
    })
  },
  // 部门删除人员
  removeDeptUser(payload) {
    return httpRequest.post(`${baseUrl}moveUser/removeDeptUser`, payload).then((data) => {
      return data
    })
  },
  // 教师异动
  // 异动-获取机构号
  getTeacherInfo(payload) {
    return httpRequest.post(`${baseUrl}identity/queryUserDept`, payload).then((data) => {
      return data
    })
  },
  // 异动-获取待处理条数
  getEventNum(payload) {
    return httpRequest.post(`${baseUrl}teacherReshuffle/auditorCount`, payload).then((data) => {
      return data
    })
  },
  // 异动-已发起列表
  getLanchedList(payload) {
    return httpRequest.post(`${baseUrl}teacherReshuffle/initiatedPage`, payload).then((data) => {
      return data
    })
  },
  // 异动-发起列表详情
  getListDetail(payload) {
    return httpRequest.post(`${baseUrl}teacherReshuffle/query`, payload).then((data) => {
      return data
    })
  },
  // 异动-判断审批按钮权限
  getAuditorCheck(payload) {
    return httpRequest.get(`${baseUrl}teacherReshuffle/auditor/check`, payload).then((data) => {
      return data
    })
  },
  // 异动-撤销流程
  concelProcess(payload) {
    return httpRequest.post(`${baseUrl}teacherReshuffle/setup`, payload).then((data) => {
      return data
    })
  },
  // 异动-处理中列表
  getAuditorList(payload) {
    return httpRequest.post(`${baseUrl}teacherReshuffle/auditorPage`, payload).then((data) => {
      return data
    })
  },
  // 异动-已处理列表
  getReviewedList(payload) {
    return httpRequest.post(`${baseUrl}teacherReshuffle/reviewedPage`, payload).then((data) => {
      return data
    })
  },
  // 异动-发起流程
  newLanch(payload) {
    return httpRequest.post(`${baseUrl}teacherReshuffle/new`, payload).then((data) => {
      return data
    })
  },
  // 异动-审批
  auditprocess(payload) {
    return httpRequest.post(`${baseUrl}teacherReshuffle/audit`, payload).then((data) => {
      return data
    })
  },
  // // 异动-审批-获取机构列表
  // getDeptList(payload) {
  //   return httpRequest.post(`${baseUrl}dept/deptListBySchool`, payload).then((data) => {
  //     return data;
  //   });
  // },
  // 异动-审批-获取机构列表
  getDeptList(payload) {
    return httpRequest.get(`${baseUrl}dept/list`, payload).then((data) => {
      return data
    })
  },
  // 树权限管理---
  // 根据节点id获取权限列表
  getAuthUser(payload) {
    return httpRequest.post(`${baseUrl}auth/authUser`, payload).then((data) => {
      return data
    })
  },
  // 批量新增节点权限
  addBatchSave(payload) {
    return httpRequest.post(`${baseUrl}auth/batchSave`, payload).then((data) => {
      return data
    })
  },
  // 根据节点获取所有教师列表
  getTreeTeacherList(payload) {
    return httpRequest.post(`${baseUrl}auth/getTeacherList`, payload).then((data) => {
      return data
    })
  },
  // 根据id删除权限
  delAuth(payload) {
    return httpRequest.delete(`${baseUrl}auth/`, payload).then((data) => {
      return data
    })
  },
  // 树节点人员管理
  // 人员节点搜索
  treeUserSearch(payload) {
    return httpRequest.post(`${baseUrl}tree/user/userSearch`, payload).then((data) => {
      return data
    })
  },
  // 用户服务Feign接口---
  // 根据userId获取用户信息
  getUserInfoByUserId(payload) {
    return httpRequest.get(`${baseUrl}user/api/getUserInfoByUserId`, payload).then((data) => {
      return data
    })
  },
  // queryUserExtensionInfo
  queryCurrentUserExtensionInfo(payload) {
    return httpRequest.get(`${baseUrl}user/api/queryCurrentUserExtensionInfo`, payload).then((data) => {
      return data
    })
  },

  /** *
   *
   * 获取组织机构列表
   *
   */
  getOrgList(payload) {
    return httpRequest.post(`${baseUrl}xxjbsjlb/schoolList`, payload).then((data) => {
      return data
    })
  },
  // 获取教育局学校树
  getDeptTree(payload) {
    return httpRequest.get(`${baseUrl}dept/deptTree`, payload).then((data) => {
      return data
    })
  },
  // 保存授权
  saveInpower(payload) {
    return httpRequest.post(`${baseUrl}role/inpower/v2`, payload).then((data) => {
      return data
    })
  },
  // 获取角色授权的学校
  getInpowerDeptInfo(payload) {
    return httpRequest.get(`${baseUrl}role/inpowerDeptInfo`, payload).then((data) => {
      return data
    })
  },
  // 部门（机构） 信息管理---
  // 创建部门
  createDept(payload) {
    return httpRequest.post(`${baseUrl}dept/`, payload).then((data) => {
      return data
    })
  },
  // 修改部门
  modifyDept(payload) {
    return httpRequest.put(`${baseUrl}dept/`, payload).then((data) => {
      return data
    })
  },
  // 删除部门
  delDept(payload) {
    return httpRequest.delete(`${baseUrl}dept/`, payload).then((data) => {
      return data
    })
  },
  // 拱墅新立方 - 创建部门
  createXlfDept(payload) {
    return httpRequest.post(`${baseUrl}dept/createNewDept`, payload).then((data) => {
      return data
    })
  },
  // 拱墅新立方 - 修改部门
  modifyXlfDept(payload) {
    return httpRequest.post(`${baseUrl}dept/deptModify`, payload).then((data) => {
      return data
    })
  },
  // 拱墅新立方 - 删除部门
  delXlfDept(payload) {
    return httpRequest.post(`${baseUrl}dept/delete`, payload).then((data) => {
      return data
    })
  },
  // 部门人员关系---
  // 获取部门人员信息列表
  treeDeptUserList(payload) {
    return httpRequest.get(`${baseUrl}deptUser/treeDeptUserList`, payload).then((data) => {
      return data
    })
  },
  // 组管理---
  // 新增组
  groupTreeAdd(payload) {
    return httpRequest.post(`${baseUrl}team/treeAdd`, payload).then((data) => {
      return data
    })
  },
  // 修改组
  groupTreeModify(payload) {
    return httpRequest.post(`${baseUrl}team/treeModify`, payload).then((data) => {
      return data
    })
  },
  // 删除组
  groupTreeRemove(payload) {
    return httpRequest.post(`${baseUrl}team/treeRemove`, payload).then((data) => {
      return data
    })
  },
  // 年级基本数据---
  // 查询组织机构年级列表
  getGradeList(payload) {
    return httpRequest.post(`${baseUrl}njsjzlb/list`, payload).then((data) => {
      return data
    })
  },
  // 获取年级数据
  getGradeById(payload) {
    return httpRequest.get(`${baseUrl}njsjzlb/getById`, payload).then((data) => {
      return data
    })
  },
  // 创建年级信息
  createGrade(payload) {
    return httpRequest.post(`${baseUrl}njsjzlb/create`, payload).then((data) => {
      return data
    })
  },
  // 修改年级信息信息
  modifyGrade(payload) {
    return httpRequest.post(`${baseUrl}njsjzlb/edit`, payload).then((data) => {
      return data
    })
  },
  // 删除年级信息信息
  deleteGrade(payload) {
    return httpRequest.post(`${baseUrl}njsjzlb/remove`, payload).then((data) => {
      return data
    })
  },
  // 用户身份信息确认
  bindUserIdentity(payload) {
    return httpRequest.get(`${baseUrl}identity/bindUserIdentity`, payload).then((data) => {
      return data
    })
  },
  // 绑定学生家长
  newParentsStudent(payload) {
    return httpRequest.post(`${baseUrl}parents/newParentsStudent`, payload).then((data) => {
      return data
    })
  },
  // 解绑学生
  unBindUserIdentity(payload) {
    return httpRequest.get(`${baseUrl}identity/unBindUserIdentity`, payload).then((data) => {
      return data
    })
  },
  // 查
  // 查询教职工
  getTeacherListById(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/selectTeacherListTest`, payload).then((data) => {
      return data
    })
  },
  // 查询学生
  getStudentListById(payload) {
    return httpRequest.get(`${baseUrl}xsjbsjzlb/studentByChooseListTest`, payload).then((data) => {
      return data
    })
  },
  // 查询家长
  getParentListById(payload) {
    return httpRequest.get(`${baseUrl}parents/getParentBySzfjh`, payload).then((data) => {
      return data
    })
  },
  // 查询学校下的学区列表
  getListByXxdm(payload) {
    return httpRequest.get(`${baseUrl}xqjbsjzlb/campusList`, payload).then((data) => {
      return data
    })
  },
  // 查询年级下的班级
  getClassLisByid(payload) {
    return httpRequest.post(`${baseUrl}bjsjzlb/getModelListByNjId`, payload).then((data) => {
      return data
    })
  },
  // 查询符合条件的学生
  studentByChooseList(payload) {
    return httpRequest.get(`${baseUrl}xsjbsjzlb/studentByChooseList`, payload).then((data) => {
      return data
    })
  },
  // 查询学校下的校区列表
  getListsByXxdm(payload) {
    return httpRequest.get(`${baseUrl}xqjbsjzlb/listByXxId`, payload).then((data) => {
      return data
    })
  },
  // 删除学生
  deleteStudent(payload) {
    return httpRequest.delete(`${baseUrl}xsjbsjzlb/remove`, payload).then((data) => {
      return data
    })
  },
  // 查询学区下的年级列表
  getListByJgh(payload) {
    return httpRequest.get(`${baseUrl}njsjzlb/getListByJgh`, payload).then((data) => {
      return data
    })
  },
  // 对年级执行升级操作
  upgrade(payload) {
    return httpRequest.post(`${baseUrl}njsjzlb/upgrade`, payload).then((data) => {
      return data
    })
  },

  // 用户列表
  getUserinfoList(payload) {
    return httpRequest.post(`${baseUrl}user/info/userList`, payload).then((data) => {
      return data
    })
  },

  // 学生账号管理-用户列表
  getStudentUserinfoList(payload) {
    return httpRequest.post(`${baseUrl}user/info/userListXs`, payload).then((data) => {
      return data
    })
  },
  // 修改用户状态
  renewUserStatus(payload) {
    return httpRequest.post(`${baseUrl}user/info/updateUserStatus`, payload).then((data) => {
      return data
    })
  },
  // 批量修改用户密码
  renewUserPwd(payload) {
    return httpRequest.post(`${baseUrl}user/info/updateAccountPassword`, payload).then((data) => {
      return data
    })
  },
  // 修改账户状态
  renewAccountStatus(payload) {
    return httpRequest.post(`${baseUrl}user/info/updateAccountStatus`, payload).then((data) => {
      return data
    })
  },

  // 班级基本数据---
  // 查询组织机构班级列表
  getClassList(payload) {
    return httpRequest.post(`${baseUrl}bjsjzlb/list`, payload).then((data) => {
      return data
    })
  },
  // 创建班级信息
  createClass(payload) {
    return httpRequest.post(`${baseUrl}bjsjzlb/new`, payload).then((data) => {
      return data
    })
  },
  // 编辑班级信息
  editClass(payload) {
    return httpRequest.post(`${baseUrl}bjsjzlb/renew`, payload).then((data) => {
      return data
    })
  },
  // 修改班级信息
  modifyClass(payload) {
    return httpRequest.post(`${baseUrl}bjsjzlb/renew`, payload).then((data) => {
      return data
    })
  },
  // 删除班级信息
  deleteClass(payload) {
    return httpRequest.post(`${baseUrl}bjsjzlb/delete`, payload).then((data) => {
      return data
    })
  },
  // 查询组织机构班级列表
  getXzbhList(payload) {
    return httpRequest.get(`${baseUrl}bjsjzlb/xzbhList`, payload).then((data) => {
      return data
    })
  },
  // 查询可用年份
  getlastGrade(payload) {
    return httpRequest.get(`${baseUrl}njsjzlb/getXn`, payload).then((data) => {
      return data
    })
  },
  // 新增年级查询可用年份
  getlastXnChoose(payload) {
    return httpRequest.get(`${baseUrl}njsjzlb/xnChoose`, payload).then((data) => {
      return data
    })
  },
  // 查询年级下的班级列表
  getListByBjId(payload) {
    return httpRequest.post(`${baseUrl}bjsjzlb/getListByBjId`, payload).then((data) => {
      return data
    })
  },
  // 查询年级下的所有班级Model（升学）
  getModelListByNjId(payload) {
    return httpRequest.post(`${baseUrl}bjsjzlb/getModelListByNjId`, payload).then((data) => {
      return data
    })
  },
  // 班级班主任数据子类---
  // 获取班主任信息列表
  getClassTeacher(payload) {
    return httpRequest.get(`${baseUrl}classTeacher/list`, payload).then((data) => {
      return data
    })
  },
  // 创建班级班主任信息
  createTeacher(payload) {
    return httpRequest.post(`${baseUrl}classTeacher/new`, payload).then((data) => {
      return data
    })
  },
  // 删除班级班主任信息
  delTeacher(payload) {
    return httpRequest.post(`${baseUrl}classTeacher/delete`, payload).then((data) => {
      return data
    })
  },
  // 根据班级数据子类表ID修改班级班主任信息
  modifyTeacher(payload) {
    return httpRequest.post(`${baseUrl}classTeacher/renew`, payload).then((data) => {
      return data
    })
  },
  // 教职工学科班级关系数据子类---
  // 获取教职工班级学科信息列表
  getCourseTeacher(payload) {
    return httpRequest.get(`${baseUrl}courseTeacher/list`, payload).then((data) => {
      return data
    })
  },
  // 创建教职工学科班级关系信息
  createCourseTeacher(payload) {
    return httpRequest.post(`${baseUrl}courseTeacher/new`, payload).then((data) => {
      return data
    })
  },
  // 删除教职工学科班级关系信息
  delCourseTeacher(payload) {
    return httpRequest.post(`${baseUrl}courseTeacher/delete`, payload).then((data) => {
      return data
    })
  },
  // 修改教职工学科班级关系信息
  modifyCourseTeacher(payload) {
    return httpRequest.post(`${baseUrl}courseTeacher/renew`, payload).then((data) => {
      return data
    })
  },
  // 查询钉钉配置信息
  getQueryConfig(payload) {
    return httpRequest.get(`${baseUrl}ding/config/queryConfig`, payload).then((data) => {
      return data
    })
  },
  // 查询钉钉配置信息
  getSaveConfig(payload) {
    return httpRequest.post(`${baseUrl}ding/config/saveConfig`, payload).then((data) => {
      return data
    })
  },
  // 设置用户核对字段---
  // 获取教师（学生）信息核对数据
  getCheckDataList(payload) {
    return httpRequest.get(`${baseUrl}check/data/getList`, payload).then((data) => {
      return data
    })
  },
  // 获取历史记录
  getChecklogs(payload) {
    return httpRequest.get(`${baseUrl}check/log/get`, payload).then((data) => {
      return data
    })
  },
  // 数据核对信息修改数据（新）
  checkDataUpdate(payload) {
    return httpRequest.post(`${baseUrl}check/data/update`, payload).then((data) => {
      return data
    })
  },
  // 数据核对信息确认数据（新）
  checkDataCheck(payload) {
    return httpRequest.post(`${baseUrl}check/data/check`, payload).then((data) => {
      return data
    })
  },
  // 查询设置核对用户字段列表
  getCheckColumnList(payload) {
    return httpRequest.get(`${baseUrl}checkUserColumn/columnList`, payload).then((data) => {
      return data
    })
  },
  // 查询核对用户字段列表（重写后）
  getCheckConfig(payload) {
    return httpRequest.get(`${baseUrl}check/config/get`, payload).then((data) => {
      return data
    })
  },
  // 修改字段配置
  CheckConfigUpdate(payload) {
    return httpRequest.post(`${baseUrl}check/config/update`, payload).then((data) => {
      return data
    })
  },
  // 设置审核字段状态
  setCheckColumn(payload) {
    return httpRequest.post(`${baseUrl}checkUserColumn/setup`, payload).then((data) => {
      return data
    })
  },

  // 数据字典代码集数据子类---
  // 根据数据字典类型获取数据字典代码集信息列表
  getCodeList(payload) {
    return httpRequest.get(`${baseUrl}dictCode/list`, payload).then((data) => {
      return data
    })
  },

  // 数据字典代码集数据子类---扩展接口
  // 根据数据字典类型获取数据字典代码集信息列表
  getCodeListExtend(payload) {
    return httpRequest.post(`${baseUrl}dictCode/list/extend`, payload).then((data) => {
      return data
    })
  },

  // getCodeListById(payload) {
  //   return httpRequest.get(`${baseUrl}dictCode/listById`, payload).then((data) => {
  //     return data
  //   })
  // },
  getCodeListByLb(payload) {
    return httpRequest.get(`${baseUrl}dictCode/list`, payload).then((data) => {
      return data
    })
  },
  getCodeListIgjd(payload) {
    return httpRequest.get(`${baseUrl}dictCode/listIgjd`, payload).then((data) => {
      return data
    })
  },
  // 行政区划数据子类表---
  // 获取行政区数据列表
  getAreaCode(payload) {
    return httpRequest.get(`${baseUrl}xzqhsjzlb/list`, payload).then((data) => {
      return data
    })
  },
  // 校区基本数据子类---
  // 根据id获取学校校区Model
  getCampusInfo(payload) {
    return httpRequest.get(`${baseUrl}xqjbsjzlb/campus`, payload).then((data) => {
      return data
    })
  },
  // 创建校区基本数据子类信息
  createCampus(payload) {
    return httpRequest.post(`${baseUrl}xqjbsjzlb/new`, payload).then((data) => {
      return data
    })
  },
  // 查询学校剩余可用校区代码
  getCampusDm(payload) {
    return httpRequest.get(`${baseUrl}xqjbsjzlb/xqm`, payload).then((data) => {
      return data
    })
  },
  // 修改校区基本数据子类信息
  modifyCampus(payload) {
    return httpRequest.post(`${baseUrl}xqjbsjzlb/renew`, payload).then((data) => {
      return data
    })
  },
  // 删除校区基本数据信息
  delCampus(payload) {
    return httpRequest.delete(`${baseUrl}xqjbsjzlb/delete`, payload).then((data) => {
      return data
    })
  },
  // 查询未毕业的校区列表
  getUnGraduateXqList(payload) {
    return httpRequest.get(`${baseUrl}xqjbsjzlb/getUnGraduateXqList`, payload).then((data) => {
      return data
    })
  },
  // 查询某校区未毕业学生的班级列表
  getUnGraduateClassByXqId(payload) {
    return httpRequest.get(`${baseUrl}xqjbsjzlb/getUnGraduateClassByXqId`, payload).then((data) => {
      return data
    })
  },
  // 根据班级和年级的id查看对应的学生列表
  getStuListByNjIdAndBjId(payload) {
    return httpRequest.get(`${baseUrl}xqjbsjzlb/getStuListByNjIdAndBjId`, payload).then((data) => {
      return data
    })
  },
  // 学生分页列表
  studentPageByClassCode(payload) {
    return httpRequest.post(`${baseUrl}userDataCheck/studentPageByClassCode`, payload).then((data) => {
      return data
    })
  },
  // /
  setTeacherCheckOk(payload) {
    return httpRequest.post(`${baseUrl}userDataCheck/classTeacherCheckOk`, payload).then((data) => {
      return data
    })
  },
  // /
  schoolListByClassTeacher(payload) {
    return httpRequest.post(`${baseUrl}xxjbsjlb/schoolListByClassTeacher`, payload).then((data) => {
      return data
    })
  },
  // 学校下所有年级一键升级操作
  schoolUpAllClass(payload) {
    return httpRequest.post(`${baseUrl}njsjzlb/oneKeyUpgrade?xqId=${payload}`, {}).then((data) => {
      return data
    })
  },

  // 创建班级-改组织机构树-2022.9.23
  createClassNew(payload) {
    return httpRequest.post(`${baseUrl}bjsjzlb/newBj`, payload).then((data) => {
      return data
    })
  },
  // 创建年级-改组织机构树-2022.9.23
  createGradeNew(payload) {
    return httpRequest.post(`${baseUrl}njsjzlb/createNj`, payload).then((data) => {
      return data
    })
  },

  // 获取所有节点的学生数据
  getAllNodeStudentList(payload) {
    return httpRequest.post(`${baseUrl}xsjbsjzlb/getOrgTreeStudentPageNew`, payload).then((data) => {
      return data
    })
  },

  // 获取毕业的学生数据
  getOrgTreeStudentPageGradua(payload) {
    return httpRequest.post(`${baseUrl}xsjbsjzlb/getOrgTreeStudentPageGradua`, payload).then((data) => {
      return data
    })
  },

  xnxqList(payload) {
    return httpRequest.get(`${baseUrl}xqsjzlb/getXnxq`, payload).then((data) => {
      return data
    })
  },
  // 获取当前角色权限
  getCurrentPermsByRole(payload) {
    return httpRequest.post(`${rbacUrl}role/perms/current`, payload).then((data) => {
      return data
    })
  },

  // 学校机构树查询数据列表
  getSchoolListAll(payload) {
    return httpRequest.get(`${rbacUrl}org/tree/data/school`, payload).then((data) => {
      return data
    })
  },

  // 获取部门信息
  getDeptInfo(payload) {
    return httpRequest.get(`${baseUrl}dept/get/${payload}`, {}).then((data) => {
      return data
    })
  },

  // 获取教师信息
  getTeacherBaseInfo(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/getJzgInfo`, payload).then((data) => {
      return data
    })
  },

  // 岗位信息新增
  saveJobInfo(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/job/save`, payload).then((data) => {
      return data
    })
  },

  // 岗位信息查询
  getJobInfo(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/job/list`, payload).then((data) => {
      return data
    })
  },

  // 岗位信息编辑
  updateJobInfo(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/job/update`, payload).then((data) => {
      return data
    })
  },

  // 岗位信息删除
  deleteJobInfo(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/job/delete`, payload).then((data) => {
      return data
    })
  },

  // 专业技能信息新增
  saveMajorInfo(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/major/save`, payload).then((data) => {
      return data
    })
  },
  // 专业技能信息查询
  getMajorInfo(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/major/info`, payload).then((data) => {
      return data
    })
  },
  // 专业技能信息编辑
  updateMajorInfo(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/major/update`, payload).then((data) => {
      return data
    })
  },
  // 专业技能信息删除
  deleteMajorInfo(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/major/delete`, payload).then((data) => {
      return data
    })
  },

  // 获取部门编号
  getDeptSort(payload) {
    return httpRequest.get(`${baseUrl}dept/get/sort/${payload}`, {}).then((data) => {
      return data
    })
  },

  // 获取部门列表
  getDeptQuery(payload) {
    return httpRequest.post(`${baseUrl}dept/query`, payload).then((data) => {
      return data
    })
  },

  // 组织机构树（部门）
  orgTreeDept(payload) {
    return httpRequest.get(`${rbacUrl}org/tree/dept`, payload).then((data) => {
      return data
    })
  },

  // 筛选配置项
  searchFieldList(payload) {
    return httpRequest.post(`${baseUrl}list/search/field/list`, payload).then((data) => {
      return data
    })
  },

  // 部门导出
  exportDeptData(payload) {
    return httpRequest.post(`${baseUrl}dept/export`, payload).then((data) => {
      return data
    })
  },

  // 添加部门获取节点代码
  getOfficeById(payload) {
    return httpRequest.get(`${baseUrl}dept/get/prefix`, payload).then((data) => {
      return data
    })
  },

  // 查询所属组织选项
  getSelectOptions(payload) {
    return httpRequest.get(`${rbacUrl}org/tree/node/list`, payload).then((data) => {
      return data
    })
  },
  // 查询所属组织选项
  getTreeSelectOptions(payload) {
    return httpRequest.get(`${rbacUrl}org/tree/node/list/xs`, payload).then((data) => {
      return data
    })
  },

  // 重新雇用教师信息查询
  findTeacherInfo(payload) {
    return httpRequest.post(`${baseUrl}teacherReshuffle/rehire/find`, payload).then((data) => {
      return data
    })
  },

  // 重新雇用教师保存方法
  saveTeacherInfo(payload) {
    return httpRequest.post(`${baseUrl}teacherReshuffle/rehire/save`, payload).then((data) => {
      return data
    })
  },

  // 离职
  dimission(payload) {
    return httpRequest.post(`${baseUrl}teacherReshuffle/dimission`, payload).then((data) => {
      return data
    })
  },

  // 退休
  retire(payload) {
    return httpRequest.post(`${baseUrl}teacherReshuffle/retire`, payload).then((data) => {
      return data
    })
  },

  // 教职工排序
  teacherSort(payload) {
    return httpRequest.post(`${baseUrl}jzgjbsjzlb/sort`, payload).then((data) => {
      return data
    })
  },

  // 获取当前账号身份
  getAccountIndenty(payload) {
    return httpRequest.get(`${baseUrl}user/info/org/iden`, payload).then((data) => {
      return data
    })
  },

  // 获取行政部门
  getDeptByOrg(payload) {
    return httpRequest.get(`${baseUrl}dept/org/sub`, payload).then((data) => {
      return data
    })
  },

  getUserInfoIsCanSetup(payload) {
    return httpRequest.get(`${baseUrl}user/info/org/role`, payload).then((data) => {
      return data
    })
  },
  // 获取机构下的人员
  getUserOrgUser(payload) {
    return httpRequest.post(`${baseUrl}teacherReshuffle/jzg/list`, payload).then((data) => {
      return data
    })
  },
  // 获取离职列表
  getDimissionList(payload) {
    return httpRequest.post(`${baseUrl}teacherReshuffle/dimission/list`, payload).then((data) => {
      return data
    })
  },
  // 获取退休列表
  getRehireList(payload) {
    return httpRequest.post(`${baseUrl}teacherReshuffle/rehire/list`, payload).then((data) => {
      return data
    })
  },
  // 教师机构历史信息查询
  getOrgHistory(payload) {
    return httpRequest.get(`${baseUrl}teacherReshuffle/org/history`, payload).then((data) => {
      return data
    })
  },

  getJzgStatistics(payload) {
    return httpRequest.get(`${baseUrl}jzgjbsjzlb/org/statistics`, payload).then((data) => {
      return data
    })
  },
  // 学生异动
  studentMoveInfo(payload) {
    return httpRequest.post(`${baseUrl}studentMoveInfo/create`, payload).then((data) => {
      return data
    })
  },

  // 获取合作机构的序号和类型
  getOrganizationId(payload) {
    return httpRequest.get(`${baseUrl}hzjgjbsjlb/category/xh/list`, payload).then((data) => {
      return data
    })
  },

  // 创建合作机构
  addOrganizationNew(payload) {
    return httpRequest.post(`${baseUrl}hzjgjbsjlb/new`, payload).then((data) => {
      return data
    })
  },

  // 获取合作机构的信息
  getOrganizationInfo(payload) {
    return httpRequest.get(`${baseUrl}hzjgjbsjlb/info`, payload).then((data) => {
      return data
    })
  },

  // 修改合作机构
  changeOrganizationRenew(payload) {
    return httpRequest.post(`${baseUrl}hzjgjbsjlb/renew`, payload).then((data) => {
      return data
    })
  },

  // 删除合作机构
  delOrganization(payload) {
    return httpRequest.post(`${baseUrl}hzjgjbsjlb/delete`, payload).then((data) => {
      return data
    })
  },
  // 查询组织机构实体
  queryDataEntity(payload) {
    return httpRequest.get(`${baseUrl}org/entity/data/queryDataEntity`, payload).then((data) => {
      return data
    })
  },
  // 查询组织机构实体
  signFormTemplateSave(payload) {
    return httpRequest.post(`${baseUrl}signFormTemplate/save`, payload).then((data) => {
      return data
    })
  },
  // 查询我的签到表模版列表
  signFormTemplateList(payload) {
    return httpRequest.get(`${baseUrl}signFormTemplate/list`, payload).then((data) => {
      return data
    })
  },
  // 删除模版
  signFormTemplateDelete(payload) {
    return httpRequest.post(`${baseUrl}signFormTemplate/delete`, payload).then((data) => {
      return data
    })
  },
  // 模版详情
  signFormTemplateDetail(payload) {
    return httpRequest.get(`${baseUrl}signFormTemplate/detail`, payload).then((data) => {
      return data
    })
  },
  // 签到表导出
  signFormTemplateExport(payload) {
    return httpRequest.post(`${baseUrl}signFormTemplate/export`, payload).then((data) => {
      return data
    })
  },

  // 重新分班文件模板
  exportClassDivideTemplate(payload) {
    return httpRequest.get(`${baseUrl}xsjbsjzlb/ImportInXsDownload`, payload).then((data) => {
      return data
    })
  },
  // 获取当前人身份及机构
  getUserJyjInfo(payload) {
    return httpRequest.get(`${baseUrl}/role/org/query`, payload).then((data) => {
      return data
    })
  },

  // 获取家庭情况
  getOrgTreeFamilyInfo(payload) {
    return httpRequest.post(`${psyUrl}analysis/itemStatus/getOrgTreeStudentPageNewBjXs`, payload).then((data) => {
      return data
    })
  },

  // 获取家庭情况
  getOrgTreeFamilyInfoList(payload) {
    return httpRequest.post(`${psyUrl}analysis/itemStatus/getOrgTreeStudentStatistics`, payload).then((data) => {
      return data
    })
  },

  // 获取学生ListNew
  getOrgTreeStudentPageNewBjXsFace(payload) {
    return httpRequest.post(`${baseUrl}xsjbsjzlb/getOrgTreeStudentPageNewBjXsFace`, payload).then((data) => {
      return data
    })
  },
}
