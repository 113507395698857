const title = process.env.VUE_APP_LOGO_TITLE ? process.env.VUE_APP_LOGO_TITLE : '教育小脑云平台' // 项目名称
// const jkmc = 'analy' // 接口名称

const jkmc = '' // 接口名称

const imgBaseUrl = '/api/' + jkmc // 图片base路径
const loginMc = process.env.VUE_APP_LOGIN_MC ? process.env.VUE_APP_LOGIN_MC : 'login'
const dlmc = process.env.VUE_APP_DLMC ? process.env.VUE_APP_DLMC : 'login' // 登录名称
const logoImg = process.env.VUE_APP_LOGO_IMG ? process.env.VUE_APP_LOGO_IMG : 'logo' // logo 图标
const publicName = '' // 本地json地址的前缀

// 是否启用统一身份认证
const isCertified = false
const host = 'http://sjfx.tybd.91sst.com'
// 8438
const certifiedUrl = host + '/backend'

// const certifiedJkmc = isCertified ? '' : ('./' + jkmc)

const certifiedJkmc =
  process.env.NODE_ENV == 'production' ? process.env.VUE_APP_AXIOS_BASEURL : 'https://jindong.tybd.91sst.com/api/'
console.log('---------------------' + certifiedJkmc)


export const getEncryptCode = (param) => {
  let public_key =
    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCpu2kryFOuOBGUM40teBQC56y6MxsOLFefai8Omm6gbj3R3+opte7QztUupq5aHuUUzxPFqaURpCRW5JFvkEL7cQXT7/fW4j3yNSTUqkzE/22+7ou1AiQ0jFJUVNthuvOixkSW9iys+Tb0lpncp3/CUooNrjf86VIbPJEH2tnpswIDAQAB';

  let encryptParam = new JSEncrypt();
  encryptParam.setPublicKey(public_key);

  return encryptParam.encrypt(param.toString());
};

export const getUrlSearch = (name) => {
  // 未传参，返回空
  if (!name) return null
  // 查询参数：先通过search取值，如果取不到就通过hash来取
  var after = decodeURIComponent(window.location.search)
  after = after.substr(1) || window.location.hash.split('?')[1]
  // 地址栏URL没有查询参数，返回空
  if (!after) return null
  // 如果查询参数中没有"name"，返回空
  if (after.indexOf(name) === -1) return null

  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  // 当地址栏参数存在中文时，需要解码，不然会乱码
  var r = decodeURI(after).match(reg)
  // 如果url中"name"没有值，返回空
  if (!r) return null

  return r[2]
}

export default {
  // 判断是否存在特殊字符
  title,
  jkmc,
  imgBaseUrl,
  publicName,
  dlmc,
  loginMc,
  logoImg,
  isCertified,
  certifiedUrl,
  certifiedJkmc,
}
