/**
 * 请不要直接import此文件
 * import当前项目的
 * 注意：
 * 此文件禁止出现透传云字样、项目标记等；如果有透传云字样、项目标记，放到sr/lang/xxx文件夹里面；
 */

export default {
  common: {
    Chinese: '中文',
    English: 'English',
    add: '添加',
    delete: '删除',
    edit: '修改',
    search: '搜索',
    select: '查找',
    distribution: '分配',
    reset_passwords: '重置密码',
    assign_role: '分配角色',
    Modify_email: '修改邮箱',
    Modify_phone_number: '修改手机号',
    Account: '账号密码登录',
    Tel: '手机号登录',
    Register: '立即注册',
    Forget_the_password: '忘记密码？',
    Existing_account_Quick_login: '已有账号？ 快捷登录',
    Login_using_other_methods: '使用其它方式登录',
    Login: '立即登录',
    wechat: '微信登录',
    test: '体验账号',
    official_website: '官方网站',
    optional: '选填',
    Previous_Step: '上一步',
    next_step: '下一步',
    retrieve_password: '找回密码',
    Please_bind_the_existing_account: '已有账号，请绑定',
    No_account_please_register: '未有帐号，请注册',
    Remember_the_password: '记住密码',
    to: '至',
    Start_time: '开始日期',
    End_time: '结束日期',
    'Remember_the_password?': '记得密码？',
    SN: 'SN',
    Logging_success: '日志记录成功',
    Failure_to_send_data: '数据下发失败',
    The_lens_is_shaded: '镜头处于遮蔽状态',
    The_lens_is_on: '镜头处于开启状态',
    Please_enter_the_name_of_camera_or_monitor: '请输入摄像头名称/监护对象姓名',
    distribution_Promission: '分配权限',
    Power: '用户权限',
    devices: '个设备',
    'Updated_on:': '更新于:',
    Acquisition_formula: '采集公式',
    Control_formula: '控制公式',
    Step_length: '步长',
    ignore: '忽略',
    Task_event: '任务事件',
    Offline_unknown_version: '未知版本',
    version: '版本号',
    stand: '台',
    All_series: '全部系列',
    Monitoring_center: '监测中心',
    Whether_to_collect_variables_actively: '您将要主动采集变量,是否继续',
    Whether_to_switch_switch_state: '您将要切换开关状态,是否继续',
    current_device: '当前设备',
    Values_for: '数值为',
    Switch_off: '开关关闭',
    Switch_on: '开关开启',
    Please_deal_with_it_as_soon_as_possible: '请尽快处理',
    Has_returned_to_normal: '已经恢复正常',
    below: '低于',
    above: '高于',
    between: '介于',
    betweenCn: '之间',
    alarm: '报警',
    equal: '等于',
    switch: '开关',
    or: '或',
    Unconnected_camera: '未连接摄像头',
    Firmware_version_distribution: '固件版本分布',
    today: '本天',
    This_month: '本月',
    This_week: '本周',
    fault: '故障',
    year: '年',
    month: '月',
    day: '日',
    system: '(系统)',
    deleteDataTips:
      '确认删除该变量？执行该操作后，和该变量相关的历史数据会被删除，报警规则、联动控制、定时任务、触发器等设置将会失效',
    changeDataOrSlaveTips: '确认修改?修改后会导致关联的定时信息、联动控制失效',
    deleteSlaveTips:
      '确认删除该从机？执行该操作后，和该从机相关的历史数据会被删除，报警规则、联动控制、定时任务、触发器等设置将会失效',
    System_Power: '系统权限',
    Serial_index: '串口序号',
    Put_away: '收起',
    Expand: '展开',
    Added_on_other_platforms: '已在其他平台添加',
    unknown: '未知',
    Log_service: '日志服务',
    Select_product: '选择产品',
    Connect_to_the_cloud: '接入上云',
    Your_device_SN_is: '您的设备SN为',
    Communication_password_is: '通讯密码为',
    Can_also_device_list: '后续您也可以通过“设备列表->查看”，找到通讯密码',
    Please_enable_first: '请先启用',
    device_not_support_monintor: '该设备不具备网络监测功能',
    device_cant_turn_off_configration: '该设备云组态功能不能关闭',
    saveToLeave: '您修改的内容还未保存,确定离开吗?',
    Not_support_HTML5_Video: '您的浏览器不支持 HTML5 video 标签。',
    Acquisition_methods: '采集方式',
    edge_computing: '边缘计算',
    cloud_collection: '云端轮询',
    brand: '品牌',
    noUrlTips: '此品牌未与有人建立商务合作,暂不提供购买渠道.',
    edge: '边缘',
    cloud: '云端',
    stop_sort: '结束排序',
    Product_brand: '产品品牌',
    bit: '位',
    bit8_unsigned: '8位 无符号',
    bit8_signed: '8位 有符号',
    bit16_unsigned: '16位 无符号',
    bit16_signed: '16位 有符号',
    bit32_unsigned: '32位 无符号',
    bit32_signed: '32位 有符号',
    bit32_float: '32位 浮点数',
    bit32_unsignedABCD: '32位 无符号(AB CD)',
    bit32_unsignedCDAB: '32位 无符号(CD AB)',
    bit32_signedABCD: '32位 有符号(AB CD)',
    bit32_signedCDAB: '32位 有符号(CD AB)',
    bit32_floatABCD: '32位 浮点数(AB CD)',
    bit32_floatCDAB: '32位 浮点数(CD AB)',
    string_type: '字符串型',
    bit64_float: '64位 浮点数',
    bit32_timestamp: '32位 时间戳',
    bytes30_location: '30字节 定位型',
    Product_brand_EN: '品牌英文名称',
  },
  button: {
    sure: '确认',
    cancel: '取消',
    add: '添加',
    add_subordinate_group: '添加下级分组',
    register_an_account: '注册账号',
    play: '播放',
    edit: '编辑',
    resetpassword: '重置密码',
    change_Password: '修改密码',
    delete: '删除',
    Remove: '移除',
    Multiple_delete: '批量删除',
    Disable: '禁用',
    Enable: '启用',
    Search: '查询',
    save: '保存',
    Reset: '重置',
    send_test_email: '发送测试邮件',
    click_upload: '点击上传',
    historical_query: '历史查询',
    active_acquisition: '主动采集',
    timing_task: '定时任务',
    test: '测试',
    Mulitiple_delete: '批量删除',
    Device_grouping: '设备分组',
    Project_grouping: '项目分组',
    unbind: '解绑',
    Modify_Warranty_Date: '修改过保日期',
    Add_More: '继续添加',
    DownLoad: '下载数据',
    run: '运行',
    Settings: '设置',
    Copy_applications: '复制应用',
    Copy_link: '复制链接',
    share: '分享',
    share_link: '分享链接',
    view: '查看',
    Download_Data: '下载数据',
    Preview: '预览',
    Preview_alarm_processing: '预览报警处理',
    Process: '处理',
    Smoke_configure: '烟感配置',
    Refresh: '刷新',
    register_account: '注册账号',
    debug: '数据调试',
    get_security_code: '获取验证码',
    Add_node: '添加数据点',
    Select_the_file: '选择文件',
    Send: '发送',
    Edit_node: '修改数据点',
    Save: '保存',
    More: '更多',
    Add_device_template: '添加设备模板',
    Update_device_template: '更新设备模板',
    Add_slave: '添加从机',
    Device_info: '设备详情',
    History_Data: '历史记录',
    Alarm_data: '报警记录',
    status: '状态',
    name: '名称',
    Device_number: '设备编号',
    Owned_users: '所属用户',
    address: '地址',
    Retrieve: '后重新获取',
    Device_export: '导出设备',
    Order_export: '导出订单',
    Import_device: '导入设备',
    Application_for_opening: '申请开通',
    copy: '复制',
    Subuser: '子用户',
    Binding: '关联',
    Untied: '解绑',
    Set_user_super_administrator: '设为客户超级管理员',
    Synchronize: '同步',
    Slave_number_authorization: '从机数量授权',
    device_level_authorization: '设备层级授权',
    Set_level1_Vip: '设为一级会员',
    Set_neutral_Vip: '设为中性会员',
    Neutral_manage: '中性管理',
    Set_nonmember: '设为非会员',
    trigger: '触发器',
    The_test_account_cannot_click: '测试账号无法点击',
    Bind_and_login: '绑定并登录',
    Register_and_bind: '注册并绑定',
    Template_Configuration: '模板组态',
    Import_data_template: '导入数据模板',
    Export_template: '导出模板',
    Publish: '发表',
    export: '导出',
    Add_variable: '添加变量',
    Variable_name: '变量名称',
    Edit_variable: '修改变量',
    Firmware: '固件',
    Add_Subordinate: '添加下级',
    Add_Model: '添加型号',
    Upgrade_Task: '升级任务',
    Upgrade_Details: '升级详情',
    Already_Disable: '已禁用',
    Already_Enable: '已启用',
    Configuration_Task: '配置任务',
    Configuration_Details: '配置详情',
    Add_To_Task: '添加到任务',
    Configuration: '配置',
    Upgrade: '升级',
    Add_Instruction: '添加指令',
    Add_Task: '添加任务',
    End: '结束',
    Create_Articles: '创建文章',
    Article_View: '文章查看',
    Other_Data_Points: '其他数据点',
    View_SIM_Card: '查看SIM卡',
    Unbound: '未绑定',
    Dispense_With_SIM_Card: '无需SIM卡',
    Update_Record: '升级记录',
    Make_Record: '配置记录',
    The_Online_Record: '在线记录',
    Device_Log: '设备日志',
    The_remote_reading: '远程读取',
    Binding: '绑定',
    reset: '重启',
    Equipment_Restart: '设备重启',
    Query_version: '查询版本',
    Factory_Reset: '恢复出厂设置',
    Help_Information: '帮助信息',
    Send_data_in_HEX_format: '发送数据，输入的格式为HEX',
    Send_data_in_ASCll_format: '发送数据，输入的格式为ASCll',
    Accept_data_and_display_in_HEX_format: '接收数据,按HEX格式显示',
    Accept_data_and_display_in_ASCll_format: '接收数据,按ASCll格式显示',
    Start_the_voice: '启动语音',
    End_of_the_speech: '结束语音',
    State_of_the_lens: '镜头状态',
    Camera_open: '开启镜头',
    Cover_the_lens: '遮蔽镜头',
    Lens_flip: '镜头翻转',
    Enable_movement_tracking: '开启移动跟踪',
    Turn_off_movement_tracking: '关闭移动跟踪',
    Amplification: '放大',
    Narrow: '缩小',
    read: '读取',
    Edit_device_template: '编辑设备模板',
    Add_project: '添加项目',
    Edit_project: '编辑项目',
    Add_group: '添加分组',
    Binding_ys7Cloud_account: '绑定萤石云账号',
    Unbind_ys7Cloud_account: '解绑萤石云账号',
    Import_from_subuser_list: '从子用户列表导入',
    Maintenance_report: '维修报告',
    End_of_the_repair_order: '结束工单',
    view_log: '查看日志',
    Network_IO_device_template: '网络IO设备模板',
    View_alarm: '查看报警',
    finish: '完成',
    Add_tag: '添加标签',
    New_category: '新建类别',
    Operation_record: '操作记录',
    Visit_the_official_website: '进入官网浏览',
    Device_Promission: '设备权限',
    Export_order: '导出工单',
    Sent_orders: '派单',
    Order_receiving: '接单',
    Unreviewed: '未审核',
    Export_QR_code: '导出设备二维码',
    Device_QR_code: '设备二维码',
    Generate_QR_code: '生成二维码',
    Map: '地图',
    Enter_subusers: '进入子账号',
    Add_users: '添加用户',
    Edit_users: '编辑用户',
    Pre_page: '上一页',
    Next_page: '下一页',
    Add_By_ID: '没有SN，点这里',
    Return_added_by_SN_mode: '返回输入SN',
    Delete_category: '删除类别',
    User_Profiles: '用户档案',
    Set_Alarm: '设置报警',
    Set_alarm_rule: '设置报警规则',
    Edit_alarm_rule: '修改报警规则',
    I_know: '我知道了',
    No_longer_prompt: '不再提示',
    Push_mode: '推送方式',
    Running_Configuration: '运行组态',
    Edit_Head_Portrait: '修改头像',
    edit_ID: '编辑ID',
    Parameter_configuration: '参数配置',
    Reboot_device: '重启设备',
    Download_the_log: '下载日志',
    configuration_permissions: '组态权限',
    Share_the_configuration: '分享组态',
    Import: '导入',
    Copy_Info: '复制信息',
    Enter_the_account: '进入账号',
    Export_log: '导出日志',
    Add_the_agreement: '添加协议',
    Add_product: '添加产品',
    Edit_the_product: '编辑产品',
    Edit_agreement: '编辑协议',
    Import_variable: '导入变量',
    Download_excel_template: '下载Excel模板',
    Set_up_more_slaves: '设置更多从机',
    I_want_to_enter_the_product: '我要入驻产品库',
    Settled_products: '入驻产品',
    Get_latitude_and_longitude: '获取经纬度',
    Import_template: '导入模板',
    Copy_Lat_and_Long: '复制经纬度',
    debugging: '调试',
    Determine_creation: '确定创建',
    Determine_editors: '确定编辑',
    Choose_a_template: '选择模板',
    Replace_template: '更换模板',
    Newbie_Tutorial: '新手教程',
    jump_over: '跳过',
    re_upload: '重新上传',
    payment: '付款',
    Cancellation_of_order: '取消订单',
    Application_invoice: '申请发票',
    Export_variable: '导出变量',
    Confirm_receipt: '确认收款',
    Confirm_the_goods: '确认发货',
    export_record: '导出记录',
    export_details: '导出明细',
  },
  //顶部导航栏
  nav: {
    neture: '中性',
    monitor: '大屏',
    switch_old_version: '切换旧版',
    logout: '退出',
    return_main_account: '返回主账号',
    return_check_pass: '返回有人通行证',
    My_check_pass: '我的通行证',
  },
  //弹窗标题
  dialog: {
    Detail: '预览',
    Add_Monitor: '添加摄像头',
    Edit_Monitor: '编辑摄像头',
    Add_data_push: '添加数据推送',
    Edit_data_push: '编辑数据推送',
    Add_Alarm_Contact: '新增报警联系人',
    Edit_Alarm_Contact: '修改报警联系人',
    Add_Contact: '新增联系人',
    Edit_Contact: '修改联系人',
    Add_Alarm_Contact_Group: '新增报警联系组',
    Edit_Alarm_Contact_Group: '修改报警联系组',
    Add_device_tag: '添加设备分组',
    Edit_device_tag: '编辑设备分组',
    Copy_applications: '复制应用',
    Edit_HMI: '修改组态',
    Process_alarm: '处理报警',
    View_alarm_record: '预览处理报警',
    Debug: '数据调试',
    Edit: '编辑',
    Add_HMI: '添加独立应用',
    Edit_HMI: '修改独立应用',
    Add_HMI_Model: '添加模板应用',
    Edit_HMI_Model: '编辑模板应用',
    Edit_Data_Template: '编辑数据模板',
    Edit_Device_Promission: '编辑设备权限',
  },
  //页面标题
  pageTitle: {
    Home_Page: '首页',
    Monitor_List: '摄像头列表',
    Video_Management: '视频管理',
    TRIGGER_LIST: '触发器列表',
    ADD_TRIGGER: '添加触发器',
    EDIT_TRIGGER: '修改触发器',
    System_Setting: '系统设置',
    'E-mail_Server': '邮件服务器',
    Email_Template: '邮件模板',
    WeChat_Push: '微信推送',
    Mobile_Terminal: '手机端',
    GROUP_LIST: '透传组列表',
    ADD_GROUP: '添加透传组',
    EDIT_GROUP: '修改透传组',
    DEVICE_TAG: '设备分组',
    Device_overview: '设备概况',
    Protocol: '通讯协议',
    User: '所属用户',
    Subordinate_project: '所属项目',
    Map: '设备地图',
    Alarm_Record: '报警记录',
    'ON-LINE_RECORD': '设备上下线',
    HISTORICAL_DATA: '历史记录',
    RECORD_OF_EXECUTION: '执行记录',
    TASK_MONITORING: '任务监控',
    ADD_TIMED_TASKS: '添加定时',
    EDIT_TIMED_TASKS: '编辑定时',
    ADD_DEVICE: '添加设备',
    Confirm_add: '确认添加',
    EXCEL_BATCH_IMPORT: 'EXCEL批量导入',
    SINGLE_HMI: '独立应用',
    HMI_MODEL: '模板应用',
    Data_debugging: '数据调试',
    Configuration_application: '组态应用',
    Positioning_track: '定位轨迹',
    Video_Surveillance: '视频监控',
    ADD_TEMPLATE: '添加模板',
    Article_List: '文章列表',
    Today_Alarm: '今日报警',
    ADD_IN_BATCHES: '批量添加',
    Add_Devices_In_Bulk: '批量添加设备',
    EDIT_DEVICE: '修改设备',
    Firmware_Management: '固件管理',
    Resource_and_user: '项目和用户',
    Project_manager: '项目管理器',
    All_projects: '所有项目',
    Alarm_notification: '报警通知',
    Device_configuration: '设备组态',
    Neutral_Introduced: '中性介绍',
    Independent_trigger: '独立触发器',
    Engineering_quantity_statistics: '工程量统计',
    Execution_log: '执行日志',
    Built_in_device_library: '内置设备库',
    Warranty_period_management: '质保期管理',
    DTU_manage: 'DTU管理',
    DTU_Online_rate: 'DTU在线率',
    Today_Failure_recovery_rate: '当日故障恢复率',
  },
  //Card卡片标题
  cardTitle: {
    How_to_use_Cloud_SCADA: '云组态快速使用',
    Project_overview: '工程概况',
    Update_log: '更新日志',
    Focus_on_WeChat_official_account: '关注公众平台',
    Mobile_terminal: '手机端',
    Wechat_applet: '小程序',
    News_list: '新闻通知',
    Login_information: '登录信息',
    TRIGGER_SETTINGS: '触发器设置',
    ALARM_DEAD_ZONE: '报警死区',
    TRIGGER_RULE: '触发规则',
    SAVE_RULE: '保存规则',
    TRIGGER_ADD_FLOW: '触发器添加流程',
    WECHAT_BINDING: '微信绑定',
    UserInfo: '用户信息',
    Password: '用户密码',
    Communication_password: '通讯密码',
    Add_node: '添加数据点',
    About_offline: '关于下线',
    Device_Type: '设备类型',
    NODE_LIST: '数据词典',
    Multiple_add: '批量添加数据点',
    Node_overview: '数据点概况',
    Slave_list: '从机列表',
    Node_list: '数据点列表',
    function_description: '功能说明',
    Select_function_description: '选择功能说明',
    schedule_specification: '计划时间说明',
    Data_saving: '数据保存',
    VariableOverview: '变量概况',
    Series_Management: '系列管理',
    Model_Management: '型号管理',
    Firmware_Update: '固件升级',
    Firmware_Update_Version: '固件升级版本',
    Remote_Configuration: '远程配置',
    Alarm_Configuration: '报警配置',
    Add_Alarm_Group: '添加报警组',
    Main_data_parameter: '主要数据参数',
    All_parameters: '全部参数',
    Data_curve: '数据曲线',
    Weather_forecast: '天气预报',
    Area: '所在地区',
    Grouping_under_current_project: '当前项目下的分组',
    Return_item: '返回项目',
    Device_under_current_grouping: '当前分组下的设备',
    Return_group: '返回分组',
    Activation_rate: '电机激活率',
    activated: '已激活',
    inactivated: '未激活',
    Curve_display: '曲线展示',
    List_display: '列表展示',
    Recharge_record: '充值记录',
    Settlement_details: '结算明细',
    Order_management: '订单管理',
    order_details: '订单详情',
  },
  //表单的label
  label: {
    name: '姓名',
    '：': '：',
    company: '公司',
    address: '地址',
    read_write: '读写',
    readWriteInfo: {
      only_read: '只读',
      read_write: '读写',
      only_write: '只写',
    },
    user_name: '用户名',
    compony_name: '公司名',
    password: '密码',
    passwordagain: '确认密码',
    phone: '手机',
    phonenumber: '手机号',
    verificationCode: '验证码',
    phoneVerificationCode: '手机验证码',
    get_verification_code: '获取验证码',
    monitor_name: '监控名称',
    serial_number: '序列号',
    verification_code: '验证码',
    device_name: '设备名称',
    device_number: '设备编号',
    Version: '当前版本',
    custom_domain: '自定义域名',
    domain: '域名',
    system_name: '系统名称',
    company_name: '公司名称',
    logo: '系统Logo',
    email: '邮箱账号',
    email_authorization_code: '邮箱授权码',
    email_server: '邮件服务器',
    port: '端口',
    template_name: '模板名称',
    template_content: '模板内容',
    template_ID: '模板ID',
    verify_file: '校验文件',
    role_name: '角色名称',
    role_description: '角色描述',
    DATA_PUSH: '数据推送',
    remark: '备注',
    user_password: '用户密码',
    new_password: '新密码',
    confirm_password: '确认密码',
    reset_passwords: '重置密码',
    Designated_device: '指定设备',
    Specify_data_model: '指定数据模板',
    querying: '采集',
    control: '控制',
    Communication_password: '通讯密码',
    WeChat_binding_management: '微信绑定管理',
    User_management: '用户管理',
    Choose_node: '选择数据点',
    Choose_data_model: '选择数据模板',
    group: '组',
    Group_name: '透传组名称',
    Choose_the_node_for_group: '勾选节点',
    Choose_node_for_twin_group: '勾选对应组节点',
    Parent_tag: '上级分类',
    Parent_grouping: '上级分组',
    Sequence: '排序',
    Configuration_name: '组态名称',
    Slave: '从机',
    Value: '数值',
    Device_tag: '所属分组',
    Project_tag: '项目分组',
    User: '所属用户',
    Querying_frequency: '采集频率',
    Protocol: '通讯协议',
    Location: '设备地址',
    Alarm_status: '处理状态',
    Result: '处理结果',
    Process_time: '处理时间',
    version_of_role_management: '角色管理',
    // Old_version_of_role_management:"老版角色管理",
    Menu_Permissions: '菜单权限',
    Data_Point_Permissions: '数据点权限',
    selection_function: '选择功能',
    select_receiving_device: '选择接收设备',
    send_data: '发送数据',
    planned_time: '计划时间',
    Implement_way: '执行方式',
    Device_picture: '设备图片',
    Opcode: '操作码',
    Alarm_duration: '报警持续时间',
    Heartbeat_interval: '心跳间隔',
    Alarm_interval: '报警间隔',
    EXCEL_file: 'EXCEL文件',
    Device_prefix: '设备前缀',
    'Name/Address': '名称/地址',
    Device_Type: '设备类型',
    Communication_device: '通讯设备',
    Time_of_last_edition: '修改时间',
    Operation: '操作',
    Status: '状态',
    'Data_model_name:': '数据模板名称：',
    Slave_index: '从机序号',
    Slave_name: '从机名称',
    Slave_IP: '从机IP',
    Slave_port: '从机端口',
    Register_address: '寄存地址',
    Nickname: '昵称',
    project_name: '项目名称',
    Is_start_using: '是否启用',
    management: '负责人',
    tel: '联系电话',
    take_charge_project: '负责项目',
    management_and_tel: '姓名及电话',
    association_role: '关联角色',
    ADD_DEVICE_TEMPLATE: '添加设备模板',
    UPDATE_DEVICE_TEMPLATE: '更新设备模板',
    Registered_user_management: '注册用户管理',
    Subuser_management: '子用户管理',
    VIP: '会员',
    device_level: '设备层级',
    Subordinate_role: '所属角色',
    Error_logs: '错误日志',
    Error_message: '错误信息',
    Slow_logs: '慢请求日志',
    Article_title: '文章标题',
    Article_type: '文章类型',
    Cover_image: '封面图',
    Alarm_status: '报警状态',
    SN: '设备SN',
    Device_number: '添加设备数',
    Package_length: '合包长度',
    Choose_project: '选择项目',
    Protocols_and_drivers: '协议和产品',
    Contacts: '联系人',
    Variable_quantity: '变量数量',
    Variable_list: '变量列表',
    Storage_type: '存储方式',
    Timing_storage: '定时存储',
    Variable_storage: '变化存储',
    Firmware_Package: '固件包',
    Upgrade_Mode: '升级方式',
    Superior_Series: '上级系列',
    Series_Name: '系列名称',
    Series_SN_Number: '系列SN编号',
    Model_SN_Number: '型号SN编号',
    Model_of_communication_module: '通讯模块型号',
    Download_Mode: '下载模式',
    baud_Rate: '波特率',
    Data_Bits: '数据位',
    Stop_Bit: '停止位',
    Parity_Bit: '校验位',
    Flow_Control: '流控',
    LAN_Address: 'LAN口地址',
    Agreement: '协议',
    IPV4_Address: 'IPV4地址',
    IPV4_Subnet_Mask: 'IPV4子网掩码',
    task_Time: '任务时间',
    Configuration_Content: '配置内容',
    Tasks_Added: '已添加的任务',
    User_Type: '用户类型',
    Contract_Period: '合同期限',
    Network_Conditions: '网络状况',
    Binding_SIM_Card: '绑定SIM卡',
    Enabled_Function: '已启用功能',
    account: '账号',
    top_user: '上级用户',
    user_type: '用户类型',
    belongs_resource: '所属项目',
    role: '角色',
    tag: '标签',
    resource_auth: '资源权限',
    Resource: '所属资源',
    Local_network_download: '本地网络下载',
    Static_IP_address: '静态IP',
    Variable: '变量',
    Assign_users: '分配用户',
    Associated_user: '关联用户',
    Device_password: '设备密码',
    HIKVISION: '海康',
    itangwang: '顽石',
    association_project: '关联项目',
    Company_email_address: '公司联系邮箱',
    Company_contact_and_phonenumber: '公司联系人及电话',
    geographical_location: '地理区位',
    Engaged_in_the_industry: '从事行业',
    scale_of_company: '公司规模',
    Nature_of_company: '公司性质',
    expiration_date: '截止日期',
    Owner_account: '所属主账户',
    Maintenance_device: '维保设备',
    Maintenance_type: '维保类型',
    Plan_name: '计划名称',
    Plan_status: '计划状态',
    Next_maintenance_time: '下次维保时间',
    Repair_device: '报修设备',
    Work_order_number: '工单编号',
    Problem_content: '问题内容',
    Work_order_status: '工单状态',
    Work_order_type: '工单类型',
    Submission_time: '提交时间',
    Owned_customer: '所属客户',
    All_storage: '全部存储',
    Device_tags: '设备标签',
    Maintenance_Plan: '维保计划',
    Warranty_period_maintenance_time: '保内维保时间',
    Maintenance_cycle: '维保周期',
    Start_maintenance_time: '开始维保时间',
    Send_work_orders_in_advance: '提前派工单时间',
    All_types: '所有类型',
    Warranty_period: '保内维保',
    out_of_the_warranty_period: '保外维保',
    All_plans: '所有计划',
    have_not_started: '未开始',
    Wait_for_maintenance: '待维保',
    Pending: '待处理',
    Already_receiving: '已受理',
    Already_sent_orders: '已派单',
    over_and_done_with: '已结束',
    Already_end_of_the_repair_order: '已结单',
    Submitted_to_the_user: '提交用户',
    Maintenance_repairs: '维保报修',
    fault_description: '故障描述',
    upload_pictures: '上传图片',
    upload_files: '上传文件',
    upload_device_pictures: '上传设备图片',
    delete_pictures: '删除图片',
    problem_description: '问题描述',
    repairs_uesr: '报修用户',
    usr: '有人',
    Operation_logs: '操作日志',
    position: '职位',
    Wechat_public_accounts_QR_code: '微信公众号二维码',
    Configure_manager: '组态管理',
    Alarm_management: '报警管理',
    Application_cases: '应用案例',
    Smart_Home: '智能家居',
    'Electric_power_control,acquisition': '电力控制，采集',
    Firmware_type: '固件类型',
    Baseline_version: '基线版本',
    Model_Series: '型号系列',
    Equipment_shift: '设备班次',
    workorder_management: '工单管理',
    maintenance_personnel: '维修人员',
    the_means_of_notification: '通知方式',
    Work_order_description: '工单描述',
    Regular_maintenance: '常规维保',
    Fault_maintenance: '故障维保',
    Work_Order_Repair: '我的工单',
    Customer_Management: '客户管理',
    Change_stroage: '变化幅度存储',
    emailVerificationCode: '邮箱验证码',
    Cloud_configuration_system: '云组态系统',
    Cloud_monitoring_system: '云监测系统',
    Internet_of_things_card_management: '物联网卡管理',
    Equipment_detection_system: '设备检测系统',
    Device_template_count: '设备模板数',
    Alarm_name: '报警名称',
    System_notice: '系统公告',
    equipment_monitoring: '设备监测',
    SN_code: 'SN码',
    monitor_logo: '大屏Logo',
    background_information: '背景信息',
    Prerequisites_for_creating_alarm_notifications: '创建报警通知前提条件',
    Alarm_push_mechanism: '报警推送机制',
    Enabling_instructions: '启用说明',
    'What_can_we_do_to_plan_tasks?': '计划任务能做什么？',
    'How_to_View_Task_Execution_Results_Log?': '如何查看任务执行结果日志？',
    Problem_solving: '问题解答',
    User_Head_Portrait: '用户头像',
    TimeZone: '时区',
    Language: '语言',
    Lower_delivery_mode: '下发方式',
    Selection_variables: '选择变量',
    Models_of_distribution: '型号分布',
    User_rights: '用户权限',
    file_name: '文件名称',
    acquire_time: '获取时间',
    file_type: '文件类型',
    file_size: '文件大小',
    region: '区域',
    Operation_center: '运营中心',
    Neutral_users: '中性用户',
    Project: '项目',
    Template: '模板',
    Independent_configuration: '独立组态',
    Independent_alarm: '独立报警',
    Number: '数量',
    Daily_activity: '日活跃',
    Monthly_activity: '月活跃',
    Total: '总数',
    Daily_notification: '日通知',
    Monthly_notification: '月通知',
    Daily_income: '日收入',
    Monthly_income: '月收入',
    Totalize: '总计',
    Daily_record: '日记录',
    Monthly_record: '月记录',
    Total_record: '总记录',
    Protocols_and_product: '协议和产品',
    Operation_user: '操作用户',
    Event_content: '事件内容',
    Operation_state: '操作状态',
    Device_location: '设备位置',
    Client: '客户端',
    PC_Terminal: 'PC端',
    Product_agreement_library: '产品协议库',
    Product_agreement_library_Management: '产品协议库管理',
    Protocol_name: '协议名称',
    Reasons_for_offline: '下线原因',
    Warranty_period: '质保期',
    DTU_name: 'DTU名称',
    Region_Agent: '所属区域-代理商',
    Add_date: '添加日期',
    DTU_Overdue_Date: 'DTU过保日期',
    Outbound_date: '出库日期',
    Inverter_material_code: '变频器物料代码',
    Inverter_name: '变频器名称',
    Inverter_barcode: '变频器条码',
    Inverter_specifications: '变频器规格',
    Inverter_Overdue_Date: '变频器过保日期',
    Motor_material_code: '电机物料代码',
    Motor_name: '电机名称',
    Motor_barcode: '电机条码',
    Motor_specifications: '电机规格',
    Motor_Overdue_Date: '电机过保日期',
    End_user_mobile_phone_number: '终端用户手机号',
    Activation_time: '激活时间',
    English_name: '英文名称',
    Latitude_and_longitude: '经纬度',
    Device_activation_date: '设备激活日期',
    Network_monitoring: '网络监测',
    details: '详情',
    Group_type: '分组类型',
    Product_model: '产品型号',
    Transparent_transmission_strategy_list: '透传策略列表',
    Edge_group_name: '边缘端组名',
    Group_SN: '组SN',
    Create_passthrough_strategy: '创建透传策略',
    Edit_passthrough_strategy: '编辑透传策略',
    Selected_products: '已选产品',
    Existing_template: '已有模板',
    New_template: '新建模板',
    Device_template_transparent_transmission: '设备模板/透传',
    'Name/Model': '名称/型号',
    Client_software: '客户端软件',
    Order_status: '订单状态',
    Invoice_situation: '发票情况',
    Recharge_state: '充值状态',
    Payment_method: '支付方式',
    Order_number: '订单编号',
    Invoice_receiving_email: '发票接收邮箱',
    Invoice_nature: '发票性质',
    Addressee: '收件人',
    Electronic_invoice: '电子发票',
    Paper_invoice: '纸质发票',
    personal: '个人',
    enterprise: '企业',
    Effective_time: '生效时间',
    Expire_date: '到期时间',
  },
  //表单类的placeholder
  placeholder: {
    please_input_sort_number: '请输入排序数字',
    please_input_group_description: '请输入分组描述',
    please_input_group_name: '请输入分组名称',
    please_input_username: '请输入用户名',
    please_input_account: '请输入账号',
    please_input_compnany: '请输入公司名',
    please_input_password: '请输入密码',
    please_input_passwordagain: '请确认密码',
    please_input_phone: '请输入手机号',
    please_input_phoneOrEmail: '请输入手机号或邮箱',
    please_enter_the_verification_code: '请输入验证码',
    please_input_address: '请输入地址',
    please_input_remark: '请输入备注',
    Please_input_trigger_name: '请输入触发器名称',
    Please_input_alarm_contact_name: '请输入报警联系人姓名',
    Please_input_contact_name: '请输入联系人姓名',
    Please_input_alarm_group_name: '输入报警组名称',
    choose_device: '请选择设备',
    choose_slave: '请选择从机',
    choose_node: '请选择数据点',
    choose_data_model: '请选择数据模板',
    please_choose: '请选择',
    please_input: '请输入...',
    weChat_official_account_template_ID: '微信公众号模板ID',
    device_number_or_name: '设备编号或设备名称',
    SN_or_device_name: 'SN或设备名称',
    Contact_group_name: '联系组名称',
    'Please_choose_the_trigger_you_want_to_delete!': '请选择要删除的触发器！',
    'Please_choose_the_alarm_contact_you_want_to_delete!': '请选择要删除的警报联系人！',
    Invalid_group_name: '请输入透传组名称',
    'Contact_name,_mobile_number_or_email': '联系人姓名、手机号或邮箱！',
    Please_input_configuration_name: '请输入组态名称',
    Please_enter_the_account_number: '请输入账号',
    Please_enter_your_password: '请输入密码',
    Please_enter_your_mobile_phone_number: '请输入手机号',
    Please_enter_the_verification_code: '请输入验证码',
    Sort_by_online_status: '按在线状态排序',
    Default_sort: '默认排序',
    Online: '在线',
    Offline: '离线',
    Alarm: '报警',
    malfunction: '故障',
    Device_ID_or_name: '请输入设备编号或名称',
    Please_enter_the_device_id: '请输入设备ID',
    Please_enter_a_task_name: '请输入任务名称',
    Modbus_address_of_modbus_number: '地址或表号',
    Template_names_should_not_exceed_twenty_characters: '模板名称不可超过二十个字符',
    'Please_input_your_E-mail_template': '请添加您的邮件模板',
    Please_select_device_grouping: '请选择设备分组',
    Please_select_project_grouping: '请选择项目分组',
    'Function_code_is_04H,address_is_000AH,_you_should_fill_in_30011': '功能码04H，起始地址000AH，则填：30011',
    Maximum_register_length_is_64: '寄存器长度最大值为64',
    The_uplink_data_of_the_device_is_displayed_after_calculation_by_the_formula: '设备上行数据经公式计算后显示',
    Actively_write_data_to_the_device_and_send_it_to_the_data_after_the_inverse_calculation_formula:
      '主动向设备写数据经方向运算公式后下发',
    The_uplink_data_of_the_device_is_displayed_after_calculation_by_the_formula: '设备上行数据经公式计算后显示',
    Actively_write_data_to_the_device_and_send_it_to_the_data_after_the_inverse_calculation_formula:
      '主动向设备写数据经反向运算公式后下发',
    System_automatic_generation: '系统自动生成',
    Chose_the_configuration: '请选择组态',
    Please_input_register_address: '请输入寄存器地址',
    Please_input_slave_name: '请输入从机名称',
    Please_input_slave_IP: '请输入从机IP',
    Please_input_slave_port: '请输入从机端口',
    Please_input_slave_index: '请输入从机序号',
    Please_input_data_point_name: '请输入数据点名称',
    Please_input_number: '请输入序号',
    Please_enter_the_search_content: '请输入搜索内容',
    Please_enter_the_unit: '请输入单位',
    Please_enter_the_variable_description: '请输入变量描述',
    Please_enter_the_data_model_name: '请输入数据模板名称',
    Please_enter_a_device_name: '请输入设备名称',
    Please_enter_SN: '请输入SN',
    Please_enter: '请输入',
    Please_input_manager: '请输入负责人',
    Please_input_tel: '请输入联系电话',
    Please_select_take_charge_project: '请选择负责项目',
    Please_enter_the_template_name: '请输入模板名称',
    The_user_name_is_between_3_and_20_characters_long: '用户名长度在3-20个字符之间',
    The_user_name_cannot_contain_special_characters: '用户名不能包含特殊字符',
    'The_user_name_only_supports_Chinese,English_letters,Numbers,and_underscores':
      '用户名仅支持中文、英文字母、数字和下划线',
    Please_fill_in_the_remarks: '请填写备注信息',
    Processed_alarm: '已处理的报警',
    Unprocessed_alarm: '未处理的报警',
    Processed: '已处理',
    Unprocessed: '未处理',
    Restored: '已恢复',
    Not_restored: '未恢复',
    Please_enter_MAC: '请输入MAC码',
    'Please_enter_MAC/IMEI': '请输入MAC/IMEI码',
    'Please_enter_MAC/IMEI/NID': '请输入MAC/IMEI/NID码',
    Please_enter_the_number_of_added_devices: '请输入添加设备数',
    'Rang：1-64，integer': '参数范围：1-64的正整数',
    project_number_or_name: '项目编号或项目名称',
    Please_input_variable_name: '请输入变量名称',
    Please_select_variable: '请选择变量',
    Has_chosen: '已选择',
    data_points: '个数据点',
    variables: '个变量',
    SN_or_name: '请输入SN或设备名称',
    account_already_exists: '账号已经存在',
    Please_select_superior_series: '请选择上级系列',
    Please_enter_series_name: '请输入系列名称',
    Please_enter_more_than_50: '请输入长度为50之内的字符',
    Please_choose_upload_file: '请选择上传文件',
    Please_choose_the_date_and_time: '请选择日期时间',
    Please_choose_the_user_type: '请选择用户类型',
    Please_enter_articles_name: '请输入文章名称',
    Please_select_a_project: '请选择所属项目',
    already_exists: '已经存在',
    Please_enter_data_template_name: '请输入数据模板名称',
    '(Cannot_exceed_20_characters)': '(20位字符以内)',
    Please_assign_users: '请分配用户',
    Disable_enabled_state: '禁用启用状态',
    Please_enter_a_project_name: '请输入项目名称',
    '(Cannot_exceed_20_characters)': '(20位字符以内)',
    Please_select_the_imported_project: '请选择导入的项目',
    Please_select_the_imported_template: '请选择导入的数据模板',
    Please_input_slave_address: '请输入从机地址',
    'Please_choose_the_alarm_notification_you_want_to_delete!': '请选择要删除的报警通知！',
    Must_be_a_positive_integer: '必须为正整数',
    The_length_of_time_exceeds_the_limit: '时间长度超出限制',
    Please_select_tags: '请选择标签',
    Select_tags: '选择标签',
    Please_enter_a_category_name: '请输入类别名称',
    Please_enter_stroage_rangeability: '请输入变化幅度',
    The_length_of_value_exceeds_the_limit: '变化幅度长度超出限制',
    shutdown: '关机',
    run: '运行',
    standby: '待机',
    All_items: '全部项目',
    There_are_no_related_users_for_this_project: '该项目暂无关联用户',
    'Please_enter_0-10_characters': '请输入0-10位字符',
    Please_enter_deliver_data: '请输入下发的数据',
    Please_enter_contact_name_email: '请输入联系人姓名、邮箱',
    'Please_choose_the_task_you_want_to_delete!': '请选择要删除的定时任务！',
    Please_enter_the_variable_name: '请输入变量名称查询',
    choose_protocol_and_product: '请选择协议或产品',
    Please_enter_sorting_rule_for_down: '请输入排序（排序号越大越靠前）',
    please_input_interval_time: '请输入间隔时间',
    Please_enter_product_agreement_name: '请输入产品/协议名称',
    Order_number_or_resource_name_query: '订单号或资源名查询',
  },
  //表单验证规则提示
  rules: {
    please_input_monitor_name: '请填写监控名称',
    please_input_the_number: '请输入数字',
    please_input_account: '请填写账号',
    please_input_phoneOrEmail: '请输入手机号或邮箱',
    Please_enter_the_correct_phone_number_or_email: '请输入正确的手机号或邮箱',
    please_input_serial_number: '请填写序列号',
    please_input_verification_code: '请填写验证码',
    please_select_device: '请选择设备',
    please_input_domain_name: '请填写自定义域名',
    please_input_system_name: '请填写系统名称',
    please_input_company_name: '请填写公司名称',
    please_input_domain_name: '请填写域名',
    please_select_expiration_data: '请选择截止日期',
    please_input_email: '请填写邮箱账号',
    please_input_email_authorization_code: '请填写邮箱授权码',
    please_input_email_server: '请填写邮件服务器',
    please_input_port: '请填写端口号',
    the_mail_format_is_incorrect: '邮箱格式不正确',
    Please_enter_the_role_name: '请输入角色名称',
    Please_enter_the_role_description: '请输入角色描述',
    Please_enter_the_verification_code: '请输入验证码',
    Please_enter_your_mobile_phone_number: '请输入手机号',
    The_phone_number_must_be_a_numeric_value: '手机号必须为数字值',
    Please_fill_in_at_least_one_type_of_alarm_contact: '请至少填写一种报警联系方式',
    Phone_number_format_error: '请输入正确的手机号码',
    please_input_your_vaild_email: '请输入正确的邮箱',
    Remarks_cannot_be_greater_than_200_characters: '备注不能大于200字符',
    Enter_the_password: '请输入密码',
    Enter_the_passwordagain: '请再次输入密码',
    Enter_the_repassword: '请输入确认密码',
    The_two_passwords_do_not_match: '两次输入密码不一致',
    please_input_name: '请输入姓名',
    please_input_position: '请输入职位',
    Please_enter_a_user_name: '请输入用户名',
    reset_passwords: '重置密码',
    Please_select_trigger_condition: '请选择触发条件',
    Please_select_linkage_device: '请先选择联动设备',
    Please_select_linkage_slave: '请先选择联动从机',
    Please_select_linkage_node: '请先选择联动数据点',
    Please_select_linkage_data_model: '请先选择联动数据模板',
    'Please_input_trigger_name!': '请输入触发器名称!',
    '1_to_20_characters_in_length': '长度在 1 到 20 个字符',
    'Minimum_value_can_not_be_empty!': '最小值不能为空！',
    'Please_fill_in_the_correct_minimum!': '请填写正确的最小值！',
    'Please_fill_in_the_correct_maximum!': '请填写正确的最大值！',
    'Please_fill_in_the_correct_maximum_or_minimum!': '请填写正确的最大值或最小值！',
    'Maximum_value_can_not_be_empty!': '最大值不能为空！',
    'Maximum_or_minimum_cannot_be_empty!': '最大值或最小值不能为空！',
    'Please_select_model_node!': '请选择模板数据点！',
    'The_alarm_mode_cannot_be_empty!': '报警方式不能为空！',
    'Alarm_contact/group_cannot_be_empty!': '报警联系人/组不能为空！',
    'Alarm_contact_cannot_be_empty!': '报警联系人不能为空！',
    'Contact_cannot_be_empty!': '联系人不能为空！',
    'Invalid_name!': '名字不能为空!',
    'The_data_sent_cannot_be_empty!': '下发数据不能为空!',
    Group_name_cannot_be_empty: '请输入设备分组名称',
    Sequence_cannot_be_empty: '排序不能为空',
    Sequence_must_be_numbers: '排序必须为数字',
    Please_select_the_processing_status: '请选择处理状态',
    Please_fill_in_the_processing_results: '请填写处理结果',
    Please_choose_slave: '请选择从机',
    Please_choose_data_point: '请选择数据点',
    Sending_data_cannot_be_empty: '发送数据不能为空',
    Please_enter_an_integer_or_decimal: '请输入小数或整数',
    Please_enter_the_hexadecimal_data_format: '请输入16进制数据格式',
    The_length_of_sending_data_should_not_exceed_256_characters: '发送数据长度不能超过256个字符',
    Please_input_template_name: '请输入模板名称',
    Template_names_should_not_exceed_twenty_characters: '模板名称不可超过二十个字符',
    please_input_AppID: '请输入AppID',
    please_input_AppSecret: '请输入AppSecret',
    please_input_template_ID: '请输入模板ID',
    please_input_template_name: '请输入模板名称',
    Please_select: '请选择上报频率',
    Please_select_report_type: '请选择上报方式',
    the_tel_format_is_incorrect: '联系电话格式不正确',
    the_Phone_number_format_is_incorrect: '手机号格式不正确',
    Passwords_are_only_Numbers_or_a_combination_of_upper_and_lower_case_letters: '密码仅为数字或大小写字母组合',
    association_role_cannot_be_empty: '关联角色不能为空！',
    take_charge_project_cannot_be_empty: '负责项目不能为空！',
    Slave_quantity_cannot_be_empty: '从机数量不能为空！',
    please_input_slave_quantity: '请输入从机数量',
    Slave_quantity_must_be_greater_than_zero: '从机数量必须大于0',
    Device_level_cannot_be_empty: '设备层级不能为空！',
    please_input_device_level: '请输入设备层级',
    Device_level_must_be_greater_than_0_and_Less_than_4: '设备层级必须大于0且小于4',
    Remarks_no_longer_than_30_characters: '备注长度不超过30个字符',
    Please_enter_the_article_title: '请输入文章标题',
    Plese_select_the_article_type: '请选择文章类型',
    '9_characters_in_length': '长度为9个字符',
    '1_to_15_characters_in_length': '长度在 1 到 15 个字符',
    '1_to_20_characters_in_length': '长度不能超过20个字符',
    '1_to_10_characters_in_length': '长度不能超过10个字符',
    Please_choose_protocols_and_drivers: '请选择协议和驱动',
    Please_select_storage_type: '请选择存储方式',
    Please_select_linkage_variable: '请先选择联动变量',
    'The_save_rule_cannot_be_empty!': '保存规则不能为空！',
    'The_trigger_status_cannot_be_empty!': '触发器状态不能为空！',
    Please_get_verification_code: '请先获取验证码',
    '1_to_30_characters_in_length': '长度不能超过30个字符',
    '4_to_30_characters_in_length,One_Chinese_character_occupies_two_characters': '请输入4-30位字符,一个汉字占2个字符',
    Please_select_storage_time: '请选择存储频率',
    The_register_address_format_is_not_valid: '寄存器地址格式不合法',
    Please_enter_the_article_content: '请输入文章内容',
    'Upload_picture_size_can_not_exceed_2MB!': '上传图片大小不能超过 2MB!',
    Upload_picture_size_can_not_exceed: '上传图片大小不能超过',
    'Upload_pictures_can_only_be_in_JPG_or_PNG_format!': '上传图片只能是 JPG或者PNG 格式!',
    Select_the_data_template_to_delete: '请选择要删除的数据模板',
    Upload_file_cannot_be_empty: '上传文件不能为空',
    Length_cannot_exceed_100_characters: '长度不能超过100个字符',
    'Up_to_five_images_can_be_uploaded_and_Upload_pictures_in_JPG/PNG_format_no_more_than_2MB!':
      '最多可上传五张图片且上传图片为不超过2MB的 JPG/PNG 格式!',
    'Up_to_five_images_can_be_uploaded!': '最多可上传五张图片!',
    'Please_upload_Wechat_Public_Number_Two-Dimensional_Code': '请上传微信公众号二维码',
    'Upload_file_can_not_exceed_15MB!': '上传文件大小不能超过 15MB!',
    'Upload_file_can_not_exceed_10MB!': '上传文件大小不能超过 10MB!',
    Please_input_rangeability: '请输入变化幅度',
    Rangeability_must_be_greater_than_0: '变化幅度必须大于0',
    'cannot_contain_special_characters_and_are_between_4_and_30_in_length,One_Chinese_character_occupies_two_characters':
      '不能包含特殊字符且长度需在4到30之间,一个汉字占2个字符',
    '6_and_20_characters,_including_at_least_2_letters,_Numbers_and_special_symbols,_excluding_Spaces':
      '6-20个字符，字母、数字和特殊符号至少包含2种，不包括空格',
    'The_push_mode_cannot_be_empty!': '推送方式不能为空！',
    'Pictures_can_only_upload_jpg/png_files,and_no_more_than_2M': '图片只能上传jpg/png文件，且不超过2M',
    Please_fill_in_at_least_one_type_of_contact: '请在手机号或者邮箱至少选择一种进行填写',
    '1_to_40_characters_in_length,One_Chinese_character_occupies_two_characters': '请输入1-40位字符,一个汉字占2个字符',
    The_download_data_cannot_be_empty_and_must_be_numerical: '下发数据不能为空，且必须为数值',
    The_interval_time_cannot_be_empty_and_must_be_numerical_and_non_zero: '间隔时间不能为空，且必须为非0整数数值',
    The_time_cannot_be_empty: '时间不能为空',
    Please_enter_trigger_threshold: '请输入触发阈值',
    Please_enter_the_value_of_A: '请输入A的值',
    Please_enter_the_value_of_B: '请输入B的值',
    'Please_fill_in_the_correct_alarm_value!': '请填写正确的报警值！',
    'Alarm_value_can_not_be_empty!': '报警值不能为空！',
    Please_enter_the_correct_device_ID: '请输入正确的设备Id',
    'Please_0-20_valid_characters': '请输入0-20位有效字符',
    'cannot_contain_special_characters_and_are_between_1_and_30_in_length,One_Chinese_character_occupies_two_characters':
      '不能包含特殊字符且长度需在1到30之间,一个汉字占2个字符',
    '1_to_30_characters_in_length,One_Chinese_character_occupies_two_characters': '请输入1-30位字符,一个汉字占2个字符',
    'cannot_contain_special_characters_and_are_between_1_and_64_in_length,One_Chinese_character_occupies_two_characters':
      '不能包含特殊字符且长度需在1到64之间,一个汉字占2个字符',
    Start_time_cannot_be_equal_to_end_time: '开始时间不能等于结束时间',
    The_length_of_url_should_not_exceed_256_characters: '链接长度不能超过256个字符',
    The_length_No_more_than_50_characters: '长度不能超过50个字符',
    The_length_of_product_model_should_not_exceed_256_characters: '产品型号长度不能超过256个字符',
    'cannot_contain_special_characters_and_are_between_1_and_256_in_length,One_Chinese_character_occupies_two_characters':
      '不能包含特殊字符且长度需在1到256之间,一个汉字占2个字符',
  },
  //确认弹框的提示语
  confirm: {
    Tips: '提示',
    'Are_you_sure_you_want_to_delete_this_record,_or_not?': '您将要删除该记录, 是否继续?',
    'Are_you_sure_you_want_to_delete_the_specified_alarm_contact?': '您确定要删除指定的警报联系人吗？',
    'Are_you_sure_you_want_to_edit_the_trigger_status?': '确定要编辑触发器的状态？',
    'Are_you_sure_you_want_to_delete_chosen_trigger?': '确定要删除指定触发器？',
    'Are_you_sure_delete_this_device?': '您将删除该设备，是否继续？',
    'Are_you_sure_delete_this_Data_model?': '您将删除该模板，是否继续？',
    'Are_you_sure_delete_this_monitor?': '您将删除该摄像头, 是否继续?',
    'You_are_going_to_switch_languages,_Do_you_want_to_continue?': '您将要切换语言, 是否继续?',
    'You_are_going_to_take_the_initiative_to_collect_data_points,_Do_you_want_to_continue?':
      '您将要主动采集数据点，是否继续?',
    'Are_you_sure_about_the_operation?': '是否确定操作?',
    Are_you_sure_you_want_to_start_this_task: '您确定启动该任务?',
    Are_you_sure_you_want_to_pause_this_task: '您确定关闭该任务?',
    'Are_you_sure_you_want_to_remove_this_contact?': '您确定要移除此联系人吗？',
    'Are_you_sure_to_switch?': '确定切换吗？',
    'Are_you_sure_you_want_to_change_the_enabled_state?': '您将要修改启用状态，是否继续？',
    'Are_you_sure_you_want_to_set_this_user_to_be_the_customer_super_administrator?':
      '您将要将此用户设为客户超级管理员，是否继续？',
    'Are_you_sure_you_want_to_set_this_user_to_be_level1_Vip?': '您将要将此用户设为一级会员，是否继续？',
    'Are_you_sure_you_want_to_set_this_user_to_be_neutral_Vip?': '您将要将此用户设为中性会员，是否继续？',
    'Are_you_sure_you_want_to_set_this_user_to_be_nonmember?': '您将要将此用户设为非会员，是否继续？',
    'Are_you_sure_you_want_to_set_this_role_to_be_the_default_role?': '您将要将此角色设为默认角色，是否继续？',
    'Are_you_sure_you_want_to_cancel_this_role_to_be_the_default_role?': '您将要取消此角色的默认角色，是否继续？',
    'You_do_not_have_a_role_to_associate_yet,_do_you_want_to_add_it?': '您目前还没有可关联的角色，是否去添加?',
    'Once_the_device_template_is_replaced,all_historical_data,alarm_etc_records_of_the_device_will_be_lost_and_cannot_be_recovered?':
      '一旦更换设备模板，将丢失该设备的所有历史数据、报警、定时任务等记录，且不可恢复，是否继续?',
    Are_you_sure_you_want_to_delete_this: '您将要删除该',
    ',or_not?': ',是否继续?',
    'Are_you_sure_to_export_the_data_template?': '是否导出数据模板？',
    'Are_you_sure_you_want_to_restart?': '您将要重启，是否继续？',
    'Are_you_sure_you_want_to_Factory_Reset?': '您将要恢复出厂设置，是否继续？',
    'You_are_sure_to_unbind_the_ys7Cloud_account?': '您确定要解绑萤石云账号？',
    'Are_you_sure_you_want_to_delete_the_repair_order?': '您将要结束该工单, 是否继续?',
    'The_phone_number_has_been_bound_to_other_users,_shall_we_release_the_binding_relationship_with_other_users?':
      '手机号已绑定了其他用户，是否解除与其他用户的绑定关系?',
    'The_unfinished_work_orders_under_the_maintenance_plan_will_be_automatically_terminated,_Do_you_want_to_continue?':
      '该维保计划下的未完成工单会被主动结束，确定删除维保计划？',
    'Your_application_for_registration_has_been_received,_The_administrator_will_review_it_as_soon_as_possible':
      '已收到您的注册申请,管理人员将会尽快审核',
    'Are_you_sure_it_is_enabled?': '您确定启用么？',
    'Are_you_sure_to_disable_it?': '您确定禁用么？',
    'You_are_about_to_apply_through_this_account,_Do_you_want_to_continue?': '您即将通过此账号的申请, 是否继续?',
    'This_function_will_be_opened_soon,_do_you_want_to_continue?': '即将开通该功能, 是否继续?',
    'User_added_successfully,_whether_to_assign_device_for_user?': '用户添加成功，是否为用户分配设备?',
    'This_operation_will_close_sharing,do_you_want_to_continue?': '此操作将关闭分享, 是否继续?',
    'This_operation_will_enable_sharing,do_you_want_to_continue?': '此操作将开启分享, 是否继续?',
    'The_order_cannot_be_restored_after_cancellation,_Are_you_sure_to_cancel': '订单取消后将无法恢复，您确认取消?',
  },
  //传入表格组件的表头名字
  column: {
    Name: '名称',
    DATA_MODEL: '数据模板',
    Node: '数据点',
    Alarm_dead_zone: '报警死区',
    Alarm_push_content: '报警推送内容',
    Resume_normal_push_content: '恢复正常推送内容',
    Trigger_name: '触发器名称',
    Alarm_contact: '报警联系人',
    Alarm_contact_group: '报警联系组',
    Open_linkage: '开启联动',
    Linkage_object: '联动对象',
    Linkage_type: '联动类型',
    Deliver_data: '下发数据',
    Linkage_parameter: '联动参数',
    Data_model_name: '数据模板名称',
    Triggering_condition: '触发条件',
    Save_rule: '保存规则',
    Alarm_method: '报警方式',
    Linkage: '联动',
    Trigger_status: '触发器状态',
    Device_name: '设备名称',
    onlineStatus: '在线状态',
    Monitor_Management: '摄像头管理',
    Monitor_name: '摄像头名称',
    Associated_device: '关联设备',
    Serial_number: '序列号',
    Verification_Code: '验证码',
    Update_time: '更新时间',
    Operation: '操作',
    Slave_name: '从机名称',
    Current_value: '当前值',
    Data_point_name: '数据点名称',
    Type_of_data: '数据类型',
    Open_state: '开启状态',
    Role_Name: '角色名字',
    Role_description: '角色描述',
    Creation_time: '创建时间',
    Account: '用户名',
    Email: '邮箱',
    Time_of_last_login: '上次登录时间',
    Group_name: '组名',
    Node_number: '组节点数',
    Twin_group_node_number: '对应组节点数',
    User: '所属用户',
    Device_tag_name: '分组名称',
    Note: '分组描述',
    Device_ID: '设备ID',
    Time: '时间',
    Alarm_content: '报警内容',
    Alarm_time: '报警时间',
    Alarm_state: '报警状态',
    Process_state: '处理状态',
    Value: '数值型',
    Switching_status: '开关型',
    Character_type: '字符型',
    Location_type: '定位型',
    bytes_unsigned_integer: '字节无符号整数',
    bytes_signed_integer: '字节有符号整数',
    'bytes_unsigned_integer(AB_CD)': '字节无符号整数(AB CD)',
    'bytes_unsigned_integer(CD_AB)': '字节无符号整数(CD AB)',
    'bytes_signed_integer(AB_CD)': '字节有符号整数(AB CD)',
    'bytes_signed_integer(CD_AB)': '字节有符号整数(CD AB)',
    'bytes_float(AB_CD)': '字节浮点型(AB CD)',
    'bytes_float(CD_AB)': '字节浮点型(CD AB)',
    byte_base_station_positioning: '字节定位',
    byte_GPS_positioning: '字节GPS定位',
    '(Precision)': '小数位数',
    Read_only: '只读',
    'Write&Read': '读写',
    Just_write: '只写',

    Register: '寄存器',
    Node_type: '数据类型',
    Data_type: '数值类型',
    Precision: '小数位数',
    Data_storage: '存储类型',
    Units: '单位',
    Not_storage: '不存储',
    Change_storage: '变化时存储',
    Storage: '存储',
    task_name: '任务名称',
    Function_center: '功能中心',
    execution_event: '执行事件',
    execution_object: '执行对象',
    contents_of_execution: '执行内容',
    actuating_equipment: '执行设备',
    execution_time: '执行时间',
    Number: '序号',
    Device_number: '设备号',
    Data_model: '关联模板',
    Status: '状态',
    Formula: '公式',
    Inverse_formula: '反向运算公式',
    Data_storage: '数据存储',
    Register_length: '寄存器长度',
    Node_note: '变量描述',
    Floating_point_precision: '反向运算浮点精度',
    Timer_status: '定时器状态',
    History: '历史查询',
    read: '读取',
    Regular_report: '定时上报',
    Change_report: '变化上报',
    Real_time_report: '实时上报',
    '(not_recommend)': '(不推荐)',
    IP: '从机IP',
    SlaveName: '从机名称',
    Port: '端口',
    Parameter_name: '参数名称',
    Sampling_frequency: '采集频率',
    Report_type: '上报方式',
    PLC_model: 'PLC型号',
    Template_name: '模板名称',
    Slave_quantity: '从机数量',
    Data_point_quantity: '数据点数量',
    Register_type: '寄存器类型',
    Advanced_options: '高级选项',
    Device_template_name: '设备模板名称',
    '175px': '120px',
    Variable_name: '变量名称',
    'Slave/Variable': '从机/变量',
    ConfigurationDesign: '组态设计',
    Firmware_Name: '固件名称',
    Equipment_Series: '设备系列',
    Equipment_Type: '设备型号',
    Firmware_Version: '固件版本',
    Adding_Time: '添加时间',
    Firmware_Quantity: '固件数量',
    Release_Time: '发布时间',
    User_Group: '用户群',
    Upgrade_Progress: '升级进度',
    Upgraded_Version: '升级版本',
    Wait_Upgraded_Version: '目标版本',
    Data_format: '数据格式',
    Publication_Time: '发表时间',
    Whether_to_delete_the_following_checked_items: '是否删除下列选中项',
    Log_type: '日志类型',
    Upload_time: '上传时间',
    Log_size: '日志大小',
    Monitor_type: '摄像头类型',
    data_model: '数据模板',
    Device_num: '设备数量',
    Name_of_alarm_notification: '报警通知名称',
    Number_of_associated_devices: '关联设备数量',
    Enabled_state: '启用状态',
    Address: '地址',
    'Do_not_collect_(active_reporting)': '不采集(主动上报)',
    Utilization_rate: '稼动率',
    Original_version: '原版本',
    Alarm_Rules: '报警规则',
    Relevant_Contacts: '关联联系人',
    Association_operation: '关联操作',
    Config_Progress: '配置进度',
    Task_status: '任务状态',
    Enable_the_alarm: '启用报警',
    Push_contact: '推送联系人',
    Linkage_variable: '联动变量',
    Execution_interval: '执行间隔',
    Execution_task: '执行中任务',
    Upgrade_results: '升级结果',
    Configuration_result: '配置结果',
    Linkage_data_point: '联动数据点',
    MAC: 'MAC',
    IMEI: 'IMEI',
    IMEI2: 'IMEI2',
    Model_name: '模块名称',
    ReadWrite: '读写方式',
    Number_of_client_software_or_devices: '客户端软件或设备数',
  },
  //页面里的词语
  content: {
    Focus_on_WeChat_official_account: '关注公众平台',
    Click_to_view_my_device: '"我的设备"查看',
    Add_data_model: '添加数据模板',
    Add_device: '添加设备',
    'Configure&install_device': '接入设备',
    Monitor: '监控中心',
    Device_count: '设备数',
    Node_count: '数据点数',
    Trigger_count: '触发器数',
    'Sub-account_count': '二级用户数',
    Device_settings: '管理设备',
    Node_settings: '管理数据点',
    Trigger_settings: '管理触发器',
    Account_settings: '管理用户',
    time_of_last_login: '上次登录时间',
    login_times: '登录次数',
    Cloud_SCADA: '物联网云组态',
    Cloud_Interface: '物联网云接口',
    Transferring: '物联网云交换机',
    update_log: '更新记录',
    More: '立即查看',
    Json_data_flow: 'Json数据流',
    Raw_data_flow: '原始数据流',
    Enabled: '开启',
    Disabled: '关闭',
    SMS: '短信',
    Wechat: '微信',
    'E-mail': '邮件',
    OFF: '开关OFF',
    ON: '开关ON',
    Value_is_less_than: '数值小于',
    Value_is_more_than: '数值大于',
    and_less_than: '并小于',
    or_less_than: '或小于',
    Value_is_more_than_A_and_less_than_B: '数值大于A且小于B',
    Value_is_more_than_B_or_less_than_A: '数值小于A或大于B',
    Value_is_equal: '数值等于',
    Save: '保存阈值',
    Not_save: '不保存',
    Save_All: '保存所有报警',
    Unselected: '未选',
    Selected: '已选',
    '(contacts)': '（联系人）',
    '(contact_group)': '（联系组）',
    Device_in_group: '透传组设备',
    Device_in_twin_group: '对应透传组设备',
    Device_list: '设备列表',
    Data_are_transferred_between_the_group_and_twin_group: '注:透传组仅支持数据透传的通讯协议。',
    Default_group: '默认分组',
    Default_project: '默认项目',
    All_group: '全部分组',
    Top_menu: '顶级菜单',
    Device_name: '设备名称',
    Device_information: '设备信息',
    Device_ID: '设备编号',
    Protocol: '通讯协议',
    Cloud_Key: '通讯密码',
    Querying_frequency: '采集频率',
    Graph: '历史曲线',
    Device: '设备',
    Time: '时间段',
    Slave: '从机',
    Node: '数据点',
    Wait_for_procession: '未处理',
    Misinformation: '误报',
    Processed: '已处理',
    Process: '处理',
    Not_process: '不处理',
    restore_to_normal: '恢复正常',
    Recovery: '故障恢复',
    alarm: '报警',
    Alarm_history: '报警历史记录',
    Send_data_to_data_points: '向数据点发送数据',
    Data_point_distribution: '数据点下发',
    Plc_data_point_distribution: '变量下发',
    Send_data_to_the_device: '向设备发送数据',
    A_stream_of_hexadecimal_data: '16进制数据流发送',
    Add_slave: '添加从机',
    menu: '菜单',
    link: '链接',
    user_bind: '用户绑定',
    user_unbind: '用户解绑',
    close_video: '关闭视频',
    data_point_number: '关联数据点数',
    // 设备类型
    Default: '默认设备',
    LoRaWAN_module: 'LoRaWAN模块',
    'Networking_I/O_controller': '网络IO',
    Add_device_via_QR_code: '二维码添加',
    LoRa_module: 'LoRa模块',
    LoRa_concentrator: 'LoRa集中器',
    'Telecom_CoAP/NB-IoT': '电信CoAP/NB-IoT',
    PLC_cloud_gateway: 'PLC云网关',
    New_PLC_cloud_gateway: '新版PLC云网关',
    // 通讯协议
    Transfering: '数据透传',
    USR_Smoking_Sensor: '有人烟感',
    Automatically_create: '系统自动生成',
    Default_cloud_key: '账号默认通讯密码',
    lng: '经度',
    lat: '纬度',
    'SN/Check_code': 'SN/校验码',
    Positioning: '正在定位',
    Modify_the_default_custom_domain_name: '修改默认自定义域名',
    The_device_has_no_location_information: '该设备暂无位置信息',
    Tips: '提示',
    //设备调试
    Receive_in_hexadecimal: '按十六进制接收',
    Send_in_hexadecimal: '按十六进制发送',
    //设备详情
    The_device_does_not_have_address_targeting: '该设备没有地址定位',
    The_device_has_no_data_points: '该设备没有数据点',
    Data_point_ID: '数据点ID',
    Variable_ID: '变量ID',
    Receiving_device: '接收设备',
    All: '全部',
    Send_as_hex: '按16进制发送',
    Selected_device: '已选择设备',
    Select_quantity: '选择数量',
    '(Positioning_device)': '定位型设备',
    address: '详细地址',
    Please_choose_data_model: '请选择关联模板',
    On: '开',
    Off: '关',
    No_Vip: '非会员',
    level1_Vip: '一级会员',
    neutral_Vip: '中性会员',
    Edit_slave: '编辑从机',
    Normal: '正常',
    Deleted: '已删除',
    Registration_successful_and_logging: '注册成功,登录中...',
    Registration_successful_and_Contact_the_administrator_for_review: '注册成功,请联系管理员进行审核！',
    uploadCoverImage: '上传平面图： ',
    Create_New_Contacts: '新建联系人',
    Binding_account: '绑定账号',
    Contacts_Have_Been_Selected: '已选择联系人：',
    The_device_has_no_variable: '该设备没有变量',
    FTP_Upgrade: 'FTP升级',
    HTTP_Upgrade: 'HTTP升级',
    All_Users: '所有用户',
    Designated_User: '指定用户',
    Task_Information: '任务信息',
    Select_Device: '选择设备',
    To_be_upgraded: '待升级',
    Upgradeing: '升级中',
    configurating: '配置中',
    Data_point_alarm: '数据点报警',
    Cloud_monitoring_alarm: '云监测报警',
    Upgrade_Success: '升级成功',
    Upgrade_Failed: '升级失败',
    Waiting: '等待中',
    In_Execution: '执行中',
    Has_Ended: '已结束',
    No_configuration_yet: '暂无组态',
    The_device_has_no_configuration_yet: '该设备暂无组态',
    The_device_does_not_have_a_slave: '该设备不存在从机',
    Conduct_Configure: '进行配置',
    Serial_Port_Parameters: '串口参数',
    All_status: '全部状态',
    Article: '文章',
    Success: '成功',
    Video_URL: '视频URL',
    Video_width: '视频宽度',
    Video_height: '视频高度',
    Symbols: '符号',
    'What_would_you_like_to_say?': '您想说点什么？',
    receive_mode: '接收方式',
    transmit_mode: '发送方式',
    hexadecimal: '十六进制',
    string: '字符串',
    After_Focusing_on_the_Public_Number: '关注公众号后',
    Scan_code_binding_WeChat: '扫码绑定微信',
    Binded_Users: '已绑定用户',
    After_clicking_the_button_you_can_select_the_data_template_where_belong_to_the_device_from_the_filter_menu_of_the_data_template:
      '点击按钮后可以在数据模板的筛选菜单中选择设备自带的数据模板',
    After_clicking_the_button_you_can_select_the_data_template_of_network_IO_device_from_the_filter_menu_of_the_data_template:
      '点击按钮后可以在数据模板的筛选菜单中选择网络IO设备的数据模板',
    Click_the_button_again_to_select_the_data_template_in_the_current_account_from_the_filter_menu_of_the_data_template:
      '再次点击按钮可以在数据模板的筛选菜单中选择当前账号内的数据模板',
    Software_Version: '软件版本',
    Hardware_Version: '硬件版本',
    Commonly_used_instructions: '常用指令',
    User_of_Equipment: '设备所属用户',
    User_Address: '用户地址',
    User_Phone: '用户手机号',
    User_remark: '用户备注',
    Selected_Equipment: '已选设备',
    Project_master_account: '项目主账户',
    Firmware_version_can_upgraded_to_this_version: '可升级到此版本的固件版本',
    series: '系列',
    Cloud_configuration_function: '云组态功能',
    Add_project: '创建项目',
    Value_is_less_than_A_and_more_than_B: '数值小于A且大于B',
    The_execution_of_Warranty_period_will_stop_when_it_expires: '保内维保到期停止执行',
    The_repair_order_progress: '工单进度',
    'Engineers_are_being_assigned_to_your_problem,_Please_be_patient!': '正在为您的问题分配工程师，请耐心等候！',
    Engineers_have_been_assigned_to_your_problem: '已经为您的问题分配了工程师',
    Please_wait_patiently: '请耐心等候',
    Engineers: '工程师',
    Working_on_your_problem: '正在解决您的问题',
    has_finished_the_work_order: '已经完成了工单',
    Please_confirm_whether_the_problem_has_been_solved_in_time: '请您及时确认问题是否得到解决',
    Take_the_initiative_to_end: '主动结束',
    Process_and_results: '处理过程及结果',
    Purchase_usr_4G_camera: '购买有人4G摄像头',
    Create_New_Role: '新建角色',
    Device_data_points: '设备数据点数',
    Fast_access: '快速接入',
    Configuring_device_templates_according_to_data_rules: '根据数据规则配置设备模板',
    Add_devices_on_the_cloud_based: '设备在云端添加',
    Hardware_debugging: '硬件调试',
    Connecting_devices_to_the_cloud_based: '设备和云端进行连接',
    Cloud_based_View: '云端查看',
    Viewing_data_by_monitoring_screen_and_mobile_phone: '通过监控大屏和手机端查看数据',
    Overview_of_Cloud: '透传云概况',
    Quick_Start_Tutorial: '快速入门教程',
    'Follow_WeChat_Subscription,receive_alarm_push': '关注公众号，接收微信报警推送',
    Use_Teaching: '使用教学',
    See_more: '查看更多',
    Solution: '解决方案',
    Industrial_PLC: '工业PLC',
    Electric_power_monitoring_and_acquisition: '电力监控、采集',
    Intelligent_agriculture: '智慧农业',
    _time: '次',
    Your_browser_does_not_support_Video_Tags: '你的浏览器不支持video标签',
    Number_of_subusers: '子用户数',
    Air_environment_control_system: '空气环境控制系统',
    Full_Firmware: '全量固件',
    Incremental_firmware: '增量固件',
    have_all_the_equipment_permissions_under_their_own_associated_projects: '拥有自己关联项目下的所有设备权限',
    Work_order_administrator_or_maintenance_personnel_have_all_the_equipment_permissions_under_their_own_associated_projects:
      '工单管理员或维保人员拥有自己关联项目下的所有设备权限',
    The_work_order_has_not_been_allocated_yet: '工单还未分配',
    The_work_order_has_been_assigned_to_the_engineer: '工单已分配给工程师',
    has_accepted_the_work_order: '已受理工单',
    has_finished_the_work_order: '已经完成了工单',
    process_mode: '处理方式',
    Send_orders_personnel: '派单人员',
    Site_disposal: '现场处理',
    'Off-site_disposal': '非现场处理',
    '1、leave_for': '1、前往',
    fluorite_open_platform: '"萤石开放平台"',
    registered_account: '注册帐号;',
    synching: '同步中',
    Telephone_Voice: '电话语音',
    Offline_time_of_equipment: '设备离线时间',
    'Within_minutes,the_number_of_dropouts_exceeded': '分钟内，掉线次数超过',
    Wireless_Signal_Intensity: '无线信号强度',
    Current_monthly_flow_consumption: '当月流量消耗',
    Battery_level: '电池电量',
    Card_inconsistency: '机卡不一致',
    Push_rule: '推送规则',
    Push_Notice: '推送通知',
    Push_mechanism: '推送机制',
    Only_the_first_push: '仅第一次推送',
    interval: '间隔',
    Push_time_interval: '推送时间间隔',
    Push_once_within_minute: '分钟推送一次',
    minute: '分钟',
    I_want_to_run_neutral: '我想要中性运行',
    'Neutral_operation_minimum_10,000,_consulting_sales': '中性运行最低1万起，咨询销售',
    You_can_use_your_own_domain_name_for_login_and_your_own_company_information_for_login_information:
      '可使用公司自己的域名登录，登录信息使用自己的公司信息',
    Remove_all_information_and_links_from_the_USR_Cloud_corporate_operating_system:
      '去除“有人物联网”公司的所有信息和链接运行系统',
    'WeChat、_email_alarm_can_use_their_own_company_mailbox_and_WeChat': '微信、邮件报警可以使用自己公司的邮箱和微信',
    'Small_program_neutral_operation,_custom_LOGO': '小程序中性运行、自定义LOGO',
    The_first_method: '方式一',
    The_second_method: '方式二',
    Offline_alarm: '离线报警',
    Signal_intensity: '信号强度',
    Flow_consumption: '流量消耗',
    Electronic_fence: '电子围栏',
    Alarm_type: '报警类型',
    Cloud_monitor_function: '云监测功能',
    Overview_of_Cloud_confige: '云组态概况',
    Configuration: '组态',
    Template_settings: '管理模板',
    Operator: '操作人',
    'Contact_at_least_one_of_e-mails,and_Wechat': '联系方式至少填写邮箱、微信的其中一种',
    'Contact_at_least_one_of_mobile_phones,e-mails,and_Wechat': '联系方式至少填写手机、邮箱、微信的其中一种',
    'Contact_at_least_one_of_mobile_phones,e-mails': '联系方式至少填写手机、邮箱的其中一种',
    Please_fill_in_your_email_address_for_contact_information: '联系方式请填写邮箱',
    'Contact_at_least_one_of_mobile_phones,Wechat': '联系方式至少填写手机、微信的其中一种',
    Network_type: '网络类型',
    Basic_Equipment_Information: '基本信息',
    Network_information: '网络信息',
    Equipment_Recording: '设备记录',
    The_first_step: '第一步',
    The_second_step: '第二步',
    Send_data_to_variable: '向变量发送数据',
    Slave_no_answer: '从机无应答',
    The_slave_gave_an_error_response: '从机给了一个错误应答',
    unable_to_connect: '无法连接',
    No_such_key: '无此key',
    Data_collection_failed: '数据采集失败',
    Decimal_system: '十进制',
    The_validation_code_is_sent_successfully_and_the_input_is_valid_within_5_minutes: '验证码发送成功，5分钟内输入有效',
    Go_To_USR_Account: '进入有人云',
    Update_content: '更新内容',
    Open: '开通',
    Opened: '已开通',
    person_wants: '人想要',
    Unnamed: '未命名',
    Cloud_configuration: '云组态',
    Cloud_monitoring: '云监测',
    Check_All: '全选',
    useful: '常用语',
    Get_log_time_period: '获取日志时间段',
    Get: '获取',
    weakest: '极弱',
    weak: '弱',
    middle: '中',
    strong: '强',
    strongest: '极强',
    Already_begun: '已开始',
    Bytes: '字节数',
    Equipment_flow_monitoring: '设备流量监测',
    Upload_traffic: '上传流量',
    Download_traffic: '下载流量',
    Signal_quality_monitoring: '信号质量监测',
    signal_intensity: '信号强度',
    Configuration_failure: '配置失败',
    Configuration_success: '配置成功',
    To_be_configured: '待配置',
    In_configuration: '配置中',
    Serial_command_cannot_be_configured_multiple_times_at_the_same_time: '串口指令不能同时配置多次',
    Client_list: '客户列表',
    Subuser_list: '子用户列表',
    Manager_staff_list: '管理人员工单',
    Maintenance_engineer_order: '维修工程师工单',
    Ethernet: '以太网',
    Being_acquired: '正在获取',
    Get_success: '获取成功',
    Acquisition_failure: '获取失败',
    Main_equipment: '主设备',
    Copy_link_and_password: '复制链接和密码',
    Close_to_share: '关闭分享',
    Password_required_for_access: '访问需要密码',
    QR_code: '二维码',
    Share_the_qr_code_with_others: '将二维码分享给他人',
    The_other_side_can_view_through_WeChat_scan: '对方通过微信扫一扫即可查看',
    Download_qr_code: '下载二维码',
    Open_to_share: '开启分享',
    Mode: '模式',
    High_power_consumption: '高功耗',
    Low_power_consumption: '低功耗',
    all_project_contacts: '(所有项目联系人)',
    Please_select_the_device_first: '请先选择设备',
    Click_share_to_share_the_configuration_screen_of_the_selected_device: '点击“分享”，对所选设备的组态画面进行分享',
    Return_to_continue_sharing: '返回继续分享',
    Silence_interval: '沉默时间间隔',
    Quantity_statistics_of_each_function: '各功能数量统计',
    Ranking: '排名',
    Statistics_of_number_of_users: '用户数量统计',
    Statistics_of_active_users: '活跃用户统计',
    Statistics_of_connected_devices: '连接设备统计',
    Statistics_of_active_equipment: '活跃设备统计',
    Statistics_of_active_equipment_models: '活跃设备型号统计',
    Thermal_diagram_of_equipment: '设备热力图',
    Number_of_registered_devices: '注册设备数量',
    Number_of_equipment_configuration_drawings: '设备组态图数量',
    Number_of_sub_users: '子用户数量',
    Number_of_devices_enabling_cloud_configuration: '开启云组态的设备数量',
    Users: '用户',
    Notice: '通知',
    Amount: '金额',
    Data: '数据',
    Total_change_in_the_past_year: '近一年总量变化',
    New_in_recent_30_days: '近30天新增',
    Monthly_activity_in_the_past_year: '近一年月活跃度',
    Daily_activity_in_the_past_30_days: '近30天日活跃度',
    Activity_in_the_past_30_days: '近30天活跃度',
    Yesterday_activity: '昨日活跃度',
    Voice: '语音',
    fail: '失败',
    Manual_positioning: '手动定位',
    Automatic_positioning: '自动定位',
    Register_Time: '注册时间',
    product_group: '产品分组',
    Add_root_group: '添加根分组',
    Product_agreement_list: '产品协议列表',
    system_exception: '系统异常',
    Equipment_online: '设备上线',
    Equipment_out_of_line: '设备不在线',
    Version_mismatch: '版本不匹配',
    Issued_instructions: '下发指令',
    Equipment_receiving_instruction: '设备收到指令',
    Firmware_download_failed: '固件下载失败',
    Firmware_download_completed: '固件下载完成',
    Arrival_end_time: '到达结束时间',
    Product_protocol_grouping: '产品协议分组',
    Product: '产品',
    every_interval: '每间隔',
    minutes_execute_once: '分钟执行一次',
    Alarm_silence_time: '报警沉默时间',
    Please_check_document_configuration_neutral: '请查看文档配置中性',
    Protocol_group: '协议分组',
    Product_group: '产品分组',
    Edge_group: '边缘端',
    Management_end: '管理端',
    See_how_to_use: '查看如何使用',
    How_to_use: '如何使用',
    'What_effect_can_be_achieved_through_the_transparent_transmission_strategy?': '通过透传策略，能实现什么功能？',
    Strategy_start_and_stop: '策略启停',
    Passthrough_target: '透传目标',
    client_software: '客户端软件',
    Real_device: '真实设备',
    Available_client_software: '可用的客户端软件有',
    Achievable_effects: '可实现的效果',
    Official_virtual_serial_port_software:
      '1、官方提供的虚拟串口软件，<a href="http://www.usr.cn/Download/924.html" target="_blank">点击下载</a>',
    'In_the_PC_virtual_a_virtual_serial_port,_the_user_is_serial_software_can_open_the_virtual_serial_port':
      '在PC上虚拟出一个虚拟串口，用户的串口软件可以打开这个虚拟串口',
    Data_from_each_device_in_the_edge_end_device_group_can_be_received_through_this_virtual_serial_port:
      '可通过此虚拟串口，接收来自“边缘端”设备组中的每个设备的数据',
    'Send_data_to_this_virtual_serial_port,_and_each_device_in_the_edge_end_device_group_will_receive_it':
      '向此虚拟串口发送数据，则“边缘端”设备组中的每个设备都将收到',
    Other_software_developed_based_on_the_SDK_provided_by_us: '基于我们提供的SDK，开发的其他软件。（SDK敬请期待）',
    data_from_each_device_at_the_edge_end_is_passed_to_each_device_at_the_management_end_and_vice_versa:
      '注：来自“边缘端”的每个设备的数据，都将透传给“管理端”的每个设备，反之亦然',
    The_passThrough_policy_has_been_successfully_created: '已成功创建透传策略',
    The_passthrough_policy_has_been_edited_successfully: '已成功编辑透传策略',
    'We_did_not_create_a_passThrough_policy_for_you,_because_communication_from_PC_software_to_a_single_device_requires_no_additional_configuration_of_the_passThrough_policy,_All_passThrough_devices_support_this_usage_natively':
      '我们并没有为您创建透传策略，因为PC软件到单个设备的通信，无需额外配置透传策略。所有支持透传的设备，都原生支持这种用法。',
    Device_data_storage: '设备数据存储量',
    What_is_product_library: '什么是产品库',
    Product_warehouse: '入驻产品库',
    Difference_between_edge_computing_and_Cloud_Collection: '边缘计算和云端轮询的区别',
    How_to_monitor_multiple_slaves: '如何监控多个从机',
    How_to_configure_design: '如何组态设计',
    How_to_view_the_configuration_screen: '如何查看组态画面',
    About_user_rights_definitions: '关于用户权限定义',
    About_device_positioning: '关于设备定位',
    Click_to_buy: '点击购买',
    Variable_name: '变量名',
    Data_pattern: '数据模式',
    Mobile_scan_add_device_quickly: '手机端扫一扫，快速添加设备',
    Order_summary: '订单摘要',
    User_purchase: '购买用户',
    Payment_amount: '支付金额',
    Order_amount: '订单金额',
    Delivery_status: '发货状态',
    Order_type: '订单类型',
    Invoice_information: '发票信息',
    Type_of_opening: '开具类型',
    Invoice_status: '发票状态',
    Invoice_rise: '发票抬头',
    Nature_of_invoicing: '开票性质',
    Invoice_type: '发票类型',
    Amount_of_invoice: '开票金额',
    Tax_registration_certificate_No: '税务登记证号',
    Receiving_mailbox: '接收邮箱',
    Name_of_Bank_of_deposit: '开户银行名称',
    Basic_account_No: '基本开户账号',
    Registered_address: '注册场所地址',
    Sign_up_for_landline: '注册固定电话',
    Unpaid: '未支付',
    Paid: '已支付',
    Cancelled: '已取消',
    Failure: '已失效',
    Order: '订单',
    No_application: '未申请',
    Have_applied_for: '已申请',
    In_audit: '审核中',
    Arrival_account: '已到账',
    Alipay: '支付宝',
    Under_the_line: '线下',
    Account_balance: '账户余额',
    Recharge_amount: '充值金额',
    Recharge_users: '充值用户',
    Payment_status: '支付状态',
    VAT_general_invoice: '增值税普通发票',
    VAT_special_invoice: '增值税专用发票',
    Unshipped: '未发货',
    Shipped: '已发货',
    Edge_device_to_customer_software: '实现“设备”与“客户端软件”间的数据透传',
    Edge_devices_to_real_physical_devices: '实现“设备”与“设备”间的数据透传',
    Order_purchase_resources: '订单购买资源',
    Purchase_content: '购买内容',
    USR_user: '有人云用户',
    Reseller_users: '经销商用户',
    resources_name: '资源名称',
    Price: '售价',
    Purchase_quantity: '购买数量',
    Recharge: '充值',
    Renew: '续期',
    New_purchase: '新购',
  },
  //help帮助栏里面的文字
  help: {
    'E-mail_Server': '邮件服务器',
    User_WeChat: '用户微信',
    'E-mail_wildcards': '邮件通配符',
    Neutral_public_number_alarm_push: '中性公众号报警推送',
    Click_to_see_how_to_set_it_up: '点击查看如何设置',
    Device_category: '设备类',
    Slave_category: '从机类',
    Data_point_category: '数据点类',
    Trigger_category: '触发器类',
    User_information_category: '用户信息类',
    device_ID: '设备ID',
    device_name: '设备名称',
    slave_index: '从机序号',
    slave_name: '从机名称',
    data_point_ID: '数据点ID',
    data_point_name: '数据点名称',
    data_point_value: '数据点值',
    trigger_name: '触发器名称',
    trigger_condition: '触发条件',
    trigger_time: '触发时间',
    account: '用户名称',
    System_setting: '系统设置',
    Domain_name_resolution: '域名解析',
    Same_as_SCADA_software_register: '与组态软件的寄存器写法相同，填十进制寄存器地址，寄存器为起始地址+1.',
    //设备调试的help里的文字
    Data_transmission_instructions: '数据发送说明',
    Data_reception_instructions: '数据接收说明',
    Two_formats: '两种格式',
    Decimal_Hex: '10进制 16进制',
    'Decimal_send_description:': '十进制发送说明:',
    'Hex_send_description:': '十六进制发送说明',
    'Enter_numbers_directly,for_example:25': '直接输入数字，例：25',
    '1:_0x_format,_for_example:_0x06,_0xaf,_etc': '1. 0x格式，例：0x06、0xaf 等',
    '2:_String_format,_for_example:_12_(i_0x12),_when_the_input_character_is_too_long,allow_two_groups_to_be_separated_by_spaces,_for_example:_1314_can_be_entered_in_13_14_format':
      '2. 字符串格式，例：12（即代表0x12），当输入字符过长时，允许两位一组用空格分隔，例：1314可以以13 14格式输入',
    'For_example,_if_function_code_is_03H_or_06H,_address_is_0000H,_you_should_fill_in_40001':
      '功能码03H或06H，起始地址0000H，则填：40001',
    'Function_code_is_04H,_address_is_000AH,_you_should_fill_in_30011': '功能码04H，起始地址000AH，则填：30011',
    'Function_code_is_01H_or_05H,_address_is_0002H,_you_should_fill_in_00003':
      '功能码01H或05H，起始地址0002H，则填：00003',
    'Function_code_is_02H, address_is_0003H,_you_should_fill_in_10004': '功能码02H，起始地址0003H，则填：10004',
    'Function_code_is_02H,_address_is_0003H,_you_should_fill_in_10004': '功能码02H，起始地址0003H，则填：10004',
    Big_endian: '大端在前',
    Little_endian: '小端在前',
    '4_bytes_unsigned_integer(AB_CD):_123456_is_0x0001_0xE240': '4字节无符号整数(AB CD)：123456 为 0x0001 0xE240',
    '4_bytes_unsigned_integer(CD_AB):_123456_is_0xE240_0x0001': '4字节无符号整数(CD AB)：123456 为 0xE240 0x0001',
    '4_bytes_signed_integer(AB_CD):_-123456_is_0xFFFE_0x1DC0': '4字节有符号整数(AB CD)：-123456为 0xFFFE 0x1DC0',
    '4_bytes_signed_interger(CD_AB):_-123456_is_0x1DC0_0xFFFE': '4字节有符号整数(CD AB)：-123456为 0x1DC0 0xFFFE',
    '‘%s’_is_placeholder': '公式中的‘%s’为占位符，是固定字段.',
    Plus: '加',
    Minus: '减',
    Multiply: '乘',
    Divide: '除',
    Remainder: '余数',
    slave_IP: '从机IP',
    slave_port: '从机端口',
    'Enter_\\r\\n_will_convert_to_a_space_carriage_return': '输入\\r\\n将会转化为空格回车',
    Said_the_enter: '表示回车',
    Said_the_newline: '表示换行',
    slave_address: '从机地址',
    Note: '注',
    Owned_Slave: '所属从机',
  },
  //message 用来翻译「成功、警告、消息、错误」类的操作反馈。
  message: {
    //警告
    Please_fill_in_the_complete_information: '请填写完整信息',
    Number_cannot_be_empty: '数值不能为空',
    Alarm_duration_is_2_bytes: '报警持续时间长度为2字节',
    Heartbeat_interval_is_4_bytes: '心跳间隔长度为4字节',
    Alarm_interval_is_4_bytes: '报警间隔长度为4字节',
    'Are_you_sure_you_want_to_delete_chosen_device?': '您将删除该设备，是否继续？',
    'Please_choose_which_device_you_want_to_delete!': '您没有选中任何设备',
    'Are_you_sure_you_want_to_delete_chosen_device?': '您将删除选中项，是否继续？',
    'This_equipment_has_been_set_as_the_first_item!': '该设备已置为第一项！',
    'This_equipment_has_been_set_as_the_last_item!': '该设备已经为最后一项！',
    'This_data_point_has_been_set_as_the_first_item!': '该数据点已置为第一项！',
    'This_data_point_has_been_set_as_the_last_item!': '该数据点已经为最后一项！',
    Image_not_uploaded: '未上传图片!',
    Please_try_again_after_5s: '请5s后再试',
    //成功
    Successfully_bind_ys7Cloud_account: '绑定萤石云账号成功',
    Successfully_unbind_ys7Cloud_account: '解绑萤石云账号成功',
    Modify_the_camera_information_successfully: '修改摄像头信息成功',
    Delete_the_device_successfully: '删除设备成功',
    Add_the_device_successfully: '添加设备成功',
    'Add_successfully!': '添加成功',
    'Delete_successfully!': '删除成功！',
    'Tasks_with_the_same_equipment_at_the_same_time_exist!': '存在相同设备相同时间的任务',
    The_data_push_verification_is_successful: '数据推送校验成功！',
    'Modified_successfully!': '修改成功！',
    Sent_successfully: '发送成功',
    Processing_successfully: '处理成功',
    'Successful_sorting!': '排序成功',
    'Successful_acquisition!': '采集成功！',
    Unbind_is_successfully: '解绑成功！',
    distribution_successfully: '分配成功',
    reset_passwords_successfully: '重置成功',
    Password_reset: '密码已重置！',
    Sync_Task_Has_Been_Created: '同步任务已创建',
    Cancel_the_order_successfully: '取消订单成功！',
    Invoice_application_succeeded: '发票申请成功！',
    //消息
    Cancel_deletion_operation: '取消删除操作',
    Debugging_is_not_supported_for_this_device_type: '该设备类型不支持调试',
    Canceled_collection: '取消采集',
    Add_up_to_three_tags: '最多添加三个标签',
    Add_up_to_five_tag_categories: '最多添加五个标签类别',
    //错误
    Please_enter_the_role_name: '请输入角色名称!',
    The_role_name_cannot_be_empty: '角色名字不能为空',
    Edit_success: '编辑成功',
    Delete_success: '删除成功',
    Cancel_delete: '已取消删除！',
    You_can_only_choose: '您只能选择',
    group: '分组',
    Incorrect_user_name_or_password: '用户名或密码不正确',
    Please_contact_the_administrator_for_account_review: '请联系管理员进行账号审核!',
    Incorrect_phone_number_or_verification_code: '手机号或验证码不正确',
    No_data: '暂无数据',
    Please_enter_a_hexadecimal_string_that_conforms_to_the_specification: '请输入符合规范的16进制的字符串',
    Incorrect_data_format: '非正确的数据格式',
    No_more_than_20_characters: '不能超过20个字符!',
    No_more_than_15_characters: '不能超过15个字符!',
    No_more_than_50_characters: '不能超过50个字符!',
    No_more_than_80_characters: '不能超过80个字符!',

    //图标tips
    Roof_placement: '置顶',
    Tail_setting: '置尾',
    Move_up: '上移',
    Move_down: '下移',
    Data_is_empty: '数据为空',
    open_success: '开启成功！',
    closed_success: '已经关闭！',
    Group_description_cannot_exceed_200_characters: '分组描述不能超过200个字符',
    Please_Choose_the_node_for_group: '请勾选节点',
    Please_Choose_node_for_twin_group: '请勾选对应组节点',
    Incorrect_format: '格式不正确',
    Modify_the_information_successfully: '信息修改成功',
    Domain_name_is_not_supported: '不支持填写此域名！',
    Domain_name_is_not_standardized: '域名不规范！',
    The_pictures_uploaded_successfully: '图片上传成功',
    'Upload_logo_image_size_can_not_exceed_1MB!': '上传logo图片大小不能超过 1MB!',
    'Upload_head_image_size_can_not_exceed_1MB!': '上传头像图片大小不能超过 1MB!',
    'Upload_logo_only_supports_jpg、_gif、_png_format!': '上传logo仅支持jpg、gif、png格式!',
    'Test_E-mail_sent_successfully': '测试邮件发送成功',
    'Test_E-mail_delivery_failed': '测试邮件发送失败',
    Mail_template_updated_successfully: '邮件模板更新成功',
    The_information_saved_successfully: '信息保存成功',
    Binding_success: '绑定成功',
    Upload_success: '上传成功',
    Confirm_delete: '确认删除',
    Communication_password_length_is_8_bits: '通讯密码长度为8位！',
    Confirm_remove: '确认移除',
    'URL_can_not_be_empty!': 'URL不能为空！',
    'Name_can_not_be_empty!': '名称不能为空！',
    'Register_can_not_be_empty!': '寄存器不能为空！',
    'File_upload_failed_please_re-upload': '文件上传失败，请重新上传',
    The_corresponding_target_is_not_selected: '未选中相应目标',
    Register_length_can_not_exceed_64: '寄存器长度不能超过64',
    The_register_length_can_not_be_less_than_1: '寄存器长度不能小于1',
    Node_name_or_Register_can_not_be_empty: '数据点名称或寄存器不能为空',
    Please_enter_data: '请输入数据',
    Copy_success: '复制成功！',
    Copy_failure: '复制失败！',
    Configuration_name_cannot_exceed_20_characters: '组态名称不能超过20个字符!',
    'Choosen_device_can_not_be_empty!': '关联设备不能为空！',
    The_device_number_you_selected: '你选择的设备号',
    'No_slave_can_not_add!': '没有从机,无法添加!',
    The_number_of_slaves_must_be_the_same: '设备从机数量必须一致！',
    The_associated_template_from_the_machine_must_be_consistent: '从机下从机序号对应的关联模版必须一致',
    No_slave: '没有从机',
    No_associated_device_please_associate_the_device_first: '没有关联设备,请先关联设备',
    URL_formal_is_illegal: 'URL格式不合法',
    Template_name_length_can_not_exceed_30: '设备模板名称长度不能超过30！',
    Data_name_can_not_exceed_50: '名称长度不能超过50！',
    Index_can_not_exceed_20: '序号长度不能超过20！',
    Port_can_not_exceed_65535: '端口不能超过65535！',
    Content_can_not_exceed_65535: '端口不能超过65535个字符！',
    IP_is_illegal: 'IP地址不合法',
    Please_enter_Email: '请输入邮箱',
    The_maximum_number_of_slaves_is: '最大从机数量为',
    Slave_information_cannot_be_empty: '从机信息不能为空',
    Please_fill_in_the_information_of_the_slave: '从机信息请填写完整',
    The_slave_serial_number_must_be_a_number: '从机序号必须为数字',
    Slave_serial_number_cannot_be_the_same: '从机序号不能相同',
    Please_add_an_Excel_file: '请添加Excel文件！',
    Upload_templates_can_only_be_in_xls_or_xlsx_format: '上传模板只能是xls、xlsx格式!',
    'The_current_limit_selects_1_file_,_I_selected': '当前限制选择 1 个文件，本次选择了',
    files_this_time: '个文件',
    Selected_altogether: '共选择',
    You_are_sure_to_remove: '你确定移除',
    Can_not_be_empty: '不能为空',
    Upload_image_format_is_wrong: '上传图片格式不对!',
    'Upload_image_size_can_not_exceed_1M!': '上传图片大小不能超过1M!',
    Communication_password_must_be_8_digits: '通讯密码必须为8位',
    Device_ID_must_be_number: '设备ID必须为数字',
    The_range_is_from_1_to_65535: '范围应在1~65535之间',
    The_range_is_from_0_to_65535: '范围应在0~65535之间',
    The_sum_of_these_should_be_less_than_65535: '该两项之和应小于65535',
    The_range_is_from_0_to_7: '范围应在0~7之间',
    The_range_is_from_1_to_127: '范围应在1~127之间',
    Required_fields_can_not_be_empty: '必填项不能为空！',
    Delete_failed: '删除失败',
    The_data_push_is_not_available: '该数据推送不可用',
    Please_select_slave_first: '请先选择从机',
    'Read-only_data_points_do_not_support_this_operation': '只读型数据点不支持此操作',
    The_registered_user_has_no_child_users: '该注册用户暂无子用户!',
    Please_Binding_Wechat: '请绑定微信',
    Are_you_sure_you_want_to_unbind_Wechat: '确定要解除已绑定微信？',
    Wechat_Unbind_successfully: '微信解绑成功！',
    Canceled_modification: '已取消修改！',
    The_associated_role_is_not_selected: '未选择关联角色!',
    Set_user_super_administrator_successfully: '设为用户超级管理员成功！',
    The_device_has_no_location_information: '该设备暂无位置信息',
    The_number_of_added_devices_cannot_be_empty: '添加设备数不能为空',
    Please_enter_a_numeric_value: '请输入正整数',
    'The_number_of_added_devices_should_be_between_1-100': '添加设备数应在1-100之间',
    Please_add_a_device_template: '请添加设备模板',
    Variable_name_can_not_exceed_50: '名称长度不能超过50！',
    Procedure_image_input_register: '过程映像输入寄存器',
    Procedure_image_output_register: '过程映像输出寄存器',
    Variable_storage: '变量存储器',
    Bit_memory: '位存储器',
    Data_block: '数据块',
    name_can_not_exceed_30: '名称不超过30个字符',
    Slave_device_number_must_be_a_number_or_letter: '从机设备号必须为数字或字母',
    The_slave_device_number_cannot_be_the_same: '从机设备号不能相同',
    'To_avoid_abuse_of_data_push_functionality,_new_additions_are_no_longer_supported_If_necessary,_please_contact_the_sales_manager_for_private_deployment':
      '为避免数据推送功能被滥用，不再支持新增。如有需要，请联系销售经理私有部署。',
    Picture_insertion_failed: '图片插入失败',
    Please_enter_the_correct_video_URL: '请输入正确的视频URL',
    The_number_of_data_points_can_not_exceed_500: '模板下数据点个数不能超过500',
    Cancel_export: '已取消导出！',
    Cancel_download: '已取消下载！',
    Please_select_data_point: '请选择数据点',
    Are_you_sure_to_download_the_data_history: '是否下载历史数据?',
    On_failure: '执行失败',
    Command_format_error: '指令格式错误',
    Cancel_restart: '已取消重启！',
    Cancel_Factory_Reset: '已取消恢复出厂设置',
    Instructions_cannot_be_null: '指令不能为空',
    Task_cannot_be_null: '任务不能为空',
    Address_can_not_exceed_255_and_less_than_0: '从机地址范围在0~255之间',
    has_benn_used: '已被使用',
    can_not_exceed_30: '不超过30个字符',
    'The_work_order_is_over!': '工单已结束!',
    'Upload_wechat_image_only_supports_jpg、_png_format!': '上传微信公众号图片仅支持jpg、png格式!',
    Sent_orders_successfully: '派单成功!',
    Order_receiving_successfully: '接单成功!',
    This_device_type_does_not_support_timing_tasks_for_the_time_being: '该设备类型暂不支持定时任务',
    Please_enter_alarm_push_interval_time: '请输入报警推送间隔时间',
    Unselected_menu: '未选择菜单',
    Please_select_the_data_point: '请选择要删除的数据点',
    Canceled_operation: '已取消操作！',
    Please_enter_push_interval_time: '请输入推送间隔时间',
    Please_select_a_device_template: '请选择设备模板',
    The_alarm_dead_zone_value_must_be_greater_than_or_equal_to_0: '报警死区的值必须大于等于0',
    Alarm_status_is_not_enabled_Linkage_cannot_be_enabled: '报警状态未启用，无法启用联动',
    Please_select_time: '请选择时间',
    Template_name_length_can_not_less_than_4: '设备模板名称长度不能小于4！',
    Value_name_length_can_not_exceed_30: '变量名称长度不能超过30！',
    Slave_name_length_can_not_exceed_30: '从机名称长度不能超过30！',
    Slave_name_length_can_not_less_than_4: '从机名称长度不能小于4！',
    Value_name_length_can_not_less_than_4: '变量名称长度不能小于4！',
    Please_enter_alarm_dead_zone: '请输入报警死区',
    Select_device_and_variable: '选择设备和变量',
    Please_enter_the_alarm_name: '请输入报警名称',
    Value_of_the_variable_reaches_the_trigger_condition: '变量值达到触发条件时',
    Push_mechanism: '推送机制',
    Push_interval: '推送时间间隔',
    Please_select_the_execution_time: '请选择执行时间',
    Please_enter_a_numerical_value_and_not_a_negative_number: '请输入数值且不能为负数',
    Alarm_notification_name_cannot_contain_spaces: '报警通知名称不能包含空格',
    This_configuration_is_not_yet_associated_with_the_device: '该组态暂未关联设备',
    Please_fill_in_the_configuration_name: '请填写组态名称!',
    Configuration_name_cannot_exceed_40_characters: '组态名称不能超过40个字符!',
    Please_try_again_after_3s: '请3s后再试',
    Please_enter_decimal_digits: '请输入十进制数字',
    Device_is_disabled_and_is_being_used_by_someone_on_the_cloud_platform: '设备已禁用，正在有人云平台使用',
    This_device_type_does_not_support_synchronization_at_this_time: '该设备类型暂时不支持同步',
    Template_name_length_can_not_exceed_64: '设备模板名称长度不能超过64！',
    Assign_function_menu_permissions_to_the_current_role: '请为当前角色分配功能菜单权限!',
    Template_name_can_not_contains_special_characters: '设备模板名称不能包含特殊字符',
    Do_you_want_to_delete_the_project: '是否删除该项目？',
    Please_dismiss_the_project_manager_first: '请先解除关联的项目管理员',
    New_equipment: '新增设备',
    Closed_share: '已关闭分享',
    Started_share: '已开启分享',
    Link_copied: '链接已复制',
    Upload_file_can_only_be_in_xls_or_xlsx_format: '只允许上传.xls或.xlsx格式的文件!',
    Please_upload_a_file_in_the_correct_format: '请上传正确格式的文件！',
    Only_one_file_can_be_uploaded: '只可上传一个文件，请先删除当前文件再次上传',
    The_upload_file_cannot_exceed_20M: '上传文件不能超20M！',
    Edit_device_template_to_set_more_slaves: '编辑设备模板，便可设置更多从机',
    Device_actively_disconnects_from_the_cloud: '设备主动与云端断开连接',
    Device_reported_data_timeout: '设备上报数据超时',
    No_related_variables_found: '未搜索到相关变量',
    deleted_alarm_notification_tip: '和该报警通知相关联的设备已删除，请重新设置报警通知',
    processing_result_length_can_not_exceed_255: '处理结果长度不能超过255',
    Device_is_required: '设备为必选项！',
    At_command_cannot_contain_Chinese_characters: 'AT指令不能包含汉字',
    The_number_of_transparent_transmission_strategies_exceeds_the_limit: '透传策略数量超过限制',
  },
  //prop默认值相关
  prop: {
    The_last_hour: '最近一小时',
    Today: '今天',
    Last_7_days: '最近7天',
    Last_30_days: '最近30天',
    Last_5_minutes: '最近5分钟',
    Last_2_hour: '最近2小时',
    Last_1_day: '最近1天',
    Last_3_days: '最近3天',
  },
  notify: {
    status: '状态',
    online: '上线',
    offline: '下线',
    trigger_name: '触发器名称',
    describe: '描述',
    number: '数值',
    alarm_Project: '报警项目',
    Datapoint_name: '数据点名称',
    content: '报警内容',
    alarm_time: '报警时间',
    datapoint: '数据点',
    current_value_is: '当前值',
    is_normal: '恢复正常',
    have_alarmed: '发生报警',
    normal_time: '恢复时间',
    Synchronized_completion_of_device_template: '设备模板同步完成',
    Successful_synchronization_of_device_template: '设备模板同步成功',
    Restore_content: '恢复内容',
    Restored_content: '恢复正常内容',
    Alarm_value: '报警值',
    Restore_value: '恢复值',
  },
  // 日期
  week: {
    Monday: '星期一',
    Tuesday: '星期二',
    Wednesday: '星期三',
    Thursday: '星期四',
    Friday: '星期五',
    Saturday: '星期六',
    Sunday: '星期天',
    once_executed: '执行一次',
    timed_execution: '定时执行',
    repeated_execution: '重复执行',
    single_execution: '单次执行',
    time_interval_cycle_execution: '按时间间隔循环执行',
    hour: '时',
    minute: '分',
    '1_Second': '1秒钟',
    '5_Second': '5秒钟',
    '15_Second': '15秒钟',
    '30_Second': '30秒钟',
    '1_Minute': '1分钟',
    '3_Minute': '3分钟',
    '5_Minute': '5分钟',
    '10_Minute': '10分钟',
    '30_Minute': '30分钟',
    '1_Hours': '1小时',
    '5_Hours': '5小时',
    '1_day': '1天',
    '5_days': '5天',
    '15_days': '15天',
    Slave_auto_Upload: '不采集(主动上传)',
    '2_Minute': '2分钟',
    Seconds: '秒',
    Minutes: '分钟',
    Hours: '小时',
    Days: '天',
    Not_querying: '不采集',
    Year: '年',
    Month: '月',
    Week: '周',
    one_day: '一天',
    two_day: '两天',
    three_day: '三天',
    hebdomad: '一周',
    fortnight: '两周',
    one_month: '一个月',
    Not_in_advance: '不提前',
    '20_Minute': '20分钟',
  },
  //监控大屏
  monitor: {
    home: '系统首页',
    monitoring: '设备监控',
    management: '后台管理',
    exit: '退出登录',
    setting: '设置',
    Device_survey: '设备概况',
    Alarm_statistics: '报警统计',
    Device_total: '设备总数',
    Online_device: '在线设备',
    Offline_device: '离线设备',
    Alarm_total: '报警总数',
    Handled_total: '已处理报警',
    Untreated_total: '未处理报警',
    Today_Alarm_total: '今日报警总数',
    Today_Handled_total: '今日已处理报警',
    Today_Untreated_total: '今日未处理报警',
    Online_rate: '在线率',
    Treatment_rate: '处理率',
    handled: '已处理',
    untreated: '未处理',
    online: '在线',
    offline: '离线',
    Device_list: '设备列表',
    Alarm_info: '报警信息',
    No_device: '暂无设备',
    Device_name: '设备名称',
    Alarm_content: '报警内容',
    processing: '报警处理',
    Alarm_processing: '报警处理',
    Processing_result: '处理结果',
    False_positives: '误报',
    confirm: '确认',
    sure: '确认',
    Processing_description: '处理描述',
    save: '保存',
    device: '设备',
    content: '内容',
    Device_number: '设备编号',
    DEVICE_TEMPLATE: '设备模板',
    address: '设备地址',
    View_details: '查看详情',
    Essential_information: '基本信息',
    User_info: '用户信息',
    Owned_users: '所属用户',
    company: '公司名称',
    Alarm_contact: '报警联系人',
    triggers: '关联触发器',
    Timing_tasks: '关联定时任务',
    configuration: '组态画面',
    Real_time_data: '实时数据',
    to: '至',
    Start_time: '开始时间',
    End_time: '结束时间',
    Only_write: '只写',
    acquisition: '主动采集',
    cancel: '取消',
    Untreated_alarm: '未处理报警',
    History_data: '历史数据',
    History_Alarm: '历史报警',
    The_device_does_not_have_location_information: '该设备不存在位置信息',
    The_device_does_not_have_configuration: '该设备暂未创建组态',
    Device_template: '设备模板',
    Data_template: '数据模板',
    Real_time_data_graph: '实时数据图表',
    Real_time_track: '实时轨迹',
    History_track: '历史轨迹',
    Service_record: '服务记录',
    Recently_maintenance_time: '最近维保时间',
    Recently_maintenance_status: '最近维保状态',
    Next_maintenance_time: '下次维保时间',
    Parameter_data: '参数数据',
    Protocol_and_driver: '协议与驱动',
    The_device_is_currently_without_a_camera: '该设备暂无绑定摄像头',
    overview: '系统总览',
  },
  title: {
    search: '点击搜索',
    add_plan: '添加计划',
    edit_plan: '编辑计划',
    priview_plan: '查看计划',
    add_repair: '添加报修',
    edit_repair: '编辑报修',
    priview_work_ordern: '查看工单',
    'Advanced_Configuration-User_Permissions': '高级配置 - 用户权限',
    Device_positioning: '设备定位',
    Finish_adding: '完成添加',
    USR_Notice: '有人云公告',
    Select_a_set_of_edge_end_devices: '选择一组“边缘端”设备',
    Set_passthrough_targets_as_management_side: '设置透传目标，作为"管理端"',
  },
  msg: '一些信息',
  registertitle: '欢迎注册',
  readLogin: '确认阅读并同意',
  officialWeb: '进入官方网站',
  logoLimitation: '仅支持jpg、gif、png格式；大小为1M以内,尺寸为106*60像素',
  triggerSetting:
    '<p>触发器可以设置不同的设备和数据点绑定不同的报警方式。</p>' +
    '<p>当设备触发报警条件时会进入报警状态，服务器推送报警消息，后面的报警信息将不再推送，直到恢复正常数据时推送设备恢复消息。</p>' +
    '<p>触发器仅支持非数据透传的通讯协议设备。</p>',
  deadZone:
    '<p>报警死区的作用是为了防止变量值在报警限上下频繁波动时，产生许多不真实的报警，在原报警限上下增加' +
    '一个报警限的阈值，使原报警限界线变为一条报警限带。</p>' +
    '<p>例如：设定触发器高于100时触发报警，设定报警死区为10，则设备只有在大于110时才会触发报警推送，在小于90时才会恢复正常状态。</p>',
  triggerRule:
    '<p>默认触发规则为状态切换报警：</p>' +
    '<p>例：温度大于40报警；温度到50会触发报警推送并存储报警信息，再次上传“60”不会触发报警且不保存数据。温度到30，会触发恢复报警推送并存储报警消息。</p>',
  saveRule:
    '<p>保存规则指的是报警数据的保存规则：</p> <p><strong>保存：</strong></p>' +
    '<p>保存触发器触发报警时的数据，以及从报警状态恢复到正常状态时的数据。</p>' +
    '<p><strong>不保存：</strong></p> <p>不保存报警数据。</p>',
  alarmContacsGroupLink: '点击查看如何设置报警联系人/组。',

  AddDataPush: '必须以http://开头',

  wechatBingdingTip: '注：微信报警功能有两种微信绑定方式（推荐使用第二种）:',
  wechatBingdingGenerateCodeDesc: '关注公众平台后扫描二维码即可直接绑定微信。',
  usrinfo: '可以在用户信息中编辑您在本系统中的用户资料',
  Password: '可以修改当前用户的登录密码',
  Communication_passwordone: '设置默认设备接入的密码，支持密码自定义，实现不同设备接入密码不同',
  Communication_passwordtwo: '设备一旦接入成功后轻易不要修改，否则会造成设备由于密码错误无法再次接入。',
  addNode: '<p><strong>数据保存：</strong>线上数据仅保存35天。</p>',
  aboutOffline: '<p>若2小时内设备无任何数据收发，平台识别可能为异常，将主动踢掉设备，设备可以重连以保证数据接收。</p>',
  companyEmailAuthorizationCode: '企业邮箱的“邮箱授权码”请填写“邮箱密码”。',
  personalEmailAuthorizationCode: ' 个人邮箱的“邮箱授权码”请填写“邮箱授权码”。',
  testSendEmailConfigSever: ' 点击“测试发送邮件”后，邮箱收到一封测试邮件，表示邮件服务器配置成功。',
  deviceSlave:
    '<p><strong>什么是从机？</strong></p>' +
    '<p>设备是连接平台的串口转网络设备，从机是RS-485总线上的串口设备</p>' +
    '<p><strong>什么是序号？</strong></p>' +
    '<p>在远程采集和控制中，通过设备ID、序号、数据点ID锁定要控制的数据点。</p>' +
    '<p><strong>什么是设备号？</strong></p>' +
    '<p>Modbus协议填从机地址，DL/T645协议填电表号</p>' +
    '<p class="usr"><strong>从机数量？</strong></p>' +
    '<p class="usr">从机最大数量支持3个。如需测试更多从机，请联系开通测试账户。地址：<a href="http://h.usr.cn/" target="_blank">http://h.usr.cn/</a>。请注明：多从机测试。</p>' +
    '<p class="usr"><strong>注：正式运行请联系我司部署私有服务器。</strong></p>',
  deviceSlaveSimplify:
    '<p><strong>什么是从机？</strong></p>' +
    '<p>设备是连接平台的串口转网络设备，从机是RS-485总线上的串口设备</p>' +
    '<p><strong>什么是序号？</strong></p>' +
    '<p>在远程采集和控制中，通过设备ID、序号、数据点ID锁定要控制的数据点。</p>' +
    '<p><strong>什么是设备号？</strong></p>' +
    '<p>Modbus协议填从机地址。</p>',
  deviceCloudKey:
    '<p>设置默认设备接入的密码，支持密码自定义，实现不同设备接入密码不同。</p>' +
    '<p>设备一旦接入成功后轻易不要修改，否则会造成设备由于密码错误无法再次接入。</p>',
  deviceUser: '<p>平台支持多用户管理机制，以实现不同用户登录后，只能控制自己权限内的设备。</p>',
  deviceType:
    '<p>根据有人的设备类型选择：</p>' +
    '<p><strong>默认设备：</strong>包含有人品牌的DTU、串口服务器等，添加完成后，系统自动分配ID，并通过软件写入设备内完成接入。<a href="http://cloud.usr.cn/device_access.html" target="_blank" class="usr">如何设置设备？</a> </p>' +
    '<p class="usr"><strong>LoRa集中器：</strong><a href="http://cloud.usr.cn/device_access.html#LoRa集中器" target="_blank">点击查看如何添加</a></p>' +
    '<p class="usr"><strong>CoAP/NB-IoT：</strong><a href="http://cloud.usr.cn/device_access.html#NB-IOT DTU和模块接入" target="_blank">点击查看如何添加</a></p>',
  deviceProtocol:
    '<p>平台将根据设定的协议格式解析，并根据数据点的设定存入数据库、处理报警、推送给前端。</p>' +
    '<p><strong>Modbus RTU：</strong>服务器将做Modbus RTU主机，主动向设备发送读取数据指令。</p>' +
    '<p class="usr">注：若用户自主开发设备，需要主动上传数据（如：按键控制状态、传感器主动上报、NB-IoT/Lora只能主动上报的通讯方式），平台支持按Modbus RTU有人扩展指令主动上报，详见《<a href="http://cloud.usr.cn/Public/ModbusRTU/ModbusRTU.xlsx" target="_blank">Modbus RTU有人扩展指令</a>》</p>' +
    '<p><strong>DL/T645：</strong>电表协议，可用于电力抄表，支持97和07两种格式。</p>' +
    '<p><strong>数据透传：</strong>仅希望通过平台获取数据流，可利用SDK获取设备数据流，也可利用透传组设定好透传关系。</p>' +
    '<p>CoAP/NBIoT设备支持通过网页添加“任务队列”透传数据。</p>',
  addDevicePictureTip: '仅支持jpg、gif、png格式；大小为1M以内',
  excelDeviceprefix: '设备会以 设备前缀+IMEI 作为设备的名称',
  limitUploadOneFile: '当前限制选择 1 个文件，若要重新上传，请删除原文件',
  cameraTip: '<p>序列号即摄像头机身上的9位序列号，<br> 验证码即摄像头机身上的6位验证码，<br> 如下图所示:</p>',
  excelSelectTip:
    '<p style="margin-bottom:10px;"><strong>注：excel批量导入一次最多导入100条数据。</strong></p>' +
    '<p style="margin-bottom:10px;">excel样板下载地址：<a href="http://console.usr.cn/uploads/device/excel_model.xlsx" target="_blank">点击下载excel样版</a></p>' +
    '<p style="margin-bottom:10px;">也可以使用购买设备时所给的Excel(大客户批量定制)</p>',
  customDomainTip:
    '<p style="word-break: break-all;">通过自定义域名进入系统将完全中性。<br/>自定义域名必须以console开头，支持三级域名，如：console.test.com或console.abc.test.com，设置后必须CNAME解析到cname.usr.cn，详见右侧域名解析。</p>',
  domainPlaceHolder: "请输入'console.*'格式的域名",
  Example: '如：',
  FloatABCD: '4字节浮点型(AB CD)：12.3 为 0x3F9D 0x70A4',
  FloatCDAB: '4字节浮点型(CD AB)：12.3 为 0x70A4 0x3F9D',
  FormulaTip: '提示：支持输入简单的js函数，如：(%s/1000).toFixed(2)，效果为数据点值除1000后保留两位小数',
  SaveDataTip: '数据默认存储35天数据，当不选择数据存储时，数据库只会默认存储最新的一条数据。',
  Communication_passwordTip: '设置默认设备接入的密码，支持密码自定义，实现不同设备接入密码不同。',
  Communication_passwordTip2: '设备一旦接入成功后轻易不要修改，否则会造成设备由于密码错误无法再次接入。',
  timingFuntion: '<p><strong>定时任务功能用于在设定的时间、设定的频率向设备发送数据。</strong></p>',
  timeingSelectFuction:
    '<p><strong>向设备发送数据：</strong>向设备发送的是发原始数据流。</p>' +
    '<p><strong>向数据点发送数据：</strong>向数据点发送的是按照设备的协议自动组成数据包下发，如：Modbus协议设备将数据点值组成Modbus协议下发。</p>',
  timeingschedule:
    '<p><strong>定时任务的执行时间计划分为执行一次、定时执行和重复执行三种。</strong></p>' +
    '<p><strong>执行一次：</strong>以选择的“时分”时间参数为指定时间，定时下发数据，执行一次后任务结束。</p>' +
    '<p><strong>定时执行：</strong>以选择的“周时分”时间参数为指定时间，定时下发数据，每天最多执行一次，需手动停止任务。</p>' +
    '<p><strong>重复执行：</strong>以选择的“时分”时间参数为执行频间隔，重复下发数据，需手动停止任务。</p>',
  PackageLengthHelp:
    '<p>根据设备需求，modbus现在允许用户自定义单个设备单次自动采集中合并下发的寄存器长度（默认长度为32）。</p>',
  have_not_device_yet: '您当前还未添加设备，请前往添加',
  NextStep: '下一步，配置从机和变量',
  DebuggerTipMain: '一、仅数据透传协议，支持数据发送',
  DebuggerTip: '二、举例：需要给设备发送数据流 0x31, 0x32, 0x33，下面两种操作方式都能实现：',
  DebuggerExp1: '输入"31 32 33 "，选中“输入的格式为HEX”，点击发送',
  DebuggerExp2: '输入"123"，选中“输入的格式为ASCII”，点击发送',
  DebuggerExp3: '三、输入的格式为ASCII时，支持的转义字符如下',
  DebuggerExp4: '举例："AT+VER\\r\\n"',
  DebuggerExp5: '四、输入的格式为HEX时，下面两种输入格式，可实现同样的效果：',
  DebuggerExp6: '举例：接收的数据流为"0x31, 0x32, 0x33",',
  DebuggerExp7: '如果接收数据按HEX显示，则显示为"0x31 0x32 0x33"',
  DebuggerExp8: '如果接收数据按ASCII显示，则显示为"123"',
  ANNOUNCEMENT_TITLE: '关于系统维护升级的公告',
  ANNOUNCEMENT_P_TITLE: '尊敬的用户：',
  ANNOUNCEMENT_P_1:
    '    我司将于2019年5月7日08:30至2019年5月7日10:30暂停云平台服务，进行系统升级，给您带来的不便深感抱歉。',
  ANNOUNCEMENT_P_2: '    以下是V2.7.0版本更新内容，敬请期待',
  ANNOUNCEMENT_P_3: '  1. 增加了数据模板导入导出功能;',
  ANNOUNCEMENT_P_4: '  2. 修复了已知的bug，优化了用户体验;',
  ANNOUNCEMENT_P_5: '  3. 进行了性能优化，极大提高了负载能力和设备接入能力;',
  ANNOUNCEMENT_P_END: '2019年5月6日',
  ANNOUNCEMENT_A_GO_NEW_ADDRESS: '进入新版本',
  Industrial_PLC_Describe: 'PLC通过DTU直接接入透传云，实现设备的远程监控和控制',
  Industrial_PLC_Describes: '专业为PLC打造联网终端，助力PLC联网，实现设备远程监控和控制',
  Electric_power_monitoring_and_acquisition_describe:
    '通过DTU连接电表和透传云实现远程抄表，监控电表负荷、功率，后台进行电费管理，电费统计和监控报警',
  Intelligent_agriculture_describe: '集智慧大棚，工厂，畜牧，养殖于一体的农业生态管理系统，实现对农业的智能化管理',
  Smart_Home_describe: '实时采集消防主机及前端报警设备运行状态，通过视频查看火灾现场，快速定位，控制灾情',
  Equipment_monitoring_info_for_quick_get_start:
    '通过云组态的监控大屏可以看见设备状态、设备的基本信息以及相关的设备数据：实时数据、报警记录/数据',
  Configuration_management_info_for_quick_get_start: '通过简单拖拽即可用组态实现工艺流程图',
  Alarm_management_info_for_quick_get_start: '对每个监测点设置好报警条件，可以通过邮件、短信、微信进行报警推送',
  Mobile_Terminal_info_for_quick_get_start: '通过微信小程序即可实现手机端快速查看设备运行情况、报警、组态等基本信息',
  NeutralWechatLimitation: '仅支持jpg、png格式；大小为1M以内,尺寸为344*344像素',
  Neutral_Wechat_Bind_Tips: '',
  Neutral_WECHAT_BINGDING_PUBLIC_IMG_DESC: '扫描二维码关注公众平台。',
  Account_registration_instructions_2: '2、登录进入“我的应用”，完善应用信息，然后刷新页面获取AppKey和Secret;',
  Account_registration_instructions_3: '3、复制AppKey和Secret在本页面绑定“萤石云帐号”。',
  AddSNForPlcSomeMessage: '该版本设备固件可能不支持云组态，请联系有人公司升级固件。',
  AddSNFor780SomeMessage: '该设备型号需升级固件才可连接云组态，如已升级可忽略此提示',
  Neutralization_of_Internet_Platform_Desc_For_Quick_Get_Start: '物联网平台中性化处理',
  Neutralization_of_Internet_Platform_Desc2_For_Quick_Get_Start: '低费用开发物联网平台，低成本做项目赚钱',
  Neutralization_of_Internet_Platform_Desc3_For_Quick_Get_Start: '拥有自己的物联网平台',
  Neutralization_of_Internet_Platform_Desc4_For_Quick_Get_Start: '平台中性，一键搞定',
  OldDevAddHelp:
    '<p></p><p>填写原透传云上设备ID（20位），可填写本下级用户设备ID，填写完后需要按照云组态的项目、角色、权限重新分配。详见<a href="http://cloud.usr.cn/document/72.html" target="_blank">《文档》</a></p>',
  DevSNAddTips:
    '<p></p><p>设备背面的SN。</p><img style="height:150px;width:150px;display:inline-block;" src="static/image/SNTipsPic.png"></img>',
  DevSNAddTipsForStep:
    '<p></p><p>①．设备的SN码，一般在设备背面的贴膜上。（不同设备型号的贴膜外观可能有差异）</p><img style="height:150px;width:150px;display:inline-block;" src="static/image/SNTipsPic.png"></img><p>②. 如果设备已在透传云上正常运行，想转到有人云上继续使用，请查看原透传云设备添加方法，见右侧帮助栏。</p>',
  AddByDevIDTips:
    '<p></p><p>原透传云或暂不支持SN方式添加的设备可以通过ID方式添加，添加完成后需要把云端生成的设备ID和密码写入设备中。详见<a href="http://cloud.usr.cn/document/72.html" target="_blank">《文档》</a></p>',
  AddByDevIDTipsForStep: '<p></p><p>原透传云设备添加方法，见右侧帮助栏。</p>',
  CloudConfigurationTips:
    '<p></p><p>开启<a href="http://cloud.usr.cn/document/1.html" target="_blank">云组态功能</a>可以对传感器、单片机、PLC等终端设备的数据进行远程监控。</p>',
  DevTemplateAddTips:
    '<p></p><p>云组态将通讯协议、数据变量采集规则、报警规则、组态画面设计等放入设备模板中。所有关联模板的设备都将基于此模板规则工作。</p>',
  DevTemplateAddTipsForStep:
    '<p>设备模板：云组态将通讯协议、数据变量采集规则、报警规则、组态画面设计等放入设备模板中。所有关联模板的设备都将基于此模板规则工作。</p>' +
    '<p>透传：可通过配置透传策略，实现“设备到客户端软件/上位机”、“设备到设备”的相互透传通信。</p>',
  CloudDetectionTips:
    '<p></p><p>对联网模块进行远程参数配置和固件升级，实时状态监控和异常报警，如：信号强度、电池电量、流量情况等。详见<a href="http://cloud.usr.cn/document/monitor/1.html" target="_blank">《文档》</a></p>',
  CloudDetectionTipsForStep:
    '<p></p><p>联网模块实时监控和异常报警，如：信号强度、电池电量、流量等情况，详见<a href="http://cloud.usr.cn/document/monitor/1.html" target="_blank">《文档》</a>。</p>',
  UserRightsTips:
    '<p></p><p>上级用户可以通过用户权限分配给自己的子级用户，关联的用户才可以看见此设备。详见<a href="http://cloud.usr.cn/document/74.html" target="_blank">《文档》</a></p>',
  DevTagsTips:
    '<p></p><p>设备标签是设备的一个属性，方便对设备进行分类，一个设备可以附3个标签。详见<a href="http://cloud.usr.cn/document/15.html" target="_blank">《文档》</a></p>',
  ModeForNBDeviceTips:
    '<p></p><p>NB卡开启PSM请选择低功耗模式且该模式下不支持云端主动轮询；NB卡关闭PSM或为DRX请选择高功耗模式</p>',
  Cloud_active_not_supported: '不支持云端主动轮询',
  ModeForNBDeviceTipsForStep:
    '<p>低功耗（PSM）：NB卡开启PSM，请选择低功耗模式，省电但数据实时性低，低功耗只支持数据透传或ModBus主动上报；</p><p>高功耗（eDRX) ：NB卡关闭PSM或为DRX，请选择高功耗模式，耗电量大，数据可立即直达；</p>',
  edgeTips:
    '边缘计算：由联网模块进行数据采集和解析，然后按照一定的规律上报云端。适合高频（秒级）采集应用场景，本地高频采集完后上报给云端，云端做数据的存储和展示。',
  cloudTips:
    '云端轮询：云端做主机按照一定的规律去轮询终端设备，联网模块只做数据透传，把串口信号转成网络信号发给云端。适合低频（分钟级）采集场景。',
  supportTips: '支持边缘计算的产品：PLCNET系列，G780V2(固件版本V1.1.0之后)',
  protocolPro: '边缘计算和云端轮询的区别？',
  templateTips1: '什么是设备模板？',
  templateTips2:
    '新版云服务将通讯协议、数据变量采集规则、报警配置、组态画面设计等放入设备模板中。所有关联模板的设备都将基于此模板规则工作。',
  templateTips3: '对于设备厂家，可以理解为一个设备型号是一个模板。',
  templateTips4:
    '先“添加模板”，然后“模板组态”编辑。详见<a href="http://cloud.usr.cn/document/17.html" target="_blank">《文档》</a>',
  templateTips5: '什么是模板组态？',
  templateTips6: '设备关联模板即可在监控大屏展现此组态。组态编辑时，控件只需关联模板内的数据变量，无需勾选设备。',
  templateTips8: '模板组态页面只能在系统前台大屏中运行，控制大屏中选中设备的从机和变量。',
  templateTips9: '什么是独立组态（扩展功能中）？',
  templateTips10: '适用于工程商做单个工程项目，组态设计时，控件可关联账号内所有设备的数据变量。',
  templateTips11:
    '独立组态页面可单独链接运行，若未设置密码，则是公开的，任何账号有了运行链接均可进入。详见<a href="http://cloud.usr.cn/document/25.html" target="_blank">《文档》</a>',
  templateTips12:
    '注：组态画面中展示“无权限”，需到<a href="http://cloud.usr.cn/document/28.html" target="_blank">“子用户”</a>-><a href="http://cloud.usr.cn/document/74.html" target="_blank">“设备权限”</a>中在本账号下添加上此设备的查看或操作权限。',
  addDevForPlcHelpAddDev:
    '<p>带贴标的产品，出厂默认支持接入云服务，只需输入标贴上的SN和MAC/IMEI即可直接添加。</p>' +
    '<p>早期通过透传云添加的设备可通过ID方式继续添加。</p>' +
    '<p><strong>手机扫码添加</strong></br>关注公众平台</p>' +
    '<p><img style="height:150px;width:150px;display:inline-block;" src="static/image/icon_wechat.jpg"></img></p>' +
    '<p>点击底部栏->有人云->添加设备，扫描“设备贴标”上的二维码。</p>',
  addDevForPlcHelpDevTemplate:
    '<p><strong>什么是设备模板？</strong></p>' +
    '<p>云组态系统中配置与设备数据协议的解析规则，实现远程采集和控制。新版云服务将通讯协议、数据变量采集规则、报警规则、组态画面设计等放入设备模板中。所有关联模板的设备都将基于此模板规则工作。</p>',
  addDevForPlcHelpUserRights:
    '<p>关联的用户都将拥有设备和此设备变量的所有控制权限。</p><p>若要控制可见、可编辑、可删除的详细修改权限：进入右上角“用户权限”-“子用户管理”-“设备权限”中配置。</p>',
  addDevForPlcHelpDevicePositioning:
    '<p><strong>手动定位：</strong>在地图上选中设备所在的固定位置。</p>' +
    '<p><strong>自动定位：</strong>设备自动定位位置，目前仅G780V2（ 固件版本V1.2.1之后）和ModbusRTU（云端轮询）定位型变量支持选择自动定位功能。G780V2的定位方式支持基站定位，ModbusRTU(云端轮询)定位型变量的定位方式支持基站定位、GPS定位和NB粗定位，支持通过定位轨迹查看定位数据。</p>',
  addDevForPlcHelpFinishAdding:
    '<p>添加成功，设备重新上电即可上线;</p><p>若不方便操作上电，设备也会在1个小时内自动上线。</p>',
  projectTips: '<p></p><p>选择项目后不可更改。</p>',
  Add_SN_Tips: '注：若设备上无SN码则点击右侧ID方式添加',
  SN_error1: '该设备SN暂不支持直接添加，可通过点击右侧“没有SN”添加',
  SN_error2: '该设备SN暂不支持直接添加',
  SN_error3: '请输入正确格式的SN码（20或15位字符）',
  Sure_Delete_Tags: '确定删除该标签(添加过此标签的设备将自动解绑)？',
  fomulaIcon1:
    '<p></p><p>设备上行数据经采集公式计算后显示 。</p><p>公式中的%s为占位符，是固定字段。</p><p>如：</p><p>加：%s+10</p><p>减：%s-10</p><p>乘：%s*10</p><p>除：%s/10</p><p>余数：%s%10</p>',
  fomulaIcon2:
    '<p></p><p>主动向设备写数据经控制公式计算后下发 。</p><p>公式中的%s为占位符，是固定字段。</p><p>如：</p><p>加：%s+10</p><p>减：%s-10</p><p>乘：%s*10</p><p>除：%s/10</p><p>余数：%s%10</p>',
  fomulaIcon3: '<p></p><p>数据有变化的时候存储 。</p>',
  TipsForPushTimeInterval:
    '<p></p><p>推送时间间隔：</p><p>① 假设设备的采集频率为10分钟，推送时间间隔设置的1分钟。第一次报警（8.00）触发，立即推送，第二次报警需要下一次轮询并且设备还是处于报警状态（8.10）。</p>' +
    '<p>② 假设采集频率设置的1分钟，推送时间间隔设置的5分钟，第一次报警（8.00）立即推送，下一次推送时间则是（8.05）。</p>',
  Mail_Recipient_For_Neutral_Manage: '%ACCOUNT%',
  Device_Template_Add_Alarm_Contact_Tip: '设置好报警规则后，可以在“报警管理 > 报警通知” 设置推送联系人和推送方式',
  Go_to_plcnet_tips: '注：透传云账号可用于登录有人云云组态',
  SelectLanguageTips: '用于邮件报警、短信报警和报警记录所推送消息的语言设置',
  SelectTimeZoneTips: '时区修改后，系统中的数据将会按照新时区的时间展示',
  delete_tags_message1: '删除该分类将删除该分类下的所有标签，是否继续?',
  close_Switch_For_Cloud_configuration:
    '云组态功能关闭之后，会导致数据收发失败，定时任务，报警通知，独立触发器等设置失效。是否继续？',
  delete_PLC_device_message:
    '确认删除该设备？执行此操作后，该设备产生的数据将会被删除，定时任务、触发器等设置将会失效。',
  delete_PLC_devices_message:
    '确认删除所选设备？执行此操作后，这些设备产生的数据将会被删除，定时任务、触发器等设置将会失效。',
  Explain_for_operation_center:
    '说明：日活跃，每天凌晨1点抽取昨天0:00:00-23:59:59的数据；月活跃，每月1号抽取上月第一天0:00:00至最后一天的23:59:59的数据。',
  Tips_for_operation_center_users_count:
    '<p><strong>近一年总量变化：</strong></p><p>近一年每月用户总数统计情况</p><p><strong>近30天新增：</strong></p><p>近30天用户新增数量统计情况</p>',
  Tips_for_operation_center_active_users:
    '<p><strong>近一年月活跃度：</strong></p><p>近一年每月活跃用户统计情况</p><p><strong>近30天日活跃度：</strong></p><p>近30天日活跃用户统计情况</p>',
  Tips_for_operation_center_devices_count:
    '<p><strong>近一年总量变化：</strong></p><p>近一年每月设备总数统计情况</p><p><strong>近30天新增：</strong></p><p>近30天设备新增数量统计情况</p>',
  Tips_for_operation_center_active_devices:
    '<p><strong>近一年月活跃度：</strong></p><p>近一年每月活跃设备统计情况</p><p><strong>近30天日活跃度：</strong></p><p>近30天日活跃设备统计情况</p>',
  Tips_for_operation_center_active_devices_type:
    '<p><strong>近30天活跃度：</strong></p><p>近30天日活跃设备型号统计情况</p><p><strong>昨日活跃度：</strong></p><p>昨日日活跃设备型号统计情况</p>',
  Tips_for_operation_center_ranking:
    '<p><strong>注册设备数量：</strong></p><p>指用户已注册的设备，包含在线、离线所有设备</p>' +
    '<p><strong>设备组态图数量：</strong></p><p>指用户账号下存在的组态图数量，包含独立组态图和模板组态图</p>' +
    '<p><strong>子用户数量：</strong></p><p>指除主账号外注册所有下级子账号</p>' +
    '<p><strong>变量数量：</strong></p><p>指在设备模板中所有采集的变量数量</p>' +
    '<p><strong>开启云组态的设备数量：</strong></p><p>指设备当前已开启云组态功能的设备数量</p>',
  // + '<p><strong>设备数据存储量：</strong></p><p>指设备的上报数据记录、设备的上下线数据记录和设备的报警数据记录条数总和</p>',
  Tips_for_excel_add_dev: '温馨提示：只支持同一型号的设备批量导入，文件大小不得超过20M。',
  Tips_for_excel_Map: '温馨提示：通过此处获取设备定位的经纬度，填写在excel模板中',
  UploadSuccessDevNum1: '文件上传成功，共',
  UploadSuccessDevNum2: '条设备信息。',
  FailCheckAndDownload: '下载设备列表（不合法设备已经在文件中标记）',
  CheckFailedTips: '文件中存在不合法的设备，请检查并更改后再重新上传',
  CheckFailedNoData: '上传文件的内容不可为空，请重新上传',
  FailImport: '下载变量列表（不合法变量已经在文件中标记）',
  SettledProductsMessage:
    '请把您的申请发送到邮箱yuexiaoyang@usr.cn中，邮箱的内容请包括您的姓名、手机号、公司名称，方便我们与您联系。',
  Targeting_type_tips_for_manual_positioning: '<p>在地图上选择设备所在的固定位置。</p>',
  Targeting_type_tips_for_automatic_positioning:
    '<p>设备自动定位位置，目前仅G780V2（ 固件版本V1.2.1之后）和ModbusRTU(云端轮询)定位型变量支持选择自动定位功能。</p>',
  please_Upload_Excel_Tips: '请上传正确格式的Excel文件',
  fomulaIcon4:
    '<p>1、如果设备只有一个串口，串口序号请选择"1"。</p><p>2、如果设备是双串口时，使用串口的标识为COM1时串口序号请选择"1"，串口标识为COM2时串口序号请选择"2"。</p>',
  ExplanationForTC:
    '<p>如何查看设备的透传数据？</p><p>1、通过虚拟串口软件获取，<a href="http://www.usr.cn/Download/924.html" target="_blank">虚拟串口软件下载请点击下载</a></p>' +
    '<p>2、通过数据推送功能获取，<span style="color:#FF863C;">敬请期待</span></p><p>3、通过二次开发SDK获取，<span style="color:#FF863C;">敬请期待</span>',
  OperatingProcedures_1:
    '<p>操作流程：</p>' +
    '<p style="margin-left: 30px;">1、下载对应设备的软件；</p><p style="margin-left: 30px;">2、在软件中把系统分配的SN和通讯密码进行配置；</p><p style="margin-left: 30px;">3、设备已在软件中配置完成连接；</p>' +
    '<p style="margin-left: 30px;">具体操作流程请<a href="http://cloud.usr.cn/document/69.html" target="_blank">点击查看如何配置上云</a>；</p>',
  OperatingProcedures_2:
    '<p>操作流程：</p>' +
    '<p style="margin-left: 30px;">1、下载对应设备的软件；</p><p style="margin-left: 30px;">2、在软件中把系统分配的SN和通讯密码进行配置；</p><p style="margin-left: 30px;">3、设备已成连接；</p>' +
    '<p style="margin-left: 30px;">具体操作流程请<a href="http://cloud.usr.cn/document/69.html" target="_blank">点击查看如何配置上云</a>；</p>',
  OperatingProcedures_3:
    '<p>配置已完成，设备将在1小时内自动上线，重新上电则立即上线，添加的设备可以在设备列表查看。</p>',
  How_to_set_device_slave_tips: '如何给设备设置从机，请查看右侧帮助文档“如何监控多个从机”。',
  Login_company_filing_information: '',
  directlyAddDeviceTips: '云组态功能关闭，无需选择产品 / 协议 / 数据透传，可直接添加设备。',
  DevGroupTipsForStep:
    '选择设备所属的项目和分组，系统按照项目做隔离，归属不同项目的设备不可关联相同的模板、报警等资源。',
  EnableTipsForStep: '只有启用开启，设备才会上线，启用功能未开启，远程配置和固件升级功能可以正常使用。',
  CloudConfigurationForStep:
    '<p>开启云组态功能，可以实现对自动化过程和装备的监控。</p>' +
    '<p>可以通过关联产品（设备模板），快速实现，<p><p>也可以使用数据透传，结合二次开发来实现。</p>',
  NewbieTutorial1: '<p>填入设备贴膜上的SN和MAC/IMEI</p>' + '<p>（透传云设备如何转入，见右侧帮助栏）</p>',
  NewbieTutorial2: '<p>新建产品模板，设备将基于产品模板规则工作。</p>',
  NewbieTutorial3: '<p>添加完成后，如何让设备上线。</p>',
  NBTpisWhenAddEnd: '配置已完成，NB设备主动发数据后自动上线，重新上电则立即上线，添加的设备可以在设备列表查看。',
  IOTipsWhenAddEnd: '<p style="text-align: center;">配置已完成，重新上电则立即上线，添加的设备可以在设备列表查看。</p>',
  EnabledDevTipsInStep3: '<p style="text-align: center;">配置已完成，添加的设备可以在设备列表查看。</p>',
  MACtipsForStep: 'MAC、IMEI，任填一个即可。另外，对于LoRa节点设备，这里填写Lora节点的NID。',
  addDevForPlcHelpAddDevProductLibrary:
    '<p>产品库中汇集了各类优秀合作厂家的产品，针对每个产品型号，平台预先配置了对应的设备模板。用户从产品库中选购产品后，可直接关联通信模块使用，无需进行变量配置、组态绘制等操作，实现快速对接。</p>',
  addDevForPlcHelpAddDevProductWarehouse:
    '<p>更多产品型号正在火爆入驻中，欢迎各大设备厂商与我们洽谈合作。</p>' +
    '<p style="margin-top:20px">1、我们可以给入驻厂家引流 —— 点击“购物车”图标可以调转到产品介绍页或购买页</p>' +
    '<p>2、入驻产品库后，能极大降低用户的使用门槛，用户会优先选择入驻产品库的产品</p>' +
    '<p>3、支持厂商以自己品牌自主运营云平台</p>' +
    '<p style="margin-top:20px">请把您的申请发送到邮箱yuexiaoyang@usr.cn中，邮箱的内容请包括您的姓名、手机号、公司名称，方便我们与您联系。</p>',
  addDevForPlcHelpAddDevAgreementsProducts:
    '<p><strong>边缘计算和云端轮询的区别？</strong></p>' +
    '<p>边缘计算：由联网模块进行数据采集和解析，然后按照一定的规律上报云端。适合高频（秒级）采集应用场景，本地高频采集完后上报给云端，云端做数据的存储和展示。</p>' +
    '<p>支持边缘计算的产品：PLCNET系列，G780V2(固件版本V1.1.0之后)</p>' +
    '<p>云端轮询：</p>' +
    '<p>①云端做主机，去轮询终端设备，联网模块只做数据透传。适合低频（分钟级）采集场景</p>' +
    '<p>②对于按键状态上报等场景，可以按Modbus RTU扩展指令主动上报，需要用户自主开发。<a href="https://mp.usr.cn/uploads/basetemplate/ModbusRTUExtendInstructions.xlsx">下载协议</a></p>',
  addDevForPlcHelpAddDevMonitorSlave:
    '<p>左侧菜单选择“设备模板”进入设备模板列表，选择“设备模板”，点击“编辑”，点击“添加从机”，即可添加从机。</p>',
  addDevForPlcHelpAddDevConfigurationDesign: '<p>通过组态编辑器简单的拖拽实现工艺流程图，绑定数据直观展示。</p>',
  addDevForPlcHelpAddDevHowConfigurationDesign:
    '<p>左侧菜单选择“设备模板”进入设备模板列表，点击“组态设计”，进入“组态设计”页面，通过组态编辑器,即可完成组态设计。</p>',
  addDevForPlcHelpAddDevConfigurationScreen:
    '<p>监控大屏可见：选择分组下的设备，即可查看设备组态；</p>' +
    '<p>扩展功能：功能中心->独立组态，关联上本设备数据查看，点击“运行”即可查看组态；</p>' +
    '<p>小程序：手机扫一扫，关注有人云小程序；</p>',
  configMenuWarning: '请联系管理员配置菜单',
};
