/**
 * 请不要直接import此文件
 * import当前项目的
 * 注意：
 * 此文件禁止出现透传云字样、项目标记等；如果有透传云字样、项目标记，放到sr/lang/xxx文件夹里面；
 */
export default {
  common: {
    Chinese: 'Chinese',
    English: 'English',
    add: 'Add',
    delete: 'Delete',
    edit: 'Edit',
    search: 'Search',
    select: 'Select',
    distribution: 'Distribution',
    reset_passwords: 'Reset Password',
    assign_role: 'Assign Roles',
    Modify_email: 'Modify Email',
    Modify_phone_number: 'Modify Phone Number',
    Account: 'Account Login',
    Tel: 'Phone Number Login',
    Register: 'Register Now',
    Forget_the_password: 'Forget Password',
    Existing_account_Quick_login: 'Existing Account? Quick Login',
    Login_using_other_methods: 'Login via Other Methods',
    Login: 'Login Now',
    wechat: 'Login via Wechat',
    test: 'Account for Experience',
    official_website: 'Official Website',
    optional: 'Optional',
    Previous_Step: 'Previous Step',
    next_step: 'Next step',
    retrieve_password: 'Retrieve Password',
    Please_bind_the_existing_account: 'Please bind the existing account',
    No_account_please_register: 'Please register account first',
    Remember_the_password: 'Store the Password',
    to: 'To',
    Start_time: 'Start Date',
    End_time: 'End Date',
    'Remember_the_password?': 'Remember the Password?',
    SN: 'SN',
    Logging_success: 'Loggings Succeed',
    Failure_to_send_data: 'Data Issuance Failure',
    The_lens_is_shaded: 'Lens is covered',
    The_lens_is_on: 'Lens is opened',
    Please_enter_the_name_of_camera_or_monitor: 'Please enter the name of camera or monitoring object',
    distribution_Promission: 'Distribution Permission',
    Power: 'User Permission',
    devices: 'One Device',
    'Updated_on:': 'Updated on',
    Acquisition_formula: 'Acquisition formula',
    Control_formula: 'Control formula',
    Step_length: 'Step length',
    ignore: 'ignore',
    Task_event: 'Task event',
    Offline_unknown_version: 'Unknown version',
    version: 'version',
    stand: 'stand',
    All_series: 'All series',
    Monitoring_center: 'Monitoring center',
    Whether_to_collect_variables_actively: 'Whether to collect variables actively',
    Whether_to_switch_switch_state: 'Whether to switch switch state',
    current_device: 'current device',
    Values_for: 'Values for',
    Switch_off: 'Switch off',
    Switch_on: 'Switch on',
    Please_deal_with_it_as_soon_as_possible: 'Please deal with it as soon as possible',
    Has_returned_to_normal: 'Has returned to normal',
    below: 'below',
    above: 'above',
    between: 'between',
    betweenCn: '',
    alarm: 'alarm',
    equal: 'equal',
    switch: 'switch',
    or: 'or',
    Unconnected_camera: 'Unconnected camera',
    Firmware_version_distribution: 'Firmware version distribution',
    today: 'today',
    This_month: 'This month',
    This_week: 'This week',
    fault: 'fault',
    year: '/',
    month: '/',
    day: '/',
    system: '(SYSTEM)',
    deleteDataTips:
      'Confirm deleting the variable? After this operation, the historical data related to this variable will be deleted, and the Settings of alarm rules, linkage control, timing task, trigger will be invalid',
    changeDataOrSlaveTips:
      'Confirm the modification? After modification, the related timing information and linkage control will fail',
    deleteSlaveTips:
      'Confirm delete the slave? After this operation, the historical data related to the slave machine will be deleted, and the Settings of alarm rules, linkage control, timing task, trigger will be invalid',
    System_Power: 'System Power',
    Serial_index: 'Serial Index',
    Put_away: 'Put away',
    Expand: 'Expand',
    Added_on_other_platforms: 'is added on other platforms',
    unknown: 'unknown',
    Log_service: 'Log service',
    Select_product: 'Product select',
    Connect_to_the_cloud: 'Access to cloud platform',
    Your_device_SN_is: 'Your device SN is',
    Communication_password_is: 'Communication password is',
    Can_also_device_list: 'You can also find the communication password through "Device List-> View"',
    Please_enable_first: 'Please enable',
    device_not_support_monintor: 'The device does not have network monitoring function',
    device_cant_turn_off_configration: "The device's cloud configuration function cannot be turned off",
    saveToLeave: 'Your modified content has not been saved, are you sure to leave?',
    Not_support_HTML5_Video: 'Your browser does not support the HTML5 video tag.',
    Acquisition_methods: 'Acquisition methods',
    edge_computing: 'edge computing',
    cloud_collection: 'The cloud polling',
    brand: 'brand',
    noUrlTips:
      'This brand has not established business cooperation with someone, so it does not provide purchasing channels for the time being.',
    edge: 'edge',
    cloud: 'cloud',
    stop_sort: 'Stop sequence',
    Product_brand: 'Product brand',
    bit: 'bit',
    bit8_unsigned: '8-bit unsigned',
    bit8_signed: '8-bit signed',
    bit16_unsigned: '16-bit unsigned',
    bit16_signed: '16-bit signed',
    bit32_unsigned: '32-bit unsigned',
    bit32_signed: '32-bit signed',
    bit32_float: '32-bit float',
    bit32_unsignedABCD: '32-bit unsigned(AB CD)',
    bit32_unsignedCDAB: '32-bit unsigned(CD AB)',
    bit32_signedABCD: '32-bit signed(AB CD)',
    bit32_signedCDAB: '32-bit signed(CD AB)',
    bit32_floatABCD: '32-bit float(AB CD)',
    bit32_floatCDAB: '32-bit float(CD AB)',
    string_type: 'string',
    bit64_float: '64-bit float',
    bit32_timestamp: '32-bit timestamp',
    bytes30_location: '30bytes-location',
    Product_brand_EN: 'Product brand English name',
  },
  button: {
    sure: 'OK',
    cancel: 'Cancel',
    add: 'Add',
    add_subordinate_group: 'Add subgroup',
    register_an_account: 'Register an account',
    play: 'Play',
    edit: 'Edit',
    resetpassword: 'Reset Password',
    change_Password: 'Edit Password',
    delete: 'Delete',
    Remove: 'Remove',
    Multiple_delete: 'Bulk Deletion',
    Disable: 'Disable',
    Enable: 'Enable',
    Search: 'Query',
    save: 'Save',
    Reset: 'Reset',
    send_test_email: 'Send test email',
    click_upload: 'Click to upload',
    historical_query: 'Historical Query',
    active_acquisition: 'Active Acquisition',
    timing_task: 'Timing Tasks',
    test: 'Testing',
    Mulitiple_delete: 'Bulk Deletion',
    Device_grouping: 'Device Grouping',
    Project_grouping: 'Project Grouping',
    unbind: 'Unbind',
    Modify_Warranty_Date: 'Modify Warranty Date',
    Add_More: 'Add More',
    DownLoad: 'Download Data',
    run: 'Run',
    Settings: 'Settings',
    Copy_applications: 'Copy Applications',
    Copy_link: 'Copy Link',
    share: 'Share',
    share_link: 'Share Links',
    view: 'View',
    Download_Data: 'Download Data',
    Preview: 'Preview',
    Preview_alarm_processing: 'Preview Alarm Processing',
    Process: 'Process',
    Smoke_configure: 'Smoke Sensor Configure',
    Refresh: 'Refresh',
    register_account: 'Register Account',
    debug: 'Debug',
    get_security_code: 'Get Verification Code',
    Add_node: 'Add Nodes',
    Select_the_file: 'Select the File',
    Send: 'Send',
    Edit_node: 'Edit Node',
    Save: 'Save',
    More: 'More',
    Add_device_template: 'Add Device Template',
    Update_device_template: 'Update device template',
    Add_slave: 'Add Slave',
    Device_info: 'Device Info',
    History_Data: 'Historial Data',
    Alarm_data: 'Alarm Data',
    status: 'Status',
    name: 'Name',
    Device_number: 'Device No.',
    Owned_users: 'Subordinate Users',
    address: 'Address',
    Retrieve: 'Retrieve',
    Device_export: 'Device Export',
    Order_export: 'Order Export',
    Import_device: 'Import device',
    Application_for_opening: 'Apply For Open Account',
    copy: 'Copy',
    Subuser: 'Subuser',
    Binding: 'Binding',
    Untied: 'Untied',
    Set_user_super_administrator: 'Set super administrator',
    Synchronize: 'Synchronize',
    Slave_number_authorization: 'Slave Quantity Authorization',
    device_level_authorization: 'Device Level Authorization',
    Set_level1_Vip: 'Set Primary Member',
    Set_neutral_Vip: 'Set Neutral Member',
    Neutral_manage: 'Neutral Management',
    Set_nonmember: 'Set Non-member',
    trigger: 'Trigger',
    The_test_account_cannot_click: "The test account can't click",
    Bind_and_login: 'Bind and login',
    Register_and_bind: 'Register and bind',
    Template_Configuration: 'Template Configuration',
    Import_data_template: 'Import data template',
    Export_template: 'Export template',
    Publish: 'Publish',
    export: 'Export',
    Add_variable: 'Add variable',
    Variable_name: 'Variable name',
    Edit_variable: 'Edit variable',
    Firmware: 'Firmware',
    Add_Subordinate: 'Add Subordinate',
    Add_Model: 'Add Model',
    Upgrade_Task: 'Upgrade Task',
    Upgrade_Details: 'Upgrade Details',
    Already_Disable: 'Disabled',
    Already_Enable: 'Enabled',
    Configuration_Task: 'Configuration Task',
    Configuration_Details: 'Configuration Details',
    Add_To_Task: 'Add To Tasks',
    Configuration: 'Configuration',
    Upgrade: 'Upgrade',
    Add_Instruction: 'Add Instruction',
    Add_Task: 'Add Task',
    End: 'End',
    Create_Articles: 'Create Articles',
    Article_View: 'View Article',
    Other_Data_Points: 'Other Data nodes',
    View_SIM_Card: 'Check SIM Card',
    Unbound: 'Unbound',
    Dispense_With_SIM_Card: "Don't need SIM card",
    Update_Record: 'Update Record',
    Make_Record: 'Configuration Record',
    The_Online_Record: 'Online Record',
    Device_Log: 'Device Log',
    The_remote_reading: 'Remote Reading',
    reset: 'reset',
    Equipment_Restart: 'Device Reboot',
    Query_version: 'Query version',
    Factory_Reset: 'Reload to factory settings',
    Help_Information: 'Help',
    Send_data_in_HEX_format: 'Send data in HEX format',
    Send_data_in_ASCll_format: 'Send data in ASCll format',
    Accept_data_and_display_in_HEX_format: 'Receive data and display in HEX format',
    Accept_data_and_display_in_ASCll_format: 'Receive data and display in ASCll format',
    Start_the_voice: 'Start Speach',
    End_of_the_speech: 'End Speach',
    State_of_the_lens: 'Status of the lens',
    Camera_open: 'Open the lens',
    Cover_the_lens: 'Close the lens',
    Lens_flip: 'Flip the lens',
    Enable_movement_tracking: 'Enable Movement Tracking',
    Turn_off_movement_tracking: 'Disable Movement Tracking',
    Amplification: 'Enlarge',
    Narrow: 'Shrink',
    read: 'Read',
    Edit_device_template: 'Edit device template',
    Add_project: 'Add Project',
    Add_group: 'Add Group',
    Binding_ys7Cloud_account: 'Bind EZVIZ Cloud account',
    Unbind_ys7Cloud_account: 'Unbind EZVIZ Cloud account',
    Import_from_subuser_list: 'Import from subuser list',
    Maintenance_report: 'Report',
    End_of_the_repair_order: 'Close Ticket',
    view_log: 'View Logs',
    Network_IO_device_template: 'Network IO Device Template',
    View_alarm: 'View Alarm',
    finish: 'Finish',
    Add_tag: 'Add tags',
    New_category: 'New Category',
    Operation_record: 'Operation Record',
    Visit_the_official_website: 'Visit Official Website',
    Device_Promission: 'Device Permission',
    Export_order: 'Export Tickets',
    Sent_orders: 'Dispatch ticket',
    Order_receiving: 'Ticket is Accepted',
    Unreviewed: 'Unauthorised',
    Export_QR_code: 'Export QR code of Device',
    Device_QR_code: 'Device QR code',
    Generate_QR_code: 'Generate QR code',
    Map: 'Map',
    Enter_subusers: 'Access user',
    Add_users: 'Add Users',
    Edit_users: 'Edit Users',
    Pre_page: 'Pre Page',
    Next_page: 'Next Page',
    Add_By_ID: 'No SN, click here',
    Return_added_by_SN_mode: 'Return input SN',
    Delete_category: 'Delete Category',
    User_Profiles: 'User Profiles',
    Set_Alarm: 'Set Alarm',
    Set_alarm_rule: 'Set alarm rule',
    Edit_alarm_rule: 'Edit alarm rule',
    I_know: 'I know',
    No_longer_prompt: 'No longer prompt',
    Push_mode: 'Push mode',
    Running_Configuration: 'Configuration',
    Edit_Head_Portrait: 'Edit Head Portrait',
    edit_ID: 'edit ID',
    Parameter_configuration: 'Parameter configuration',
    Reboot_device: 'Reboot device',
    Download_the_log: 'Download the log',
    configuration_permissions: 'configuration permissions',
    Share_the_configuration: 'Share',
    Import: 'Import',
    Copy_Info: 'Copy Info',
    Enter_the_account: 'Access account',
    Export_log: 'Export log',
    Add_the_agreement: 'Add the agreement',
    Add_product: 'Add product',
    Edit_the_product: 'Edit the product',
    Edit_agreement: 'Edit agreement',
    Import_variable: 'Import variable',
    Download_excel_template: 'Download Excel template',
    Set_up_more_slaves: 'Set up more slaves',
    I_want_to_enter_the_product: 'I want to enter the product library',
    Settled_products: 'Settled products',
    Get_latitude_and_longitude: 'Get latitude and longitude',
    Import_template: 'Import template',
    Copy_Lat_and_Long: 'Copy Latitude and Longitude',
    debugging: 'debugging',
    Determine_creation: 'Determine creation',
    Determine_editors: 'Determine editors',
    Choose_a_template: 'Choose a template',
    Replace_template: 'Replace template',
    Newbie_Tutorial: 'Newbie Tutorial',
    jump_over: 'jump over',
    re_upload: 're-upload',
    payment: 'payment',
    Cancellation_of_order: 'Cancellation of order',
    Application_invoice: 'Application invoice',
    Export_variable: 'Export variable',
    Confirm_receipt: 'Confirm receipt',
    Confirm_the_goods: 'Confirm the delivery',
    export_record: 'export record',
    export_details: 'export details',
  },
  nav: {
    neture: 'Neture',
    monitor: 'Monitor Page',
    switch_old_version: 'Switch to Old Version',
    logout: 'Logout',
    return_main_account: 'Return to Main Account',
    return_check_pass: 'Return to USR Account',
    My_check_pass: 'My check pass',
  },
  dialog: {
    Detail: 'Preview',
    Add_Monitor: 'Add Camera',
    Edit_Monitor: 'Edit Camera',
    Add_data_push: 'Add Data Push',
    Edit_data_push: 'Edit Data Push',
    Add_Alarm_Contact: 'Add Alarm Contact',
    Edit_Alarm_Contact: 'Edit Alarm Contact',
    Add_Contact: 'Add Contact',
    Edit_Contact: 'Edit Contact',
    Add_Alarm_Contact_Group: 'Add Alarm Contact Group',
    Edit_Alarm_Contact_Group: 'Edit Alarm Contact Group',
    Add_device_tag: 'Add Device Goup',
    Edit_device_tag: 'Edit Device Goup',
    Copy_applications: 'Copy Application',
    Edit_HMI: 'Edit Application',
    Process_alarm: 'Process Alarms',
    View_alarm_record: 'Preview Process Alarms',
    Debug: 'Debug',
    Edit: 'Edit',
    Add_HMI: 'Add Application',
    Add_HMI_Model: 'Add Application Template',
    Edit_HMI_Model: 'Edit Application Template',
    Edit_Data_Template: 'Edit Data Template',
    Edit_Device_Promission: 'Edit Device Promission',
  },
  pageTitle: {
    Home_Page: 'Home Page',
    Monitor_List: 'Camera List',
    Video_Management: 'Video Management',
    TRIGGER_LIST: 'Trigger List',
    ADD_TRIGGER: 'Add Trigger',
    EDIT_TRIGGER: 'Edit Trigger',
    System_Setting: 'System Setting',
    'E-mail_Server': 'Email Server',
    Email_Template: 'Email Template',
    WeChat_Push: 'WeChat Push',
    Mobile_Terminal: 'Mobile Terminal',
    GROUP_LIST: 'Transparent Transmission Goup List',
    ADD_GROUP: 'Add Transparent Transmission Goup',
    EDIT_GROUP: 'Edit Transparent Transmission Goup',
    DEVICE_TAG: 'Device Group',
    Device_overview: 'Device Overview',
    Protocol: 'Communication Protocol',
    User: 'Subordinate User',
    Subordinate_project: 'Subordinate Project',
    Map: 'Devices Map',
    Alarm_Record: 'Alarm Record',
    'ON-LINE_RECORD': 'Devices Online/Offline',
    HISTORICAL_DATA: 'Historical Data',
    RECORD_OF_EXECUTION: 'Execution Record',
    TASK_MONITORING: 'Task Monitoring',
    ADD_TIMED_TASKS: 'Add Timed Tasks',
    EDIT_TIMED_TASKS: 'Edid Timed Tasks',
    ADD_DEVICE: 'Add Device',
    Confirm_add: 'Confirm add',
    EXCEL_BATCH_IMPORT: 'Excel Batch Import',
    SINGLE_HMI: 'Single Application',
    HMI_MODEL: 'Template Application',
    Data_debugging: 'Data Debugging',
    Configuration_application: 'Configuration Application',
    Positioning_track: 'Positioning Track',
    Video_Surveillance: 'Video Moniter',
    ADD_TEMPLATE: 'Add Template',
    Article_List: 'Article List',
    Today_Alarm: 'Alarms Today',
    ADD_IN_BATCHES: 'Add in Batches',
    Add_Devices_In_Bulk: 'Add Devices In Bulk',
    EDIT_DEVICE: 'Edit Device',
    Firmware_Management: 'Firmware Management',
    Resource_and_user: 'Project and User',
    Project_manager: 'Project Manager',
    All_projects: 'All Projects',
    Alarm_notification: 'Alarm Notification',
    Device_configuration: 'Device Configuration',
    Neutral_Introduced: 'Neutral Introduction',
    Independent_trigger: 'Independent Trigger',
    Engineering_quantity_statistics: 'Project Quantity Statistics',
    Execution_log: 'Execution Log',
    Built_in_device_library: 'Built in device library',
    Warranty_period_management: 'Warranty period management',
    DTU_manage: 'DTU manage',
    DTU_Online_rate: 'DTU Online rate',
    Today_Failure_recovery_rate: 'Today failure recovery rate',
  },
  cardTitle: {
    How_to_use_Cloud_SCADA: 'Quick Use Cloud Configuration',
    Project_overview: 'Project Overview',
    Update_log: 'Update Log',
    Focus_on_WeChat_official_account: 'Follow WeChat Public Number',
    Mobile_terminal: 'Mobile Terminal',
    Wechat_applet: 'WeChat Applet',
    News_list: 'News List',
    Login_information: 'Login Information',
    TRIGGER_SETTINGS: 'Trigger Settings',
    ALARM_DEAD_ZONE: 'Alarm Dead Zone',
    TRIGGER_RULE: 'Trigger Rule',
    SAVE_RULE: 'Save Rule',
    TRIGGER_ADD_FLOW: 'Trigger Add Flow',
    WECHAT_BINDING: 'WeChat Binding',
    UserInfo: 'User Info',
    Password: 'Password',
    Communication_password: 'Communication Password',
    Add_node: 'Add Data Node',
    About_offline: 'About Offline',
    Device_Type: 'Device Type',
    NODE_LIST: 'Data Node List',
    Multiple_add: 'Add Data Nodes in Batch',
    Node_overview: 'Data Node Overview',
    Slave_list: 'Salves List',
    Node_list: 'Data Node List',
    function_description: 'Function Description',
    Select_function_description: 'Select Function Description',
    schedule_specification: 'Schedule Description',
    Data_saving: 'Data Saving',
    VariableOverview: 'Data Nodes Overview',
    Series_Management: 'Series Management',
    Model_Management: 'Model Management',
    Firmware_Update: 'Firmware Upgrade',
    Firmware_Update_Version: 'Firmware Upgrade Version',
    Remote_Configuration: 'Remote Configuration',
    Alarm_Configuration: 'Alarm Configuration',
    Add_Alarm_Group: 'Add Alarm Group',
    Main_data_parameter: 'Main Data Parameters',
    All_parameters: 'All Parameters',
    Data_curve: 'Data Curve',
    Weather_forecast: 'Weather Forecast',
    Area: 'Area',
    Grouping_under_current_project: 'Group under Current Project',
    Return_item: 'Return to Project',
    Device_under_current_grouping: 'Device under Current Group',
    Return_group: 'Return Group',
    Activation_rate: 'Motor Activation rate',
    activated: 'activated',
    inactivated: 'inactivated',
    Curve_display: 'Curve display',
    List_display: 'List display',
    Recharge_record: 'Recharge record',
    Settlement_details: 'Settlement details',
    Order_management: 'Order management',
    order_details: 'order details',
  },
  label: {
    name: 'Name',
    '：': '：',
    company: 'Company',
    address: 'Address',
    read_write: 'Read & Write',
    readWriteInfo: {
      only_read: 'Read Only',
      read_write: 'Read & Write',
      only_write: 'Write Only',
    },
    user_name: 'User Name',
    compony_name: 'Company Name',
    password: 'Password',
    passwordagain: 'Confirm Password',
    phone: 'Mobile Phone',
    phonenumber: 'Phone Number',
    verificationCode: 'Verification Code',
    phoneVerificationCode: 'Phone Verification Code',
    get_verification_code: 'Get Verification Code',
    monitor_name: 'Monitor Name',
    serial_number: 'Serial Number',
    verification_code: 'Verification Code',
    device_name: 'Device Name',
    device_number: 'Device Number',
    Version: 'Current Version',
    custom_domain: 'Custom Domain',
    domain: 'Domain',
    system_name: 'System Name',
    company_name: 'Company Name',
    logo: 'System Logo',
    email: 'Email Account',
    email_authorization_code: 'Email Authorization Code',
    email_server: 'Email Server',
    port: 'Port',
    template_name: 'Template Name',
    template_content: 'Template Content',
    template_ID: 'Template ID',
    verify_file: 'Verify File',
    role_name: 'Role Name',
    role_description: 'Role Description',
    DATA_PUSH: 'Data Push',
    remark: 'Remark',
    user_password: 'User Password',
    new_password: 'New Password',
    confirm_password: 'Confirm Password',
    reset_passwords: 'Reset Password',
    Designated_device: 'Appoint Device',
    Specify_data_model: 'Appoint Data Template',
    querying: 'Acquisition',
    control: 'Control',
    Communication_password: 'Communication Password',
    WeChat_binding_management: 'WeChat Binding Management',
    User_management: 'User Management',
    Choose_node: 'Choose Data Node',
    Choose_data_model: 'Choose Data Template',
    group: 'Group',
    Group_name: 'Transparent Tranmission Group Name',
    Choose_the_node_for_group: 'Check Node',
    Choose_node_for_twin_group: 'Check Node for Matching Group',
    Parent_tag: 'Parent Tag',
    Parent_grouping: 'Parent Group',
    Sequence: 'Sequence',
    Configuration_name: 'Configuration Name',
    Slave: 'Slave',
    Value: 'Value',
    Device_tag: 'Subordinate Group',
    Project_tag: 'Project Group',
    User: 'Subordinate User',
    Querying_frequency: 'Query Frequency',
    Protocol: 'Communication Protocol',
    Location: 'Device Location',
    Alarm_status: 'Alarm Status',
    Result: 'Process Result',
    Process_time: 'Process Time',
    version_of_role_management: 'Role Management',
    Menu_Permissions: 'Menu Permissions',
    Data_Point_Permissions: 'Data Nodes Permissions',
    selection_function: 'Selection Function',
    select_receiving_device: 'Select Receive Device',
    send_data: 'Send Data',
    planned_time: 'Shedule',
    Implement_way: 'Implement Method',
    Device_picture: 'Device Picture',
    Opcode: 'Operation Code',
    Alarm_duration: 'Alarm Duration',
    Heartbeat_interval: 'Heartbeat Interval',
    Alarm_interval: 'Alarm Interval',
    EXCEL_file: 'Excel File',
    Device_prefix: 'Device Prefix',
    'Name/Address': 'Name/Address',
    Device_Type: 'Device Type',
    Communication_device: 'Communication Device',
    Time_of_last_edition: 'Edit Time',
    Operation: 'Operation',
    Status: 'Status',
    'Data_model_name:': 'Data model name:',
    Slave_index: 'Slave Number',
    Slave_name: 'Slave Name',
    Slave_IP: 'Slave IP',
    Slave_port: 'Slave Port',
    Register_address: 'Register Address',
    Nickname: 'Nickname',
    project_name: 'Project Name',
    Is_start_using: 'Enable?',
    management: 'Management',
    tel: 'Tel',
    take_charge_project: 'Responsible Project',
    management_and_tel: 'Name and Tel',
    association_role: 'Associat Role',
    ADD_DEVICE_TEMPLATE: 'Add Device Template',
    UPDATE_DEVICE_TEMPLATE: 'Update Device Template',
    Registered_user_management: 'Registered Users Management',
    Subuser_management: 'Subusers Management',
    VIP: 'Member',
    device_level: 'Device Level',
    Subordinate_role: 'Subordinate Role',
    Error_logs: 'Error Logs',
    Error_message: 'Error Message',
    Slow_logs: 'Slow Request Logs',
    Article_title: 'Article Title',
    Article_type: 'Article Type',
    Cover_image: 'Cover Image',
    SN: 'Device SN',
    Device_number: 'Add Device Number',
    Package_length: 'Package Length',
    Choose_project: 'Choose Project',
    Protocols_and_drivers: 'Protocol and Product',
    Contacts: 'Contacts',
    Variable_quantity: 'Quantity of VariableS',
    Variable_list: 'Data Nodes list',
    Storage_type: 'Storage Type',
    Timing_storage: 'Timing Storage',
    Variable_storage: 'Variable Storage',
    Firmware_Package: 'Frimware Pakage',
    Upgrade_Mode: 'Upgrade Mode',
    Superior_Series: 'Superior Series',
    Series_Name: 'Series Name',
    Series_SN_Number: 'Series SN Number',
    Model_SN_Number: 'Model SN Number',
    Model_of_communication_module: 'Communication Modules Model',
    Download_Mode: 'Download Mode',
    baud_Rate: 'Baud Rate',
    Data_Bits: 'Data Bit',
    Stop_Bit: 'Stop Bit',
    Parity_Bit: 'Parity Bit',
    Flow_Control: 'Flow Control',
    LAN_Address: 'LAN Address',
    Agreement: 'Agreement',
    IPV4_Address: 'IPV4 Address',
    IPV4_Subnet_Mask: 'IPV4 Subnet Mask',
    task_Time: 'Task Time',
    Configuration_Content: 'Configuration Content',
    Tasks_Added: 'Tasks Added',
    User_Type: 'User Type',
    Contract_Period: 'Contract Period',
    Network_Conditions: 'Network Conditions',
    Binding_SIM_Card: 'Binding SIM Card',
    Enabled_Function: 'Enabled Function',
    account: 'Account',
    top_user: 'Superior User',
    user_type: 'User Type',
    belongs_resource: 'Subordinate Project',
    role: 'Role',
    tag: 'Tag',
    resource_auth: 'Resource Authority',
    Resource: 'Subordinate Project',
    Local_network_download: 'Local Network Download',
    Static_IP_address: 'Static IP Address',
    Variable: 'Variable',
    Assign_users: 'Assign Users',
    Associated_user: 'Associated User',
    Device_password: 'Device Password',
    HIKVISION: 'HIKVISION',
    itangwang: 'WiSTONE',
    association_project: 'Association Project',
    Company_email_address: 'Company Email Address',
    Company_contact_and_phonenumber: 'Company Contact and Phone number',
    geographical_location: 'Geographical Location',
    Engaged_in_the_industry: 'Engaged Industry',
    scale_of_company: 'Company Scale',
    Nature_of_company: 'Company Nature',
    expiration_date: 'Expiration Date',
    Owner_account: 'Subordinate Primary Account',
    Maintenance_device: 'Maintenance Device',
    Maintenance_type: 'Maintenance Type',
    Plan_name: 'Plan Name',
    Plan_status: 'Plan Status',
    Next_maintenance_time: 'Next Maintenance Time',
    Repair_device: 'Repair Device',
    Work_order_number: 'Ticket Number',
    Problem_content: 'Problem Content',
    Work_order_status: 'Ticket Status',
    Work_order_type: 'Ticket Type',
    Submission_time: 'Submission Time',
    Owned_customer: 'Subordinate Customer',
    All_storage: 'All Storage',
    Device_tags: 'Device Tags',
    Maintenance_Plan: 'Maintenance Plan',
    Warranty_period_maintenance_time: 'Maintenance Time in Warranty Period',
    Maintenance_cycle: 'Maintenance Cycle',
    Start_maintenance_time: 'Maintenance Start Time',
    Send_work_orders_in_advance: 'The Time Assigning Ticket in Advance',
    All_types: 'All Types',
    Warranty_period: 'Maintenance within Warranty Period',
    out_of_the_warranty_period: 'Maintenance beyond Warranty Period',
    All_plans: 'All Plans',
    have_not_started: 'Not Start',
    Wait_for_maintenance: 'Pending Maintenance',
    Pending: 'Pending',
    Already_receiving: 'Accepted',
    Already_sent_orders: 'Assigned',
    over_and_done_with: 'Ended',
    Already_end_of_the_repair_order: 'Ticket Completed',
    Submitted_to_the_user: 'Submitted to User',
    Maintenance_repairs: 'Maintenance Repairs',
    fault_description: 'Fault Description',
    upload_pictures: 'Upload Pictures',
    upload_files: 'upload files',
    upload_device_pictures: 'Upload Device Pictures',
    delete_pictures: 'Delete Pictures',
    problem_description: 'Problem Description',
    repairs_uesr: 'Maintenance User',
    usr: 'USR IOT',
    Operation_logs: 'Operation Logs',
    position: 'Position',
    Wechat_public_accounts_QR_code: 'Wechat Public Account QR Code',
    Configure_manager: 'Confige Manage',
    Alarm_management: 'Alarming Manage',
    Application_cases: 'Application Cases',
    Smart_Home: 'Smart Home',
    'Electric_power_control,acquisition': 'Electric power Control and Acquisition',
    Firmware_type: 'Firmware Type',
    Baseline_version: 'Baseline Version',
    Model_Series: 'Model Series',
    Equipment_shift: 'Device Batch',
    workorder_management: 'Tickets Management',
    maintenance_personnel: 'Maintenance Personnel',
    the_means_of_notification: 'Notification Method',
    Work_order_description: 'Ticket Description',
    Regular_maintenance: 'Regular Maintenance',
    Fault_maintenance: 'Fault Maintenance',
    Work_Order_Repair: 'My Ticket',
    Customer_Management: 'Customer Management',
    Change_stroage: 'Change Range Storage',
    emailVerificationCode: 'Email Verification Code',
    Cloud_configuration_system: 'Cloud Configuration System',
    Cloud_monitoring_system: 'Cloud Monitoring System',
    Internet_of_things_card_management: 'IoT Card Management',
    Equipment_detection_system: 'Device Detection System',
    Device_template_count: 'Templates Numbers',
    Alarm_name: 'Alarm Name',
    System_notice: 'System Notice',
    equipment_monitoring: 'Device Monitoring',
    SN_code: 'SN Code',
    monitor_logo: 'Monitor Page Logo',
    background_information: 'background information',
    Prerequisites_for_creating_alarm_notifications: 'Prerequisites for creating alarm notifications',
    Alarm_push_mechanism: 'Alarm push mechanism',
    Enabling_instructions: 'Enabling instructions',
    'What_can_we_do_to_plan_tasks?': 'What can we do to plan tasks?',
    'How_to_View_Task_Execution_Results_Log?': 'How to View Task Execution Results Log?',
    Problem_solving: 'Problem solving',
    User_Head_Portrait: 'User Head Portrait',
    TimeZone: 'TimeZone',
    Language: 'Language',
    Lower_delivery_mode: 'Lower delivery mode',
    Selection_variables: 'Selection variables',
    Models_of_distribution: 'Models of distribution',
    User_rights: 'User rights',
    file_name: 'file name',
    acquire_time: 'acquire time',
    file_type: 'file type',
    file_size: 'file size',
    region: 'region',
    Operation_center: 'Operation center',
    Neutral_users: 'Neutral users',
    Project: 'Project',
    Template: 'Template',
    Independent_configuration: 'Independent configuration',
    Independent_alarm: 'Independent alarm',
    Number: 'Number',
    Daily_activity: 'Daily activity',
    Monthly_activity: 'Monthly activity',
    Total: 'Total',
    Daily_notification: 'Daily notification',
    Monthly_notification: 'Monthly notification',
    Daily_income: 'Daily income',
    Monthly_income: 'Monthly income',
    Totalize: 'Total',
    Daily_record: 'Daily record',
    Monthly_record: 'Monthly record',
    Total_record: 'Total record',
    Protocols_and_product: 'Protocols and product',
    Operation_user: 'Operation user',
    Event_content: 'Event content',
    Operation_state: 'Operation state',
    Device_location: 'Device location',
    Client: 'Client',
    PC_Terminal: 'PC Terminal',
    Product_agreement_library: 'Product agreement library',
    Product_agreement_library_Management: 'Product agreement library Management',
    Protocol_name: 'Protocol name',
    Reasons_for_offline: 'Reasons for offline',
    Warranty_period: 'Warranty period',
    DTU_name: 'DTU name',
    Region_Agent: 'Region Agent',
    Add_date: 'Add date',
    DTU_Overdue_Date: 'DTU Overdue Date',
    Outbound_date: 'Outbound date',
    Inverter_material_code: 'Inverter material code',
    Inverter_name: 'Inverter name',
    Inverter_barcode: 'Inverter barcode',
    Inverter_specifications: 'Inverter specifications',
    Inverter_Overdue_Date: 'Inverter Overdue Date',
    Motor_material_code: 'Motor material code',
    Motor_name: 'Motor name',
    Motor_barcode: 'Motor barcode',
    Motor_specifications: 'Motor specifications',
    Motor_Overdue_Date: 'Motor Overdue Date',
    End_user_mobile_phone_number: 'End user mobile phone number',
    Activation_time: 'Activation time',
    English_name: 'English name',
    Latitude_and_longitude: 'Latitude and longitude',
    Device_activation_date: 'Device activation date',
    Network_monitoring: 'Network monitoring',
    details: 'Details',
    Group_type: 'Group type',
    Product_model: 'Product model',
    Transparent_transmission_strategy_list: 'Transparent transmission strategy list',
    Edge_group_name: 'Edge group name',
    Group_SN: 'Group SN',
    Create_passthrough_strategy: 'Create passthrough strategy',
    Edit_passthrough_strategy: 'Edit passthrough strategy',
    Selected_products: 'The selected product',
    Existing_template: 'Existing template',
    New_template: 'Add template',
    Device_template_transparent_transmission: 'Template /transparent transmission',
    'Name/Model': 'Name/model number',
    Client_software: 'Client software',
    Order_status: 'Order status',
    Invoice_situation: 'Invoice_situation',
    Recharge_state: 'Recharge state',
    Payment_method: 'Payment method',
    Order_number: 'Order number',
    Invoice_receiving_email: 'Invoice receiving email',
    Invoice_nature: 'Invoice nature',
    Addressee: 'Addressee',
    Electronic_invoice: 'Electronic invoice',
    Paper_invoice: 'Paper invoice',
    personal: 'personal',
    enterprise: 'enterprise',
    Effective_time: 'Effective time',
    Expire_date: 'Expire date',
  },
  placeholder: {
    please_input_sort_number: 'Please Input Sort Number',
    please_input_group_description: 'Please Input Group Description',
    please_input_group_name: 'Please Input Group Name',
    please_input_username: 'Please Input Username',
    please_input_account: 'Please Input Account',
    please_input_compnany: 'Please Input Company Name',
    please_input_password: 'Please Input  Password',
    please_input_passwordagain: 'Please Input Password Again',
    please_input_phone: 'Please Input Phone Number',
    please_input_phoneOrEmail: 'Please Input Phone number or Email',
    please_enter_the_verification_code: 'Please Input Verification Code',
    please_input_address: 'Please Input Address',
    please_input_remark: 'Please Input Remarks',
    Please_input_trigger_name: 'Please Input Trigger Name',
    Please_input_alarm_contact_name: 'Please Input Alarm Contact Name',
    Please_input_contact_name: 'Please Input Contact Name',
    Please_input_alarm_group_name: 'Please Input Alarm Group Name',
    choose_device: 'Pelase Choose Device',
    choose_slave: 'Pelase Choose Slave',
    choose_node: 'Pelase Choose Nodes',
    choose_data_model: 'Pelase Choose Data Template',
    please_choose: 'Pelase Choose',
    please_input: 'Please Input',
    weChat_official_account_template_ID: 'Wechat Public Account Template ID',
    device_number_or_name: 'Device Number or Device Name',
    SN_or_device_name: 'SN or Device Name',
    Contact_group_name: 'Contact Group Name',
    'Please_choose_the_trigger_you_want_to_delete!': 'Pelase Choose the trigger you want to delete',
    'Please_choose_the_alarm_contact_you_want_to_delete!': 'Pelase Choose the alarm contact you want to delete',
    Invalid_group_name: 'Please Input transparent transmission gourp name',
    'Contact_name,_mobile_number_or_email': 'Contact name, phone number or email',
    Please_input_configuration_name: 'Please Input Configuration Name',
    Please_enter_the_account_number: 'Please Input Account Number',
    Please_enter_your_password: 'Please Input Password',
    Please_enter_your_mobile_phone_number: 'Please Input mobile phone number',
    Please_enter_the_verification_code: 'Please Input the verification code',
    Sort_by_online_status: 'Sort by online status',
    Default_sort: 'Default sort',
    Online: 'Online',
    Offline: 'Offline',
    Alarm: 'Alarm',
    malfunction: 'malfunction',
    Device_ID_or_name: 'Please Input device ID or name',
    Please_enter_the_device_id: 'Please enter the device ID',
    Please_enter_a_task_name: 'Please Input task name',
    Modbus_address_of_modbus_number: 'Modbus Address or modbus number',
    Template_names_should_not_exceed_twenty_characters: 'Template name should not exceed twenty characters',
    'Please_input_your_E-mail_template': 'Please input your email template',
    Please_select_device_grouping: 'Pelase Choose device group',
    Please_select_project_grouping: 'Pelase Choose project group',
    'Function_code_is_04H,address_is_000AH,_you_should_fill_in_30011':
      'Function Code is 04H, Start address is 000AH, then input 30011',
    Maximum_register_length_is_64: 'Maximum register length is 64',
    The_uplink_data_of_the_device_is_displayed_after_calculation_by_the_formula:
      'Device uplink data is shown after calculation by formula',
    Actively_write_data_to_the_device_and_send_it_to_the_data_after_the_inverse_calculation_formula:
      'Write data to the device after the reverse calculation formula issued',
    System_automatic_generation: 'Automatic system generation',
    Chose_the_configuration: 'Pelase Choose configuration',
    Please_input_register_address: 'Please Input Register Address',
    Please_input_slave_name: 'Please Input Slave Name',
    Please_input_slave_IP: 'Please Input Slave IP',
    Please_input_slave_port: 'Please Input Slave Port',
    Please_input_slave_index: 'Please Input Slave number',
    Please_input_data_point_name: 'Please Input Name of Data Point',
    Please_input_number: 'Please Input Number',
    Please_enter_the_search_content: 'Please Input Search Content',
    Please_enter_the_unit: 'Please Input the Unit',
    Please_enter_the_variable_description: 'Please Input Variable Description',
    Please_enter_the_data_model_name: 'Please Input Data Template Name',
    Please_enter_a_device_name: 'Please Input Device Name',
    Please_enter_SN: 'Please Input SN',
    Please_enter: 'Please Input ',
    Please_input_manager: 'Please Input manager',
    Please_input_tel: 'Please Input contact phone number',
    Please_select_take_charge_project: 'Pelase Choose project in charge',
    Please_enter_the_template_name: 'Please Input template name',
    The_user_name_is_between_3_and_20_characters_long: 'The length of the user name is between 3 and 20 characters',
    The_user_name_cannot_contain_special_characters: 'User names cannot contain special characters',
    'The_user_name_only_supports_Chinese,English_letters,Numbers,and_underscores':
      'User names support only Chinese, English letters, Numbers, and underscores',
    Please_fill_in_the_remarks: 'Please fill in the note information',
    Processed_alarm: 'Handled alarms',
    Unprocessed_alarm: 'Unhandled alarms',
    Processed: 'Handled',
    Unprocessed: 'Unhandled',
    Restored: 'Restored',
    Not_restored: 'Not restored',
    Please_enter_MAC: 'Please Input MAC address',
    'Please_enter_MAC/IMEI': 'Please Input MAC/IMEI number',
    'Please_enter_MAC/IMEI/NID': 'Please Input MAC/IMEI/NID number',
    Please_enter_the_number_of_added_devices: 'Please Input Number of devices added',
    'Rang：1-64，integer': 'Parameter range: positive integer from 1 to 64',
    project_number_or_name: 'Project number or project name',
    Please_input_variable_name: 'Please Input variable name',
    Please_select_variable: 'Pelase Choose variate',
    Has_chosen: 'Selected',
    data_points: 'Data points',
    variables: 'variates',
    SN_or_name: 'Please Input SN or device name',
    account_already_exists: 'Account has already existed',
    Please_select_superior_series: 'Pelase Choose superior series',
    Please_enter_series_name: 'Please Input series name',
    Please_enter_more_than_50: 'Please Input Characters of length less than 50',
    Please_choose_upload_file: 'Pelase Choose file to be uploaded',
    Please_choose_the_date_and_time: 'Pelase Choose date and time',
    Please_choose_the_user_type: 'Pelase Choose user type',
    Please_enter_articles_name: 'Please Input article name',
    Please_select_a_project: 'Pelase Choose project',
    already_exists: 'already existed',
    Please_enter_data_template_name: 'Please Input data template name',
    '(Cannot_exceed_20_characters)': 'No more than 20 characters',
    Please_assign_users: 'Please Assign users',
    Disable_enabled_state: 'Disable enabled state',
    Please_enter_a_project_name: 'Please Input project name',
    Please_select_the_imported_project: 'Pelase Choose Imported project',
    Please_select_the_imported_template: 'Pelase Choose Imported data templates',
    Please_input_slave_address: 'Please Input slave address',
    'Please_choose_the_alarm_notification_you_want_to_delete!': 'Pelase Choose Alarm notification to delete',
    Must_be_a_positive_integer: 'Must be a positive integer',
    The_length_of_time_exceeds_the_limit: 'Time limit exceeded',
    Please_select_tags: 'Pelase Choose tags',
    Select_tags: 'Select tags',
    Please_enter_a_category_name: 'Please Input category name',
    Please_enter_stroage_rangeability: 'Please Input range of changes',
    The_length_of_value_exceeds_the_limit: 'The range of variation exceeds the limit',
    shutdown: 'Shutdown',
    run: 'Run',
    standby: 'Standby',
    All_items: 'All items',
    There_are_no_related_users_for_this_project: 'The project has no associated users',
    'Please_enter_0-10_characters': 'Please enter 0-10 characters',
    Please_enter_deliver_data: 'Please Input Issued data',
    Please_enter_contact_name_email: 'Please enter contact name email',
    'Please_choose_the_task_you_want_to_delete!': 'Please choose the task you want to delete!',
    Please_enter_the_variable_name: 'Please enter the variable name',
    choose_protocol_and_product: 'Please choose protocol and product',
    Please_enter_sorting_rule_for_down: 'Please enter sorting (the larger the sorting number is, the higher it is)',
    please_input_interval_time: 'please input interval time',
    Please_enter_product_agreement_name: 'Please enter product model/protocol type',
    Order_number_or_resource_name_query: 'Order number or resource name query',
  },
  rules: {
    please_input_monitor_name: 'Please fill in the monitoring name',
    please_input_the_number: 'Please Input number',
    please_input_account: 'Please fill in the account number.',
    please_input_phoneOrEmail: 'Please Input Mobile phone number or email',
    Please_enter_the_correct_phone_number_or_email: 'Please Input correct Mobile phone number or email',
    please_input_serial_number: 'Please fill in the serial number',
    please_input_verification_code: 'Please fill in the verification code',
    please_select_device: 'Pelase Choose device',
    please_input_domain_name: 'Please enter domain name',
    please_input_system_name: 'Please fill in the system name',
    please_input_company_name: 'Please fill in the company name',
    please_select_expiration_data: 'Please select deadline',
    please_input_email: 'Please fill in the email',
    please_input_email_authorization_code: 'Please fill in the email authorization code',
    please_input_email_server: 'Please fill in the mail server',
    please_input_port: 'Please fill in the port number',
    the_mail_format_is_incorrect: 'The email format is incorrect',
    Please_enter_the_role_name: 'Please Input role name',
    Please_enter_the_role_description: 'Please Input Role description',
    Please_enter_the_verification_code: 'Please Input Verification code',
    Please_enter_your_mobile_phone_number: 'Please Input Mobile phone no.',
    The_phone_number_must_be_a_numeric_value: 'The phone number must be a numeric value',
    Please_fill_in_at_least_one_type_of_alarm_contact: 'Please provide at least one contact information for the police',
    Phone_number_format_error: 'Please enter the correct mobile phone number',
    please_input_your_vaild_email: 'Please enter the correct email address',
    Remarks_cannot_be_greater_than_200_characters: 'Remark cannot be greater than 200 characters',
    Enter_the_password: 'Please Input password',
    Enter_the_passwordagain: 'Please Input password again',
    Enter_the_repassword: 'Please enter your confirmation password',
    The_two_passwords_do_not_match: 'The two passwords do not match',
    please_input_name: 'Please Input name',
    please_input_position: 'Please Input position',
    Please_enter_a_user_name: 'Please Input user name',
    reset_passwords: 'Reset password',
    Please_select_trigger_condition: 'Pelase Choose trigger condition',
    Please_select_linkage_device: 'Please select linkage device first',
    Please_select_linkage_slave: 'Please select linkage slave first',
    Please_select_linkage_node: 'Please select linkage data points first',
    Please_select_linkage_data_model: 'Please select linkage data template first',
    'Please_input_trigger_name!': 'Please enter the trigger name',
    '1_to_20_characters_in_length': 'The length cannot exceed 20 characters',
    'Minimum_value_can_not_be_empty!': 'The minimum cannot be empty!',
    'Please_fill_in_the_correct_minimum!': 'Please enter the correct minimum!',
    'Please_fill_in_the_correct_maximum!': 'Please fill in the correct maximum!',
    'Please_fill_in_the_correct_maximum_or_minimum!': 'Please fill in the correct maximum or minimum value!',
    'Maximum_value_can_not_be_empty!': 'The maximum value cannot be empty!',
    'Maximum_or_minimum_cannot_be_empty!': 'The maximum or minimum cannot be empty!',
    'Please_select_model_node!': 'Please select template data point!',
    'The_alarm_mode_cannot_be_empty!': 'Alarm mode cannot be empty!',
    'Alarm_contact/group_cannot_be_empty!': 'Alarm contact/group cannot be empty!',
    'Alarm_contact_cannot_be_empty!': 'Alarm contact cannot be empty!',
    'Contact_cannot_be_empty!': 'Contact cannot be empty!',
    'Invalid_name!': 'Name cannot be empty!',
    'The_data_sent_cannot_be_empty!': 'Send data cannot be empty!',
    Group_name_cannot_be_empty: 'Please enter device group name',
    Sequence_cannot_be_empty: 'The sort cannot be empty',
    Sequence_must_be_numbers: 'The sort must be numeric',
    Please_select_the_processing_status: 'Please select the processing state',
    Please_fill_in_the_processing_results: 'Please fill in the processing result',
    Please_choose_slave: 'Pelase Choose Slave',
    Please_choose_data_point: 'Pelase Choose Data node',
    Sending_data_cannot_be_empty: 'Send data cannot be empty',
    Please_enter_an_integer_or_decimal: 'Please enter decimal or integer',
    Please_enter_the_hexadecimal_data_format: 'Please Input Hexadecimal data format',
    The_length_of_sending_data_should_not_exceed_256_characters: 'Send data length cannot exceed 256 characters',
    Please_input_template_name: 'Please Input Template name',
    Template_names_should_not_exceed_twenty_characters: 'Template names cannot exceed twenty characters',
    please_input_AppID: 'Please Input AppID',
    please_input_AppSecret: 'Please Input AppSecret',
    please_input_template_ID: 'Please Input template ID',
    please_input_template_name: 'Please Input template name',
    Please_select: 'Pelase Choose Report frequency',
    Please_select_report_type: 'Pelase Choose report method',
    the_tel_format_is_incorrect: 'The contact number format is not correct',
    the_Phone_number_format_is_incorrect: 'the phone number format is incorrect',
    Passwords_are_only_Numbers_or_a_combination_of_upper_and_lower_case_letters:
      'Passwords are numeric or uppercase only',
    association_role_cannot_be_empty: 'The associated role cannot be empty!',
    take_charge_project_cannot_be_empty: 'Responsible project cannot be empty!',
    Slave_quantity_cannot_be_empty: 'Slave number cannot be empty!',
    please_input_slave_quantity: 'Please Input Slave number',
    Slave_quantity_must_be_greater_than_zero: 'The number of slaves must be greater than 0',
    Device_level_cannot_be_empty: 'Device level cannot be empty!',
    please_input_device_level: 'Please enter device level',
    Device_level_must_be_greater_than_0_and_Less_than_4: 'The device level must be greater than 0 and less than 4',
    Remarks_no_longer_than_30_characters: 'Remark length shall not exceed 30 characters',
    Please_enter_the_article_title: 'Please Input article title',
    Plese_select_the_article_type: 'Pelase Choose article type',
    '9_characters_in_length': 'Length is 9 characters',
    '1_to_15_characters_in_length': 'Length between 1 and 15 characters',
    '1_to_10_characters_in_length': 'The length cannot exceed 10 characters',
    Please_choose_protocols_and_drivers: 'Please select protocol and driver',
    Please_select_storage_type: 'Please select storage mode',
    Please_select_linkage_variable: 'Please select linkage variables first',
    'The_save_rule_cannot_be_empty!': 'Save rule cannot be empty!',
    'The_trigger_status_cannot_be_empty!': 'Trigger state cannot be empty!',
    Please_get_verification_code: 'Please get the verification code first',
    '1_to_30_characters_in_length': 'The length cannot exceed 30 characters',
    '4_to_30_characters_in_length,One_Chinese_character_occupies_two_characters':
      'Please enter 4-30 characters, one Chinese character occupies 2 characters',
    Please_select_storage_time: 'Pelase Choose storage frequency',
    The_register_address_format_is_not_valid: 'Register address format not valid',
    Please_enter_the_article_content: 'Please enter the article content',
    'Upload_picture_size_can_not_exceed_2MB!': 'Upload image size cannot exceed 2MB!',
    Upload_picture_size_can_not_exceed: 'Upload image size cannot exceed',
    'Upload_pictures_can_only_be_in_JPG_or_PNG_format!': 'Upload pictures can only be JPG or PNG format!',
    Select_the_data_template_to_delete: 'Pelase Choose The data template to delete',
    Upload_file_cannot_be_empty: 'The upload file cannot be empty',
    Length_cannot_exceed_100_characters: 'The length cannot exceed 100 characters',
    'Up_to_five_images_can_be_uploaded_and_Upload_pictures_in_JPG/PNG_format_no_more_than_2MB!':
      'Up to five images can be uploaded in JPG/PNG format of up to 2MB!',
    'Up_to_five_images_can_be_uploaded!': 'Up to five pictures can be uploaded!',
    'Please_upload_Wechat_Public_Number_Two-Dimensional_Code': 'Please upload WeChat public number QR code',
    'Upload_file_can_not_exceed_15MB!': 'Upload file size cannot exceed 15MB!',
    'Upload_file_can_not_exceed_10MB!': 'Upload file size cannot exceed 10MB!',
    Please_input_rangeability: 'Please Input rangeability',
    Rangeability_must_be_greater_than_0: 'Rangeability must be greater than 0',
    'cannot_contain_special_characters_and_are_between_4_and_30_in_length,One_Chinese_character_occupies_two_characters':
      'cannot contain special characters and the length should be between 4 and 30. One Chinese character accounts for 2 characters',
    '6_and_20_characters,_including_at_least_2_letters,_Numbers_and_special_symbols,_excluding_Spaces':
      '6-20 characters, including at least 2 types of letters, Numbers and special symbols',
    'The_push_mode_cannot_be_empty!': 'Push mode cannot be empty!',
    'Pictures_can_only_upload_jpg/png_files,and_no_more_than_2M':
      'Pictures can only be uploaded as JPG/PNG files, and no more than 2M',
    Please_fill_in_at_least_one_type_of_contact:
      'Please select at least one of them in your phone number or email address',
    '1_to_40_characters_in_length,One_Chinese_character_occupies_two_characters':
      '1 to 40 characters in length,One Chinese character occupies two characters',
    The_download_data_cannot_be_empty_and_must_be_numerical: 'The download data cannot be empty and must be numerical',
    The_interval_time_cannot_be_empty_and_must_be_numerical_and_non_zero:
      'Interval cannot be empty and must be a non-0 integer value',
    The_time_cannot_be_empty: 'Time cannot be empty',
    Please_enter_trigger_threshold: 'Please enter trigger threshold',
    Please_enter_the_value_of_A: 'Please enter the value of A',
    Please_enter_the_value_of_B: 'Please enter the value of B',
    'Please_fill_in_the_correct_alarm_value!': 'Please fill in the correct alarm value!',
    'Alarm_value_can_not_be_empty!': 'Alarm value can not be empty！',
    Please_enter_the_correct_device_ID: 'Please enter the correct device ID',
    'Please_0-20_valid_characters': 'Please enter 0-20 valid characters',
    'cannot_contain_special_characters_and_are_between_1_and_30_in_length,One_Chinese_character_occupies_two_characters':
      'cannot contain special characters and the length should be between 1 and 30. One Chinese character accounts for 2 characters',
    '1_to_30_characters_in_length,One_Chinese_character_occupies_two_characters':
      'Please enter 1-30 characters, one Chinese character occupies 2 characters',
    'cannot_contain_special_characters_and_are_between_1_and_64_in_length,One_Chinese_character_occupies_two_characters':
      'cannot contain special characters and the length should be between 1 and 64. One Chinese character accounts for 2 characters',
    Start_time_cannot_be_equal_to_end_time: 'Start time cannot be equal to end time',
    The_length_of_url_should_not_exceed_256_characters: 'The length of url should not exceed 256 characters',
    The_length_No_more_than_50_characters: 'The length No more than 50 characters',
    The_length_of_product_model_should_not_exceed_256_characters:
      'The length of product model should not exceed 256 characters',
    'cannot_contain_special_characters_and_are_between_1_and_256_in_length,One_Chinese_character_occupies_two_characters':
      'cannot contain special characters and the length should be between 1 and 256. One Chinese character accounts for 2 characters',
  },
  confirm: {
    Tips: 'Tips',
    'Are_you_sure_you_want_to_delete_this_record,_or_not?': 'You will delete the record. Do you want to continue?',
    'Are_you_sure_you_want_to_delete_the_specified_alarm_contact?':
      'Are you sure you want to delete the specified alert contact?',
    'Are_you_sure_you_want_to_edit_the_trigger_status?': 'Are you sure you want to edit the status of the trigger?',
    'Are_you_sure_you_want_to_delete_chosen_trigger?': 'Are you sure you want to delete the specified trigger?',
    'Are_you_sure_delete_this_device?': 'You will delete the device, do you want to continue?',
    'Are_you_sure_delete_this_Data_model?': 'You will delete this template, do you want to continue?',
    'Are_you_sure_delete_this_monitor?': 'You will delete this camera, do you want to continue?',
    'You_are_going_to_switch_languages,_Do_you_want_to_continue?':
      'You will switch languages, do you want to continue?',
    'You_are_going_to_take_the_initiative_to_collect_data_points,_Do_you_want_to_continue?':
      'You are going to collect data points actively. Do you want to continue?',
    'Are_you_sure_about_the_operation?': 'Are you sure about the operation?',
    Are_you_sure_you_want_to_start_this_task: 'Are you sure you started the task?',
    Are_you_sure_you_want_to_pause_this_task: 'Are you sure to close the task?',
    'Are_you_sure_you_want_to_remove_this_contact?': 'Are you sure you want to remove this contact?',
    'Are_you_sure_to_switch?': 'Are you sure to switch?',
    'Are_you_sure_you_want_to_change_the_enabled_state?':
      'You will change the enabled status, do you want to continue?',
    'Are_you_sure_you_want_to_set_this_user_to_be_the_customer_super_administrator?':
      'You are going to make this user the customer super administrator. Do you want to continue?',
    'Are_you_sure_you_want_to_set_this_user_to_be_level1_Vip?':
      'You are going to make this user a level 1 member. Do you want to continue?',
    'Are_you_sure_you_want_to_set_this_user_to_be_neutral_Vip?':
      'You will make this user a neutral member, whether to continue?',
    'Are_you_sure_you_want_to_set_this_user_to_be_nonmember?':
      'You are going to make this user a non-member. Do you want to continue?',
    'Are_you_sure_you_want_to_set_this_role_to_be_the_default_role?':
      'Are you sure you want to set this role to be the default role?',
    'Are_you_sure_you_want_to_cancel_this_role_to_be_the_default_role?':
      'You want to cancel the default role for this role, do you want to continue?',
    'You_do_not_have_a_role_to_associate_yet,_do_you_want_to_add_it?':
      "You don't have an associated role yet, do you want to add one?",
    'Once_the_device_template_is_replaced,all_historical_data,alarm_etc_records_of_the_device_will_be_lost_and_cannot_be_recovered?':
      'Once the equipment template is changed, all the historical data,Timed task, alarm and other records of the equipment will be lost and cannot be recovered. Do you want to continue?',
    Are_you_sure_you_want_to_delete_this: 'You will delete the',
    ',or_not?': 'Do you want to continue?',
    'Are_you_sure_to_export_the_data_template?': 'Do you export data templates?',
    'Are_you_sure_you_want_to_restart?': 'You will restart, do you want to continue?',
    'Are_you_sure_you_want_to_Factory_Reset?': 'You will restore factory Settings. Do you want to continue?',
    'You_are_sure_to_unbind_the_ys7Cloud_account?': 'Are you sure you want to unpack your EZVIZ  cloud account?',
    'Are_you_sure_you_want_to_delete_the_repair_order?':
      'You are about to finish the work order. Do you want to continue?',
    'The_phone_number_has_been_bound_to_other_users,_shall_we_release_the_binding_relationship_with_other_users?':
      'The mobile phone number has been bound to other users. Do you want to break the binding relationship with other users?',
    'The_unfinished_work_orders_under_the_maintenance_plan_will_be_automatically_terminated,_Do_you_want_to_continue?':
      'The outstanding work orders under the maintenance plan will be terminated voluntarily. Confirm deleting the maintenance plan?',
    'Your_application_for_registration_has_been_received,_The_administrator_will_review_it_as_soon_as_possible':
      'Your application for registration has been received and will be reviewed as soon as possible',
    'Are_you_sure_it_is_enabled?': 'Are you sure it is enabled?',
    'Are_you_sure_to_disable_it?': 'Are you sure to disable it?',
    'You_are_about_to_apply_through_this_account,_Do_you_want_to_continue?':
      'You are going to apply for this account, do you want to continue?',
    'User_added_successfully,_whether_to_assign_device_for_user?':
      'User added successfully, whether to assign device for user?',
    'This_operation_will_close_sharing,do_you_want_to_continue?':
      'This operation will close sharing, do you want to continue?',
    'This_operation_will_enable_sharing,do_you_want_to_continue?':
      'This operation will enable sharing, do you want to continue?',
    'The_order_cannot_be_restored_after_cancellation,_Are_you_sure_to_cancel':
      'The order cannot be restored after cancellation, Are you sure to cancel?',
  },
  column: {
    Name: 'Name',
    DATA_MODEL: 'Data Model',
    Node: 'Node',
    Alarm_dead_zone: 'Alarm Dead Zone',
    Alarm_push_content: 'Alarm Push Content',
    Resume_normal_push_content: 'Resume normal push content',
    Trigger_name: 'Trigger Name',
    Alarm_contact: 'Alarm Contact',
    Alarm_contact_group: 'Alarm Contact Group',
    Open_linkage: 'Open Linkage',
    Linkage_object: 'Linkage object',
    Linkage_type: 'Linkage type',
    Deliver_data: 'Deliver data',
    Linkage_parameter: 'Linkage parameter',
    Data_model_name: 'Data model name',
    Triggering_condition: 'Triggering condition',
    Save_rule: 'Save rules',
    Alarm_method: 'Alarm method',
    Linkage: 'Linkage',
    Trigger_status: 'Trigger status',
    Device_name: 'Device name',
    onlineStatus: 'Online Status',
    Monitor_Management: 'Monitor Management',
    Monitor_name: 'Monitor name',
    Associated_device: 'Associated device',
    Serial_number: 'Serial number',
    Verification_Code: 'Verification Code',
    Update_time: 'Update time',
    Operation: 'Operation',
    Slave_name: 'Slave name',
    Current_value: 'Current value',
    Data_point_name: 'Data node name',
    Type_of_data: 'Type of data',
    Open_state: 'Open status',
    Role_Name: 'Role Name',
    Role_description: 'Role description',
    Creation_time: 'Creation time',
    Account: 'Username',
    Email: 'Email',
    Time_of_last_login: 'Time of last login',
    Group_name: 'Goup name',
    Node_number: 'nodes number of group',
    Twin_group_node_number: 'nodes number of Corresponding group',
    User: 'Subordinate User',
    Device_tag_name: 'Group name',
    Note: 'Goup description',
    Device_ID: 'Device ID',
    Time: 'Time',
    Alarm_content: 'Alarm Content',
    Alarm_time: 'Alarm time',
    Alarm_state: 'Alarm state',
    Process_state: 'Process state',
    Value: 'Value',
    Switching_status: 'Switch-type',
    Character_type: 'Character type',
    Location_type: 'Location type',
    bytes_unsigned_integer: 'Byte unsigned integer',
    bytes_signed_integer: 'Byte signed integer',
    'bytes_unsigned_integer(AB_CD)': 'Byte unsigned integer(AB CD)',
    'bytes_unsigned_integer(CD_AB)': 'Byte unsigned integer(CD AB)',
    'bytes_signed_integer(AB_CD)': 'Byte signed integer(AB CD)',
    'bytes_signed_integer(CD_AB)': 'Byte signed integer(CD AB)',
    'bytes_float(AB_CD)': 'Byte Float(AB_CD)',
    'bytes_float(CD_AB)': 'Byte Float(CD AB)',
    byte_base_station_positioning: 'Byte location',
    byte_GPS_positioning: 'Byte GPS location',
    '(Precision)': 'decimal digits',
    Read_only: 'Read Only',
    'Write&Read': 'Write&Read',
    Just_write: 'Write Only',
    Register: 'Register',
    Node_type: 'Data Type',
    Data_type: 'Value Type',
    Precision: 'decimal digits',
    Data_storage: 'Data storage',
    Units: 'Units',
    Not_storage: 'Do not store',
    Change_storage: 'Variable storage',
    Storage: 'Storage',
    task_name: 'Task Name',
    Function_center: 'Function Center',
    execution_event: 'Execution Event',
    execution_object: 'Execution Object',
    contents_of_execution: 'Execution Content',
    execution_time: 'Execution Time',
    Number: 'Number',
    Device_number: 'Device Number',
    Data_model: 'Associated template',
    Status: 'Status',
    Formula: 'Formula',
    Inverse_formula: 'Inverse operation formula',
    Register_length: 'Register length',
    Node_note: 'Variable description',
    Floating_point_precision: 'Reverse arithmetic floating point precision',
    Timer_status: 'Timer state',
    History: 'History query',
    read: 'Read',
    Regular_report: 'Timing Report',
    Change_report: 'Variate Report',
    Real_time_report: 'Real time Report',
    '(not_recommend)': 'Not recommend',
    IP: 'Slave IP',
    SlaveName: 'Slave Name',
    Port: 'Port',
    Parameter_name: 'Parameter Name',
    Sampling_frequency: 'Collection Frequency',
    actuating_equipment: 'actuating equipment',
    Report_type: 'Report Method',
    PLC_model: 'PLC Model',
    Template_name: 'Template Name',
    Slave_quantity: 'Number of slaveS',
    Data_point_quantity: 'Data nodes quantity',
    Register_type: 'Register Type',
    Advanced_options: 'Advanced Options',
    Device_template_name: 'Device Template Name',
    '175px': '180px',
    Variable_name: 'Variable name',
    'Slave/Variable': 'Slave/Variable',
    ConfigurationDesign: 'Configuration',
    Firmware_Name: 'Firmware Name',
    Equipment_Series: 'Device Series',
    Equipment_Type: 'Device Model',
    Firmware_Version: 'Firmware Version',
    Adding_Time: 'Adding Time',
    Firmware_Quantity: 'Firmware Quantity',
    Release_Time: 'Release Time',
    User_Group: 'User Group',
    Upgrade_Progress: 'Upgrade Progress',
    Upgraded_Version: 'Upgraded Version',
    Wait_Upgraded_Version: 'Target Version',
    Data_format: 'Data format',
    Publication_Time: 'Release Time',
    Whether_to_delete_the_following_checked_items: 'Whether to delete the following selections',
    Log_type: 'Log Type',
    Upload_time: 'Upload Time',
    Log_size: 'Log Size',
    Monitor_type: 'Camera Type',
    data_model: 'Data Template',
    Device_num: 'Device Quantity',
    Name_of_alarm_notification: 'Alarm notification name',
    Number_of_associated_devices: 'Number of device context',
    Enabled_state: 'Enabled state',
    Address: 'Address',
    'Do_not_collect_(active_reporting)': 'Do not collect (report voluntarily)',
    Utilization_rate: 'Utilization rate',
    Original_version: 'Original version',
    Alarm_Rules: 'Alarm Rules',
    Relevant_Contacts: 'Relevant Contacts',
    Association_operation: 'Association operation',
    Config_Progress: 'Config Progress',
    Task_status: 'Task status',
    Enable_the_alarm: 'Enable the alarm',
    Push_contact: 'Push contact',
    Linkage_variable: 'Linkage variable',
    Execution_interval: 'Execution interval',
    Execution_task: 'Execution task',
    Upgrade_results: 'Upgrade results',
    Configuration_result: 'Configuration result',
    Linkage_data_point: 'Linkage data point',
    MAC: 'MAC',
    IMEI: 'IMEI',
    IMEI2: 'IMEI2',
    Model_name: 'Model name',
    ReadWrite: 'Read/write',
    Number_of_client_software_or_devices: 'Number of client software or devices',
  },
  content: {
    Focus_on_WeChat_official_account: 'Add Public Platform',
    Click_to_view_my_device: 'My device check',
    Add_data_model: 'Add Data Template',
    Add_device: 'Add Device',
    'Configure&install_device': 'Access Device',
    Monitor: 'Monitor Center',
    Device_count: 'Device Qty',
    Node_count: 'Nodes Qty',
    Trigger_count: 'Triggers Qty',
    'Sub-account_count': 'Sub-account Qty',
    Device_settings: 'Manage Devices',
    Node_settings: 'Manage Nodes',
    Trigger_settings: 'Manage Triggers',
    Account_settings: 'Manage Users',
    time_of_last_login: 'Last Login Time',
    login_times: 'Login Times',
    Cloud_SCADA: 'IoT Cloud SCADA',
    Cloud_Interface: 'IoT Cloud Interface',
    Transferring: 'IoT Cloud Exchanger',
    update_log: 'Update Log',
    More: 'More',
    Json_data_flow: 'Json Data Flow',
    Raw_data_flow: 'Raw Data Flow',
    Enabled: 'Enabled',
    Disabled: 'Disabled',
    SMS: 'SMS',
    Wechat: 'Wechat',
    'E-mail': 'Email',
    OFF: 'OFF',
    ON: 'ON',
    Value_is_less_than: 'Value is less than',
    Value_is_more_than: 'Value is more than',
    and_less_than: 'and less than',
    or_less_than: 'or less than',
    Value_is_more_than_A_and_less_than_B: 'Value is more than A and less than B',
    Value_is_more_than_B_or_less_than_A: 'Value is more than B or less than A',
    Value_is_equal: 'Value is equal to',
    Save: 'Save',
    Not_save: 'Not Save',
    Save_All: 'Save All',
    Unselected: 'Unselected',
    Selected: 'Selected',
    '(contacts)': '(Contacts)',
    '(contact_group)': '(Contact Group)',
    Device_in_group: 'Device in group',
    Device_in_twin_group: 'Corresponding device in group',
    Device_list: 'Device list',
    Data_are_transferred_between_the_group_and_twin_group:
      'Note:Only communication protocol for data transparent transmission be supported in group',
    Default_group: 'Default grouping',
    Default_project: 'Default project',
    All_group: 'All grouping',
    Top_menu: 'Top menu',
    Device_name: 'Device name',
    Device_information: 'Device info',
    Device_ID: 'Device ID',
    Protocol: 'Communication protocol',
    Cloud_Key: 'Communication Password',
    Querying_frequency: 'Collection frequency',
    Graph: 'Historical curve',
    Device: 'Device',
    Time: 'Time period',
    Slave: 'Salve',
    Node: 'Data point',
    Wait_for_procession: 'unprocessed',
    Misinformation: 'Misinformation',
    Processed: 'Processed',
    Process: 'Processing',
    Not_process: 'No processing',
    restore_to_normal: 'Return to normal',
    Recovery: 'Recovery',
    alarm: 'Alarm',
    Alarm_history: 'Alarm history',
    Send_data_to_data_points: 'Send data to data points',
    Data_point_distribution: 'Data point distribution',
    Plc_data_point_distribution: 'Variable distribution',
    Send_data_to_the_device: 'Send data to the device',
    A_stream_of_hexadecimal_data: 'Send in hexadecimal data stream',
    Add_slave: 'Add slave',
    menu: 'Menu',
    link: 'Link',
    user_bind: 'User binding',
    user_unbind: 'User unbinding',
    close_video: 'Close the video',
    data_point_number: 'Associated data points',
    Default: 'Default device',
    LoRaWAN_module: 'LoRaWAN module',
    'Networking_I/O_controller': 'Networking IO',
    Add_device_via_QR_code: 'Add via QR Code',
    LoRa_module: 'LoRa module',
    LoRa_concentrator: 'LoRa gateway',
    'Telecom_CoAP/NB-IoT': 'Telecom_CoAP/NB-IoT',
    PLC_cloud_gateway: 'PLC Cloud gateway',
    New_PLC_cloud_gateway: 'New PLC Cloud gateway',
    Transfering: 'transparent transmission',
    USR_Smoking_Sensor: 'USR smoking alarm',
    Automatically_create: 'Automatic generation in system',
    Default_cloud_key: 'Default Communication Password',
    lng: 'Longitude',
    lat: 'Latitude',
    'SN/Check_code': 'SN/Verification code',
    Positioning: 'Positioning',
    Modify_the_default_custom_domain_name: 'Modify default custom domain',
    The_device_has_no_location_information: 'The device has no location information',
    Tips: 'Tips',
    Receive_in_hexadecimal: 'Receive in hexadecimal',
    Send_in_hexadecimal: 'Send in hexadecimal',
    The_device_does_not_have_address_targeting: 'The device has no address localization',
    The_device_has_no_data_points: 'The device has no data points',
    Data_point_ID: 'Data point ID',
    Variable_ID: 'Variable ID',
    Receiving_device: 'Receiving device',
    All: 'All',
    Send_as_hex: 'Send in hexadecimal',
    Selected_device: 'The selected device',
    Select_quantity: 'Select quantity',
    '(Positioning_device)': 'Positioning device',
    address: 'Detailed address',
    Please_choose_data_model: 'Please choose associated template',
    On: 'On',
    Off: 'Off',
    No_Vip: 'Non-members',
    level1_Vip: 'Primary member',
    neutral_Vip: 'Neutral member',
    Edit_slave: 'Edit slave',
    Normal: 'Normal',
    Deleted: 'Deleted',
    Registration_successful_and_logging: 'Registration succeed, and logging...',
    Registration_successful_and_Contact_the_administrator_for_review:
      'Registration succeed, please contact the administrator for verification',
    uploadCoverImage: 'Please upload the plan',
    Create_New_Contacts: 'New contacts',
    Binding_account: 'Binding account',
    Contacts_Have_Been_Selected: 'Contacts have been selected',
    The_device_has_no_variable: 'The device has no variable',
    FTP_Upgrade: 'FTP upgrade',
    HTTP_Upgrade: 'HTTP upgrade',
    All_Users: 'All users',
    Designated_User: 'Designated user',
    Task_Information: 'Task info',
    Select_Device: 'Select device',
    To_be_upgraded: 'Waiting for upgrading',
    Upgradeing: 'Upgrading',
    configurating: 'In the configration',
    Data_point_alarm: 'Data point alarm',
    Cloud_monitoring_alarm: 'Cloud monitoring alarm',
    Upgrade_Success: 'Upgrade success',
    Upgrade_Failed: 'Upgrade failed',
    Waiting: 'Waiting',
    In_Execution: 'Running',
    Has_Ended: 'Finished',
    No_configuration_yet: 'No configuration',
    The_device_has_no_configuration_yet: 'The device has no configuration yet',
    The_device_does_not_have_a_slave: 'The device does not have a slave',
    Conduct_Configure: 'Configuration',
    Serial_Port_Parameters: 'Serial port parameters',
    All_status: 'All status',
    Article: 'Articles',
    Success: 'Success',
    Video_URL: 'Video URL',
    Video_width: 'Video width',
    Video_height: 'Video height',
    Symbols: 'Symbols',
    'What_would_you_like_to_say?': 'What would you like to say?',
    receive_mode: 'Receving method',
    transmit_mode: 'Sending method',
    hexadecimal: 'Hexadecimal',
    string: 'Charater string',
    After_Focusing_on_the_Public_Number: 'After following the public number',
    Scan_code_binding_WeChat: 'Binding wechat by scaning the QR code',
    Binded_Users: 'Bound user',
    After_clicking_the_button_you_can_select_the_data_template_where_belong_to_the_device_from_the_filter_menu_of_the_data_template:
      'After clicking the button you can select the data template where belong to the device from the filter menu of the data template',
    After_clicking_the_button_you_can_select_the_data_template_of_network_IO_device_from_the_filter_menu_of_the_data_template:
      'After clicking the button,you can choose data template of the network IO device in the data template fliter menu',
    Click_the_button_again_to_select_the_data_template_in_the_current_account_from_the_filter_menu_of_the_data_template:
      'Clicking the button again,you can choose the data template of current account in the data template fliter menu',
    Software_Version: 'Software version',
    Hardware_Version: 'Hardware version',
    Commonly_used_instructions: 'Common used instructions',
    User_of_Equipment: 'Devices Subordinate User',
    User_Address: 'User address',
    User_Phone: 'User phone number',
    User_remark: 'User remark',
    Selected_Equipment: 'The selected device',
    Project_master_account: 'Project master account',
    Firmware_version_can_upgraded_to_this_version: 'Firmware version which could be upgraded to this version',
    series: 'Series',
    Cloud_configuration_function: 'Configuration',
    Add_project: 'Create project',
    Edit_project: 'Edit project',
    Value_is_less_than_A_and_more_than_B: 'Value is less than A, but greater than B',
    The_execution_of_Warranty_period_will_stop_when_it_expires: 'When warranty period expires,it will stop to execute',
    The_repair_order_progress: 'Ticket processing',
    'Engineers_are_being_assigned_to_your_problem,_Please_be_patient!':
      'Engineers are being assigned to your problem, please be patient',
    Engineers_have_been_assigned_to_your_problem: 'Engineers have been assigned to your problem',
    Please_wait_patiently: 'Please be patient',
    Engineers: 'Engineers',
    Working_on_your_problem: 'Working on your problem',
    has_finished_the_work_order: 'The tickets have been finished',
    Please_confirm_whether_the_problem_has_been_solved_in_time:
      'Please confirm timely whether the problem has been solved',
    Take_the_initiative_to_end: 'End initiatively',
    Process_and_results: 'Process and results',
    Purchase_usr_4G_camera: 'Buy 4G camera of USR',
    Create_New_Role: 'Create new role',
    Device_data_points: 'No.of device data point',
    Fast_access: 'Access fast',
    Configuring_device_templates_according_to_data_rules: 'Configuring the device template based on the data rules',
    Add_devices_on_the_cloud_based: 'Add the device in Cloud',
    Hardware_debugging: 'Hardware debug',
    Connecting_devices_to_the_cloud_based: 'The device is connected to cloud',
    Cloud_based_View: 'Cloud view',
    Viewing_data_by_monitoring_screen_and_mobile_phone: 'Viewing the data by monitoring screen and mobile phone',
    Overview_of_Cloud: 'Overview of Cloud',
    Quick_Start_Tutorial: 'Quick start course',
    'Follow_WeChat_Subscription,receive_alarm_push':
      'Following the official accounts, receiving the wechat alarm pushing',
    Use_Teaching: 'Usage teaching',
    See_more: 'See more',
    Solution: 'Solution',
    Industrial_PLC: 'Industrial PLC',
    Electric_power_monitoring_and_acquisition: 'Electrical power monitoring and collection',
    Intelligent_agriculture: 'Intelligent agriculture',
    _time: 'Times',
    Your_browser_does_not_support_Video_Tags: 'Your browser does not support the video tags',
    Number_of_subusers: 'Number of subusers',
    Air_environment_control_system: 'Air environmental control system',
    Full_Firmware: 'Full firmware',
    Incremental_firmware: 'Increment firmware',
    all_the_equipment_permissions_under_their_own_associated_projects:
      ' all the device permissions under their own associated projects',
    have_all_the_equipment_permissions_under_their_own_associated_projects:
      ' have all the equipment permissions under their own associated projects',
    Work_order_administrator_or_maintenance_personnel_have_all_the_equipment_permissions_under_their_own_associated_projects:
      'Tickets administrator or maintenance person have all the device permissions under their own associated projects',
    The_work_order_has_not_been_allocated_yet: 'Undistributed tickets',
    The_work_order_has_been_assigned_to_the_engineer: 'The tickets have been distributed to the engineer',
    has_accepted_the_work_order: 'The tickets have been accepted',
    process_mode: 'Processing method',
    Send_orders_personnel: 'Dispatch personnel',
    Site_disposal: 'In-site processing',
    'Off-site_disposal': 'Off-side processing',
    '1、leave_for': 'Leave for',
    fluorite_open_platform: 'Ezviz open platform',
    registered_account: 'Account registration',
    synching: 'Synchronizing',
    Telephone_Voice: 'Voice over a telephone',
    Offline_time_of_equipment: 'Offline time of device',
    'Within_minutes,the_number_of_dropouts_exceeded': 'Times of lost connection exceeds x within minutes',
    Wireless_Signal_Intensity: 'Wireless signal intensity',
    Current_monthly_flow_consumption: 'Flow consumption of current month',
    Battery_level: 'Bettery electricity',
    Card_inconsistency: 'Card inconsistency',
    Push_rule: 'Pushing rules',
    Push_Notice: 'Pushing notice',
    Push_mechanism: 'Pushing mechanism',
    Only_the_first_push: 'Pushing only first time',
    interval: 'Interval',
    Push_time_interval: 'Push time interval',
    Push_once_within_minute: 'Pushing once within minute',
    minute: 'minute',
    I_want_to_run_neutral: 'I want to run it in neutral',
    'Neutral_operation_minimum_10,000,_consulting_sales': 'Neutral operation minimum 10,000, consulting sales',
    You_can_use_your_own_domain_name_for_login_and_your_own_company_information_for_login_information:
      "You can log in using your company's own domain name,and your login information uses your own company information",
    Remove_all_information_and_links_from_the_USR_Cloud_corporate_operating_system:
      'To run the system by delete all information and links related to USR IOT company',
    'WeChat、_email_alarm_can_use_their_own_company_mailbox_and_WeChat':
      "Wechat,email alarm,you can use your company's email and wechat",
    'Small_program_neutral_operation,_custom_LOGO': 'Mini programs running in neutral,customized LOGO',
    The_first_method: 'The first method',
    The_second_method: 'The second method',
    Offline_alarm: 'Offline alarm',
    Signal_intensity: 'Signal intensity',
    Flow_consumption: 'Flow consumption',
    Electronic_fence: 'Electronic fence',
    Alarm_type: 'Alarm type',
    Cloud_monitor_function: 'Cloud monitor',
    Overview_of_Cloud_confige: 'Overview of Cloud configuration',
    Configuration: 'Configuration',
    Template_settings: 'Manage template',
    Operator: 'Operator',
    'Contact_at_least_one_of_e-mails,and_Wechat': 'please at least fill in one of email and wechat',
    'Contact_at_least_one_of_mobile_phones,e-mails,and_Wechat':
      'please at least fill in one of phone number,email or wechat',
    'Contact_at_least_one_of_mobile_phones,e-mails': 'please at least fill in one of mobile phone or email',
    Please_fill_in_your_email_address_for_contact_information: 'Please fill in email address for contact information',
    'Contact_at_least_one_of_mobile_phones,Wechat': 'please at least fill in one of e-mails and Wechat',
    Network_type: 'Networking type',
    Basic_Equipment_Information: 'Device basic information',
    Network_information: 'Type of network',
    Equipment_Recording: 'Records of device',
    The_first_step: 'The first step',
    The_second_step: 'The second step',
    Send_data_to_variable: 'Send data to variable',
    Slave_no_answer: 'Slave no answer',
    The_slave_gave_an_error_response: 'The slave gave an error response',
    unable_to_connect: '无法连接',
    No_such_key: 'No such key',
    Data_collection_failed: 'Data collection failed',
    Decimal_system: 'Decimal system',
    The_validation_code_is_sent_successfully_and_the_input_is_valid_within_5_minutes:
      'The validation code is sent successfully and the input is valid within 5 minutes',
    Go_To_USR_Account: 'Go To USR Account',
    Update_content: 'Update content',
    Open: 'Open',
    Opened: 'Opened',
    person_wants: '',
    Unnamed: 'Unnamed',
    Cloud_configuration: 'Cloud configuration',
    Cloud_monitoring: 'Cloud monitoring',
    Check_All: 'Check All',
    useful: 'useful',
    Get_log_time_period: 'Get log time period',
    Get: 'Get',
    weakest: 'weakest',
    weak: 'weak',
    middle: 'middle',
    strong: 'strong',
    strongest: 'strongest',
    Already_begun: 'begun',
    Bytes: 'Bytes',
    Equipment_flow_monitoring: 'Equipment flow monitoring',
    Upload_traffic: 'Upload traffic',
    Download_traffic: 'Download traffic',
    Signal_quality_monitoring: 'Signal quality monitoring',
    signal_intensity: 'signal intensity',
    Configuration_failure: 'Configure failure',
    Configuration_success: 'Configure success',
    To_be_configured: 'To be configured',
    In_configuration: 'configuring',
    Serial_command_cannot_be_configured_multiple_times_at_the_same_time:
      'Serial command cannot be configured multiple times at the same time',
    Client_list: 'Client list',
    Subuser_list: 'Subuser list',
    Manager_staff_list: 'Manager staff list',
    Maintenance_engineer_order: 'Maintenance engineer order',
    Ethernet: 'Ethernet',
    Being_acquired: 'Being acquired',
    Get_success: 'Get success',
    Acquisition_failure: 'Acquisition failure',
    Main_equipment: 'Main equipment',
    Copy_link_and_password: 'Copy',
    Close_to_share: 'Close to share',
    Password_required_for_access: 'Password required for access',
    QR_code: 'QR code',
    Share_the_qr_code_with_others: 'Share the qr code with others',
    The_other_side_can_view_through_WeChat_scan: 'The other side can view through WeChat scan',
    Download_qr_code: 'Download qr code',
    Open_to_share: 'Open to share',
    Mode: 'Mode',
    High_power_consumption: 'High power consumption',
    Low_power_consumption: 'Low power consumption',
    all_project_contacts: '(all project contacts)',
    Please_select_the_device_first: 'Please select the device first',
    Click_share_to_share_the_configuration_screen_of_the_selected_device:
      "Click 'share' to share the configuration screen of the selected device",
    Return_to_continue_sharing: 'Return to continue sharing',
    Silence_interval: 'Silence interval',
    Quantity_statistics_of_each_function: 'Quantity statistics of each function',
    Ranking: 'Ranking',
    Statistics_of_number_of_users: 'Statistics of number of users',
    Statistics_of_active_users: 'Statistics of active users',
    Statistics_of_connected_devices: 'Statistics of connected devices',
    Statistics_of_active_equipment: 'Statistics of active equipment',
    Statistics_of_active_equipment_models: 'Statistics of active equipment models',
    Thermal_diagram_of_equipment: 'Thermal diagram of equipment',
    Number_of_registered_devices: 'Number of registered devices',
    Number_of_equipment_configuration_drawings: 'Number of equipment configuration drawings',
    Number_of_sub_users: 'Number of sub users',
    Number_of_devices_enabling_cloud_configuration: 'Number of devices enabling cloud configuration',
    Users: 'Users',
    Notice: 'Notice',
    Amount: 'Amount',
    Data: 'Data',
    Total_change_in_the_past_year: 'Nearly a year',
    New_in_recent_30_days: 'Recent 30 days',
    Monthly_activity_in_the_past_year: 'Nearly a year',
    Daily_activity_in_the_past_30_days: 'Recent 30 days',
    Activity_in_the_past_30_days: 'Recent 30 days',
    Yesterday_activity: 'Yesterday activity',
    Voice: 'Voice',
    fail: 'fail',
    Manual_positioning: 'Manual positioning',
    Automatic_positioning: 'Automatic positioning',
    Register_Time: 'Register Time',
    product_group: 'product group',
    Add_root_group: 'Add root group',
    Product_agreement_list: 'Product agreement list',
    system_exception: 'system exception',
    Equipment_online: 'Equipment online',
    Equipment_out_of_line: 'Equipment out of line',
    Version_mismatch: 'Version mismatch',
    Issued_instructions: 'Issued instructions',
    Equipment_receiving_instruction: 'Equipment receiving instruction',
    Firmware_download_failed: 'Firmware download failed',
    Firmware_download_completed: 'Firmware download completed',
    Arrival_end_time: 'Arrival end time',
    Product_protocol_grouping: 'Product protocol grouping',
    Product: 'Product',
    every_interval: 'every interval',
    minutes_execute_once: 'minutes execute once',
    Alarm_silence_time: 'Alarm silence time',
    Please_check_document_configuration_neutral: 'Please check document configuration neutral',
    Protocol_group: 'Protocol group',
    Product_group: 'Product group',
    Edge_group: 'Edge group',
    Management_end: 'Management end',
    See_how_to_use: 'See_how_to_use',
    How_to_use: 'How to use',
    'What_effect_can_be_achieved_through_the_transparent_transmission_strategy?':
      'What functions can be realized through the transparent transmission strategy?',
    Strategy_start_and_stop: 'Strategy start and stop',
    Passthrough_target: 'Passthrough target',
    client_software: 'client software',
    Real_device: 'Real device',
    Available_client_software: 'Available client software',
    Achievable_effects: 'Achievable effects',
    Official_virtual_serial_port_software:
      '1. Official virtual serial port software, <a href="http://www.usr.cn/Download/924.html" target="_blank">Click to download</a>',
    'In_the_PC_virtual_a_virtual_serial_port,_the_user_is_serial_software_can_open_the_virtual_serial_port':
      "In the PC virtual a virtual serial port, the user's serial software can open the virtual serial port",
    Data_from_each_device_in_the_edge_end_device_group_can_be_received_through_this_virtual_serial_port:
      'Data from each device in the edge end device group can be received through this virtual serial port',
    'Send_data_to_this_virtual_serial_port,_and_each_device_in_the_edge_end_device_group_will_receive_it':
      'Send data to this virtual serial port, and each device in the edge end device group will receive it',
    Other_software_developed_based_on_the_SDK_provided_by_us:
      'Other software developed based on the SDK provided by us.(stay tuned for SDK)',
    data_from_each_device_at_the_edge_end_is_passed_to_each_device_at_the_management_end_and_vice_versa:
      'Note: data from each device at the edge end is passed to each device at the management end and vice versa',
    The_passThrough_policy_has_been_successfully_created: 'The pass-through policy has been successfully created',
    The_passthrough_policy_has_been_edited_successfully: 'The passthrough policy has been edited successfully',
    'We_did_not_create_a_passThrough_policy_for_you,_because_communication_from_PC_software_to_a_single_device_requires_no_additional_configuration_of_the_passThrough_policy,_All_passThrough_devices_support_this_usage_natively':
      'We did not create a passthrough policy for you, because communication from PC software to a single device requires no additional configuration of the passthrough policy.All pass-through devices support this usage natively.',
    Device_data_storage: 'Device data storage',
    What_is_product_library: 'What is product library',
    Product_warehouse: 'Product warehouse',
    Difference_between_edge_computing_and_Cloud_Collection: 'Difference between edge computing and The cloud polling',
    How_to_monitor_multiple_slaves: 'How to monitor multiple slaves',
    How_to_configure_design: 'How to configure design',
    How_to_view_the_configuration_screen: 'How to view the configuration screen',
    About_user_rights_definitions: 'About user rights definitions',
    About_device_positioning: 'About device positioning',
    Click_to_buy: 'Click to buy',
    Variable_name: 'Variable name',
    Data_pattern: 'Data pattern',
    Mobile_scan_add_device_quickly: 'Mobile scan, add device quickly',
    Order_summary: 'Order summary',
    User_purchase: 'User purchase',
    Payment_amount: 'Payment amount',
    Order_amount: 'Order amount',
    Delivery_status: 'Delivery status',
    Order_type: 'Order type',
    Invoice_information: 'Invoice information',
    Type_of_opening: 'Type of opening',
    Invoice_status: 'Invoice status',
    Invoice_rise: 'Invoice rise',
    Nature_of_invoicing: 'Nature of invoicing',
    Invoice_type: 'Invoice type',
    Amount_of_invoice: 'Amount of invoice',
    Tax_registration_certificate_No: 'Tax registration certificate No',
    Receiving_mailbox: 'Receiving mailbox',
    Name_of_Bank_of_deposit: 'Name of Bank of deposit',
    Basic_account_No: 'Basic account No',
    Registered_address: 'Registered address',
    Sign_up_for_landline: 'Sign up for landline',
    Unpaid: 'Unpaid',
    Paid: 'Paid',
    Cancelled: 'Cancelled',
    Failure: 'Failure',
    Order: 'Order',
    No_application: 'No application',
    Have_applied_for: 'Have_applied_for',
    In_audit: 'In audit',
    Arrival_account: 'Arrival account',
    Alipay: 'Alipay',
    Under_the_line: 'Under the line',
    Account_balance: 'Account balance',
    Recharge_amount: 'Recharge amount',
    Recharge_users: 'Recharge users',
    Payment_status: 'Payment status',
    VAT_general_invoice: 'VAT general invoice',
    VAT_special_invoice: 'VAT special invoice',
    Unshipped: 'Unshipped',
    Shipped: 'Shipped',
    Edge_device_to_customer_software: 'Realize data transmission between "device" and "client software"',
    Edge_devices_to_real_physical_devices: 'Realize data transmission between "equipment" and "equipment"',
    Order_purchase_resources: 'Order purchase resources',
    Purchase_content: 'Purchase content',
    USR_user: 'USR user',
    Reseller_users: 'Reseller users',
    resources_name: 'resources name',
    Price: 'Price',
    Purchase_quantity: 'Purchase quantity',
    Recharge: 'Recharge',
    Renew: 'Renew',
    New_purchase: 'New purchase',
  },
  help: {
    'E-mail_Server': 'E-mail server',
    User_WeChat: 'Wechat account',
    'E-mail_wildcards': 'Wildcard character of E-mail',
    Neutral_public_number_alarm_push: 'Neutral official accounts alarm pushing',
    Click_to_see_how_to_set_it_up: 'Click to see how to configure it',
    Device_category: 'Device class',
    Slave_category: 'Slave class',
    Data_point_category: 'Number of data points',
    Trigger_category: 'Trigger class',
    User_information_category: 'User information class',
    device_ID: 'Device ID',
    device_name: 'Device name',
    slave_index: 'Slave ID',
    slave_name: 'Slave name',
    data_point_ID: 'Data point ID',
    data_point_name: 'Name of data point',
    data_point_value: 'Value of data point',
    trigger_name: 'Trigger name',
    trigger_condition: 'Triggering condition',
    trigger_time: 'Triggering time',
    account: 'User name',
    System_setting: 'System settings',
    Domain_name_resolution: 'DNS',
    Same_as_SCADA_software_register:
      'The register is writen the same way with the configuration software,fill in the decimal register address,the register is the starting address +1',
    Data_transmission_instructions: 'Data sending instructions',
    Data_reception_instructions: 'Data receiving instructions',
    Two_formats: 'Two formats',
    Decimal_Hex: 'Decimal Hexadecimal',
    'Decimal_send_description:': 'Decimal send description:',
    'Hex_send_description:': 'Hexadecimal sending instructions',
    'Enter_numbers_directly,for_example:25': 'enter digits directly,example:25',
    '1:_0x_format,_for_example:_0x06,_0xaf,_etc': '1.0x format,example:0x06,0xaf etc.',
    '2:_String_format,_for_example:_12_(i_0x12),_when_the_input_character_is_too_long,allow_two_groups_to_be_separated_by_spaces,_for_example:_1314_can_be_entered_in_13_14_format':
      '2: String format, for example: 12 (i 0x12), when the input character is too long,allow two groups to be separated by spaces, for example: 1314 can be entered in 13 14 format',
    'For_example,_if_function_code_is_03H_or_06H,_address_is_0000H,_you_should_fill_in_40001':
      'Function code03H or 06H,starting address 0000H,then fill in:40001',
    'Function_code_is_04H,_address_is_000AH,_you_should_fill_in_30011':
      'Function code 04H,starting address 000AH,then fill in:30011',
    'Function_code_is_01H_or_05H,_address_is_0002H,_you_should_fill_in_00003':
      'Function code 01H or 05H,starting address 0002H,then fill in:00003',
    'Function_code_is_02H, address_is_0003H,_you_should_fill_in_10004':
      'Function code 02H,starting address 0003H,then fill in:10004',
    'Function_code_is_02H,_address_is_0003H,_you_should_fill_in_10004':
      'Function code 02H,starting address 0003H,then fill in:10004',
    Big_endian: 'Big end in front',
    Little_endian: 'Small end in front',
    '4_bytes_unsigned_integer(AB_CD):_123456_is_0x0001_0xE240':
      '4 bytes unsigned integer(AB CD):123456 is 0x0001 0xE240',
    '4_bytes_unsigned_integer(CD_AB):_123456_is_0xE240_0x0001':
      '4 bytes unsigned integer(CD AB):123456 is 0xE240 0x0001',
    '4_bytes_signed_integer(AB_CD):_-123456_is_0xFFFE_0x1DC0':
      '4 bytes unsigned integer(AB CD):-123456 is 0xFFFE 0x1DC0',
    '4_bytes_signed_interger(CD_AB):_-123456_is_0x1DC0_0xFFFE':
      '4 bytes unsigned integer(CD AB):-123456 is 0x1DC0 0xFFFE',
    '‘%s’_is_placeholder': '‘%s’in formula is placeholder,it is fixed field',
    Plus: 'Plus',
    Minus: 'Minus',
    Multiply: 'Multiply',
    Divide: 'Divide by',
    Remainder: 'Remainder',
    slave_IP: 'Slave IP',
    slave_port: 'Slave port',
    'Enter_\\r\\n_will_convert_to_a_space_carriage_return': 'Enter\\r\\n will be converted space carriage return',
    Said_the_enter: 'Indicates carriage return',
    Said_the_newline: 'Indicates line feed',
    slave_address: 'Slave address',
    Note: 'Note',
    Owned_Slave: 'Owned Slave',
  },
  message: {
    Please_fill_in_the_complete_information: 'Please fill in the information completely',
    Number_cannot_be_empty: "The value can't be empty",
    Alarm_duration_is_2_bytes: 'Length of alarm duration is 2 bytes',
    Heartbeat_interval_is_4_bytes: 'Length of heartbeat interval is 4 bytes',
    Alarm_interval_is_4_bytes: 'Length of alarm interval is 4 bytes',
    'Are_you_sure_you_want_to_delete_chosen_device?': 'You will delete the selected item,do you want to continue',
    'Please_choose_which_device_you_want_to_delete!': 'You did not select any device',
    'This_equipment_has_been_set_as_the_first_item!': 'The device has been set as the first item',
    'This_equipment_has_been_set_as_the_last_item!': 'The device has been set as the last item',
    'This_data_point_has_been_set_as_the_first_item!': 'The data point has been set as the first item',
    'This_data_point_has_been_set_as_the_last_item!': 'The data point has been set as the last item',
    Image_not_uploaded: 'Image not uploaded yet!',
    Please_try_again_after_5s: 'Please try again after 5s',
    Successfully_bind_ys7Cloud_account: 'Ezviz account binding succeed',
    Successfully_unbind_ys7Cloud_account: 'Ezviz account unbinding succeed',
    Modify_the_camera_information_successfully: 'Camera modification information succeed',
    Delete_the_device_successfully: 'Device deleted succeed',
    Add_the_device_successfully: 'Device added succeed',
    'Add_successfully!': 'Add succeed',
    'Delete_successfully!': 'Delete succeed',
    'Tasks_with_the_same_equipment_at_the_same_time_exist!': 'Tasks at the same time exist on same device',
    The_data_push_verification_is_successful: 'Data pushing verify successfully',
    'Modified_successfully!': 'Modification succeed',
    Sent_successfully: 'Sent successfully',
    Processing_successfully: 'Processing succeed',
    'Successful_sorting!': 'Ranking succeed',
    'Successful_acquisition!': 'Collection succeed',
    Unbind_is_successfully: 'Unbind succeed',
    distribution_successfully: 'Distribution succeed',
    reset_passwords_successfully: 'Password reset succeed',
    Password_reset: 'Password already reset',
    Sync_Task_Has_Been_Created: 'Sync task has been created',
    Cancel_the_order_successfully: 'Cancel the order successfully!',
    Invoice_application_succeeded: 'Invoice application succeeded!',
    Cancel_deletion_operation: 'Cancel the deleting operation',
    Debugging_is_not_supported_for_this_device_type: 'The device do not support debugging',
    Canceled_collection: 'Collection cancelled',
    Add_up_to_three_tags: 'Add 3 tag at most',
    Add_up_to_five_tag_categories: 'Add 5 tag categories at most',
    Please_enter_the_role_name: 'Please input the role name',
    The_role_name_cannot_be_empty: "The role name can't be empty",
    Edit_success: 'Edit succeed',
    Delete_success: 'Delete succeed',
    Cancel_delete: 'Cancelled delete',
    You_can_only_choose: 'You can only choose',
    group: 'Grouping',
    Incorrect_user_name_or_password: 'Wrong user name or password',
    Please_contact_the_administrator_for_account_review: 'Please contact the administrator for account audition',
    Incorrect_phone_number_or_verification_code: 'Wrong phone number or verification code',
    No_data: 'No data',
    Please_enter_a_hexadecimal_string_that_conforms_to_the_specification:
      'Please enter hexadecimal string that conforms to the specification',
    Incorrect_data_format: 'Incorrect data format',
    No_more_than_20_characters: 'No_more_than 20 characters',
    No_more_than_15_characters: 'No_more_than 15 characters',
    No_more_than_50_characters: 'No_more_than 50 characters',
    No_more_than_80_characters: 'No_more_than 80 characters',
    Roof_placement: 'Set in top',
    Tail_setting: 'Set in end',
    Move_up: 'Shift up',
    Move_down: 'Shift down',
    Data_is_empty: 'Data is empty',
    open_success: 'Open succeed',
    closed_success: 'Close succeed',
    Group_description_cannot_exceed_200_characters: 'Group description can not exceed 200 characters',
    Please_Choose_the_node_for_group: 'pls choose the node',
    Please_Choose_node_for_twin_group: 'pls choose the group node',
    Incorrect_format: 'wrong format',
    Modify_the_information_successfully: 'modify information successfully',
    Domain_name_is_not_supported: 'no support to fill in the domain name',
    Domain_name_is_not_standardized: 'domain name not standard',
    The_pictures_uploaded_successfully: 'pictures upload successfully',
    'Upload_logo_image_size_can_not_exceed_1MB!': 'upload logo image size not bigger than 1MB',
    'Upload_head_image_size_can_not_exceed_1MB!': 'Upload head image size can not exceed 1MB!',
    'Upload_logo_only_supports_jpg、_gif、_png_format!': 'upload log only support jpg,gif,png format',
    'Test_E-mail_sent_successfully': 'test email sent successfully',
    'Test_E-mail_delivery_failed': 'test email sent failure',
    Mail_template_updated_successfully: 'email format updated successfully',
    The_information_saved_successfully: 'message saved successfully',
    Binding_success: 'binding success',
    Upload_success: 'upload success',
    Confirm_delete: 'confirm to delete',
    Communication_password_length_is_8_bits: 'Communication Password length is 8 bits',
    Confirm_remove: 'confirm to remove',
    'URL_can_not_be_empty!': 'URL can not be blank',
    'Name_can_not_be_empty!': 'name can not be blank',
    'Register_can_not_be_empty!': 'register can not be blank',
    'File_upload_failed_please_re-upload': 'failed to upload the file,pls reupload',
    The_corresponding_target_is_not_selected: 'not choose the corresponding target',
    Register_length_can_not_exceed_64: 'register length can not exceed 64',
    The_register_length_can_not_be_less_than_1: 'register length can not less than 1',
    Node_name_or_Register_can_not_be_empty: 'node name or register can not blank',
    Please_enter_data: 'Please Input',
    Copy_success: 'copy success',
    Copy_failure: 'copy failure',
    Configuration_name_cannot_exceed_20_characters: 'configuration name can not bigger than 20 characters',
    'Choosen_device_can_not_be_empty!': 'choosen device can not be blank',
    The_device_number_you_selected: 'the device number you choose',
    'No_slave_can_not_add!': 'no slave,can not add',
    The_number_of_slaves_must_be_the_same: 'number of slaves must be the same',
    The_associated_template_from_the_machine_must_be_consistent:
      'the associated template from the slave must be consistent',
    No_slave: 'no slave',
    No_associated_device_please_associate_the_device_first: 'no associated device,pls associate the device firstly',
    URL_formal_is_illegal: 'URL format is illegal',
    Template_name_length_can_not_exceed_30: 'name of the template can not exceed 30',
    Data_name_can_not_exceed_50: 'name length can not exceed 50',
    Index_can_not_exceed_20: 'index can not exceed 20',
    Port_can_not_exceed_65535: 'port can not exceed 65535',
    Content_can_not_exceed_65535: 'port can not exceed 65535',
    IP_is_illegal: 'address is illegal',
    Please_enter_Email: 'Please Input Email',
    The_maximum_number_of_slaves_is: 'the max.slave qty',
    Slave_information_cannot_be_empty: 'the slave information cannot be blank',
    Please_fill_in_the_information_of_the_slave: 'pls fulfill the slave information',
    The_slave_serial_number_must_be_a_number: 'the slave number must be a number',
    Slave_serial_number_cannot_be_the_same: 'the slave number cannot be same',
    Please_add_an_Excel_file: 'pls add Excel file',
    Upload_templates_can_only_be_in_xls_or_xlsx_format: 'upload template can only be in xls,xlsx',
    'The_current_limit_selects_1_file_,_I_selected': 'limit to choose 1file,selected this time',
    files_this_time: 'fles',
    Selected_altogether: 'selected altogether',
    You_are_sure_to_remove: 'you are sure to remove',
    Can_not_be_empty: 'can not be empty',
    Upload_image_format_is_wrong: 'upload image format is wrong',
    'Upload_image_size_can_not_exceed_1M!': 'upload image size can not exceed 1M',
    Communication_password_must_be_8_digits: 'Communication Password must be 8 bits',
    Device_ID_must_be_number: 'device id must be number',
    The_range_is_from_1_to_65535: 'range from 1 to 65535',
    The_range_is_from_0_to_65535: 'range from 0 to 65535',
    The_sum_of_these_should_be_less_than_65535: 'the sum of these should be less than 65535',
    The_range_is_from_0_to_7: 'range from 0 to 7',
    The_range_is_from_1_to_127: 'rang from 1 to 127',
    Required_fields_can_not_be_empty: 'required fields can not be blank',
    Delete_failed: 'delete failed',
    The_data_push_is_not_available: 'the data push is not available',
    Please_select_slave_first: 'pls choose slave firstly',
    'Read-only_data_points_do_not_support_this_operation': 'read only data do not support this operation',
    The_registered_user_has_no_child_users: 'the registered user has no child users',
    Please_Binding_Wechat: 'pls bind wechat',
    Are_you_sure_you_want_to_unbind_Wechat: 'are you sure to unbind wechat',
    Wechat_Unbind_successfully: 'wechat unbind successfully',
    Canceled_modification: 'canceled modification',
    The_associated_role_is_not_selected: 'the associated role is not selected',
    Set_user_super_administrator_successfully: 'set user administrator successfully',
    The_device_has_no_location_information: 'the device has no location information',
    The_number_of_added_devices_cannot_be_empty: 'the number of added device can not be empty',
    Please_enter_a_numeric_value: 'Please Input',
    'The_number_of_added_devices_should_be_between_1-100': 'the number of added devices should be between 1-100',
    Please_add_a_device_template: 'pls add the device template',
    Variable_name_can_not_exceed_50: 'the name length cannot exceed 50',
    Procedure_image_input_register: 'procedure image input register',
    Procedure_image_output_register: 'procedure image output register',
    Variable_storage: 'variable storage',
    Bit_memory: 'bit memory',
    Data_block: 'data block',
    name_can_not_exceed_30: 'name can not exceed 30',
    Slave_device_number_must_be_a_number_or_letter: 'the slave device number must be a number or letter',
    The_slave_device_number_cannot_be_the_same: 'the slave device number can not be the same',
    'To_avoid_abuse_of_data_push_functionality,_new_additions_are_no_longer_supported_If_necessary,_please_contact_the_sales_manager_for_private_deployment':
      'to avoid abuse of data push functionlity,no longer support to add.if there is request,pls connect the sales manager for private deployment',
    Picture_insertion_failed: 'picture insertion failed',
    Please_enter_the_correct_video_URL: 'Please Input right URL video',
    The_number_of_data_points_can_not_exceed_500: 'the template variable  number can not exceed 500',
    Cancel_export: 'cancelled export',
    Cancel_download: 'cancelled download',
    Please_select_data_point: 'Pelase Choose data point',
    Are_you_sure_to_download_the_data_history: 'download the historical data or not',
    On_failure: 'on failure',
    Command_format_error: 'command format error',
    Cancel_restart: 'cancelled restart',
    Cancel_Factory_Reset: 'cancelled factory reset',
    Instructions_cannot_be_null: 'command can not be empty',
    Task_cannot_be_null: 'task can not be empty',
    Address_can_not_exceed_255_and_less_than_0: 'the slave address range from 0 to 255',
    has_benn_used: 'be used',
    can_not_exceed_30: 'can not exceed 30',
    'The_work_order_is_over!': 'ticket over',
    'Upload_wechat_image_only_supports_jpg、_png_format!': 'wechat image only support jpg,png format',
    Sent_orders_successfully: 'sent orders successfully',
    Order_receiving_successfully: 'order receiving successfully',
    This_device_type_does_not_support_timing_tasks_for_the_time_being: 'the device type no support timing tasks',
    Please_enter_alarm_push_interval_time: 'Please Input alarm push interval time',
    Unselected_menu: 'not selected menu',
    Please_select_the_data_point: 'Pelase Choose the data point which need to delete',
    Canceled_operation: 'canceled operation',
    Please_enter_push_interval_time: 'Please Input push interval time',
    Please_select_a_device_template: 'Pelase Choose device template',
    The_alarm_dead_zone_value_must_be_greater_than_or_equal_to_0:
      'the alarm dead zone value must bigger than or equal 0',
    Alarm_status_is_not_enabled_Linkage_cannot_be_enabled: 'alarm status is not enabled,linkage cannot be enabled',
    Please_select_time: 'Pelase Choose the time',
    Template_name_length_can_not_less_than_4: 'device template length can not less than 4',
    Value_name_length_can_not_exceed_30: 'Value name length can not exceed 30',
    Slave_name_length_can_not_exceed_30: 'Slave name length can not exceed 30',
    Slave_name_length_can_not_less_than_4: 'Slave name length can not less than 4',
    Value_name_length_can_not_less_than_4: 'Value name length can not less than 4',
    Please_enter_alarm_dead_zone: 'Please enter alarm dead zone',
    Select_device_and_variable: 'Select device and variable',
    Please_enter_the_alarm_name: 'Please enter the alarm name',
    Value_of_the_variable_reaches_the_trigger_condition: 'Value of the variable reaches the trigger condition',
    Push_mechanism: 'Push mechanism',
    Push_interval: 'Push interval',
    Please_select_the_execution_time: 'Please select the execution time',
    Please_enter_a_numerical_value_and_not_a_negative_number:
      'Please enter a numerical value and not a negative number',
    Alarm_notification_name_cannot_contain_spaces: 'Alarm notification name cannot contain spaces',
    This_configuration_is_not_yet_associated_with_the_device:
      'This configuration is not yet associated with the device',
    Please_fill_in_the_configuration_name: 'Please fill in the configuration name',
    Configuration_name_cannot_exceed_40_characters: 'Configuration name cannot exceed 40 characters',
    Please_try_again_after_3s: 'Please try again after 3s',
    Please_enter_decimal_digits: 'Please enter decimal digits',
    Device_is_disabled_and_is_being_used_by_someone_on_the_cloud_platform:
      'Device is disabled and is being used by someone on the cloud platform',
    This_device_type_does_not_support_synchronization_at_this_time:
      'This device type does not support synchronization at this time',
    Template_name_length_can_not_exceed_64: 'Template name length can not exceed 64',
    Assign_function_menu_permissions_to_the_current_role: 'Assign function menu permissions to the current role',
    Template_name_can_not_contains_special_characters: 'Template name can not contains special characters',
    Do_you_want_to_delete_the_project: 'Do you want to delete the project?',
    Please_dismiss_the_project_manager_first: 'Please dismiss the project manager first',
    New_equipment: 'New equipment',
    Closed_share: 'Closed share',
    Started_share: 'Started share',
    Link_copied: 'Link copied',
    Upload_file_can_only_be_in_xls_or_xlsx_format: 'Only files in .xls or .xlsx format can be uploaded!',
    Please_upload_a_file_in_the_correct_format: 'Please upload a file in the correct format!',
    Only_one_file_can_be_uploaded:
      'Only one file can be uploaded,Please delete the current file first and upload again',
    The_upload_file_cannot_exceed_20M: 'The upload file cannot exceed 20M!',
    Edit_device_template_to_set_more_slaves: 'Edit device template to set more slaves',
    Device_actively_disconnects_from_the_cloud: 'Device actively disconnects from the cloud',
    Device_reported_data_timeout: 'Device reported data timeout',
    No_related_variables_found: 'No related variables found',
    deleted_alarm_notification_tip:
      'The device associated with the alarm notification has been deleted. Please reset the alarm notification',
    processing_result_length_can_not_exceed_255: 'processing result length can not exceed 255',
    Device_is_required: 'Device is required!',
    At_command_cannot_contain_Chinese_characters: 'At command cannot contain Chinese characters',
    The_number_of_transparent_transmission_strategies_exceeds_the_limit:
      'The number of transparent transmission strategies exceeds the limit',
  },
  prop: {
    The_last_hour: 'the last hour',
    Today: 'today',
    Last_7_days: 'last 7 days',
    Last_30_days: 'last 30 days',
    Last_5_minutes: 'last 5 minutes',
    Last_2_hour: 'last 2hours',
    Last_1_day: 'last 1day',
    Last_3_days: 'last 3 days',
  },
  notify: {
    status: 'status',
    online: 'online',
    offline: 'offline',
    trigger_name: 'trigger name',
    describe: 'description',
    number: 'value',
    alarm_Project: 'alarm project',
    Datapoint_name: 'datapoint name',
    content: 'alarm content',
    alarm_time: 'alarm date',
    datapoint: 'datapoint',
    current_value_is: 'current value',
    is_normal: 'recover normal',
    have_alarmed: 'have alarmed',
    normal_time: 'recover time',
    Synchronized_completion_of_device_template: 'device template finish synchronized',
    Successful_synchronization_of_device_template: 'device template successful synchronization',
    Restore_content: 'recover content',
    Restored_content: 'Restored content',
    Alarm_value: 'Alarm value',
    Restore_value: 'Restore value',
  },
  week: {
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Sunday: 'Sunday',
    once_executed: 'excution once time',
    timed_execution: 'excution on time',
    repeated_execution: 'excution repeated',
    single_execution: 'single execution',
    time_interval_cycle_execution: 'time interval cycle execution',
    hour: 'hour',
    minute: 'minute',
    '1_Second': '1 second',
    '5_Second': '5 second',
    '15_Second': '15 second',
    '30_Second': '30 second',
    '1_Minute': '1 minute',
    '3_Minute': '3 minutes',
    '5_Minute': '5 minutes',
    '10_Minute': '10 minutes',
    '30_Minute': '30 minutes',
    '1_Hours': '1 hour',
    '5_Hours': '5 hours',
    '1_day': '1 day',
    '5_days': '5 days',
    '15_days': '15 days',
    Slave_auto_Upload: 'do not collect(auto-upload)',
    '2_Minute': '2 minutes',
    Seconds: 'second',
    Minutes: 'minute',
    Hours: 'hour',
    Days: 'day',
    Not_querying: 'do not collect',
    Year: 'year',
    Month: 'month',
    Week: 'week',
    one_day: '1 days',
    two_day: 'two days',
    three_day: 'three days',
    hebdomad: 'one week',
    fortnight: 'two weeks',
    one_month: 'one month',
    Not_in_advance: 'not in advance',
    '20_Minute': '20 minutes',
  },
  monitor: {
    home: 'homepage',
    monitoring: 'monitoring',
    management: 'backgroud',
    exit: 'exit',
    setting: 'setting',
    Device_survey: 'device general situation',
    Alarm_statistics: 'alarm statistics',
    Device_total: 'device total qty',
    Online_device: 'online device',
    Offline_device: 'offline device',
    Alarm_total: 'alarm total qty',
    Handled_total: 'handled alarm',
    Untreated_total: 'untreated alarm',
    Today_Alarm_total: 'alarm qty today',
    Today_Handled_total: 'handled alarm today',
    Today_Untreated_total: 'untreated alarm today',
    Online_rate: 'online rate',
    Treatment_rate: 'handling rate',
    handled: 'handled',
    untreated: 'untreaded',
    online: 'online',
    offline: 'offline',
    Device_list: 'device list',
    Alarm_info: 'alarm information',
    No_device: 'no device',
    Device_name: 'device name',
    Alarm_content: 'alarm content',
    processing: 'alarm dealing',
    Alarm_processing: 'alarm dealing',
    Processing_result: 'handled result',
    False_positives: 'false alarm',
    confirm: 'confirm',
    sure: 'confirm',
    Processing_description: 'handle description',
    save: 'Save',
    device: 'device',
    content: 'content',
    Device_number: 'device number',
    DEVICE_TEMPLATE: 'device template',
    address: 'device address',
    View_details: 'view details',
    Essential_information: 'General information',
    User_info: 'user information',
    Owned_users: 'Subordinate User',
    company: 'company name',
    Alarm_contact: 'alram contact',
    triggers: 'triggers',
    Timing_tasks: 'timing tasks',
    configuration: 'group picture',
    Real_time_data: 'real time data',
    to: 'to',
    Start_time: 'start time',
    End_time: 'end time',
    Only_write: 'only write',
    acquisition: 'active collection',
    cancel: 'cancel',
    Untreated_alarm: 'untreated alarm',
    History_data: 'historical data',
    History_Alarm: 'historical alarm',
    The_device_does_not_have_location_information: 'no location information in this device',
    The_device_does_not_have_configuration: 'the device does not have configuration',
    Device_template: 'Device template',
    Data_template: 'data template',
    Real_time_data_graph: 'real time data graph',
    Real_time_track: 'real time track',
    History_track: 'history track',
    Service_record: 'service record',
    Recently_maintenance_time: 'maintenance time recently',
    Recently_maintenance_status: 'maintenance status recently',
    Next_maintenance_time: 'next maintenance time',
    Parameter_data: 'parameters data',
    Protocol_and_driver: 'Protocol',
    The_device_is_currently_without_a_camera: 'The device is currently without a camera',
    overview: 'Overview',
  },
  title: {
    search: 'click to search',
    add_plan: 'add plan',
    edit_plan: 'edit plan',
    priview_plan: 'check the plan',
    add_repair: 'add repair',
    edit_repair: 'edit repair',
    priview_work_ordern: 'check the tech.ticket',
    'Advanced_Configuration-User_Permissions': 'advanced configuration-user permission',
    Device_positioning: 'device positioning',
    Finish_adding: 'finished to add',
    USR_Notice: 'USR Notice',
    Select_a_set_of_edge_end_devices: 'Select a set of edge end devices',
    Set_passthrough_targets_as_management_side: 'Set passthrough targets as "management side"',
  },
  msg: 'some info',
  registertitle: 'welcome to Register ',
  readLogin: 'Confirm read and agree ',
  officialWeb: 'Go to the official website',
  logoLimitation: 'Only support jpg, gif, png formats; size is less than 1M, size is 106*60 pixels',
  triggerSetting:
    '<p>USR Cloud trigger supports different alarm methods for different node. </p><p>USR Cloud only push alarm mail 1 time when you enter alarm status. When alarm release the USR Cloud will push release mail.</p><p>Trigger does not support transfering protocol.</p>',
  deadZone:
    '<p>The function of alarm dead zone is to prevent many false alarms when the value of variable fluctuates frequently in the alarm limit. A threshold of alarm limit is added to the original alarm limit so that the original alarm limit becomes an alarm limit.</p><p>For example, if the trigger is set above 100 and the alarm dead zone is set to 10, the device will trigger the alarm push only when the trigger is greater than 110 and return to normal when the trigger is less than 90.</p>',
  triggerRule:
    '<p>The default trigger rule is a state switch alarm:</p><p>Example: The temperature is greater than 40 alarms; when the temperature reaches 50, the alarm push is triggered and the alarm information is stored. Uploading 60 again will not trigger the alarm and will not save the data. When the temperature reaches 30, it will trigger the recovery alarm push and store the alarm message.</p>',
  saveRule:
    '<p>The save rule refers to the save rule of the alarm data:</p><p><strong>Save：</strong> </p><p>Saves the data when the trigger triggers an alarm and the data when it returns from the alarm state to the normal state.</p> <p><strong>Not save：</strong></p><p>The alarm data is not saved.</p>',
  alarmContacsGroupLink: 'Click to see how to set up an alarm contact/group.',
  AddDataPush: 'Must begin with http://',
  wechatBingdingTip: 'Note: The WeChat alarm function has two WeChat binding methods (the second is recommended):',
  wechatBingdingGenerateCodeDesc:
    'After scanning the QR code after paying attention to the public platform, you can directly bind WeChat.',
  usrinfo: 'You can edit your user profile in this system in the user information.',
  Password: 'You can modify the login password of the current user.',
  Communication_password:
    '<p>Cloud key is for accessing USR Cloud System. You can use default cloud key for every device or configure unique device key for every device.</p><p>Do not change cloud key once you already install the device on the field. Once password changed you need to change the settings on the device.</p>',
  addNode: '<p><strong>Save data:</strong>Online data are only reserved for 35 days.</p>',
  aboutOffline:
    '<p>If no data transmission in 2 hours, USR Cloud will disconnect the connection. If you need to keep the connection, you should configure your device to re-connect to USR Cloud.</p>',
  companyEmailAuthorizationCode:
    "Please enter 'e-mail password' for the 'email authorization code' of the company's mailbox.",
  personalEmailAuthorizationCode: " Please enter 'Authorization Code for Email' for 'Email Auth'.",
  testSendEmailConfigSever:
    " After clicking 'Test Send Mail', the mailbox receives a test mail indicating that the mail server is configured successfully.",
  deviceSlave:
    '<p><strong>What is slave?</strong></p><p>Slave is your modbus RTU slave device.</p><p><strong>What is the number?</strong></p><p>It is used to recognizing the source of data.</p><p><strong>What is the device number?</strong></p><p>Modbus protocol for the slave address, DL/T645 protocol for the meter number.</p><p><strong>Number of slaves？</strong></p><p>The maximum number of slaves is supported by three,To test more slaves please contact the opening test account.Address:<a href="http://h.usr.cn/" target="_blank">http://h.usr.cn/</a>Please specify: multi-machine test.</p><p><strong>Note: For official operation,please contact us to deploy a private server.</strong></p>',
  deviceSlaveSimplify:
    '<p><strong>What is slave?</strong></p><p>Slave is your modbus RTU slave device.</p><p><strong>What is the number?</strong></p><p>It is used to recognizing the source of data.</p><p><strong>What is the device number?</strong></p><p>Modbus protocol for the slave address.</p>',
  deviceCloudKey:
    '<p>Cloud key is for accessing USR Cloud System. You can use default cloud key for every device or configure unique device key for every device.</p><p>Do not change cloud key once you already install the device on the field. Once password changed you need to change the settings on the device.</p>',
  deviceUser:
    '<p>USR Cloud support account and sub-account. You can assign different devices and different permissions to different account&sub-account.</p>',
  deviceType: 'deviceType',
  deviceProtocol:
    '<p>The USR Cloud will be parsed according to the set protocol format, and stored in the database according to the data point settings, processing alarms, and pushing to the front end.</p><p><strong>Modbus RTU：</strong>The server will act as a Modbus RTU host and actively send read data commands to the device.</p><p>Note：If the user develops the device independently, it needs to actively upload data (such as: button control status, sensor active reporting, NB-IoT/Lora can only actively report the communication method)，Transparent transmission support is automatically reported according to Modbus RTU extension command，See details《<a href="http://cloud.usr.cn/Public/ModbusRTU/ModbusRTU.xlsx" target="_blank">Modbus RTU has extended instructions</a>》</p><p><strong>DL/T645：</strong>The meter protocol can be used for power meter reading and supports both 97 and 07 formats.</p><p><strong>Data transmission：</strong>I only hope to get the data stream through the USR Cloud,The SDK can be used to obtain the device data stream, and the transparent transmission group can also be used to set the transparent transmission relationship.</p><p>The CoAP/NBIoT device supports the addition of a "task queue" to transparently transmit data through a web page.</p>',
  addDevicePictureTip: 'Only support JPG, GIF, PNG format, the size of 1M or less',
  excelDeviceprefix: 'The device will use the device prefix +IMEI as the name of the device',
  limitUploadOneFile: 'The current limit is to select one file. To re-upload, delete the original file',
  cameraTip:
    '<p>The serial number is the 9-bit serial number on the camera body, <br> verification code is the 6-bit verification code on the camera body, <br> as shown in the following figure.</p>',
  excelSelectTip:
    '<p style="margin-bottom:10px;"><strong>Note: excel batch import can import up to 100 data at a time.</strong></p><p style="margin-bottom:10px;">Excel template download address：<a href="http://console.usr.cn/uploads/device/excel_model.xlsx" target="_blank">Click to download excel sample</a></p><p style="margin-bottom:10px;">You can also use the Excel given when you purchase the device (large customer volume customization)</p>',
  customDomainTip:
    'Custom domain names must start with console and support three-level domain names, such as console. test. com or console. abc. test. com. After setting, CNAME must be resolved to cname. usr. cn. See the right domain name resolution for details.',
  domainPlaceHolder: "Please enter the domain name in'console. *'format",
  Example: 'E.g.',
  FloatABCD: '4 bytes float(AB CD): 12.3 is 0x3F9D 0x70A4',
  FloatCDAB: '4 bytes float(CD AB): 12.3 is 0x70A4 0x3F9D',
  FormulaTip:
    'Tip: Supports simple input JS functions, such as (%s/1000).toFixed(2), which reserves two decimal places for data points divided by 1000',
  SaveDataTip: "Save data for 35 days in default. If you don't save data, system only save latest one data.",
  Communication_passwordTip:
    ' Cloud key is for accessing USR Cloud System. You can use default cloud key for every device or configure unique device key for every device. ',
  Communication_passwordTip2:
    'Do not change cloud key once you already install the device on the field. Once password changed you need to change the settings on the device.',
  timingFuntion:
    '<p><strong>The timing task function is used to send data to the device at the set time and frequency</strong></p>',
  timeingSelectFuction:
    '<p><strong>Sending data to devices：</strong>The original data stream is sent to the device.</p><p><strong>Sending data to data points：</strong>To send data points is to automatically compose data packets according to the protocol of the device, such as: Modbus protocol device will compose the data point value of the Modbus protocol issued.</p>',
  timeingschedule:
    '<p><strong>The execution time plan of scheduled tasks is divided into three types: one execution, regular execution and repeated execution.</strong></p><p><strong>Perform a：</strong>Take the selected time parameter as the specified time, send the data regularly, and finish the task after one execution.</p><p><strong>Regularly perform：</strong>Take the selected week time time parameter as the specified time, send the data regularly, and execute it up to once a day, and the task should be stopped manually.</p><p><strong>repetitive execution：</strong>Take the selected time time parameter as the execution frequency interval, repeat the data, and manually stop the task</p>',
  PackageLengthHelp:
    '<p>Depending on device requirements, MODBUS now allows users to customize the length of registers merged and issued in a single automatic collection of individual devices (the default length is 32).</p>',
  have_not_device_yet: "You haven't added the device yet. Please go to add it.",
  NextStep: 'Next, configure the slave and variables',
  DebuggerTip:
    'Example: you need to send a data stream 0x31, 0x32, 0x33 to a device, and both of the following operations are possible：',
  DebuggerExp1: 'Enter "31 32 33 ", select "input format is HEX", and click send',
  DebuggerExp2: 'Enter "123", select "input format is ASCII", and click send',
  DebuggerExp3: 'When the input format is ASCII, the supported escape characters are as follows',
  DebuggerExp4: 'For example: "AT+VER\\r\\n"',
  DebuggerExp5: 'When the input format is HEX, the following two input formats can achieve the same effect：',
  DebuggerExp6: 'Example: the received data stream is "0x31, 0x32, 0x33",',
  DebuggerExp7: 'If the received data is displayed by HEX, it will be displayed as "0x31 0x32 0x33"',
  DebuggerExp8: 'If the received data is displayed as ASCII, it will be displayed as "123"',
  DebuggerTipMain: 'Only the data transmission protocol supports data transmission',
  ANNOUNCEMENT_TITLE: 'Announcement on system maintenance upgrade',
  ANNOUNCEMENT_P_TITLE: 'respected user:',
  ANNOUNCEMENT_P_1:
    '    We will suspend the cloud platform service from 08:30 on May 7th, 2019 to 10:30 on May 7th, 2019, and we will be sorry for the inconvenience.',
  ANNOUNCEMENT_P_2: '    The following is the V2.7.0 version update, so stay tuned',
  ANNOUNCEMENT_P_3: '  1. Added data template import and export function;',
  ANNOUNCEMENT_P_4: '  2. Fixed known bugs and optimized user experience;',
  ANNOUNCEMENT_P_5: '  3. Optimized performance, greatly improving load capacity and device access;',
  ANNOUNCEMENT_P_END: 'May 06, 2019',
  ANNOUNCEMENT_A_GO_NEW_ADDRESS: 'Enter the new version',
  Industrial_PLC_Describe:
    'Through DTU, PLC directly accesses the transmission cloud to realize the remote monitoring and control of the equipment.',
  Industrial_PLC_Describes:
    'Professional for PLC to create networking terminals, help PLC networking, equipment remote monitoring and control.',
  Electric_power_monitoring_and_acquisition_describe:
    'The remote meter reading is realized by connecting the meter with the transmission cloud through DTU. The load and power of the meter are monitored, the electricity fee is managed in the background, the electricity fee statistics and the monitoring alarm are carried out.',
  Intelligent_agriculture_describe:
    'Intelligent agro-ecological management system integrates intelligent greenhouse, factory, animal husbandry and breeding to realize intelligent management of Agriculture',
  Smart_Home_describe:
    'Real-time acquisition of fire mainframe and front-end alarm equipment operation status, through video to view the fire scene, rapid positioning, disaster control',
  Equipment_monitoring_info_for_quick_get_start:
    'Through the monitoring screen of cloud configuration, we can see the status of equipment, basic information of equipment and related equipment data: real-time data, alarm record/data.',
  Configuration_management_info_for_quick_get_start:
    'Process flow chart can be realized with configuration by simple dragging and pulling',
  Alarm_management_info_for_quick_get_start:
    'Setting up alarm conditions for each monitoring point, the alarm can be pushed through mail, short message and micro-message.',
  Mobile_Terminal_info_for_quick_get_start:
    'The basic information such as device operation, alarm and configuration can be quickly checked on the mobile phone through the Wechat applet.',
  NeutralWechatLimitation: 'Only support jpg, png format; size is less than 1M, size is 344*344 pixels',
  Neutral_Wechat_Bind_Tips: '',
  Neutral_WECHAT_BINGDING_PUBLIC_IMG_DESC: 'Scan the QR code to follow the public platform.',
  Account_registration_instructions_2:
    '2. Log in to "my application", improve the application information, and then refresh the page to obtain AppKey and Secret;',
  Account_registration_instructions_3: '3. Copy AppKey and Secret to this page bound "fluorspar account.',
  AddSNForPlcSomeMessage:
    'This version of the device firmware may not support cloud configuration, please contact the "usr" company to upgrade the firmware.',
  AddSNFor780SomeMessage:
    'The device model needs to be upgraded to connect to the cloud configuration. If you have upgraded, you can ignore this prompt.',
  Neutralization_of_Internet_Platform_Desc_For_Quick_Get_Start: 'Neutralization of Internet of Things Platform',
  Neutralization_of_Internet_Platform_Desc2_For_Quick_Get_Start:
    'Low-cost development to get platform, low-cost projects to make money',
  Neutralization_of_Internet_Platform_Desc3_For_Quick_Get_Start: 'Have its own Internet of Things platform',
  Neutralization_of_Internet_Platform_Desc4_For_Quick_Get_Start: 'one key get the Platform neutrality',
  OldDevAddHelp:
    "<p></p><p>Fill in the original transparent cloud device ID (20 digits), you can fill in the subordinate user equipment ID, and you need to re-allocate according to the cloud configuration items, roles, and permissions. See the <a href='http://cloud.usr.cn/document/72.html' target='_blank'>\"Documents\"</a> for details.</p>",
  DevSNAddTips:
    '<p></p><p>SN on the back of the device</p><img style="height:150px;width:150px;display:inline-block;" src="static/image/SNTipsPic.png"></img>',
  DevSNAddTipsForStep:
    '<p></p><p>①. The SN code of the device is usually on the film on the back of the device. (The appearance of the film may be different for different device models)</p><img style="height:150px;width:150px;display:inline-block;" src="static/image/SNTipsPic.png"></img><p>②. If the device is running normally on the pass-through cloud and you want to transfer it to someone\'s cloud to continue using it, please check the original method of adding pass-through cloud devices, see the help bar on the right.</p>',
  AddByDevIDTips:
    "<p></p><p>If the original transparent device or the device does not support the SN mode, it can be added by ID. After the addition is complete, the device ID and password generated by the cloud need to be written into the device.See the <a href='http://cloud.usr.cn/document/72.html' target='_blank'>\"Documents\"</a> for details.</p>",
  AddByDevIDTipsForStep:
    '<p></p><p>See the help bar on the right for how to add the original transparent cloud device.</p>',
  CloudConfigurationTips:
    "<p></p><p>Open <a href='http://cloud.usr.cn/document/1.html' target='_blank'>cloud configuration function</a> to remotely monitor data of terminal devices such as sensors, microcontrollers and PLCs.</p>",
  DevTemplateAddTips:
    '<p></p><p>The cloud configuration puts the communication protocol, data variable collection rules, alarm rules, configuration screen design, etc. into the device template. All devices associated with the template will work based on this template rule.</p>',
  DevTemplateAddTipsForStep:
    '<p>Device template: Cloud configuration puts communication protocols, data variable collection rules, alarm rules, configuration screen design, etc. into the device template. All devices associated with the template will work based on this template rule.</p>' +
    '<p>transparent transmissio:Through the configuration of transparent transmission strategy, the "device to client software / upper computer" and "device to device" mutual transparent communication can be realized.</p>',
  CloudDetectionTips:
    "<p></p><p>Remote parameter configuration and firmware upgrade for networked modules, real-time status monitoring and abnormal alarms such as signal strength, battery power, traffic conditions, etc.See the <a href='http://cloud.usr.cn/document/monitor/1.html' target='_blank'>\"Documents\"</a> for details.</p>",
  CloudDetectionTipsForStep:
    "<p></p><p>Real-time monitoring and abnormal alarm of networking module, such as signal strength, battery power, flow, etc..See the <a href='http://cloud.usr.cn/document/monitor/1.html' target='_blank'>\"Documents\"</a> for details.</p>",
  UserRightsTips:
    "<p></p><p>The superior user can be assigned to his or her child through user rights, and the associated user can see the device.See the <a href='http://cloud.usr.cn/document/74.html' target='_blank'>\"Documents\"</a> for details. </p>",
  DevTagsTips:
    "<p></p><p>A device tag is a property of a device that facilitates the classification of devices. A device can be attached with three tags.See the <a href='http://cloud.usr.cn/document/15.html' target='_blank'>\"Documents\"</a> for details.</p>",
  ModeForNBDeviceTips:
    '<p></p><p>Please select low-power mode for NB card to enable PSM, and active cloud polling is not supported in this mode; please select high-power mode for NB card to disable PSM or DRX</p>',
  Cloud_active_not_supported: 'Cloud active polling is not supported',
  ModeForNBDeviceTipsForStep:
    '<p>Low power consumption (PSM) : NB card opens PSM, please choose low power consumption mode, save power but low real-time data, low power consumption only supports data transmission or ModBus active report;</p><p>High power consumption (eDRX) : NB card to close PSM or DRX, please choose the high power consumption mode, power consumption, data can be directly;</p>',
  edgeTips:
    'Edge calculation: data collection and analysis are carried out by the networking module, and then reported to the cloud according to certain rules. Suitable for high frequency (second level) acquisition application scenarios, local high frequency acquisition after reporting to the cloud, cloud data storage and display.',
  cloudTips:
    'The cloud polling: the host in the cloud polls the terminal equipment according to a certain rule. The networking module only does data transmission and converts serial port signal into network signal and sends it to the cloud. Suitable for low frequency (minute level) acquisition scene.',
  supportTips: 'Products supporting edge computing: PLCNET series, G780V2 (after firmware version V1.1.0)',
  protocolPro: "What's the difference between edge computing and The cloud polling?",
  templateTips1: 'What is a device template?',
  templateTips2:
    'The new version of cloud service includes communication protocol, data variable collection rules, alarm configuration, configuration screen design, etc., into the device template. All devices associated with the template will work based on this template rule.',
  templateTips3: 'For equipment manufacturers, it can be understood that a device model is a template.',
  templateTips4:
    "First add template, then template configuration edit.See the <a href='http://cloud.usr.cn/document/17.html' target='_blank'>\"Documents\"</a> for details.",
  templateTips5: 'What is template configuration?',
  templateTips6:
    'The device association template can display this configuration on the monitor screen. When configuration editing, the control only needs to associate the data variables in the template, without checking the device.',
  templateTips8:
    'The template configuration page can only run in the big screen in the foreground of the system, which controls the slave machine and variables of the selected device in the big screen.',
  templateTips9: 'What are independent configurations (in extended functionality)?',
  templateTips10:
    'It is suitable for the engineering company to do a single engineering project. During configuration design, the control can associate data variables of all devices in the account.',
  templateTips11:
    "Independent configuration page can be independently linked to run, if not set password, is open, any account with a run link can enter.See the <a href='http://cloud.usr.cn/document/25.html' target='_blank'>\"Documents\"</a> for details.",
  templateTips12:
    "If \"no permission\" is displayed in the configuration screen, please add the view or operation permission of this device to <a href='http://cloud.usr.cn/document/28.html' target='_blank'>\"child user\"</a> -> <a href='http://cloud.usr.cn/document/74.html' target='_blank'>\"device permission\"</a> under this account.",
  addDevForPlcHelpAddDev:
    '<p>With labeling products, the factory defaults to access cloud services, just enter the SN and MAC/IMEI on the label to add directly.</p><p>Devices added in the early days through the transparent cloud can continue to be added by ID.</p><p><strong>Mobile phone scan code added</strong></br>Focus on the public platform</p><p><img style="height:150px;width:150px;display:inline-block;" src="static/image/icon_wechat.jpg"></img></p><p>Click on the bottom bar -> USR Cloud -> Add Device to scan the QR code on the Device Label.</p>',
  addDevForPlcHelpDevTemplate:
    '<p><strong>What is a device template?</strong></p><p>The cloud configuration system configures the parsing rules of the device data protocol to achieve remote acquisition and control. The new version of the cloud service puts the communication protocol, data variable collection rules, alarm rules, configuration screen design, etc. into the device template. All devices associated with the template will work based on this template rule.</p>',
  addDevForPlcHelpUserRights:
    '<p>The associated users will have all control rights for the device and this device variable.</p><p>To control the visible, editable, and deleteable detailed modification permissions: Go to the "User Permissions" - "Sub User Management" - "Device Permissions" configuration in the upper right corner.</p>',
  addDevForPlcHelpDevicePositioning:
    "<p><strong>Manual positioning:</strong>Select the fixed location of the device on the map.</p><p><strong>Automatic positioning:</strong>The device automatically locates the position. Currently only G780V2 (after firmware version V1.2.1) and ModbusRTU (The cloud polling) positioning variables support the selection of automatic positioning. G780V2's positioning method supports base station positioning, ModbusRTU (The cloud polling) positioning variable positioning method supports base station positioning, GPS positioning, and NB coarse positioning, and supports viewing positioning data by positioning trajectory.</p>",
  addDevForPlcHelpFinishAdding:
    '<p>If the device is successfully added, the device can be powered on again.</p><p>If it is not convenient to operate the power, the device will automatically go online within 1 hour.</p>',
  projectTips: '<p></p><p>After selecting the item, it cannot be changed。</p>',
  Add_SN_Tips: 'Note: If there is no SN code on the device, click the ID method on the right to add',
  SN_error1:
    'This device SN does not support direct addition at present, but can be added by clicking “no SN” on the right',
  SN_error2: 'The device SN does not support direct addition',
  SN_error3: 'Please enter the correct format SN code (20 characters)',
  Sure_Delete_Tags: 'Be sure to delete the tag (devices that have added this tag will be automatically unbundled)?',
  fomulaIcon1:
    '<p></p><p>The uplink data of the equipment is displayed after calculation by collection formula.</p><p>%s in the formula is a placeholder, a fixed field.</p><p>Such as：</p><p>Add: %s+10</p><p>Relief: %s</p><p>Passenger: %s*10</p><p>Divide: %s/10</p><p>Remainder: %s%10</p>',
  fomulaIcon2:
    '<p></p><p>Active write data to equipment is issued after calculation by control formula.</p><p>%s in the formula is a placeholder, a fixed field.</p><p>Such as：</p><p>Add: %s+10</p><p>Relief: %s</p><p>Passenger: %s*10</p><p>Passenger: %s*10</p><p>Passenger: %s*10</p><p>Divide: %s/10</p><p>Remainder: %s%10</p>',
  fomulaIcon3: '<p></p><p>Store data as it changes.</p>',
  TipsForPushTimeInterval:
    '<p></p><p>Push time interval:</p><p>① Assume that the device acquisition frequency is 10 minutes and the push time interval is set to 1 minute. The first alarm (8.00) triggers, pushes immediately, the second alarm requires the next poll and the device is still in the alarm state (8.10).</p><p>② Assume that the acquisition frequency is set to 1 minute, the push time interval is set to 5 minutes, the first alarm (8.00) is immediately pushed, and the next push time is (8.05).</p>',
  Mail_Recipient_For_Neutral_Manage: '%ACCOUNT%',
  Device_Template_Add_Alarm_Contact_Tip:
    'After setting the alarm rules, you can set the push contact and push method in "Alarm Management > alarm notification"',
  Go_to_plcnet_tips: "Note: Customer's original account of USR Cloud can be used to log in new USRIOT Cloud Software",
  SelectLanguageTips: 'Language setting for email alarm, SMS alarm and alarm record push message',
  SelectTimeZoneTips:
    'After the time zone is modified, the data in the system will be displayed according to the time in the new time zone.',
  delete_tags_message1:
    'Deleting this category will delete all the labels under this category. Do you want to continue?',
  close_Switch_For_Cloud_configuration:
    'After the cloud configuration function is turned off, data transmission and reception failures, timing tasks, alarm notifications, and independent triggers are invalid.Do you want to continue?',
  delete_PLC_device_message:
    'Confirm to delete the device? After this operation, the data generated by the device will be deleted, and the settings such as timed tasks and triggers will be invalid.',
  delete_PLC_devices_message:
    'Confirm delete the selected device? After this operation, the data generated by these devices will be deleted, and the settings of timing tasks, triggers, etc. will be invalid.',
  Explain_for_operation_center:
    'Note: daily active, extract the data from 0:00:00-23:59:59 yesterday at 1:00 a.m. every day; monthly active, extract the data from 0:00:00 on the first day of last month to 23:59:59 on the last day on the first day of every month.',
  Tips_for_operation_center_users_count:
    '<p><strong>Nearly a year：</strong></p><p>Statistics of monthly total users in the past year</p><p><strong>Recent 30 days：</strong></p><p>Statistics of new users in the past 30 days</p>',
  Tips_for_operation_center_active_users:
    '<p><strong>Nearly a year：</strong></p><p>Statistics of monthly active users in the past year</p><p><strong>Recent 30 days：</strong></p><p>Statistics of active users in the past 30 days</p>',
  Tips_for_operation_center_devices_count:
    '<p><strong>Nearly a year：</strong></p><p>Statistics of monthly total equipment in the past year</p><p><strong>Recent 30 days：</strong></p><p>Statistics of newly added equipment in the past 30 days</p>',
  Tips_for_operation_center_active_devices:
    '<p><strong>Nearly a year：</strong></p><p>Statistics of monthly active equipment in the past year</p><p><strong>Recent 30 days：</strong></p><p>Statistics of active equipment in recent 30 days</p>',
  Tips_for_operation_center_active_devices_type:
    '<p><strong>Recent 30 days：</strong></p><p>Statistics of active equipment models in recent 30 days</p><p><strong>Yesterday activity：</strong></p><p>Statistics of active equipment models yesterday</p>',
  Tips_for_operation_center_ranking:
    '<p><strong>Number of registered devices：</strong></p><p>Refers to the device registered by the user, including all devices online and offline</p>' +
    '<p><strong>Number of equipment configuration drawings：</strong></p><p>It refers to the number of configuration diagrams under the user account, including independent configuration diagram and template configuration diagram</p>' +
    '<p><strong>Number of sub users：</strong></p><p>Refers to the registration of all subordinate sub accounts except the primary account</p>' +
    '<p><strong>Data nodes quantity：</strong></p><p>Refers to the number of variables collected in the device template</p>' +
    '<p><strong>Number of devices enabling cloud configuration：</strong></p><p>It refers to the number of devices with cloud configuration function enabled at present.</p>',
  // '<p><strong>Device data storage：</strong></p><p>It refers to the sum of the reported data records of the equipment, the up and down line data records of the equipment and the alarm data records of the equipment.</p>',
  Tips_for_excel_add_dev:
    'Tips: Only batch import of devices of the same model is supported,File size must not exceed 20M.',
  Tips_for_excel_Map: 'Tips: Get the latitude and longitude of the device positioning here, fill in the excel template',
  UploadSuccessDevNum1: 'File uploaded successfully, a total of',
  UploadSuccessDevNum2: 'pieces of equipment information.',
  FailCheckAndDownload: 'Download device list (illegal devices have been marked in the file)',
  CheckFailedTips: 'There are illegal devices in the file, please check and change before uploading again',
  CheckFailedNoData: 'The content of the uploaded file cannot be empty, please upload again',
  FailImport: 'Download the list of variables (illegal variables have been marked in the file)',
  SettledProductsMessage:
    'Please send your application to the mailbox yuexiaoyang@usr.cn, please include your name, mobile phone number, and company name in the mailbox, so that we can contact you.',
  Targeting_type_tips_for_manual_positioning: '<p>Select the fixed location of the device on the map.</p>',
  Targeting_type_tips_for_automatic_positioning:
    '<p>The device automatically locates the position. Currently, only G780V2 (after firmware version V1.2.1) and ModbusRTU (cloud acquisition) positioning variables support the selection of automatic positioning.</p>',
  please_Upload_Excel_Tips: 'Please upload a properly formatted Excel file',
  fomulaIcon4:
    '<p>1、If the device has only one serial port, please select serial number "1".</p><p>2、If the device has a double serial port, please select "1" when the serial port is marked as COM1, and "2" when the serial port is marked as COM2.</p>',
  ExplanationForTC:
    '<p>How to check the data of the device?</p><p>1. Acquire data through virtual serial port software VCOM, <a href="http://www.usr.cn/Download/924.html" target="_blank">Virtual serial software download click download</a></p>' +
    '<p>2. Obtained through the data push function,<span style="color:#FF863C;">coming soon</span></p><p>3. Get data through secondary development using SDKs,<span style="color:#FF863C;">coming soon</span></p>',
  OperatingProcedures_1:
    '<p>Operating procedures:</p>' +
    '<p style="margin-left: 30px;">1. Download the Corresponding equipment software;</p><p style="margin-left: 30px;">2. Configure the device with the SN number allocated by the cloud platform and the communication password in the software;</p><p style="margin-left: 30px;">3. The device has been configured through the configuration software to connect to server.</p>' +
    '<p style="margin-left: 30px;">For details, please <a href="http://cloud.usr.cn/document/69.html" target="_blank">click to see how to configure the cloud</a></p>',
  OperatingProcedures_2:
    '<p>Operating procedures:</p>' +
    '<p style="margin-left: 30px;">1. Download the Corresponding equipment software;</p><p style="margin-left: 30px;">2. Configure the device with the SN number allocated by the cloud platform and the communication password in the software;</p><p style="margin-left: 30px;">3. The device is connected;</p>' +
    '<p style="margin-left: 30px;">For details, please <a href="http://cloud.usr.cn/document/69.html" target="_blank">click to see how to configure the cloud</a></p>',
  OperatingProcedures_3:
    '<p>The configuration has been completed, the device will be online automatically within 1 hour, and will be online immediately after recharging.</p>',
  How_to_set_device_slave_tips: 'To set up a slave, see the help bar"how to monitor multiple slaves" on the right.',
  Login_company_filing_information: '',
  directlyAddDeviceTips:
    'The cloud configuration function is closed, no need to select the product / protocol / data transparent transmission, you can directly add the device.',
  DevGroupTipsForStep:
    'Select the project and group to which the equipment belongs. The system is isolated according to the project. Equipment belonging to different projects cannot be associated with the same template, alarm, and other resources.',
  EnableTipsForStep:
    'Only when the device is enabled, the device will go online. The enabled function is not enabled. The remote configuration and firmware upgrade functions can be used normally.',
  CloudConfigurationForStep:
    '<p>Enable the cloud configuration function to realize the monitoring of automated processes and equipment.</p>' +
    '<p>It can be realized quickly by related products (device templates).<p><p>Data transmission can also be used, combined with secondary development to achieve.</p>',
  NetworkMonitoringTipsForStep:
    'Real-time monitoring and abnormal alarms of the network module, such as signal strength, battery power, flow, etc., please refer to the "Documents" for details.',
  NewbieTutorial1:
    '<p>fill in SN and MAC/IMEI that is written on the device sticker</p>' +
    '<p>（Please read the helping information at right bar to learn how to roll the equipment in from USR cloud).</p>',
  NewbieTutorial2: '<p>create a new product template and the device will work based on the product template.</p>',
  NewbieTutorial3: '<p>how to bring the device online after the addition is complete.</p>',
  NBTpisWhenAddEnd:
    'The configuration is completed. NB equipment will automatically go online after actively sending data. Devices that have been added can be viewed in the device list.',
  IOTipsWhenAddEnd:
    'The configuration has been completed, repower is immediately online, the added device can be viewed in the device list.',
  EnabledDevTipsInStep3:
    '<p style="text-align: center;">The configuration is complete and the added device can be viewed in the device list.</p>',
  MACtipsForStep:
    'You can fill in either MAC or IMEI.Also, for LoRa node devices, fill in the NID of the LoRa node here',
  addDevForPlcHelpAddDevProductLibrary:
    '<p>Products from various excellent cooperative manufacturers are collected in the product library, and corresponding equipment templates are pre configured for each product model. After users choose products from the product library, they can directly associate with the communication module for use, without variable configuration, configuration drawing and other operations, to achieve rapid docking.</p>',
  addDevForPlcHelpAddDevProductWarehouse:
    '<p>More product models are in the market. Welcome to discuss and cooperate with us.</p>' +
    '<p style="margin-top:20px">1. We can drain the settled manufacturers - click the "shopping cart" icon to transfer to the product introduction page or purchase page</p>' +
    "<p>2. After entering the product library, it can greatly reduce the user's use threshold, and users will give priority to the products in the product library</p>" +
    '<p>3. Support manufacturers to independently operate cloud platform with their own brand</p>' +
    '<p style="margin-top:20px">Please send your application to yuexiaoyang@usr.cn. Please include your name, mobile number and company name in the email for us to contact you.</p>',
  addDevForPlcHelpAddDevAgreementsProducts:
    "<p><strong>What's the difference between edge computing and cloud acquisition?</strong></p>" +
    '<p>Edge computing: data collection and analysis are carried out by the networking module, and then reported to the cloud according to certain rules. It is suitable for high-frequency (second level) acquisition application scenarios. After local high-frequency acquisition, it is reported to the cloud for data storage and display.</p>' +
    '<p>Products supporting edge computing: plcnet series, g780v2 (after firmware version V1.1.0)</p>' +
    '<p>The cloud polling:</p>' +
    '<p>①The cloud as the host, to poll the terminal equipment, networking module only do data transmission. Suitable for low frequency (minute) acquisition scene.</p>' +
    '<p>②For scenarios such as key status report, it can be reported according to the Modbus RTU extension instruction, which needs to be independently developed by users. <a href="https://mp.usr.cn/uploads/basetemplate/ModbusRTUExtendInstructions.xlsx">Download agreement. </a></p>',
  addDevForPlcHelpAddDevMonitorSlave:
    '<p>Select "device template" in the left menu to enter the list of device templates, select "device template", click "Edit", click "add slave", and then add slave.</p>',
  addDevForPlcHelpAddDevConfigurationDesign:
    '<p>The process flow chart is realized by simple drag and drop of configuration editor, and the binding data is displayed intuitively.</p>',
  addDevForPlcHelpAddDevHowConfigurationDesign:
    '<p>Select "equipment template" in the left menu to enter the list of equipment templates, click "configuration design", enter the "configuration design" page, and complete the configuration design through the configuration editor.</p>',
  addDevForPlcHelpAddDevConfigurationScreen:
    '<p>The monitoring screen is visible: select the equipment under the group to view the equipment configuration;</p>' +
    '<p>Extended function: function center - > independent configuration, associated with the data of the device to view, click "run" to view the configuration;</p>' +
    '<p>Small procedures: mobile phone scan, pay attention to the small procedures;</p>',
  configMenuWarning: 'Please contact administrator to configuration menu',
};
