import Cookies from 'js-cookie'
import * as API from '@/api/app.js'

const TokenKey = 'Admin-Token'
const UserInfo = 'Admin-UserInfo'
const Jsessionid = 'JSESSIONID'
const UserNameKey = 'Admin-UserName'
export function getBaseUrl() {
  return window.location.origin
}

/**
 * 删除用户登录后的任何信息、注销登录状态
 */
export function removeUserLogin() {
  Cookies.remove(TokenKey)
  Cookies.remove(UserNameKey)
  Cookies.remove(UserInfo)
  Cookies.remove(Jsessionid)
}

//获取token
export function getToken() {
  return Cookies.get(TokenKey)
}

//存储登录用户信息
export function setUserLogin(userinfo) {
  return Cookies.set(UserInfo, JSON.stringify(userinfo))
}

// 设置 JSESSIONID
export function setJSESSIONID(jsessionid) {
  return Cookies.set(Jsessionid, jsessionid, { path: '/' })
}

//存储token
export function setToken(token) {
  //如果有dssdk 更新token
  // if (window.processEnv.IS_OPEN_DSSDK == '1') {
  //   dssdk.setToken(token)
  // }
  return Cookies.set(TokenKey, token)
}

//获取登录用户信息
export function getUserLogin() {
  if (!Cookies.get(UserInfo) || Cookies.get(UserInfo) === 'undefined') {
    return undefined
  } else {
    return JSON.parse(Cookies.get(UserInfo))
  }
}
//获取用户身份信息
export function getRoleId(roleId) {
  return sessionStorage.getItem('roleInfo') - 0
}
//存储用户身份信息
export function setRoleId(roleId) {
  return sessionStorage.setItem('roleInfo', roleId)
}
//设置语言
export function setLang(v) {
  Cookies.set(LangInfo, v)
}

//取得当前语言
export function getLang(v) {
  // return Cookies.get(LangInfo)
  return 'cn'
}

// 立即测评
export function gotoTest(userId, composeId) {
  window.open(
    process.env.VUE_APP_APPRAISAL_BASEURL_HOME + '?userId=' + userId + '&composeId=' + composeId + '&showQuit=true',
    '_blank',
  )
}

// 两个时间戳之间的描述差值
export function calculateDiffTime(startTime, endTime, formats) {
  const diff = new Date(endTime) - new Date(startTime) // 两个时间戳的差值，单位是毫秒
  // 格式化时间差为时分秒格式
  const hours = Math.floor(diff / (1000 * 60 * 60)) // 小时数
  const minutes = Math.floor(diff / (1000 * 60)) % 60 // 分钟数
  const seconds = Math.floor(diff / 1000) % 60 // 秒数
  if (hours) {
    formats = formats || 'H小时i分s秒'
  } else {
    formats = formats || 'i分s秒'
  }

  return formats.replace(/Y|m|d|H|i|s/gi, function (matches) {
    return {
      H: hours,
      i: minutes,
      s: seconds,
    }[matches]
  })
}

// 获取图片
export const getAllPhoto = async (listData, picName) => {
  let paths = []
  let data = []
  try {
    listData.forEach((item) => {
      if (item[picName]) {
        paths.push(item[picName])
      }
    })
    const res = await API.fileGetByPathList({
      paths: paths,
      channel: 'OSS_MINIO',
    })
    data = listData.map((item) => {
      const matchedFile = res.data.find((file) => item[picName] === file.path)
      if (matchedFile) {
        item[picName] = matchedFile.base64
      }
      return item
    })
  } catch (error) {}

  return listData
}
