import axios from 'axios'
import router from '@/router/index'
import common, { _getQueryId } from '@/base/common.js'
import { getToken, getBaseUrl, removeUserLogin } from './auth'
import { Message } from 'element-ui'

// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.NODE_ENV == 'development' ? process.env.VUE_APP_AXIOS_BASEURL : getBaseUrl() + '/api/', // api BASE_URL
  timeout: 30000, // 请求超时时间
  withCredentials: process.env.NODE_ENV == 'development' ? false : true,
})

const err = (error) => {
  if (error.response) {
    const data = error.response.data
  }
  return Promise.reject(error)
}
service.interceptors.request.use((config) => {
  const token = getToken()
  config.headers['Cache-control'] = 'no-cache, ${dd}no-store, must-revalidate'
  config.headers['Pragma'] = 'no-cache'
  config.headers['Expires'] = 0
  config.headers['X-Device-IMEI'] = '7990b26a-10b9-46f0-aacc-1b8f0b51bbf9'

  let downloadUrl = ['apis-third/file/upload/image', 'apis-third/file/upload', 'apis-psychology/sign/upload']
  // TODO: 导入接口上传文件设置请求头，暂时给改接口改，后续改为
  if (config.url === 'apis-psychology/survey/task/readExcel') {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
  }

  if (downloadUrl.indexOf(config.url) > -1) {
    config.headers['Content-Type'] = 'multipart/form-data'
    console.log(config.data)
    // return config
  }
  if (token) {
    config.headers['X-Authorization'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
    // if (config.method == "get") {
    //     config.params = {
    //         ...config.data
    //     };
    //     console.log('---------------------' + config.params)
    // }
    // config.headers["content-type"] = "application/json"
  }
  return config
}, err)

// response interceptor
service.interceptors.response.use(
  (config) => {
    //
    if (config.data.code && config.data.code != 200) {
      if (config.data.code == 400 && config.data.data && config.data.data.length > 0) {
        let msg = config.data.message + '</br>'
        config.data.data.forEach((e) => {
          msg += e.msg + '<br>'
        })
        Message({
          type: 'error',
          dangerouslyUseHTMLString: true,
          message: msg,
        })
        return config.data
      }
      // 登录失效问题处理
      if ([10020, 10021, 10022, 10023, 10024, 10025, 10026, 10027, 10028, 10029].includes(config.data.code)) {
        removeUserLogin()
        window.location.href =
          (process.env.NODE_ENV == 'development' ? process.env.VUE_APP_AXIOS_BASEURL : getBaseUrl() + '/api/') +
          '/apis-authz/authz/login/cas?target=' +
          window.location.href
      }
      Message.error(config.data.message)
      return config.data
    } else {
      return config.data
    }
  },
  (error) => {
    // console.log(errRes, "================errRes");

    // console.log(error.response,'================error')

    // if (errRes && errRes.response && errRes.response.code === 500) {
    //   _message("warning", "内部服务错误")
    // }

    if (error && error.response) {
      let errRes = JSON.parse(JSON.stringify(error.response))
      if (error.response.status === 500) {
        Message('warning', errRes.data.message || '内部服务错误')
      }
    }
    if (error.message === 'Network Error') {
      Message('warning', '网络异常，请重试')
      throw error.message
    }
    return Promise.reject(error)
  },
)

export default service
