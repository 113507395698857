import router from '../../router'
import { getUserLogin, removeUserLogin, getBaseUrl } from '@/utils/auth'

// const getHomePath = (name) => {
//     let homeRouter = menucusRouter[0].children.filter(e => e.name == name)
//     return homeRouter[0].path
// }

const user = {
  state: {
    user_info: null,
    token: '',
    name: '',
    role_id: '',
    unit_dept_id: '',
    school_logo: '',
    idToken: '',
    identityList: [],
    currentIdentity: null,
    currentOrg: null,
    infoCount: null,
    isOverdue: localStorage.getItem('isOverdue') ? JSON.parse(localStorage.getItem('isOverdue')) : true,
    gbCodeObj: {},
  },

  mutations: {
    SET_INFO_COUNT: (state, code) => {
      state.infoCount = code
    },
    SET_IDENTITY_LIST: (state, code) => {
      state.identityList = code
    },
    SET_CURRENT_ORG: (state, code) => {
      state.currentOrg = code
    },
    SET_CURRENT_IDENTITY: (state, code) => {
      state.currentIdentity = code
    },
    SET_USER_INFO: (state, code) => {
      state.user_info = code
    },
    SET_TOKEN: (state, code) => {
      state.token = code
    },
    SET_NAME: (state, code) => {
      state.name = code
    },
    SET_ROLE_ID: (state, code) => {
      state.role_id = code
    },
    SET_UNIT_DEPT_ID: (state, code) => {
      state.unit_dept_id = code
    },
    SET_SCHOOL_LOGO: (state, code) => {
      state.school_logo = code
    },
    SET_ID_TOKEN: (state, code) => {
      state.idToken = code
    },
    setIsOverdue: (state, data) => {
      state.isOverdue = data
      window.localStorage.setItem('isOverdue', JSON.stringify(data))
    },
    SET_GBCODEOBJ: (state, data) => {
      state.gbCodeObj = data
    },
  },

  actions: {
    userLogin({ commit }, params) {
      return new Promise((resolve, reject) => {
        login(params)
          .then((res) => {
            if (!res.data.token) {
              // 手机号关联多个用户
              resolve({
                code: 200,
                message: 'success',
                data: res.data,
              })
            } else {
              if (res.message === '认证成功') {
                commit('SET_USER_INFO', res.data.user)
                commit('SET_TOKEN', res.data.token)
                commit('SET_NAME', res.data.user.name)
                commit('SET_ROLE_ID', res.data.user.roleId)
                commit('SET_UNIT_DEPT_ID', res.data.user.unitDeptId)
                commit(
                  'SET_SCHOOL_LOGO',
                  res.data.dept.logo || res.data.dept.logo == '' ? res.data.dept.logo : 'localLogo',
                )
                if (!res.data.user.roleId) {
                  resolve({
                    message: '当前账户未分配角色，请联系管理员！',
                  })
                } else {
                  // let toUrl = getHomePath(res.data.menu)
                  resolve({
                    code: 200,
                    message: 'success',
                    path: '/',
                  })
                }
              }
            }
          })
          .catch((err) => {
            router.replace('/login')
            reject(err)
          })
      })
    },
    userLoginOut({ commit }, params) {
      return new Promise((resolve, reject) => {
        WAPI.userLogout(params)
          .then((res) => {
            commit('SET_USER_INFO', '')
            commit('SET_TOKEN', '')
            commit('SET_NAME', '')
            commit('SET_ROLE_ID', '')
            commit('SET_UNIT_DEPT_ID', '')
            commit('SET_MENU_ROUTER', '')
            commit('SET_IDENTITY_LIST', '')
            commit('SET_CURRENT_ORG', '')
            commit('SET_CURRENT_IDENTITY', '')
            commit('SET_INFO_COUNT', '')
            sessionStorage.clear()
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    casLogin({ commit }, params) {
      return new Promise((resolve, reject) => {
        sessionStorage.clear()
        console.log(getBaseUrl())
        WAPI.userCasLogout({
          service: getBaseUrl() + '/jdp',
        }).then((res) => {
          if (res.code == 200) {
            console.log(res)
            removeUserLogin()
            window.location.href = res.data
          }
        })
      })
    },
    registrateUserLogin({ commit }, params) {
      return new Promise((resolve, reject) => {
        postLoginToken(params)
          .then((res) => {
            if (!res.data.token) {
              // 手机号关联多个用户
              resolve({
                code: 200,
                message: 'success',
                data: res.data,
              })
            } else {
              if (res.message === '认证成功') {
                commit('SET_USER_INFO', res.data.user)
                commit('SET_TOKEN', res.data.token)
                commit('SET_NAME', res.data.user.name)
                commit('SET_ROLE_ID', res.data.user.roleId)
                commit('SET_UNIT_DEPT_ID', res.data.user.unitDeptId)
                commit(
                  'SET_SCHOOL_LOGO',
                  res.data.dept.logo || res.data.dept.logo == '' ? res.data.dept.logo : 'localLogo',
                )
                if (!res.data.user.roleId) {
                  resolve({
                    message: '当前账户未分配角色，请联系管理员！',
                  })
                } else {
                  // let toUrl = getHomePath(res.data.menu)
                  resolve({
                    code: 200,
                    message: 'success',
                    path: '/',
                  })
                }
              }
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    async getCodeData({ commit, state }, params) {
      // 获取国标码
      for (let k of params) {
        await this.dispatch('setCodeData', { code: k })
      }
      return new Promise((resolve) => {
        resolve(state.gbCodeObj)
      })
    },
    async setCodeData({ commit, state }, params) {
      // 插入下拉数据
      let { code } = params
      let codeObj = state.gbCodeObj
      if (!codeObj[code]) {
        let res = await WAPI.getDictCode({ type: code })
        codeObj = {
          ...codeObj,
          [code]: res.data,
        }
        commit('SET_GBCODEOBJ', codeObj)
      }
    },
    clearUserInfo({ commit }, params) {
      commit('SET_USER_INFO', null)
    },
  },
}

export default user
