<template>
  <div class="sidebar" v-if="menuList?.length">
    <div class="top-title">
      <template v-if="showLogo()">
        <img v-show="isCollapse" src="@/assets/image/logo1.png" class="logo" />
        <img v-show="!isCollapse" src="@/assets/image/logo_open.png" class="logo_open" />
      </template>
      <template v-else>
        <img src="@/assets/image/logo2.png" class="logo" />
        <span class="logoBox-title" v-show="!isCollapse">心灵e家</span>
      </template>
    </div>
    <div class="top">
      <img class="switch_icon switch_icon-right" v-if="isCollapse" :src="switchImgClose" @click="toggle" />
      <img class="switch_icon switch_icon-off" v-else :src="switchImgOpen" @click="toggle" />
    </div>
    <el-menu
      :default-active="defaultActive"
      class="el-menu-vertical"
      :collapse="isCollapse"
      active-text-color="#1A66FF"
    >
      <!-- 默认所有身份添加一个首页快捷导航 -->

      <el-menu-item index="home" @click="handleMenu('home', '/home')" class="home-menu">
        <iconpark-icon
          v-if="defaultActive == 'home'"
          name="homepage02"
          color="#1A66FF"
          class="iconpark"
        ></iconpark-icon>
        <iconpark-icon v-else name="homepage02" color="#4e5969" class="iconpark"></iconpark-icon>
        <span>首页</span>
      </el-menu-item>
      <el-submenu v-for="(item, index) in menuList" :index="index + 1 + ''">
        <template #title>
          <iconpark-icon :name="item.code" class="iconpark"></iconpark-icon>
          <span>{{ item.name }}</span>
        </template>
        <el-menu-item
          v-for="listItem in item.list"
          :index="listItem.appId"
          @click="handleMenu(listItem.appId, listItem)"
          class="menu-item-bottom"
        >
          <span>{{ listItem.appName }}</span>
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import * as API from '@/api/base.js'
export default {
  name: 'SidebarMenu',
  computed: {},
  watch: {
    $route (to, from) {
      try {
        if (to.path == '/data/home') {
          this.defaultActive = 'home'
        }
        this.initList()
        this.changeSelectMenu()
      } catch (error) {
        console.log('🚀 ~ $route ~ error:', error)
      }
    },
  },
  data () {
    return {
      isCollapse: localStorage.getItem('IS_COLLAPSE') === 'false' ? false : true,
      defaultActive: localStorage.getItem('ACTIVE_MENU') ? localStorage.getItem('ACTIVE_MENU') : 'home',
      menuList: [],
      defaultOpeneds: [],
      route: '',
      switchImgClose: require('@/assets/image/collapse_open.png'),
      switchImgOpen: require('@/assets/image/collapse_close.png'),
    }
  },
  created () {
    console.log('created')
    this.initList()
    this.getAppSidebar()
  },
  methods: {
    showLogo () {
      console.log(process.env.VUE_APP_ENV, 99999999);
      if (process.env.VUE_APP_ENV == 'nszy') {
        return true
      } else {
        return false
      }
    },
    handleMenu (name, info) {
      console.log('🚀 ~ handleMenu ~ info:', info)
      localStorage.setItem('ACTIVE_MENU', name)
      let publishUrl = ''
      if (name === 'home') {
        publishUrl = '/data/home'
      } else if (info.publishUrl.indexOf('/jdp') == -1) {
        let url = info.publishUrl
        if (info.publishUrl.indexOf('/dsp') !== -1) {
          url = info.publishUrl + (info.publishUrl.indexOf('?') === -1 ? '?appId=' : '&appid=') + info.appId
        }
        window.open(url)
        return
      } else {
        publishUrl = info.publishUrl.split('/jdp')[1]
      }
      if (publishUrl?.indexOf('data/') !== -1) {
        const newRoute = {
          path: publishUrl,
          params: this.$route.params,
        }
        this.$router.push(newRoute)
      } else {
        window.open(info.publishUrl)
      }
    },
    initList () {
      let isCollapse = localStorage.getItem('IS_COLLAPSE')
      if (!isCollapse) {
        localStorage.setItem('IS_COLLAPSE', this.isCollapse)
      }
      this.route = this.$route.path
      let list = ''
      if (localStorage.getItem('MENULIST') && localStorage.getItem('MENULIST') != 'undefined') {
        list = JSON.parse(localStorage.getItem('MENULIST'))
      }

      if (list?.length) {
        this.menuList = this.menuList.length ? this.menuList : list
        this.changeSelectMenu()
        if (this.route == '/data/home') {
          this.defaultActive = 'home'
        }
        // this.defaultOpeneds = [...new Set(this.defaultOpeneds)]
      } else {
        this.getAppSidebar()
      }
    },
    toggle () {
      this.isCollapse = !this.isCollapse
      localStorage.setItem('IS_COLLAPSE', this.isCollapse)
      this.switchImg = this.isCollapse
        ? require('@/assets/image/collapse_open.png')
        : require('@/assets/image/collapse_close.png')

      $_EventLib.dispatch('updateEcharts')
    },
    async getAppSidebar () {
      let res = await API.appMenu({})
      localStorage.setItem('MENULIST', JSON.stringify(res.data))
      this.menuList = res.data
      this.changeSelectMenu()
    },
    changeSelectMenu () {
      let path = ''
      // let defaultOpeneds = []
      this.menuList.map((item, index) => {
        item?.list?.map((listItem) => {
          path = listItem.publishUrl.split('/jdp')[1]
          // defaultOpeneds.push(index + 1 + '')
          if (path === this.route && path.indexOf('data/') !== -1) {
            this.defaultActive = listItem.appId // 手动设置 defaultActive
          }
        })
      })
      // this.defaultOpeneds = defaultOpeneds
    },
  },
}
</script>

<style lang="scss" scoped>
/* 样式可根据自己的设计进行调整 */
.sidebar {
  // width: 252px; /* 设置侧边菜单的宽度 */
  background-color: #fff; /* 设置侧边菜单的背景颜色 */
  height: 100%; /* 设置高度为视口高度，保持与页面同等高度 */
  .top-title {
    height: 60px;
    font-size: 24px;
    padding: 16px 0 16px 18px;
    line-height: 24px;
    border-right: 1px solid #e5e6eb;
    .logo {
      width: 32px;
      height: 32px;
      object-fit: cover;
      vertical-align: middle;
    }
    .logo_open {
      height: 32px;
    }
    span {
      display: inline-block;
      margin: 0 12px;
      vertical-align: middle;
      margin-top: 5px;

      font-weight: bold;
    }
  }
  .home-menu {
    margin-top: 16px;
    font-weight: 500;
  }
  .iconpark {
    margin-right: 12px;
    margin-left: 4px;
    font-size: 16px;
    line-height: 40px;
  }

  .menu_set_collapse {
    width: 64px;
  }

  /* 自定义整个滚动条 */
  ::-webkit-scrollbar {
    width: 8px; /* 对于垂直滚动条，设置宽度 */
    height: 8px; /* 对于水平滚动条，设置高度 */
  }

  /* 自定义滚动条轨道 */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* 背景色 */
  }

  /* 自定义滚动条的滑块（thumb） */
  ::-webkit-scrollbar-thumb {
    background: #dadbdc; /* 滑块颜色 */
  }

  /* 当滑块悬停或活动时，可以设置不同的颜色 */
  ::-webkit-scrollbar-thumb:hover {
    background: #b6b7ba;
  }
}
.el-menu-item.is-active {
  background-color: #edf3ff;
}
.el-menu-item.is-active::before {
  position: absolute;
  top: 8px;
  left: 2px;
  width: 4px;
  height: 24px;
  background: #1a66ff;
  content: '';
  border-radius: 0 4px 4px 0;
}
// 打开状态
.el-menu-vertical:not(.el-menu--collapse) {
  width: 232px;
  font-size: 14px !important;
  height: calc(100% - 60px);
  overflow-y: auto;

  .el-menu-item {
    font-size: 14px !important;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    color: #666;
  }
}
.menu-item-bottom {
  padding-left: 52px !important;
}
// 折叠状态
.el-menu-vertical.el-menu--collapse {
  width: 64px;
  font-size: 14px !important;
  height: calc(100% - 60px);
  .el-submenu,
  .el-tooltip,
  .el-menu-item {
    text-align: center;
    font-size: 14px !important;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
  }
  .el-submenu__icon-arrow.el-icon-arrow-right {
    display: none;
  }
}
.el-menu-item,
.el-submenu__title {
  height: 40px;
  line-height: 40px;
}
.top {
  // padding: 10px 20px;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 63px;

  .switch_icon {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 56px;
    right: 0;
  }
  .switch_icon-right {
    left: 46px;
    z-index: 999;
  }
  .switch_icon-off {
    left: 210px;
    z-index: 999;
  }
}
</style>
