export default {
  getPermissions: (state) => state.permissionList,
  getParams(state) {
    return state.params
  },
  getMenuItem(state) {
    return state.getMenuItem
  },
  getPersonInfo(state) {
    return state.personalInfo
  }
};
