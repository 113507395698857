// import httpRequest from '../common/httpRequest';
import httpRequest from '@/utils/require.js'
const baseUrl = process.env.NODE_ENV == 'production' ? '/apis-authz' : '/apis-authz'
const baseUrlUser = process.env.NODE_ENV == 'production' ? '/apis-user' : '/apis-user'

export default {
  //注册用户
  userRegister(payload) {
    return httpRequest.post(`${baseUrl}/auth/user/register`, payload).then((data) => {
      return data
    })
  },
  //用户登陆
  userLoginJwt(payload) {
    return httpRequest.post(`${baseUrl}/authz/login/jwt?sessionKey=${payload.sessionKey}`, payload).then((data) => {
      return data
    })
  },
  //用户退出
  userCasLogout(payload) {
    return httpRequest.get(`${baseUrl}/authz/casLogout?service=${payload.service}`, payload).then((data) => {
      return data
    })
  },
  //钉钉扫码登录
  loginDingtalkScancode(payload) {
    return httpRequest.post(`${baseUrl}/login/dingtalk/scancode`, payload).then((data) => {
      return data
    })
  },
  //查询用户所有登录方式
  userQueryAllAccount(payload) {
    return httpRequest.post(`${baseUrl}/auth/user/queryAllAccount`, payload).then((data) => {
      return data
    })
  },
  //更新用户认证信息
  userUpdateInfo(payload) {
    return httpRequest.post(`${baseUrl}/auth/user/updateInfo`, payload).then((data) => {
      return data
    })
  },
  //查询当前登录用户认证信息
  getUserQueryInfo(payload) {
    return httpRequest.post(`${baseUrl}/auth/user/queryInfo`, payload).then((data) => {
      return data
    })
  },
  //查询用户的所有身份
  // getQueryAllIdentity(payload) {
  //   return httpRequest.post(`${baseUrl}/auth/user/queryAllIdentity`,payload).then((data) => {
  //     return data
  //   })
  // },
  //修改密码
  updatePassword(payload) {
    return httpRequest.post(`${baseUrl}/auth/user/updatePassword`, payload).then((data) => {
      return data
    })
  },
  getDingH5Config(payload) {
    return httpRequest.get(`${baseUrl}/authz/login/dingH5Config`, payload).then((data) => {
      return data
    })
  },
  // // 获取用户身份信息
  // getCheckUserIfo(payload) {
  //   return httpRequest
  //     .get(`${baseUrl}/auth/user/identity/list`, payload)
  //     .then(data => {
  //       return data;
  //     });
  // },
  // 查看是否为平台用户
  getQueryAccountInfo(payload) {
    return httpRequest.post(`${baseUrl}/auth/user/queryAccountInfo`, payload).then((data) => {
      return data
    })
  },
  //  强制修改密码
  updateDefultPassword(payload) {
    return httpRequest.post(`${baseUrl}/auth/user/forceUpdatePassword`, payload).then((data) => {
      return data
    })
  },

  // 获取默认密码
  getDefaultPassword(payload) {
    return httpRequest.post(`${baseUrl}/policy/default`, payload).then((data) => {
      return data
    })
  },
  //  更新默认密码
  updateDefaultPassword(payload) {
    return httpRequest.post(`${baseUrl}/policy/default/update`, payload).then((data) => {
      return data
    })
  },

  //  获取密码策略
  getPasswordPolicy(payload) {
    return httpRequest.post(`${baseUrl}/policy/info`, payload).then((data) => {
      return data
    })
  },

  //  更新密码策略
  updatePasswordPolicy(payload) {
    return httpRequest.post(`${baseUrl}/policy/info/update`, payload).then((data) => {
      return data
    })
  },

  //  查询密码是否超过提醒时间
  checkPasswordExpire(payload) {
    return httpRequest.post(`${baseUrl}/policy/expire`, payload).then((data) => {
      return data
    })
  },

  //  当天不再提醒
  updateTodayHint(payload) {
    return httpRequest.post(`${baseUrl}/policy/hint/update`, payload).then((data) => {
      return data
    })
  },

  //  恢复默认初始化密码
  resetDefaultPassword(payload) {
    return httpRequest.post(`${baseUrl}/policy/default/recover`, payload).then((data) => {
      return data
    })
  },
  //  恢复默认初始化密码
  resetDefaultPasswordPolicy(payload) {
    return httpRequest.post(`${baseUrl}/policy/info/recover`, payload).then((data) => {
      return data
    })
  },

  //  获取图片验证码
  getCaptcha(payload) {
    return httpRequest.post(`${baseUrl}/auth/user/forgetPassword/generateCaptcha`, payload).then((data) => {
      return data
    })
  },

  // 获取当前登录账号的组织和身份信息
  getUserOrgRoleInfo(params) {
    return httpRequest.get(`${baseUrlUser}/user/info/org/iden`, params).then((data) => {
      return data
    })
  },

  // 获取当前登录账号的脱敏信息
  getUserDesenseInfo(params) {
    return httpRequest.get(`${baseUrlUser}/sensitive/user?id=${params.id}`, params).then((data) => {
      return data
    })
  },
}
