import common from '@/base/common.js'
import http from '@/utils/require.js'

const portalUrl = process.env.NODE_ENV == 'production' ? '/apis-portal/' : '/apis-portal/';
const baseUrl = process.env.NODE_ENV != 'development' ? common.certifiedJkmc : ''


export const appMenu = (params) => http({
    method: "get",
    url: `/apis-portal/app/getAppMenu`,
    params
})
