<template>
  <el-container class="project-box">
    <div class="container_box">
      <Sidebar></Sidebar>
      <el-container class="rightBox">
        <el-header class="flex justify_between header-box">
          <Logo :title="title" />
          <headerNav />
          <div class="flex">
            <Account ref="amdin" @remove="remove" @updateInfo="updateInfo" />
          </div>
        </el-header>
        <el-main :class="{ hasBg: hasBg }">
          <keep-alive>
            <router-view :key="$route.fullPath" />
          </keep-alive>
        </el-main>
      </el-container>
    </div>
  </el-container>
</template>
<script>
import * as API from '@/api/app.js'
import Logo from '@/components/logo/logo.vue'
import Account from '@/components/account/index.vue'
import Sidebar from './sidebarMenu.vue'
import { getUserLogin } from '@/utils/auth'
import headerNav from '@/components/layout/headerNav.vue'
export default {
  components: { Logo, Account, headerNav, Sidebar },
  props: {
    title: {
      type: String,
    },
  },
  data () {
    return {
      userInfo: getUserLogin(),
      identityId: 1,
    }
  },
  mounted () {
    console.log('当前路由信息:', this.$route);
  },
  computed: {
    hasBg () {
      // 背景图页面不留白，在这配置
      return ['/data/home'].indexOf(this.$route.fullPath) !== -1
    },
  },
  methods: {
    updateInfo (value) {
      this.userInfo = value
    },
    remove (userInfo) {
      this.userInfo = userInfo
    },
  },
}
</script>
<style lang="scss" scoped>
.project-box {
  height: 100vh;
}
.container_box {
  display: flex;
  width: 100%;
  min-height: calc(100% - 60px);
}
.logo-box {
  min-width: 230x;
}
.header-box {
  background-color: #fff;
  width: 100%;
  border-bottom: 1px solid #e5e6eb;
}

.rightBox {
  background: #f9fafb;
}
.content_box {
  margin-top: 60px;
}

.hasBg {
  padding: 0;
}
</style>
