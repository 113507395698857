import common from '@/base/common.js'
import http from '@/utils/require.js'

const baseUrl = process.env.NODE_ENV != 'development' ? common.certifiedJkmc : ''

export default {
    getSurveyRecord(params) {
        return http({
            method: "get",
            url: `/apis-psychology/survey/record/page`,
            params
        })
    },
    getSurveyRecordById(id) {
        return http({
            method: "get",
            url: `/apis-psychology/survey/record/${id}`,
            data: {}
        })
    },

    editSurveyRecord(data) {
        return http({
            method: "post",
            url: `/apis-psychology/survey/record/edit`,
            data,
        })
    },
    getSurveyRecordMonitorPage(params) {
        return http({
            method: "get",
            url: `/apis-psychology/survey/record/monitorPage`,
            params,
        })
    },
    surveyTaskSync(data) {
        return http({
            method: "post",
            url: `/apis-psychology/survey/task/sync`,
            data,
        })
    },
    deleteSurveyRecord(data) {
        return http({
            method: "post",
            url: `/apis-psychology/survey/task/delete`,
            data,
        })
    },
    // 年级列表信息
    modulesCodeGrade(params) {
        return http.get(`/apis-psychology/analysis/modules/code/grade`, { params })
    },
    // 班级列表信息
    modulesCodeClass(params) {
        return http.get(`/apis-psychology/analysis/modules/code/class`, { params })
    },

    // 判断文件是否已生成
    downloadFileStatus(params) {
        return http.post(`/apis-psychology/survey/record/query`, params)
    },

    // 导出模板
    downloadFileTask(params) {
        return http({
            method: 'post',
            url: `/apis-psychology/survey/record/download`,
            data: {
                ...params,
            },
        })
    },
}
