/**
 * 项目特有的标记
 */
import en from '../en.js';
export default Object.assign(en,{
  "Chinese": "中文",
  "PROJECT_NAME": "USR Cloud management system",
  "PROJECT_NAME_SHORT": "USR Cloud management system",
  PROJECT_LOGO: '',//LOGO
  PROJECT_LOGO_WAP: '',//LOGO
  PROJECT_DOMAIN: '',//如果配置中性会有域名
  WECHAT_PUBLIC_CODE: '',//微信公众号
  WECHAT_APPLET_CODE: '',//微信小程序二维码
  MOBILE_LOOK_OVER: '',//手机端页面----"我的设备"查看
  "WECHAT_BINGDING_PUBLIC_IMG_DESC": "Scan the QR code to follow the public platform, select the lower right corner of \"USR Cloud\", select \"WeChat binding\"",
  "wechatBingdingGenerateCodeDesc": "关注公众平台后扫描二维码可直接绑定微信",
  "USR_ClOUD_SYSTEM_THREE_MAIN_FUNCTIONS": "There are three ways to use the USR Cloud",
  "CLOUD_CONFIGURATION_INTRUDUCTION": "The USR Cloud is like configuration software. After adding devices and associated data points, it can remote monitoring devices immediately.",
  "CLOUD_INTERFACE_INTRUDUCTION": "Users can access the cloud by using the software developed by SDK we provided, and can customize control and query the device data.",
  "ClOUD_SWITCH_INTRUDUCTION": "Use pass-through group + virtual serial port software to solve the problem of how does serial port software (such as configuration) access remote serial port equipment.",
  "RECEIVING_ALARM_PUSH_USING_WECHAT_SMALL_PROGRAM_CONTROL": "Receive alarms push, use WeChat small program control",
  "HOME_PAGE_MOBILE_TERMINAL_INTRUDUCTION": "Scan the QR code and enter the small program directly (version 6.5.7 or above is required for WeChat), or \"follow the public account -- > USR Cloud -- > my device\".",
  "wechatBingdingTip": "",
  "USR_ClOUD_VIDEOS": "USR Cloud Videos",
  "SYSTEM_SETTING_NOTES1": "Note: this function only supports domain names filed in aliyun.",
  "SYSTEM_SETTING_NOTES2": "This function can achieve back-end management completely neutral custom.",
  "SYSTEM_SETTING_NOTES3": "Support custom domain name, system name, company name, logo and other information customized modification, easy to achieve neutral back-end management.",
  "SYSTEM_SETTING_NOTES4": "Note: this function is available after the application is opened.",
  "DOMAIN_NAME_RESOLUTION_TIPS": "Neutral domains must begin with console and support secondary and tertiary domains.The domain name CNAME must be resolved to \"cname.usr.cn\" before it can be used.",
  "FOR_EXAMPLE": "FOR EXAMPLE",
  "DOMAIN_NAME_RESOLUTION_EXAMPLE": "User domain name test.com, resolve console.abc.test.com to cname.usr.cn, take aliyun domain name resolution as an example",
  "DOMAIN_NAME_RESOLUTION_NOTES1": "1. Enter the domain name management page of aliyun and click \"resolution Settings\" to enter domain name resolution.",
  "DOMAIN_NAME_RESOLUTION_NOTES2": "2. Click \"Add Resolution\" to enter adding page",
  "DOMAIN_NAME_RESOLUTION_NOTES3": "3. In the add resolution page, select \"CNAME\" for record type, fill in \"console.abc\" for host record and \"cname.usr.cn\" for record value.",
  "DOMAIN_NAME_RESOLUTION_NOTES4": "4. Click \"ok\" and wait for the successful domain name resolution to access the platform.",
  "USR_CLOUD_VIDEO1": "USR Cloud Video course one",
  "USR_CLOUD_VIDEO2": "USR Cloud Video course two",
  "domainInfo": "No neutral information is available currently, click \"OK\" to go to Settings",
  "testAccountInfo": "You are currently in the test account, only have view permissions, more functions please register account to experience",
  "ANNOUNCEMENT_TITLE": "Announcements about system maintenance upgrades",
  "ANNOUNCEMENT_P_TITLE": "Respected user",
  'ANNOUNCEMENT_P_1':'    We will suspend the cloud platform service from 08:30 on May 7th, 2019 to 10:30 on May 7th, 2019, and we will be sorry for the inconvenience.',
  'ANNOUNCEMENT_P_2': '    The following is the V2.7.0 version update, so stay tuned',
  'ANNOUNCEMENT_P_3':'  1. Added data template import and export function;',
  'ANNOUNCEMENT_P_4':'  2. Fixed known bugs and optimized user experience;',
  'ANNOUNCEMENT_P_5':'  3. Optimized performance, greatly improving load capacity and device access;',
  'ANNOUNCEMENT_P_END':'May 06, 2019',
  "ANNOUNCEMENT_A_GO_NEW_ADDRESS": "Enter new version",
  "have_not_device_yet": "You have not added a device yet, please go ahead and add it",
  "User_Agreement": "<USR Cloud User Agreement>",
  "User_Agreement_address": "./static/htmls/protocol.html",
  "Neutral_Introduced": "USR Cloud Neutral Run",
  goToPlcnetForNavbar: "New cloud configuration",
  goToPlcnetForNotice_1: "The new cloud configuration goes online to replace the transparent cloud. In the future, the transparent cloud will only be maintained, ",
  goToPlcnetForNotice_2: "click to go to cloud configuration",
})
