<template>
  <div class="menu_nav flex align_center">
    <div v-if="getMenuItem.length" class="left-line">|</div>
    <template v-for="(item, index) in getMenuItem">
      <div
        :key="index"
        :class="['nav_item', { active: activeName == item.key }]"
        v-if="!item.hidden"
        @click="navChange(item)"
      >
        <span>{{ item.label }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      activeName: 0,
    }
  },
  computed: {
    ...mapGetters(['getMenuItem']),
  },
  watch: {
    $route: {
      handler: function (to, from) {
        if (to !== from) {
          this.getMenuItem.some((item) => {
            if (
              this.$route.path === item.route ||
              (item.includesRoute?.length && item.includesRoute.includes(to.path))
            ) {
              this.activeName = item.key
              return true // 终止循环
            }
            return false
          })
        }
      },
      deep: true,
      immediate: true,
    },
    getMenuItem: {
      handler(nVal) {
        if (nVal) {
          this.activeName = 0
          this.getMenuItem.some((item) => {
            if (
              this.$route.path === item.route ||
              (item.includesRoute?.length && item.includesRoute.includes(this.$route.path))
            ) {
              this.activeName = item.key
              return true // 终止循环
            }
            return false
          })
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    navChange(item) {
      if (this.activeName !== item.key) {
        // this.activeName = item.key
        const newRoute = {
          path: item.route,
          query: this.$route.query,
          params: this.$route.params,
        }
        this.$router.push(newRoute)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.main_view {
  width: 100%;
  height: 100%;
  padding: 32px 40px;
}
.menu_nav {
  flex: 1;
  height: 60px;
  margin-left: 24px;
  .left-line {
    color: #e5e6eb;
    margin-right: 8px;
  }
  .nav_item {
    padding: 0 16px;
    white-space: nowrap;
    font-size: 16px;
    color: #333333;
    height: 100%;
    line-height: 60px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    color: #333;
    &::after {
      content: '\20';
      position: absolute;
      width: 0;
      height: 4px;
      border-radius: 2px;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      background: #1a66ff;
    }
    &.active::after {
      content: '\20';
      width: 50%;
    }
  }
}
</style>
